// MUI Components
import { Button } from "@mui/material";

// Functions
import pxToRem from "assets/theme/functions/pxToRem";

// Constants
import { Colors } from "utils/Constants";

// 3rd party
import PropTypes from "prop-types";

export default function FilterButton({
  title,
  icon,
  handleClick,
  variant,
  color,
  mt = pxToRem(45),
  ml = pxToRem(0),
}) {
  return (
    <Button
      sx={{
        mr: 2,
        mt,
        ml,
        mb: 0,
        minWidth: 150,
        backgroundColor: Colors.WHITE,
        "&:hover": {
          backgroundColor: Colors.WHITE,
        },
        fontSize: pxToRem(14),
        textTransform: "none",
        alignSelf: "flex-end",
        color: { color },
      }}
      variant={variant}
      color="info"
      onClick={handleClick}
      startIcon={icon}
    >
      {title}
    </Button>
  );
}

FilterButton.defaultProps = {
  variant: "outlined",
  mt: pxToRem(45),
  ml: pxToRem(0),
  color: "info",
};

FilterButton.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  variant: PropTypes.string,
  color: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  mt: PropTypes.string,
  ml: PropTypes.string,
};
