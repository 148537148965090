import React, { useState } from "react";
import { Link } from "react-router-dom";

// 3rd party library
import PropTypes from "prop-types";

// MUI components
import { Icon, IconButton } from "@mui/material";

// Custom components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { navbarIconButton } from "examples/Navbars/DashboardNavbar/styles";

// Functions
import pxToRem from "assets/theme/functions/pxToRem";

// Constants
import { Colors } from "utils/Constants";

function ProfileAvatar({ profileUrl, name, redirecturl, width, height }) {
  const [imageLoaded, setImageLoaded] = useState(false);
  return (
    <MDBox component={redirecturl !== "" && Link} to={redirecturl !== "" && "/"}>
      <IconButton sx={navbarIconButton} size="small" disableRipple>
        {profileUrl !== null && profileUrl !== "" ? (
          <>
            {!imageLoaded && <Icon>account_circle</Icon>}
            <img
              src={profileUrl}
              alt="profile"
              width={width}
              height={height}
              style={{
                display: imageLoaded ? "inline-block" : "none",
                borderRadius: "50%",
              }}
              onLoad={() => setImageLoaded(true)}
            />
          </>
        ) : (
          <MDBox
            sx={{
              backgroundColor: Colors.PRIMARY,
              borderRadius: "50%",
              width: "30px",
              height: "30px",
            }}
          >
            <MDTypography
              sx={{
                marginTop: pxToRem(5),
                marginRight: pxToRem(3),
                color: Colors.WHITE,
                fontSize: pxToRem(16),
                fontWeight: "400",
                backgroundColor: Colors.PRIMARY,
                borderRadius: "50%",
              }}
            >
              {name.charAt(0)}
            </MDTypography>
          </MDBox>
        )}
      </IconButton>
    </MDBox>
  );
}

ProfileAvatar.propTypes = {
  profileUrl: PropTypes.string,
  name: PropTypes.string,
  redirecturl: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ProfileAvatar.defaultProps = {
  profileUrl: "",
  name: "",
  redirecturl: "",
  width: 30,
  height: 30,
};

export default ProfileAvatar;
