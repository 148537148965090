import Sessions from "utils/Sessions";
import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiService from "redux/ApiService/ApiService";

const data = {
  reportProgress: {
    reportCount: [
      {
        name: "Pending",
        count: 4,
      },
      {
        name: "Completed",
        count: 2,
      },
    ],
    totalReportCount: 6,
  },
  reportStatus: {
    reportStatusCount: [
      {
        name: "open",
        count: 2,
      },
      {
        name: "closed",
        count: 2,
      },
    ],
    totalReportStatusCount: 4,
  },
};

const getProjectProgressData = createAsyncThunk("projectProgress/api", async (params) => {
  const endPoint = `reports/project-progress-calculation?project=${params}`;
  const res = await ApiService.get(endPoint, {
    headers: { Authorization: `Bearer ${Sessions.userToken}` },
  });
  return res;
});

export const getReportProgressData = createAsyncThunk(
  "reportProgress/api",
  async () => data.reportProgress
);

export const getReportStatusData = createAsyncThunk(
  "reportStatus/api",
  async () => data.reportStatus
);

export const getShiftStatusData = createAsyncThunk("shiftStatus/api", async (params) => {
  const endPoint = `shifts/count?project=${params}`;
  const res = await ApiService.get(endPoint, {
    headers: { Authorization: `Bearer ${Sessions.userToken}` },
  });
  return res;
});

export const getFilterProjects = createAsyncThunk("filterProjects/api", async (params) => {
  const res = await ApiService.get(`projects?isActive=${params}`, {
    headers: { Authorization: `Bearer ${Sessions.userToken}` },
  });
  return res;
});

export const getFilterReports = createAsyncThunk("filterReports/api", async (params) => {
  const res = await ApiService.get(`reports?project=${params}`, {
    headers: { Authorization: `Bearer ${Sessions.userToken}` },
  });
  return res;
});

export default getProjectProgressData;
