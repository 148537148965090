import React from "react";

// material-ui
import { Autocomplete } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// utils
import Constants, { Icons } from "utils/Constants";

// 3rd party
import PropTypes, { object } from "prop-types";
import pxToRem from "assets/theme/functions/pxToRem";

function CustomAutoComeplete({
  label,
  id,
  name,
  hint,
  handleChange,
  menu,
  error,
  helperText,
  getOptionLabel,
  value,
}) {
  return (
    <>
      <MDTypography
        variant="caption"
        sx={{ fontSize: pxToRem(14), fontWeight: 500, color: "#344054" }}
      >
        {label}
      </MDTypography>
      <Autocomplete
        options={menu}
        getOptionLabel={getOptionLabel}
        name={name}
        id={id}
        variant="standard"
        value={value}
        sx={{
          "& .MuiAutocomplete-inputRoot": {
            padding: "4px",
          },
        }}
        popupIcon={Icons.ARROW_DOWN}
        onChange={(e, v) => handleChange({ target: { name, value: v[Constants.MONGOOSE_ID] } })}
        renderInput={(params) => (
          <MDInput
            {...params}
            name={name}
            onChange={(e) => handleChange(e)}
            placeholder={hint}
            error={Boolean(error)}
            helperText={helperText}
            FormHelperTextProps={{
              sx: { marginLeft: 0, color: "#FF2E2E" },
            }}
          />
        )}
      />
    </>
  );
}

CustomAutoComeplete.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  hint: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
  menu: PropTypes.arrayOf(PropTypes.object),
  error: PropTypes.bool,
  helperText: PropTypes.string,
  getOptionLabel: PropTypes.func.isRequired,
  value: PropTypes.string || PropTypes.objectOf(object),
};

CustomAutoComeplete.defaultProps = {
  handleChange: () => {},
  menu: [],
  error: false,
  helperText: "",
  value: "",
};

export default CustomAutoComeplete;
