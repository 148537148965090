import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Material Components
import { Box, Divider, Grid, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import styled from "@emotion/styled";
import MDTypography from "components/MDTypography";

// Custom Components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PageTitle from "examples/NewDesign/PageTitle";
import DataTable from "examples/Tables/DataTable/NestedTable";
import BasicButton from "examples/NewDesign/CustomButton/BasicButton";
import ReturnOrderDetailsData from "layouts/wmOrdersManagement/data/WmReturnOrderDetailsData";
import pxToRem from "assets/theme/functions/pxToRem";
import Status from "components/Table/Status";
import ConfirmationModal from "examples/modal/Confirmation/Confirmation";

// Redux component
import { useDispatch, useSelector } from "react-redux";
import { warehouseReturnOrderDetailsThunk, rejectReturnOrderThunk } from "redux/Thunks/Warehouse";
import { reloadWarehouse, resetReturnOrderDetail } from "redux/Slice/Warehouse";
import { openSnackbar } from "redux/Slice/Notification";

// 3rd Party lib
import { Feature } from "flagged";

// Utils
import Constants, { Icons, PageTitles, FeatureTags, defaultData, Colors } from "utils/Constants";
import { paramCreater } from "utils/methods/methods";

// 3rd party libraries
import moment from "moment";
import FTextField from "components/Form/FTextField";

const StyledMDBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  gap: theme.spacing(3),
  marginTop: theme.spacing(3),

  borderRadius: theme.spacing(1),
  border: "1px solid #E0E6F5",
  background: "var(--base-white, #FFF)",

  /* Shadow/sm */
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)",

  padding: theme.spacing(`${pxToRem(30)} ${pxToRem(30)}`), // Default padding for all breakpoints
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(`${pxToRem(30)} ${pxToRem(30)}`), // Adjust padding for small screens and below
    // flex direction row
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(`${pxToRem(30)} ${pxToRem(20)}`), // Adjust padding for small screens and below
    // flex direction column
    flexDirection: "column",
  },
  [theme.breakpoints.down("xs")]: {
    padding: theme.spacing(`${pxToRem(30)} ${pxToRem(10)}`), // Adjust padding for extra-small screens
    // flex direction column
    flexDirection: "column",
  },
}));

const intialReject = {
  open: false,
  remark: "",
  equipmentId: "",
  returnOrderId: "",
};

function WarehouseReturnOrderDetails() {
  const [rejectEquipment, setRejectEquipment] = useState(intialReject);
  const [tablePagination, setTablePagination] = useState({
    page: 0,
    perPage: defaultData.DATE_ON_SINGLE_API_CALL,
  });
  const [next, setNext] = useState(0);
  const { id } = useParams();
  const returnOrder = useSelector((state) => state.Warehouse);
  const ConfigData = useSelector((state) => state.config);
  const permission = ConfigData?.screens?.[9]?.screensInfo?.agreement;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentDate = new Date();

  const handleRejectClose = () => setRejectEquipment(intialReject);

  const handleFilter = async () => {
    setTablePagination({ ...tablePagination, page: 0 });
    setNext(0);
    const paramData = {
      page: 0,
      perPage: tablePagination.perPage,
    };

    await dispatch(warehouseReturnOrderDetailsThunk({ id, param: paramCreater(paramData) }));
  };

  useEffect(() => {
    (async () => {
      handleFilter();
    })();

    return () => {
      dispatch(resetReturnOrderDetail());
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (
        returnOrder.orderDetailsLoading === Constants.NO_DATA ||
        returnOrder.orderDetailsLoading === Constants.REJECTED
      ) {
        dispatch(
          openSnackbar({
            message: Constants.REDIRECTING_TO_PARENT,
            notificationType: Constants.NOTIFICATION_ERROR,
          })
        );
        navigate("/client/orders");
      }
    })();
  }, [returnOrder.returnOrderDetailsLoading]);

  const handleReject = async () => {
    const paramData = {
      returnOrder: rejectEquipment.returnOrderId,
      equipment: rejectEquipment.equipmentId,
    };
    const data = {
      param: paramCreater(paramData),
      body: {
        comment: [
          {
            user: ConfigData.config[0]?.id,
            time: moment(currentDate).format(defaultData.DATABASE_24_HOURS_FORMAT),
            status: returnOrder.returnOrderDetails?.status,
            comment: rejectEquipment.remark,
          },
        ],
      },
    };
    const res = await dispatch(rejectReturnOrderThunk(data));
    if (res.payload.status === 200) {
      handleFilter();
      setRejectEquipment({ ...rejectEquipment, open: false });
      dispatch(
        openSnackbar({
          message: Constants.RETURN_EQUIPEMENT_REJECTED_SUCCESSFULLY,
          notificationType: Constants.NOTIFICATION_SUCCESS,
        })
      );
      handleRejectClose();
    } else if (res.payload.status === 400) {
      dispatch(
        openSnackbar({
          message: Constants.RETURN_EQUIPEMENT_REJECTED_FAILED,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
  };

  const handleTablePagination = async () => {
    const paramData = {
      page: next + 1,
      perPage: tablePagination.perPage,
    };
    const res = await dispatch(
      warehouseReturnOrderDetailsThunk({ id, param: paramCreater(paramData) })
    );
    if (res.payload.status === 200) setNext(res.payload.data.length > 0 ? next + 1 : next);
  };

  const handleReload = async () => {
    await dispatch(reloadWarehouse());
    handleFilter();
  };

  const orderinfo = (title, value = "", subValue = "") => (
    <MDBox display="flex" flexDirection="column" alignItems="start" justifyContent="space-between">
      <MDTypography
        mb={pxToRem(8)}
        sx={{
          color: "var(--gray-600, #475467)",
          fontFamily: "Inter",
          fontSize: pxToRem(14),
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: pxToRem(16),
        }}
      >
        {title}
      </MDTypography>
      {title === "Status" ? (
        <Status title={value} />
      ) : (
        <MDTypography
          fontWeight="medium"
          sx={{
            mb: 1,
            color: Colors.PRIMARY,
            fontFamily: "Inter",
            fontSize: pxToRem(16),
            fontStyle: "normal",
            lineHeight: pxToRem(16),
            textTransform: "capitalize",
          }}
        >
          {value}
        </MDTypography>
      )}
      <Tooltip title={subValue}>
        <MDTypography
          variant="caption"
          sx={{
            textTransform: "capitalize",
            whiteSpace: "normal",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "100%",
          }}
        >
          {subValue}
        </MDTypography>
      </Tooltip>
    </MDBox>
  );

  const handleRejectEquipmentData = (returnOrderId, equipmentId) =>
    setRejectEquipment({ ...rejectEquipment, open: true, returnOrderId, equipmentId });

  const { columns, rows } = ReturnOrderDetailsData({
    returnOrderDetails: returnOrder.returnOrderDetails,
    returnOrderDetailsLoading: returnOrder.returnOrderDetailsLoading,
    handleRejectEquipmentData,
  });

  const rentalDateUI = () => (
    <Grid item xs={12} sm={2}>
      <MDBox
        display="flex"
        flexDirection="column"
        alignItems="start"
        justifyContent="space-between"
        px={2}
      >
        <MDTypography
          sx={{
            mb: 1,
            color: Colors.PRIMARY,
            fontFamily: "Inter",
            fontSize: pxToRem(16),
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: pxToRem(16),
            textTransform: "capitalize",
            width: "max-content",
          }}
        >
          <MDBox display="flex" flexDirection="column" width={pxToRem(130)}>
            <MDBox display="flex" flexDirection="column" justifyContent="space-between">
              <MDTypography variant="caption" fontWeight="regular">
                Return Date
              </MDTypography>
              <MDTypography fontWeight="medium" fontSize={pxToRem(16)}>
                {moment(returnOrder.returnOrderDetails?.createdAt).format(
                  defaultData.WEB_DATE_FORMAT
                )}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDTypography>
      </MDBox>
    </Grid>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Feature name={FeatureTags.WAREHOUSE}>
        <MDBox display="flex" justifyContent="space-between">
          <PageTitle
            title={`${PageTitles.ORDER_DETAIL}${returnOrder.returnOrderDetails?.order || ""}`}
          />
          <MDBox display="flex" justifyContent="center" alignItems="start" sx={{ mr: 1 }}>
            <BasicButton
              title="Print"
              icon={Icons.PRINT}
              background={Colors.WHITE}
              border
              color={Colors.BLACK}
              disabled
              tooltipLabel={Constants.COMMING_SOON}
            />
            <BasicButton
              title="Export"
              icon={Icons.EXPORT}
              background={Colors.WHITE}
              border
              color={Colors.BLACK}
              disabled
              tooltipLabel={Constants.COMMING_SOON}
            />
            <BasicButton
              icon={Icons.RELOAD}
              background={Colors.WHITE}
              border
              color={Colors.BLACK}
              action={handleReload}
            />
          </MDBox>
        </MDBox>

        <Divider sx={{ marginTop: 2 }} />

        {/* Return Order detail card */}
        <StyledMDBox>
          {permission && returnOrder.returnOrderDetailsLoading === Constants.FULFILLED ? (
            <MDBox width="100%">
              <MDBox
                width="100%"
                display="flex"
                flexDirection={{ md: "row", sm: "column" }}
                justifyContent={{ md: "space-between", sm: "column" }}
                alignItems={{ lg: "flex-start", sm: "flex-start" }}
                flexWrap="wrap"
              >
                <MDBox
                  width={{ md: "15%", sm: "100%" }}
                  height={pxToRem(100)}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <MDTypography
                    variant="h4"
                    fontWeight="medium"
                    mb={3}
                    width={{ xs: "100%", sm: "auto" }}
                    textAlign={{ xs: "center", sm: "left" }}
                    textTransform="capitalize"
                  >
                    {returnOrder.returnOrderDetails?.project}
                  </MDTypography>
                </MDBox>
                <MDBox>
                  <MDBox mb={1} display="flex" flexDirection="column" alignItems="start">
                    <MDBox display="flex" flexDirection="row" alignItems="center">
                      {returnOrder.returnOrderDetails?.returnBy?.profileImage !== null &&
                      returnOrder.returnOrderDetails?.returnBy?.profileImage !== "" ? (
                        <img
                          src={returnOrder.returnOrderDetails?.returnBy?.profileImage}
                          alt={returnOrder.returnOrderDetails?.returnBy?.name}
                          style={{
                            width: pxToRem(104),
                            height: pxToRem(104),
                            borderRadius: pxToRem(4),
                          }}
                        />
                      ) : (
                        <MDBox
                          sx={{
                            backgroundColor: Colors.PRIMARY,
                            borderRadius: pxToRem(4),
                            width: pxToRem(104),
                            height: pxToRem(104),
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <MDTypography
                            sx={{
                              marginTop: pxToRem(5),
                              marginRight: pxToRem(3),
                              color: Colors.WHITE,
                              fontSize: pxToRem(50),
                              fontWeight: "bold",
                              backgroundColor: Colors.PRIMARY,
                            }}
                          >
                            {returnOrder.returnOrderDetails?.returnBy?.name.charAt(0)}
                          </MDTypography>
                        </MDBox>
                      )}
                      <MDBox ml={2} display="flex" flexDirection="column" alignItems="flex-start">
                        <MDTypography
                          mb={pxToRem(8)}
                          variant="caption"
                          fontWeight="regular"
                          fontSize={pxToRem(14)}
                          width={{ xs: "100%", sm: "auto" }}
                          textAlign={{ xs: "center", sm: "left" }}
                          textTransform="capitalize"
                        >
                          Requested By
                        </MDTypography>
                        <MDTypography
                          fontWeight="medium"
                          width={{ xs: "100%", sm: "auto" }}
                          textAlign={{ xs: "center", sm: "left" }}
                          textTransform="capitalize"
                          fontSize={pxToRem(16)}
                        >
                          {returnOrder.returnOrderDetails?.returnBy?.name}
                        </MDTypography>
                        <MDTypography
                          variant="caption"
                          fontWeight="regular"
                          width={{ xs: "100%", sm: "auto" }}
                          marginTop={2}
                        >
                          On{" "}
                          {moment(returnOrder.returnOrderDetails?.createdAt).format(
                            defaultData.WEB_DATE_FORMAT
                          )}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
                <MDBox>
                  {orderinfo(
                    "Requested Items",
                    returnOrder.returnOrderDetails?.equipment.length || 0
                  )}
                </MDBox>
                <MDBox>
                  {orderinfo(
                    "Status",
                    `${returnOrder.returnOrderDetails?.status.replace("-", " ")}`
                  )}
                </MDBox>
                {rentalDateUI()}
                {returnOrder.returnOrderDetails?.pmRemark &&
                  returnOrder.returnOrderDetails?.pmRemark[0]?.comment?.length > 0 && (
                    <Grid item xs={12} md={2}>
                      {orderinfo(
                        "Remark",
                        returnOrder.returnOrderDetails?.pmRemark[0]?.comment,
                        ""
                      )}
                    </Grid>
                  )}
              </MDBox>
            </MDBox>
          ) : (
            <MDBox
              py={5}
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              {Icons.LOADING2}
            </MDBox>
          )}
        </StyledMDBox>

        {/* Order Table */}
        <MDBox mt={3} mb={3}>
          <DataTable
            table={{ columns, rows }}
            isSorted={false}
            entriesPerPage={{ defaultValue: defaultData.PER_PAGE }}
            showTotalEntries={false}
            noEndBorder
            loading={(permission && returnOrder.returnOrderDetailsLoading) || Constants.PENDING}
            currentPage={tablePagination.page}
            handleTablePagination={handleTablePagination}
            handleCurrentPage={(page) => setTablePagination({ ...tablePagination, page })}
          />
        </MDBox>

        {/* Single Reject confirmation modal */}
        {rejectEquipment.open && (
          <ConfirmationModal
            open={rejectEquipment.open}
            handleClose={handleRejectClose}
            title="Reject Equipment?"
            handleAction={handleReject}
          >
            <FTextField
              label="Remark"
              name="remark"
              id="remark"
              placeholder="Enter your remark"
              type="text"
              value={rejectEquipment.remark}
              handleChange={(e) =>
                setRejectEquipment({ ...rejectEquipment, remark: e.target.value })
              }
              width="100%"
              marginBottom={pxToRem(10)}
            />
          </ConfirmationModal>
        )}
      </Feature>
    </DashboardLayout>
  );
}

export default WarehouseReturnOrderDetails;
