/* eslint-disable react/function-component-definition */

// MUI components
import { Grid, Icon, Modal } from "@mui/material";

// Custom components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Styles
import style from "assets/style/Modal";

// Functions
import pxToRem from "assets/theme/functions/pxToRem";

// Constants
import { Icons } from "utils/Constants";

// 3rd party library
import PropTypes from "prop-types";

function BasicModal2({
  open,
  handleClose,
  title,
  children,
  actionButton,
  handleAction,
  disabled,
  minWidth = "50%",
}) {
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <MDBox sx={{ ...style, minWidth }}>
        <MDBox
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          borderRadius="lg"
          sx={{
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
            height: pxToRem(72),
            padding: "16px 16px 8px 16px",
            marginBottom: "8px",
          }}
        >
          <MDTypography sx={{ fontSize: pxToRem(20), color: "#191D31", fontWeight: "700" }}>
            {title}
          </MDTypography>
          <Icon sx={{ cursor: "pointer" }} fontSize="medium" onClick={handleClose}>
            {Icons.CLOSE}
          </Icon>
        </MDBox>
        <MDBox
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          px={3}
          sx={{
            maxHeight: 500,
            overflowY: "scroll",
            "::-webkit-scrollbar": { display: "none" },
            scrollbarWidth: "none",
          }}
        >
          {children}
        </MDBox>
        <MDBox px={2} mb={2} mr={1} mt={2}>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Grid item xs={0}>
              <MDButton
                variant="contained"
                disabled={disabled}
                color="info"
                onClick={handleAction}
                style={{ textTransform: "none", boxShadow: "none" }}
              >
                {actionButton}
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Modal>
  );
}

BasicModal2.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  actionButton: PropTypes.string.isRequired,
  handleAction: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  minWidth: PropTypes.string,
};

BasicModal2.defaultProps = {
  children: null,
  disabled: false,
  minWidth: "50%",
};
export default BasicModal2;
