import Sessions from "utils/Sessions";
import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiService from "redux/ApiService/ApiService";

const getAllCertificateType = createAsyncThunk("/get-certificates", async (param) => {
  const res = await ApiService.get(`/certificate-type?${param}`, {
    headers: { Authorization: `Bearer ${Sessions.userToken}` },
  })
    .then((r) => r)
    .catch((err) => err.response);
  const params = new URLSearchParams(param);
  const page = params.get("page");
  return page === "0"
    ? { data: res.data, type: "add", status: res.status }
    : { data: res.data, type: "append", status: res.status };
});

export const createCertificateQuestion = createAsyncThunk("/create-certificate", async (body) => {
  const res = await ApiService.post(
    `/certificate-type`,
    { ...body },
    {
      headers: { Authorization: `Bearer ${Sessions.userToken}` },
    }
  )
    .then((r) => r)
    .catch((err) => err.response);
  return res;
});

export const updateCertificateQuestion = createAsyncThunk("/update-certificate", async (data) => {
  const { body, editDeleteId } = data;
  const res = await ApiService.patch(
    `/certificate-type/${editDeleteId}`,
    {
      ...body,
    },
    {
      headers: { Authorization: `Bearer ${Sessions.userToken}` },
    }
  )
    .then((r) => r)
    .catch((err) => err.response);
  return res;
});

export const deleteCertificateQuestion = createAsyncThunk("/delete-certificate", async (id) => {
  const res = await ApiService.delete(`/certificate-type/${id}`, {
    headers: { Authorization: `Bearer ${Sessions.userToken}` },
  })
    .then((r) => r)
    .catch((err) => err.response);
  return res;
});
export default getAllCertificateType;
