//  feature tags
const FeatureTags = {
  SHIFT_DETAILS: "shiftDetails",
  SETUP_REPORT: "setupReport",
  ROLE_MANAGEMENT: "roleManagement",
  SETUP_EQUIPMENT: "setupEquipment",
  WAREHOUSE: "warehouse",
  NOTIFICATION: "notification",
  CERTIFICATE: "certificate",
  TRAININGMATRIX: "trainingMatrix",
  PROJECT_EQUIPMENT: "projectEquipment",
  PROJECT_TRACKER: "projectTracker",
  TOOLBOX_TALK: "toolboxTalk",
};

export default FeatureTags;
