import Sessions from "utils/Sessions";
import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiService from "redux/ApiService/ApiService";

const getTrainingMatrixData = createAsyncThunk("/get-certificates", async (param) => {
  const res = await ApiService.get(`/projects/project-matrix?${param}`, {
    headers: { Authorization: `Bearer ${Sessions.userToken}` },
  })
    .then((r) => r)
    .catch((err) => err.response);
  const params = new URLSearchParams(param);
  const page = params.get("page");
  return page === "0"
    ? { data: res.data, type: "add", status: res.status }
    : { data: res.data, type: "append", status: res.status };
});

export const exportTrainingMatrixCertificate = createAsyncThunk(
  "/get-certificates",
  async (param) => {
    const res = await ApiService.get(`/projects/project-matrix?${param}`, {
      headers: { Authorization: `Bearer ${Sessions.userToken}` },
      responseType: "blob",
    })
      .then((r) => r)
      .catch((err) => err.response);
    const params = new URLSearchParams(param);
    const page = params.get("page");
    return page === "0"
      ? { data: res.data, status: res.status, fileName: res.headers["content-disposition"] }
      : { data: res.data, status: res.status, fileName: res.headers["content-disposition"] };
  }
);
export default getTrainingMatrixData;
