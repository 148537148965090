import { createAsyncThunk } from "@reduxjs/toolkit";
import Sessions from "utils/Sessions";
import ApiService from "../ApiService/ApiService";

const createWarehouseThunk = createAsyncThunk("warehouses/create", async (body) => {
  const res = await ApiService.post(
    `warehouses`,
    { ...body },
    {
      headers: { Authorization: `Bearer ${Sessions.userToken}` },
    }
  )
    .then((r) => r)
    .catch((err) => err.response);
  return res;
});

export const warehouseListThunk = createAsyncThunk("warehouselist/api", async (param) => {
  const res = await ApiService.get(`warehouses${param ? `?${param}` : ""}`, {
    headers: { Authorization: `Bearer ${Sessions.userToken}` },
  })
    .then((r) => r)
    .catch((err) => err.response);
  const params = new URLSearchParams(param);
  const page = params.get("page");
  return page === "0"
    ? { data: res.data, type: "add", status: res.status }
    : { data: res.data, type: "append", status: res.status };
});

export const warehouseByIdThunk = createAsyncThunk("warehouses-by-id/api", async (id) => {
  const res = await ApiService.get(`warehouses/${id}`, {
    headers: { Authorization: `Bearer ${Sessions.userToken}` },
  })
    .then((r) => r)
    .catch((err) => err.response);
  return res;
});

export const productByWarehouseIdThunk = createAsyncThunk(
  "warehouses/product-by-warehouse-id/api",
  async (data) => {
    const res = await ApiService.get(
      `warehouses/${data?.id}/equipment${data?.params ? `?${data.params}` : ""}`,
      {
        headers: { Authorization: `Bearer ${Sessions.userToken}` },
      }
    )
      .then((r) => r)
      .catch((err) => err.response);
    const params = new URLSearchParams(data?.params);
    const page = params.get("page");
    if (res.status === 200)
      return page === "0"
        ? { data: res.data, type: "add", status: res.status }
        : { data: res.data, type: "append", status: res.status };
    throw res;
  }
);

export const warehouseUpdateThunk = createAsyncThunk("warehouses/update", async (body) => {
  const res = await ApiService.patch(
    `warehouses/${body.id}`,
    { ...body.body },
    {
      headers: { Authorization: `Bearer ${Sessions.userToken}` },
    }
  )
    .then((r) => r)
    .catch((err) => err.response);
  return res;
});

export const warehouseDeleteThunk = createAsyncThunk("warehouses/delete", async (id) => {
  const res = await ApiService.delete(`warehouses/${id}`, {
    headers: { Authorization: `Bearer ${Sessions.userToken}` },
  })
    .then((r) => r)
    .catch((err) => err.response);
  return res;
});

export const warehouseOrderListThunk = createAsyncThunk("warehouses/order-list", async (param) => {
  const res = await ApiService.get(`wm-order${param && `?${param}`}`, {
    headers: { Authorization: `Bearer ${Sessions.userToken}` },
  })
    .then((r) => r)
    .catch((err) => err.response);
  const params = new URLSearchParams(param);
  const page = params.get("page");
  return page === "0"
    ? { data: res.data, type: "add", status: res.status }
    : { data: res.data, type: "append", status: res.status };
});

export const warehouseReturnOrderListThunk = createAsyncThunk(
  "warehouses/return-order-list",
  async (param) => {
    const queryString = param ? `?${param}` : "";
    const res = await ApiService.get(`wm-order/return-order${queryString}`, {
      headers: { Authorization: `Bearer ${Sessions.userToken}` },
    })
      .then((r) => r)
      .catch((err) => err.response);
    const params = new URLSearchParams(param);
    const page = params.get("page");
    return page === "0"
      ? { data: res.data, type: "add", status: res.status }
      : { data: res.data, type: "append", status: res.status };
  }
);

export const warehouseOrderDetailsThunk = createAsyncThunk(
  "warehouses/order-details",
  async (data) => {
    const res = await ApiService.get(`wm-order/${data.id}`, {
      headers: { Authorization: `Bearer ${Sessions.userToken}` },
    })
      .then((r) => r)
      .catch((err) => {
        throw err.response;
      });
    return res;
  }
);

export const warehouseReturnOrderDetailsThunk = createAsyncThunk(
  "warehouses/return-order-details",
  async (data) => {
    const res = await ApiService.get(`wm-order/return/${data.id}`, {
      headers: { Authorization: `Bearer ${Sessions.userToken}` },
    })
      .then((r) => r)
      .catch((err) => {
        throw err.response;
      });
    return res;
  }
);

export const warehouseBUlkApproveRejectOrderEquipmentThunk = createAsyncThunk(
  "warehouses/bulk-approve-reject",
  async (data) => {
    const res = await ApiService.patch(
      `/wm-order/approve-reject/${data.id}`,
      { ...data.body },
      {
        headers: { Authorization: `Bearer ${Sessions.userToken}` },
      }
    )
      .then((r) => r)
      .catch((err) => err.response);
    return res;
  }
);

export const updateCommentThunk = createAsyncThunk("update-comments", async (data) => {
  const res = await ApiService.patch(
    `pm-order/order-details/${data.id}`,
    { ...data.body },
    {
      headers: { Authorization: `Bearer ${Sessions.userToken}` },
    }
  )
    .then((r) => r)
    .catch((err) => err.response);
  return res;
});

export const inTransistOrderThunk = createAsyncThunk("in-transist-order", async (data) => {
  const res = await ApiService.patch(
    `wm-order/update-order-status/${data.id}`,
    { ...data.body },
    {
      headers: { Authorization: `Bearer ${Sessions.userToken}` },
    }
  )
    .then((r) => r)
    .catch((err) => err.response);
  return res;
});

export const rejectSingleEqiuipmentTypeRequestThunk = createAsyncThunk(
  "reject-single-equipment",
  async (data) => {
    const res = await ApiService.patch(
      `wm-order/reject/${data.id}`,
      { ...data.body },
      {
        headers: { Authorization: `Bearer ${Sessions.userToken}` },
      }
    )
      .then((r) => r)
      .catch((err) => err.response);
    return res;
  }
);

export const rejectPartialOrderThunk = createAsyncThunk("reject-partial-order", async (data) => {
  const res = await ApiService.patch(
    `wm-order/reject-partial-order/${data.id}`,
    { ...data.body },
    {
      headers: { Authorization: `Bearer ${Sessions.userToken}` },
    }
  )
    .then((r) => r)
    .catch((err) => err.response);
  return res;
});

export const rejectReturnOrderThunk = createAsyncThunk("reject-return-order", async (data) => {
  const res = await ApiService.patch(
    `return-order/reject-equipment?${data.param}`,
    { ...data.body },
    {
      headers: { Authorization: `Bearer ${Sessions.userToken}` },
    }
  )
    .then((r) => r)
    .catch((err) => err.response);
  return res;
});

export default createWarehouseThunk;
