import React, { useState } from "react";

// Material Dashboard 2 React components
import { Box, Chip, Grid, Icon, Tooltip } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

// Components
import pxToRem from "assets/theme/functions/pxToRem";
import DateTime from "components/DateTime/DateTime";
import ImageUpload from "components/ImageUpload/imageUpload";
import Pdf from "assets/images/pdf.svg";
import FullScreenImageComponent from "components/ViewFullImage/ViewImage";

// utils
import Constants, { defaultData, Icons } from "utils/Constants";

// libraries
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import MDButton from "components/MDButton";
import AddIcon from "@mui/icons-material/Add";
import PropTypes from "prop-types";

export default function ProductDocument({
  data,
  setData,
  setUpdatedBody,
  updatedBody,
  documentForm,
}) {
  const [fullScreenImage, setFullScreenImage] = useState(null);
  const [selectedCertificate, setSelectedCertificate] = useState({});
  const handleImageChange = (name, imageValues) => {
    const imageInfoArray = imageValues.map((item) => ({
      url: item.url,
      size: item.size,
      name: item.name,
    }));

    setData((prevData) => ({
      ...prevData,
      [name]: imageInfoArray,
    }));
    setUpdatedBody((prevData) => ({
      ...prevData,
      [name]: imageInfoArray,
    }));
  };

  const handleImageCancel = (fieldName, updatedImageUrl) => {
    const newImageUrlArray = updatedImageUrl || data[fieldName];

    const filteredImageUrlArray = newImageUrlArray.filter((img) => img.url !== "Frame");

    setData((prevData) => ({
      ...prevData,
      [fieldName]: filteredImageUrlArray,
    }));
    setUpdatedBody((prevData) => ({
      ...prevData,
      [fieldName]: filteredImageUrlArray,
    }));
  };

  const handleChipCertificateType = (certificateTypeId, title, dateRequired) => {
    const temp = {
      title,
      certificateTypeId,
      image: [],
      startDate: "",
      endDate: "",
      isValidityDate: dateRequired,
    };
    setSelectedCertificate(temp);
  };

  const handleRemoveImage = (event, imageUrl) => {
    const tempUpdatedBody = { ...updatedBody };
    const newImageUrlArray = tempUpdatedBody.certificateType.filter((img) => img.url !== imageUrl);
    tempUpdatedBody.certificateType = newImageUrlArray;
    setUpdatedBody(tempUpdatedBody);
    event.stopPropagation();
  };

  const handleImageFullView = (imagePreview) => {
    setFullScreenImage(imagePreview);
  };
  const handleCloseFullView = () => {
    setFullScreenImage(null);
  };

  const renderPreview = () => (
    <Grid container spacing={2}>
      {updatedBody?.certificateType?.length > 0 &&
        updatedBody.certificateType
          .filter((val) => val.name)
          ?.map((cer) => (
            <Grid item xs={12} lg={6}>
              <MDTypography
                variant="caption"
                sx={{ fontSize: pxToRem(14), fontWeight: 600, marginLeft: 2 }}
              >
                {cer && cer.title?.length > defaultData.SMALLER_CONTENT_LENGTH ? (
                  <Tooltip title={cer.title}>
                    <span>{`${cer.title.slice(0, defaultData.SMALLER_CONTENT_LENGTH)}...`}</span>
                  </Tooltip>
                ) : (
                  cer?.title
                )}
              </MDTypography>
              <MDBox
                display="flex"
                flexWrap="wrap"
                border="1px solid #E0E6F5"
                borderRadius="8px"
                p={1}
                ml={2}
                position="relative"
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => handleImageFullView(cer.url)}
              >
                <Box sx={{ position: "relative" }}>
                  <img
                    src={cer && cer?.url?.includes(".pdf") ? Pdf : cer?.url}
                    alt="Preview"
                    height="60px"
                    width="60px"
                    style={{
                      border: "1px solid #D0D5DD",
                      borderRadius: "8px",
                      marginTop: "5px",
                      marginLeft: "4px",
                    }}
                  />
                </Box>

                <MDBox ml={2} mt={0}>
                  <MDTypography
                    display="block"
                    variant="caption"
                    sx={{ textTransform: "capitalize" }}
                  >
                    {cer?.name && cer?.name?.length > 15 ? (
                      <Tooltip title={cer?.name}>
                        <span>{`${cer?.name.slice(0, 15)}...`}</span>
                      </Tooltip>
                    ) : (
                      cer?.name
                    )}
                  </MDTypography>
                  {cer?.startDate !== "" && cer?.startDate !== null && (
                    <MDTypography display="block" variant="caption" color="text">
                      {`Start Date: ${moment(cer?.startDate).format(defaultData.WEB_DATE_FORMAT)}`}
                    </MDTypography>
                  )}
                  {cer?.endDate !== "" && cer?.endDate !== null && (
                    <MDTypography display="block" variant="caption" color="text">
                      {`End Date: ${moment(cer?.endDate).format(defaultData.WEB_DATE_FORMAT)}`}
                    </MDTypography>
                  )}
                  <MDTypography display="block" variant="caption" color="text">
                    {`${cer?.size} KB`}
                  </MDTypography>
                </MDBox>

                {/* Cancel icon positioned at the top-right corner */}
                <MDBox position="absolute" top={-10} right={-10} zIndex={1}>
                  <Icon
                    sx={{ color: "white", width: 30, height: 30, cursor: "pointer", zIndex: 1 }}
                    onClick={(event) => {
                      handleRemoveImage(event, cer?.url);
                    }}
                  >
                    {Icons.CROSS2}
                  </Icon>
                </MDBox>
              </MDBox>
            </Grid>
          ))}
    </Grid>
  );

  const handleCertificateChange = (name, value) => {
    const temp = { ...selectedCertificate };
    temp[name] = value;
    if (
      name === "startDate" &&
      temp.endDate !== "" &&
      moment(temp.endDate).isBefore(moment(value))
    ) {
      temp.endDate = "";
    }
    setSelectedCertificate(temp);
  };

  const handleCertificateImageChange = (imageValues) => {
    const imageInfoArray = imageValues.map((item) => ({
      url: item.url,
      size: item.size,
      name: item.name,
    }));
    const temp = { ...selectedCertificate };
    temp.image = imageInfoArray;
    setSelectedCertificate(temp);
  };

  const certificateTypeValidation = () => {
    const temp = { ...selectedCertificate };
    const errors = {};

    if (temp.image.length === 0) {
      errors.image = "Please upload certificate image";
    }
    if (temp.isValidityDate && temp.startDate === "") {
      errors.startDate = "Please select start date";
    }
    if (temp.isValidityDate && temp.endDate === "") {
      errors.endDate = "Please select end date";
    }
    if (Object.keys(errors).length > 0) {
      temp.errors = errors;
      setSelectedCertificate(temp);
      return false;
    }
    return true;
  };

  const uploadImageCertificateType = async () => {
    const certificate = { ...selectedCertificate };
    const isValid = certificateTypeValidation();
    if (!isValid) return;
    const imageInfoArray = certificate.image.map((item) => ({
      title: certificate.title,
      url: item.url,
      size: item.size,
      name: item.name,
      certificateTypeId: certificate.certificateTypeId,
      startDate: certificate.startDate,
      endDate: certificate.endDate,
    }));

    const tempUpdatedBody = { ...updatedBody };
    tempUpdatedBody.certificateType = [
      ...(tempUpdatedBody.certificateType || []),
      ...imageInfoArray,
    ];
    setUpdatedBody(tempUpdatedBody);
    setSelectedCertificate({});
  };
  const handleImageCancels = (fieldName, imageUrl) => {
    const newImageUrlArray = imageUrl || selectedCertificate[fieldName];
    const filteredImageUrlArray = newImageUrlArray.filter((img) => img.url !== "Frame");
    setSelectedCertificate((prevData) => ({
      ...prevData,
      [fieldName]: filteredImageUrlArray,
    }));
  };

  const handleCertificate = () => {
    if (Object.keys(selectedCertificate).length === 0) return null;
    return (
      <MDBox display="flex" flexDirection="column" width="100%" marginX={2}>
        <Grid container spacing={2}>
          <Grid item lg={6} sm={12} key="chooseImage">
            <ImageUpload
              label={
                selectedCertificate &&
                selectedCertificate?.title?.length > defaultData.SMALLER_CONTENT_LENGTH ? (
                  <Tooltip title={selectedCertificate?.title}>
                    <span>{`${selectedCertificate?.title?.slice(
                      0,
                      defaultData.SMALLER_CONTENT_LENGTH
                    )}...`}</span>
                  </Tooltip>
                ) : (
                  selectedCertificate?.title
                )
              }
              name={selectedCertificate?.title}
              onImageUpload={(imageValues) => handleCertificateImageChange(imageValues)}
              onImageCancel={(updatedImageUrl) => handleImageCancels("image", updatedImageUrl)}
              type="registerEquipment"
              formats={["image/jpeg", "image/jpg", "image/png", "application/pdf"]}
              maxImageCount={3}
              imageTypeError={Constants.EQUIPMENT_FILE_TYPE_NOT_ALLOWED}
              direction="column"
              error={Boolean(selectedCertificate.errors?.image)}
              helperText={selectedCertificate.errors?.image}
              resetComponent={selectedCertificate?.image?.length === 0}
            />
          </Grid>
          {selectedCertificate?.isValidityDate && (
            <Grid item lg={6} sm={12} key="startDate">
              <ReactDatePicker
                selected={
                  (selectedCertificate.startDate &&
                    moment(selectedCertificate.startDate.split(".")[0]).toDate()) ||
                  null
                }
                onChange={(date) =>
                  handleCertificateChange(
                    "startDate",
                    moment(date).format(defaultData.DATABSE_DATE_FORMAT).toString()
                  )
                }
                customInput={
                  <DateTime
                    value={selectedCertificate.startDate}
                    item={{ hint: "Start Date" }}
                    label="Start Date"
                    errors={selectedCertificate.errors?.startDate}
                    helperText={selectedCertificate.errors?.startDate}
                    placeholder="mm/dd/yyyy"
                  />
                }
                placeholderText="mm/dd/yyyy"
                error={Boolean(selectedCertificate.errors?.startDate)}
                helperText={selectedCertificate.errors?.startDate}
                dateFormat={defaultData.REACTDATETIMEPICKER_DATE_FORMAT}
              />
            </Grid>
          )}
          {selectedCertificate?.isValidityDate && (
            <Grid item lg={6} sm={12} key="endDate">
              <ReactDatePicker
                selected={
                  (selectedCertificate.endDate &&
                    moment(selectedCertificate.endDate.split(".")[0]).toDate()) ||
                  null
                }
                onChange={(date) =>
                  handleCertificateChange(
                    "endDate",
                    moment(date).format(defaultData.DATABSE_DATE_FORMAT).toString()
                  )
                }
                customInput={
                  <DateTime
                    value={selectedCertificate.endDate}
                    item={{ hint: "End Date" }}
                    label="End Date"
                    errors={selectedCertificate.errors?.endDate}
                    helperText={selectedCertificate.errors?.endDate}
                    placeholder="mm/dd/yyyy"
                  />
                }
                placeholderText="mm/dd/yyyy"
                dateFormat={defaultData.REACTDATETIMEPICKER_DATE_FORMAT}
                error={Boolean(selectedCertificate.errors?.endDate)}
                helperText={selectedCertificate.errors?.endDate}
                minDate={moment(selectedCertificate?.startDate.split(".")[0]).toDate()}
              />
            </Grid>
          )}
          <Grid item lg={6} sm={12} key="uploadBtn" marginTop={2}>
            <MDButton
              variant="contained"
              color="info"
              style={{
                textTransform: "none",
                boxShadow: "none",
                marginLeft: "16px",
                marginTop: "22px",
              }}
              onClick={() => uploadImageCertificateType()}
            >
              <Icon>{Icons.EXPORT}</Icon>&nbsp; Upload
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
    );
  };
  return (
    <Grid container spacing={2} sx={{ display: "flex", justifyContent: "center" }}>
      <Grid item xs={12}>
        <MDTypography
          sx={{
            fontSize: pxToRem(24),
            fontWeight: 600,
            color: "#667085",
            padding: "12px 0px",
          }}
        >
          Equipment Documents
        </MDTypography>
      </Grid>
      {documentForm?.length > 0
        ? documentForm
            ?.filter((item) => item.isDefaultVisible)
            ?.map((item) => {
              const obj = Object.keys(data).includes(item.id);
              const fieldValue = obj ? data?.[item.id] : "";
              switch (item.type) {
                case "images":
                  return (
                    <Grid item lg={12} sm={12} key={item.id}>
                      <ImageUpload
                        label={item?.IsRequired ? `${item.title}*` : item.title}
                        name={item?.id}
                        onImageUpload={(imageValues) => handleImageChange(item?.id, imageValues)}
                        onImageCancel={(updatedImageUrl) =>
                          handleImageCancel(item?.id, updatedImageUrl)
                        }
                        {...(fieldValue &&
                          fieldValue !== "" && {
                            data: Array.isArray(fieldValue) ? fieldValue : [fieldValue],
                          })}
                        type="registerEquipment"
                        formats={["image/jpeg", "image/jpg", "image/png", "application/pdf"]}
                        maxImageCount={3}
                        error={Boolean(data?.errors[item.id])}
                        helperText={data?.errors[item.id]}
                        imageTypeError={Constants.EQUIPMENT_FILE_TYPE_NOT_ALLOWED}
                        direction="row"
                      />
                    </Grid>
                  );

                case "certificateType":
                  return (() => {
                    const filteredCertificate = item?.options.filter(
                      (cer) => cer.dependentFieldIds.includes(updatedBody.equipmentType) || false
                    );

                    return filteredCertificate.length > 0 ? (
                      <MDBox display="flex" flexDirection="column" width="100%" marginX={2}>
                        <MDTypography
                          variant="caption"
                          sx={{ fontSize: pxToRem(14), fontWeight: 500, marginBottom: 2 }}
                        >
                          {item?.IsRequired ? `${item.title}*` : item.title}
                        </MDTypography>
                        <MDBox display="flex" flexWrap="wrap" justifyContent="flex-start">
                          {filteredCertificate.map((val) => {
                            const isReadyForRender = updatedBody?.certificateType?.some(
                              (element) => element.certificateTypeId === val?.id
                            );
                            if (isReadyForRender) return null;
                            return (
                              <Chip
                                label={
                                  val && val.title?.length > defaultData.MEDIUM_CONTENT_LENGTH ? (
                                    <Tooltip title={val.title}>
                                      <span>{`${val.title.slice(
                                        0,
                                        defaultData.MEDIUM_CONTENT_LENGTH
                                      )}...`}</span>
                                    </Tooltip>
                                  ) : (
                                    val?.title
                                  )
                                }
                                variant={
                                  selectedCertificate?.certificateTypeId === val?.id
                                    ? "filled"
                                    : "outlined"
                                }
                                onClick={() =>
                                  handleChipCertificateType(
                                    val?.id,
                                    val?.title,
                                    !val?.hasEmptyValue
                                  )
                                }
                                icon={<AddIcon color="white" />}
                                sx={{
                                  marginRight: "10px",
                                  marginBottom: "10px",
                                }}
                              />
                            );
                          })}
                        </MDBox>
                        {handleCertificate()}
                      </MDBox>
                    ) : (
                      <MDTypography
                        variant="h5"
                        sx={{
                          fontSize: pxToRem(14),
                          marginBottom: 2,
                        }}
                      >
                        Certificate Not Required for this Equipment Type
                      </MDTypography>
                    );
                  })();

                default:
                  return null;
              }
            })
        : null}
      {renderPreview()}
      <FullScreenImageComponent
        fullScreenImage={fullScreenImage}
        handleCloseFullView={handleCloseFullView}
        src={fullScreenImage}
      />
    </Grid>
  );
}
ProductDocument.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  setData: PropTypes.func.isRequired,
  setUpdatedBody: PropTypes.func.isRequired,
  updatedBody: PropTypes.objectOf(PropTypes.any).isRequired,
  documentForm: PropTypes.arrayOf(PropTypes.object).isRequired,
};
