import React, { useEffect, useState } from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import Constants from "utils/Constants";

// Custom Components
import Author from "components/Table/Author";

// assets
import pxToRem from "assets/theme/functions/pxToRem";

export default function WmOrderDetailsData(order) {
  const [equipmentLayerOrderRow, setEquipmentLayerOrderRow] = useState([]);

  useEffect(() => {
    if (order.orderDetailsLoading === Constants.FULFILLED) {
      const tempRows = order?.wmOrderDetailsEquipmentLayerData?.list.map((element, index) => {
        const temp = {
          srNo: <Author name={index + 1} />,
          equipment: (
            <MDBox display="flex" justifyContent="center" alignItems="center">
              <img
                src={
                  element.equipment?.equipmentImage?.[0]?.url
                    ? element.equipment?.equipmentImage?.[0]?.url
                    : process.env.REACT_APP_IMAGE_NOT_FOUND
                }
                alt={element.equipment?.equipmentImage?.[0]?.name}
                key={element.equipment?.equipmentImage?.[0]?.name}
                style={{
                  width: pxToRem(50),
                  height: pxToRem(50),
                  marginRight: pxToRem(10),
                  borderRadius: "8px",
                }}
              />{" "}
              <Author name={element.equipment?.name} />
            </MDBox>
          ),
          productNumber: <Author name={element.equipment?.equipmentNumber} />,
          serialNumber: <Author name={element.equipment?.serialNumber || Constants.NA} />,
          linkedQuantity: <Author name={element?.wmDispatchQuantity} />,
        };
        return temp;
      });
      setEquipmentLayerOrderRow([...tempRows]);
    }
  }, [order]);
  return {
    equipmentLayerColumns: [
      { Header: "No.", accessor: "srNo", width: "5%" },
      {
        Header: "Equipment",
        accessor: "equipment",
        width: "10%",
        align: "left",
      },
      { Header: "Product Number", accessor: "productNumber", align: "center" },
      { Header: "Serial Number", accessor: "serialNumber", align: "center" },
      { Header: "Linked Quantity", accessor: "linkedQuantity", align: "center" },
    ],
    equipmentLayerOrderRow,
  };
}
