import Sessions from "utils/Sessions";
import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiService from "redux/ApiService/ApiService";

const getQhseDashboardData = createAsyncThunk("qhseDashboard/api", async (params) => {
  const endPoint = params !== "all" ? `safety-cards/count?project=${params}` : "safety-cards/count";
  const res = await ApiService.get(endPoint, {
    headers: { Authorization: `Bearer ${Sessions.userToken}` },
  });
  return res;
});

export default getQhseDashboardData;
