import React, { useEffect, useState } from "react";

// Components
import Author from "components/Table/Author";
import By from "components/Table/By";

// Utils
import Constants, { defaultData, Icons } from "utils/Constants";

// 3rd party libraries
import moment from "moment";
import { IconButton } from "@mui/material";

export default function ApproverReturnOrderData({ listing, openReturnRemarkModal }) {
  const [returnOrderRows, setReturnOrderRows] = useState([]);
  useEffect(() => {
    if (listing) {
      const tempRows = listing?.returnProjectdata?.map((element, index) => {
        const temp = {
          collapseBtn: (
            <Author
              name={{
                returnId: element[Constants.MONGOOSE_ID],
                returnOrderdata: element?.returnOrderHistory,
              }}
            />
          ),
          srNo: <Author name={index + 1} />,
          returnOrderno: <Author name={element?.orderNumber} />,
          orderBy: (
            <By
              name={`${element?.orderBy?.firstName} ${element?.orderBy?.lastName}`}
              when={moment(element.createdAt).format(defaultData.WEB_DATE_FORMAT)}
            />
          ),
          totalItems: <Author name={element?.totalItems} />,
          totalReturnedQuantity: <Author name={element?.totalReturnedQuantity} />,
          remark: (
            <IconButton
              aria-label="fingerprint"
              color="info"
              disabled={
                element?.remark?.pmRemark?.length === 0 || element?.remark?.pmRemark === null
              }
              onClick={() => openReturnRemarkModal("user", element?.remark?.pmRemark)}
            >
              {element?.remark?.pmRemark?.length === 0 || element?.remark?.pmRemark === null
                ? Icons.COMMENT_DISABLE
                : Icons.COMMENT}
            </IconButton>
          ),
        };
        return temp;
      });
      setReturnOrderRows([...tempRows]);
    }
  }, [listing]);

  return {
    returnOrderColumns: [
      { Header: "", accessor: "collapseBtn", width: "5%" },
      { Header: "No.", accessor: "srNo", width: "5%" },
      { Header: "Order #", accessor: "returnOrderno" },
      { Header: "Return By", accessor: "orderBy" },
      { Header: "Total Items", accessor: "totalItems", align: "center" },
      { Header: "Total Returned Quantity", accessor: "totalReturnedQuantity", align: "center" },
      { Header: "PM Remark", accessor: "remark", align: "center" },
    ],
    returnOrderRows,
  };
}
