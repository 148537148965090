import React, { useState } from "react";

// Common Components
import DeleteModal from "examples/modal/deleteModal/deleteModal";
import ReportCardLayout from "layouts/report/reportCardLayout";
import ReportEditModal from "layouts/report/reportAnswerUpdate";
import pxToRem from "assets/theme/functions/pxToRem";
import MDBox from "components/MDBox";
import ViewReportAnswer from "examples/Drawers/Report/ViewReportAnswer";

// Table data
import reportDatailsData from "layouts/report/data/reportDetailsData";
import ReportDataTable from "examples/Tables/DataTable/ReportTable";

// Redux
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbar } from "redux/Slice/Notification";
import { deleteReportAnswerThunk, getReportById } from "redux/Thunks/Report";
import { reloadReportDetails, removeReportAnswer } from "redux/Slice/Report";

// Utils
import Constants, { ModalContent, defaultData } from "utils/Constants";

function ReportDetail() {
  const { reportDetails, reportDetailsLoading } = useSelector((state) => state.report);
  const ConfigData = useSelector((state) => state.config);
  const permission = ConfigData?.screens?.[6]?.screensInfo?.agreement;
  const [deleteReportAnswer, setDeleteReportAnswer] = useState({
    open: false,
    id: "",
    answerTitleId: "",
  });
  const [reportEdit, setReportEdit] = useState({
    open: false,
    questionTitle: "",
    answerType: "",
    answerData: {},
  });
  const [viewReportAnswerAnchor, setViewReportAnswerAnchor] = useState({ right: false });
  const [reportQuestionAnswer, setReportQuestionAnswer] = useState({
    question: "",
    questionNo: "",
    answer: [],
    supportedContent: [],
  });

  const location = useLocation();
  const dispatch = useDispatch();

  const handleView = async (reportAnswer, questionIndex) => {
    setViewReportAnswerAnchor({ right: true });
    setReportQuestionAnswer({
      question: reportAnswer?.title,
      questionNo: questionIndex,
      answer: reportAnswer?.answerTypes,
      supportedContent: reportAnswer?.supportedContent,
    });
  };

  const handleDelete = (id, titleId) => {
    setDeleteReportAnswer({
      open: true,
      id,
      answerTitleId: titleId,
    });
  };

  const handleDeleteReportAnswer = async () => {
    const body = {
      id: deleteReportAnswer.id,
      answerTitleId: { answerTitleIds: [deleteReportAnswer.answerTitleId] },
    };
    const res = await dispatch(deleteReportAnswerThunk(body));
    if (res.payload.status === 200) {
      await dispatch(
        removeReportAnswer({
          userId: deleteReportAnswer.id,
          answerTitleId: deleteReportAnswer.answerTitleId,
        })
      );
      await dispatch(
        openSnackbar({
          message: Constants.REPORT_DELETE_SUCCESS,
          notificationType: Constants.NOTIFICATION_SUCCESS,
        })
      );
      setDeleteReportAnswer({ id: "", answerTitleId: "", open: false });
    } else if (res.payload.status === 400) {
      await dispatch(
        openSnackbar({
          message: Constants.REPORT_DELETE_ERROR,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
      setDeleteReportAnswer({ id: "", answerTitleId: "", open: false });
    } else {
      await dispatch(
        openSnackbar({
          message: Constants.SOMETHING_WENT_WRONG,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
  };

  const handleAnswerEdit = (reportAnswer, answerType, answer, user, answerTitle) => {
    setReportEdit({
      open: true,
      questionTitle: reportAnswer.title,
      answerType: answerType?.parameterType?.uniqueKey,
      answerData: {
        answerTitleId: answer?.answerTitleId,
        answerId: user[Constants.MONGOOSE_ID],
        reportAnswer: answer.answer,
        userName: user.createdBy,
        answerTitle,
        reportAnswerData:
          answerType?.parameterType?.uniqueKey === "range" ? answerType.range : answerType.option,
      },
    });
  };

  const handleReload = async () => {
    await dispatch(reloadReportDetails());
    const body = {
      userReports: location?.state?.reportId,
    };
    await dispatch(getReportById(body));
  };

  const { reportColumns, reportRows } = reportDatailsData(
    reportDetails?.[0]?.reportQuestions,
    handleView,
    handleDelete,
    handleAnswerEdit,
    permission
  );
  return (
    <>
      <ReportCardLayout handleReload={handleReload}>
        <MDBox mt={4} sx={{ paddingBottom: pxToRem(20) }}>
          <ReportDataTable
            table={{ columns: reportColumns, rows: reportRows }}
            loading={reportDetailsLoading}
            entriesPerPage={{ defaultValue: defaultData.PER_PAGE_4 }}
            showTotalEntries={false}
            isSorted={false}
            licenseRequired
          />
        </MDBox>
      </ReportCardLayout>
      {/* Delete Modal for Report Answer */}
      <DeleteModal
        open={deleteReportAnswer.open}
        title={ModalContent.DELETE_REPORT_ANSWER}
        message={ModalContent.DELETE_REPORT_ANSWER_MESSAGE}
        handleClose={() => {
          setDeleteReportAnswer({ ...deleteReportAnswer, open: false });
        }}
        handleDelete={handleDeleteReportAnswer}
      />
      <ReportEditModal reportEdit={reportEdit} setReportEdit={setReportEdit} />

      <ViewReportAnswer
        viewReportAnswerAnchor={viewReportAnswerAnchor}
        setViewReportAnswerAnchor={setViewReportAnswerAnchor}
        reportAnswer={reportQuestionAnswer}
      />
    </>
  );
}

export default ReportDetail;
