import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import { Icons, defaultData } from "utils/Constants";
import Author from "components/Table/Author";
import moment from "moment";

export default function Member(
  memberList,
  handleOpenNewModal,
  setModalType,
  editLists,
  setEditLists,
  handleDelete
) {
  const [rows, setRows] = useState([]);
  const mongooseId = "_id";

  const handleEdit = (item) => {
    setModalType("Update");
    setEditLists({ ...editLists, member: item });
    handleOpenNewModal("Member");
  };
  useEffect(() => {
    if (memberList) {
      const list = memberList.map((item) => {
        const temp = {
          candidateName: <Author name={`${item?.user?.firstName} ${item.user?.lastName}`} />,
          function: <Author name={item?.function?.functionName} />,
          from: (
            <Author
              name={
                item.createdAt ? moment(item?.createdAt).format(defaultData.WEB_DATE_FORMAT) : ""
              }
            />
          ),
          isApprover: <Author name={item?.isApprover ? "Yes" : "No"} />,
          action: (
            <MDBox>
              <Icon
                color="secondary"
                fontSize="medium"
                onClick={() => handleEdit(item)}
                sx={{ cursor: "pointer" }}
              >
                {Icons.EDIT}
              </Icon>{" "}
              &nbsp;
              <Icon
                color="secondary"
                fontSize="medium"
                onClick={() => handleDelete("Member", item[mongooseId])}
              >
                {Icons.DELETE}
              </Icon>
            </MDBox>
          ),
        };
        return temp;
      });
      setRows([...list]);
    }
  }, [memberList]);
  return {
    membersColumns: [
      { Header: "Name", accessor: "candidateName", width: "20%", align: "left" },
      { Header: "Function", accessor: "function", width: "20%", align: "left" },
      { Header: "Active From", accessor: "from", width: "20%", align: "left" },
      { Header: "Is Approver", accessor: "isApprover", width: "20%", align: "left" },
      { Header: "Action", accessor: "action", width: "20%", align: "right" },
    ],
    membersRows: rows,
  };
}
