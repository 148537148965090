import { useState, useEffect } from "react";

// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import NotificationDialog from "examples/Dialog/Notification/notificationDialog";

// Material Dashboard 2 React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import { useMaterialUIController, setTransparentNavbar, setMiniSidenav } from "context";
import jwtDecode from "jwt-decode";
import Sessions from "utils/Sessions";
import { defaultData, Colors, Icons } from "utils/Constants";
import { useSelector } from "react-redux";
import pxToRem from "assets/theme/functions/pxToRem";
import MDTypography from "components/MDTypography";
import { Badge } from "@mui/material";

function DashboardNavbar({ absolute, light, isMini }) {
  const [open, setOpen] = useState(false);
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { transparentNavbar, fixedNavbar, darkMode, miniSidenav } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [role, setRole] = useState("");
  const Config = useSelector((state) => state.config);
  useEffect(() => {
    if (Sessions.userToken) {
      const deocdedToken = jwtDecode(Sessions.userToken);
      setRole(deocdedToken?.role);
    }
  }, []);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleCloseMenu = () => setOpenMenu(false);
  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>email</Icon>} title="0 Notification" />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });
  const handleNotificationOpen = (event) => {
    event.stopPropagation();
    setOpen(!open);
  };
  const handleNotificationClose = () => {
    setOpen(false);
  };
  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox
          color="inherit"
          ml={-2}
          mb={{ xs: 1, md: 0 }}
          sx={(theme) => navbarRow(theme, { isMini })}
        >
          <Breadcrumbs icon="home_outlined" light={light} />
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox
              display="flex"
              flexDirection="row"
              color={light ? "white" : "inherit"}
              sx={{ marginRight: -2 }}
            >
              <>
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarMobileMenu}
                  onClick={handleMiniSidenav}
                >
                  <Icon sx={iconsStyle} fontSize="medium">
                    {miniSidenav ? "menu_open" : "menu"}
                  </Icon>
                </IconButton>

                {role !== defaultData.SUPER_ADMIN_ROLE ||
                (role === defaultData.SUPER_ADMIN_ROLE &&
                  Sessions.isSuperAdminViewingAdminPanel) ? (
                  <>
                    <Link to="/client/profile">
                      <IconButton
                        sx={navbarIconButton}
                        border="2px solid black"
                        size="small"
                        disableRipple
                      >
                        {Config?.config[0]?.profileImage !== null &&
                        Config?.config[0]?.profileImage !== "" ? (
                          <>
                            {!imageLoaded && <Icon sx={iconsStyle}>account_circle</Icon>}
                            <img
                              src={Config?.config[0]?.profileImage}
                              alt="profile"
                              width="35"
                              height="35"
                              style={{
                                display: imageLoaded ? "inline-block" : "none",
                                borderRadius: "50%",
                                marginRight: pxToRem(10),
                                marginTop: pxToRem(2),
                                width: "35px",
                                height: "35px",
                                objectFit: "cover",
                              }}
                              onLoad={() => setImageLoaded(true)}
                            />
                          </>
                        ) : (
                          <MDBox
                            sx={{
                              backgroundColor: Colors.PRIMARY,
                              borderRadius: "50%",
                              width: pxToRem(35),
                              height: pxToRem(35),
                              padding: pxToRem(3),
                              marginTop: pxToRem(2),
                              marginRight: pxToRem(10),
                            }}
                          >
                            <MDTypography
                              sx={{
                                marginTop: pxToRem(5),
                                marginRight: pxToRem(3),
                                color: Colors.WHITE,
                                fontSize: pxToRem(16),
                                fontWeight: "400",
                                backgroundColor: Colors.PRIMARY,
                                borderRadius: "50%",
                              }}
                            >
                              {Config?.config[0]?.username?.charAt(0)?.toUpperCase()}
                            </MDTypography>
                          </MDBox>
                        )}
                      </IconButton>
                    </Link>
                    <Badge badgeContent={3} color="error" sx={{ marginTop: pxToRem(8) }}>
                      <MDBox
                        bgColor={Colors.PRIMARY}
                        width={pxToRem(35)}
                        height={pxToRem(35)}
                        p={pxToRem(5)}
                        borderRadius={pxToRem(8)}
                      >
                        <Icon
                          sx={iconsStyle}
                          fontSize={pxToRem(10)}
                          onClick={(e) => handleNotificationOpen(e)}
                        >
                          {Icons.NOTIFICATION}
                        </Icon>
                      </MDBox>
                    </Badge>
                  </>
                ) : null}
              </>
              {renderMenu()}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
      <NotificationDialog open={open} onClose={handleNotificationClose} />
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
