import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Feature } from "flagged";

// Material components
import MDBox from "components/MDBox";
import { Card, Divider, Grid } from "@mui/material";

// Custom components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PageTitle from "examples/NewDesign/PageTitle";
import CustomButton from "examples/NewDesign/CustomButton";
import BasicModal from "examples/modal/BasicModal/BasicModal";
import DeleteModal from "examples/modal/deleteModal/deleteModal";
import BasicButton from "examples/NewDesign/CustomButton/BasicButton";
import FilterDropdown from "components/Dropdown/FilterDropdown";
import FDropdown from "components/Dropdown/FDropdown";
import FDropdown2 from "components/Dropdown/fDropdown2";
import DataTable from "examples/Tables/DataTable";
import FTextField from "components/Form/FTextField";
import CustomRadio from "components/CustomRadio/CustomRadio";
import pxToRem from "assets/theme/functions/pxToRem";
import ResetFilterButton from "components/Buttons/ResetButton";
import MultiSelectAutoComplete from "components/Dropdown/MultipleAutocomplete";

// Table Data
import SetupReportData from "layouts/wfmwizard/DailyActivity/setupReport/data/SetupReportData";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  projectListThunk,
  scopesThunk,
  locationListByIdThunk,
  assetListThunk,
} from "redux/Thunks/FieldsData";
import createReportType, {
  getAllReportTypes,
  updateReportType,
  deleteReportType,
} from "redux/Thunks/Report";
import { openSnackbar } from "redux/Slice/Notification";
import { reloadReportTypeData, removeReportType } from "redux/Slice/Report";

// Utils
import Constants, {
  Icons,
  PageTitles,
  ButtonTitles,
  Colors,
  ModalContent,
  FeatureTags,
  defaultData,
} from "utils/Constants";
import { paramCreater } from "utils/methods/methods";
import Validator from "utils/Validations";

const initialReportState = {
  type: "new",
  openModal: false,
  list: [],
  body: {
    title: "",
    project: "",
    type: "",
    isProgressable: false,
    isPublish: false,
    reportId: "",
    scope: "",
    locations: [],
    assets: [],
  },
  editBody: {},
  errors: {},
  openDeleteModal: false,
  editDeleteId: "",
  loading: false,
};

function SetupReport() {
  const [projectList, setProjectList] = useState([]);
  const [projectReportList, setProjectReportList] = useState([]);
  const [projectScopeList, setProjectScopeList] = useState([]);
  const [projectLocations, setProjectLocations] = useState([]);
  const [projectAssets, setProjectAssets] = useState([]);
  const [reportTypeData, setReportTypeData] = useState(initialReportState);
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState([
    {
      inputLabel: "Project",
      list: [{ [Constants.MONGOOSE_ID]: "all", title: "All" }],
      selectedValue: "all",
    },
    {
      inputLabel: "Types",
      list: [
        { [Constants.MONGOOSE_ID]: "all", title: "All" },
        { [Constants.MONGOOSE_ID]: "location", title: "Location" },
        { [Constants.MONGOOSE_ID]: "asset_per_location", title: "Asset per Location" },
        { [Constants.MONGOOSE_ID]: "multiple_assets", title: "Multiple Assets" },
      ],
      selectedValue: "all",
    },
  ]);
  const [tablePagination, setTablePagination] = useState({
    page: 0,
    perPage: defaultData.DATE_ON_SINGLE_API_CALL,
  });
  const [next, setNext] = useState(0);
  const reportTypes = useSelector((state) => state.report);
  const dispatch = useDispatch();

  const handleCloseReportType = () =>
    setReportTypeData(JSON.parse(JSON.stringify(initialReportState)));

  const handleCloseDeleteReportTypeModal = () =>
    setReportTypeData((prev) => ({ ...prev, openDeleteModal: false }));

  const handleDeleteReportTypeModal = (id) => {
    setReportTypeData((prev) => ({ ...prev, openDeleteModal: true, editDeleteId: id }));
  };

  const handleLocationFetch = async (projectId) => {
    const locationRes = await dispatch(locationListByIdThunk(projectId));
    if (locationRes.payload.status) {
      let locationLists = [
        {
          title: "All",
          [Constants.MONGOOSE_ID]: "all",
        },
      ];
      locationLists = [
        ...locationLists,
        ...locationRes.payload.data.map((val) => ({
          title: val.title,
          [Constants.MONGOOSE_ID]: val[Constants.MONGOOSE_ID],
        })),
      ];
      setProjectLocations(locationLists);
      setProjectAssets([]);
    }
  };

  const handleAssetFetch = async (projectId) => {
    const assetsRes = await dispatch(assetListThunk(projectId));
    if (assetsRes.payload.status) {
      let assetLists = [
        {
          title: "All",
          [Constants.MONGOOSE_ID]: "all",
        },
      ];
      assetLists = [
        ...assetLists,
        ...assetsRes.payload.data.map((val) => ({
          title: val.cableName,
          [Constants.MONGOOSE_ID]: val[Constants.MONGOOSE_ID],
        })),
      ];
      setProjectAssets(assetLists);
      setProjectLocations([]);
    }
  };

  const handleEditReportType = async (data) => {
    const tempType = data.type.replaceAll("_", " ");
    const tempBody = {
      project: data.project?.[Constants.MONGOOSE_ID],
      title: data.title,
      type: tempType.charAt(0).toUpperCase() + tempType.slice(1),
      isProgressable: data.isProgressable,
      isPublish: data.isPublish,
      scope: data.scope[0]?.[Constants.MONGOOSE_ID],
      locations: data.locations.map((val) => ({
        title: val.title,
        [Constants.MONGOOSE_ID]: val[Constants.MONGOOSE_ID],
      })),
      assets: data.assets.map((val) => ({
        title: val.cableName,
        [Constants.MONGOOSE_ID]: val[Constants.MONGOOSE_ID],
      })),
    };
    setReportTypeData((prev) => ({
      ...prev,
      type: "update",
      openModal: true,
      body: JSON.parse(JSON.stringify(tempBody)),
      editBody: JSON.parse(JSON.stringify(tempBody)),
      editDeleteId: data[Constants.MONGOOSE_ID],
    }));

    if (data.type === "location") {
      handleLocationFetch(data.project[Constants.MONGOOSE_ID]);
    }
    if (data.type === "asset_per_location" || data.type === "multiple_assets") {
      handleAssetFetch(data.project[Constants.MONGOOSE_ID]);
    }

    const scopeRes = await dispatch(scopesThunk(data.project[Constants.MONGOOSE_ID]));

    if (scopeRes.payload.status === 200) {
      setProjectScopeList(scopeRes.payload.data.data);
    }
  };

  const { columns, rows } = SetupReportData(
    {
      reportTypeLoading: reportTypes.reportTypeLoading,
      reportType: reportTypes.reportType,
    },
    handleEditReportType,
    handleDeleteReportTypeModal
  );

  const handleFilter = async (filterVale = filters) => {
    setTablePagination({ ...tablePagination, page: 0 });
    setNext(0);
    const paramData = {
      page: 0,
      perPage: tablePagination.perPage,
      project: filterVale[0].selectedValue,
      type: filterVale[1].selectedValue,
    };

    await dispatch(reloadReportTypeData());
    await dispatch(getAllReportTypes({ param: paramCreater(paramData), byPassSlice: false }));
  };

  useEffect(() => {
    const { openNewReportType, projectId } = location.state || { openNewReportType: false };
    const tempFilters = [...filters];
    if (projectId) tempFilters[0].selectedValue = projectId;
    const fetchProjects = async () => {
      const res = await dispatch(projectListThunk());
      tempFilters[0].list = [...tempFilters[0].list, ...res.payload.data];
      setProjectList(res.payload.data);
    };
    fetchProjects();
    setFilters(tempFilters);
    setReportTypeData((prev) => ({
      ...prev,
      openModal: openNewReportType,
      body: { ...prev.body, project: projectId || "" },
    }));
    handleFilter(tempFilters);
  }, []);

  useEffect(() => {
    (async () => {
      if (reportTypeData.openModal) {
        const paramData = {
          page: 0,
          perPage: 1000,
          type: "all",
        };
        const setupReportRes = await dispatch(
          getAllReportTypes({ param: paramCreater(paramData), byPassSlice: true })
        );

        const reportLists = setupReportRes.payload.data.data.map((val) => ({
          ...val,
          title: `${val.title} - (${val.project.title})`,
        }));

        if (setupReportRes.payload.status === 200) {
          setProjectReportList(reportLists);
        }
      }
    })();
  }, [reportTypeData.openModal]);

  const handleReportTypeChange = async (e) => {
    const { name, value } = e.target;
    const temp = { ...reportTypeData };
    temp.body[name] = value;
    if (name === "project") {
      temp.body.reportId = "";
      temp.loading = true;
    }

    setReportTypeData(temp);

    if (name === "project") {
      const scopeRes = await dispatch(scopesThunk(value));

      if (scopeRes.payload.status === 200) {
        setProjectScopeList(scopeRes.payload.data.data);
        setReportTypeData((prev) => ({ ...prev, loading: false }));
      }
    } else if (name === "type" && value === "Location")
      handleLocationFetch(reportTypeData.body.project);
    else if (name === "type" && (value === "Asset per location" || value === "Multiple assets"))
      handleAssetFetch(reportTypeData.body.project);
  };

  const handleLocationAndAssetsChange = (e) => {
    const { name, value } = e.target;
    if (value[Constants.MONGOOSE_ID] !== "all") {
      const temp = { ...reportTypeData };
      temp.body[name] = [...temp.body[name], value];
      setReportTypeData(temp);
    } else if (name === "locations" && value[Constants.MONGOOSE_ID] === "all") {
      const temp = { ...reportTypeData };
      temp.body.locations = projectLocations.filter((val) => val[Constants.MONGOOSE_ID] !== "all");
      setReportTypeData(temp);
    } else if (name === "assets" && value[Constants.MONGOOSE_ID] === "all") {
      const temp = { ...reportTypeData };
      temp.body.assets = projectAssets.filter((val) => val[Constants.MONGOOSE_ID] !== "all");
      setReportTypeData(temp);
    }
  };

  const handleRemoveLocationAndAssetsChange = (name, index) => {
    const temp = { ...reportTypeData };
    temp.body[name].splice(index, 1);
    setReportTypeData(temp);
  };

  const reportTypevalidation = () => {
    const { title, project, type } = reportTypeData.body;
    const errors = {};

    const titleValidation = Validator.validate("basic2", title);
    const projectValidation = Validator.validate("basic2", project);
    const typeValidation = Validator.validate("basic2", type);
    const scopeValidation = Validator.validate("basic2", reportTypeData.body.scope);

    if (titleValidation) errors.title = titleValidation;
    if (projectValidation) errors.project = projectValidation;
    if (typeValidation) errors.type = typeValidation;
    if (scopeValidation) errors.scope = scopeValidation;

    return Object.keys(errors).length > 0 ? errors : {};
  };

  const handleCreateReportType = async () => {
    setLoading(true);
    const errors = reportTypevalidation();
    if (Object.keys(errors).length === 0) {
      const body = {
        ...reportTypeData.body,
        title: reportTypeData.body.title.trim(),
        type: reportTypeData.body.type.replaceAll(" ", "_").toLowerCase(),
        locations: reportTypeData.body.locations.map((val) => val[Constants.MONGOOSE_ID]),
        assets: reportTypeData.body.assets.map((val) => val[Constants.MONGOOSE_ID]),
      };
      if (body.reportId === "") body.reportId = undefined;
      if (body.type === "location") body.assets = undefined;
      else if (body.type === "asset_per_location" || body.type === "multiple_assets")
        body.locations = undefined;

      const res = await dispatch(createReportType(body));
      if (res.payload.status === 200) {
        handleFilter();
        dispatch(
          openSnackbar({
            message: Constants.SETUP_REPORT_CREATE_SUCCESS,
            notificationType: Constants.NOTIFICATION_SUCCESS,
          })
        );
        handleCloseReportType();
      } else if (res.payload.status === 422) {
        setReportTypeData((prev) => ({
          ...prev,
          errors: { title: Constants.SETUP_REPORT_CREATE_ERROR },
        }));
      }
    } else {
      setReportTypeData((prev) => ({ ...prev, errors }));
    }
    setLoading(false);
  };

  const isChanged = (prevArr, currentArr) => {
    if (prevArr.length !== currentArr.length) return true;
    if (
      prevArr.filter(
        (val) =>
          currentArr.findIndex((el) => el[Constants.MONGOOSE_ID] === val[Constants.MONGOOSE_ID]) ===
          -1
      ).length > 0
    )
      return true;

    return false;
  };

  const handleUpdateReportType = async () => {
    setLoading(true);
    const errors = reportTypevalidation();
    if (Object.keys(errors).length === 0) {
      const { body, editDeleteId, editBody } = reportTypeData;
      const tempBody = {
        ...(body.title !== editBody?.title && { title: body.title.trim() }),
        ...(body.isProgressable.toString() !== editBody?.isProgressable.toString() && {
          isProgressable: body.isProgressable,
        }),
        ...(body.scope !== editBody?.scope && { scope: body.scope }),
      };

      if (body.type === "Location") {
        tempBody.assets = undefined;
        if (isChanged(body.locations, editBody?.locations))
          tempBody.locations = reportTypeData.body.locations.map(
            (val) => val[Constants.MONGOOSE_ID]
          );
      } else if (body.type === "Asset per location" || body.type === "Multiple assets") {
        tempBody.locations = undefined;
        if (isChanged(body.assets, editBody?.assets))
          tempBody.assets = reportTypeData.body.assets.map((val) => val[Constants.MONGOOSE_ID]);
      }
      const res = await dispatch(updateReportType({ body: tempBody, reportTypeId: editDeleteId }));
      if (res.payload.status === 200) {
        handleFilter();
        dispatch(
          openSnackbar({
            message: Constants.SETUP_REPORT_UPDATE_SUCCESS,
            notificationType: Constants.NOTIFICATION_SUCCESS,
          })
        );
        handleCloseReportType();
      } else if (res.payload.status === 422) {
        setReportTypeData((prev) => ({
          ...prev,
          errors: { title: Constants.SETUP_REPORT_CREATE_ERROR },
        }));
      } else {
        dispatch(
          openSnackbar({
            message: Constants.SETUP_REPORT_UPDATE_ERROR,
            notificationType: Constants.NOTIFICATION_ERROR,
          })
        );
      }
    } else {
      setReportTypeData((prev) => ({ ...prev, errors }));
    }
    setLoading(false);
  };

  const handleDeleteReportType = async () => {
    const { editDeleteId } = reportTypeData;
    const res = await dispatch(deleteReportType(editDeleteId));
    if (res.payload.status === 200) {
      await dispatch(removeReportType(editDeleteId));
      await dispatch(
        openSnackbar({
          message: Constants.SETUP_REPORT_DELETE_SUCCESS,
          notificationType: Constants.NOTIFICATION_SUCCESS,
        })
      );
    } else if (res.payload.status === 400) {
      dispatch(
        openSnackbar({
          message: Constants.SETUP_REPORT_DELETE_ERROR,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
    setReportTypeData((prev) => ({ ...prev, openDeleteModal: false, editDeleteId: "" }));
  };

  const handleFilterChange = (e) => {
    const temp = [...filters];
    const { value, name } = e.target;
    const index = filters.findIndex((filter) => filter.inputLabel === name);
    temp[index].selectedValue = value;
    setFilters(temp);
    handleFilter(temp);
  };

  const handleReset = async () => {
    const resetFilters = filters.map((filter) => ({ ...filter, selectedValue: "all" }));
    setFilters(resetFilters);
    await handleFilter(resetFilters);
  };

  const handleTablePagination = async () => {
    const paramData = {
      page: next + 1,
      perPage: tablePagination.perPage,
      project: filters[0].selectedValue,
      type: filters[1].selectedValue,
    };
    const res = await dispatch(
      getAllReportTypes({ param: paramCreater(paramData), byPassSlice: false })
    );
    if (res.payload.status === 200) setNext(res.payload.data.data.length > 0 ? next + 1 : next);
  };

  const handleReload = async () => {
    await dispatch(reloadReportTypeData());
    handleFilter();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox display="flex" justifyContent="space-between" alignItems="center">
        <PageTitle title={PageTitles.SETUP_REPORT} />
        <MDBox display="flex" flexDirection="row">
          <CustomButton
            title={ButtonTitles.SETUP_REPORT}
            icon={Icons.NEW}
            background={Colors.PRIMARY}
            color={Colors.WHITE}
            openModal={() => setReportTypeData((prev) => ({ ...prev, openModal: true }))}
          />
          <BasicButton
            icon={Icons.RELOAD}
            background={Colors.WHITE}
            border
            color={Colors.BLACK}
            action={handleReload}
          />
        </MDBox>
      </MDBox>
      <Divider sx={{ marginTop: pxToRem(22) }} />
      <Feature name={FeatureTags.SETUP_REPORT}>
        <MDBox display="flex" justifyContent="space-between">
          <MDBox display="flex wrap" flexWrap="wrap" justifyContent="start">
            {filters?.map((val) => (
              <FilterDropdown
                label={val.inputLabel}
                name={val.inputLabel}
                defaultValue={val?.selectedValue}
                value={val?.selectedValue}
                handleChange={handleFilterChange}
                menu={val.list}
                key={val.inputLabel}
              />
            ))}
            <ResetFilterButton handleReset={handleReset} style={{ marginLeft: "1rem" }} />
          </MDBox>
        </MDBox>
        <MDBox mt={3} mb={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={{ defaultValue: defaultData.PER_PAGE }}
                  showTotalEntries={false}
                  noEndBorder
                  loading={reportTypes.reportTypeLoading}
                  currentPage={tablePagination.page}
                  handleTablePagination={handleTablePagination}
                  handleCurrentPage={(page) => setTablePagination({ ...tablePagination, page })}
                />
              </MDBox>
            </Card>
          </Grid>
        </MDBox>

        {/* Create and Update Report type modal */}
        <BasicModal
          open={reportTypeData.openModal}
          handleClose={handleCloseReportType}
          title={
            reportTypeData.type === "new"
              ? ModalContent.NEW_REPORT_TITLE
              : ModalContent.EDIT_REPORT_TITLE
          }
          actionButton={
            (reportTypeData.type === "new" && !loading && ButtonTitles.SUBMIT) ||
            (reportTypeData.type === "new" && loading && ButtonTitles.SUBMIT_LOADING) ||
            (reportTypeData.type === "update" && !loading && ButtonTitles.UPDATE) ||
            (reportTypeData.type === "update" && loading && ButtonTitles.UPDATE_LOADING)
          }
          disabled={loading}
          handleAction={
            reportTypeData.type === "new" ? handleCreateReportType : handleUpdateReportType
          }
        >
          <FTextField
            label="Title"
            name="title"
            id="title"
            placeholder="Enter your Report Title"
            type="text"
            value={reportTypeData.body.title}
            handleChange={handleReportTypeChange}
            width="100%"
            error={reportTypeData.errors?.title}
            helperText={reportTypeData.errors?.title}
            marginBottom={pxToRem(10)}
          />

          <FDropdown2
            label="Project"
            id="demo-select-small"
            name="project"
            value={reportTypeData.body.project}
            defaultValue=""
            options={projectList || []}
            error={reportTypeData.errors?.project}
            disabled={reportTypeData.type === "update"}
            helperText={reportTypeData.errors?.project}
            handleChange={handleReportTypeChange}
            marginBottom={pxToRem(10)}
          />

          <FDropdown
            label="Report Type*"
            id="type"
            name="type"
            disabled={reportTypeData.type === "update" || reportTypeData.body.project === ""}
            value={reportTypeData.body.type}
            menu={["Location", "Asset per location", "Multiple assets"]}
            error={reportTypeData.errors?.type}
            helperText={reportTypeData.errors?.type}
            handleChange={(name, value, id) =>
              handleReportTypeChange({ target: { name, value, id } })
            }
            marginBottom={0}
          />

          <CustomRadio
            label="is Progressable?"
            name="isProgressable"
            list={[
              { label: "Yes", value: "true" },
              { label: "No", value: "false" },
            ]}
            value={reportTypeData.body.isProgressable}
            handleChange={handleReportTypeChange}
          />

          {reportTypeData.type === "new" && (
            <FDropdown2
              label="Clone Questions from"
              id="cloneFrom"
              name="reportId"
              value={reportTypeData.body.reportId}
              options={projectReportList || []}
              error={reportTypeData.errors?.reportId}
              helperText={reportTypeData.errors?.reportId}
              handleChange={handleReportTypeChange}
              marginBottom={0}
            />
          )}

          {reportTypeData.body.project !== "" && !reportTypeData.loading && (
            <FDropdown2
              label="Scope"
              id="scope"
              name="scope"
              value={reportTypeData.body.scope}
              options={projectScopeList || []}
              error={reportTypeData.errors?.scope}
              disabled={reportTypeData.body.project === ""}
              helperText={reportTypeData.errors?.scope}
              handleChange={handleReportTypeChange}
              marginBottom={0}
            />
          )}

          {reportTypeData.body.type === "Location" && (
            <MultiSelectAutoComplete
              label="Locations"
              name="locations"
              id="locations"
              value={reportTypeData.body.locations}
              menu={projectLocations || []}
              error={reportTypeData.errors?.locations}
              hint="Enter all to select all locations"
              helperText={reportTypeData.errors?.locations}
              handleChange={handleLocationAndAssetsChange}
              handleRemove={handleRemoveLocationAndAssetsChange}
              getOptionLabel={(option) => option?.title}
            />
          )}

          {(reportTypeData.body.type === "Asset per location" ||
            reportTypeData.body.type === "Multiple assets") && (
            <MultiSelectAutoComplete
              label="Assets"
              name="assets"
              id="assets"
              value={reportTypeData.body.assets}
              menu={projectAssets || []}
              error={reportTypeData.errors?.assets}
              hint="Enter all to select all assets"
              helperText={reportTypeData.errors?.assets}
              handleChange={handleLocationAndAssetsChange}
              handleRemove={handleRemoveLocationAndAssetsChange}
              getOptionLabel={(option) => option?.title}
            />
          )}

          {reportTypeData.loading && (
            <MDBox py={5} display="flex" justifyContent="center" alignItems="center">
              {Icons.LOADING2}
            </MDBox>
          )}
        </BasicModal>

        {/* Delete Modal for Report type */}
        <DeleteModal
          open={reportTypeData.openDeleteModal}
          title={ModalContent.REPORT_DELETE_TITLE}
          message={ModalContent.REPORT_DELETE_MESSAGE}
          handleClose={handleCloseDeleteReportTypeModal}
          handleDelete={handleDeleteReportType}
        />
      </Feature>
    </DashboardLayout>
  );
}

export default SetupReport;
