import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddIcon from "@mui/icons-material/Add";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PublishIcon from "@mui/icons-material/Publish";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import RemoveIcon from "@mui/icons-material/Remove";
import { CircularProgress } from "@mui/material";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import CachedIcon from "@mui/icons-material/Cached";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import QueueOutlinedIcon from "@mui/icons-material/PlaylistAdd";
import CheckIn from "assets/images/icons/Equipments/CheckIn.svg";
import CheckOut from "assets/images/icons/Equipments/CheckOut.svg";
import QrMissing from "assets/images/icons/Equipments/QRMissing.svg";
import CertificateMissing from "assets/images/icons/Equipments/CertificateMissing.svg";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import { WorkspacePremiumOutlined } from "@mui/icons-material";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import AddCommentOutlinedIcon from "@mui/icons-material/AddCommentOutlined";
import UTurnRightIcon from "@mui/icons-material/UTurnRight";
import Colors from "utils/Constants/Colors.constants";
import InfoIcon from "@mui/icons-material/Info";

// icon constants
const Icons = {
  CHECKIN: <img src={CheckIn} alt="checkin" height="20px" width="20px" />,
  CHECKOUT: <img src={CheckOut} alt="checkout" height="20px" width="20px" />,
  NEW: <AddCircleOutlineOutlinedIcon fontSize="medium" sx={{ cursor: "pointer" }} />,
  ADD: <AddIcon fontSize="medium" sx={{ cursor: "pointer", color: "#667085" }} />,
  ADD2: <AddIcon fontSize="medium" sx={{ cursor: "pointer", color: "#475467" }} />,
  EDIT: <EditOutlinedIcon fontSize="medium" sx={{ cursor: "pointer", color: "#475467" }} />,
  EDIT2: <EditOutlinedIcon fontSize="medium" sx={{ cursor: "pointer" }} />,
  DELETE: (
    <DeleteOutlineOutlinedIcon
      color="error"
      fontSize="medium"
      sx={{ cursor: "pointer", color: "#475467" }}
    />
  ),
  DELETE2: (
    <DeleteOutlineOutlinedIcon
      fontSize="medium"
      sx={{ cursor: "pointer", zIndex: 9999, color: "red" }}
    />
  ),
  VIEW: (
    <VisibilityOutlinedIcon
      color="info"
      fontSize="medium"
      sx={{ cursor: "pointer", color: "#475467" }}
    />
  ),
  VIEW2: (
    <VisibilityOutlinedIcon
      color="info"
      fontSize="medium"
      sx={{ cursor: "pointer", color: "#ffffff" }}
    />
  ),
  RESET_FILTER: <RestartAltOutlinedIcon fontSize="medium" sx={{ cursor: "pointer" }} />,
  EXPORT: <FileUploadOutlinedIcon fontSize="medium" sx={{ cursor: "pointer" }} />,
  CLOSE: <CloseOutlinedIcon fontSize="medium" sx={{ cursor: "pointer" }} />,
  DROPDOWN: () => <KeyboardArrowDownIcon fontSize="medium" sx={{ color: "#475467" }} />,
  PUBLISH: <PublishIcon fontSize="medium" sx={{ cursor: "pointer" }} />,
  DOWNLOAD: <FileDownloadOutlinedIcon fontSize="medium" sx={{ cursor: "pointer" }} />,
  ACCEPT: <CheckIcon sx={{ cursor: "pointer" }} />,
  ACCEPT_MEDIUM: <CheckIcon fontSize="medium" sx={{ cursor: "pointer" }} />,
  ACCEPT2: <CheckCircleOutlineIcon fontSize="medium" sx={{ cursor: "pointer" }} />,
  ACCEPT3: <CheckIcon fontSize="medium" sx={{ cursor: "pointer", color: Colors.PRIMARY }} />,
  REJECT: <ClearIcon fontSize="large" sx={{ cursor: "pointer", color: "#ffffff" }} />,
  WARNING: (
    <WarningAmberOutlinedIcon fontSize="small" sx={{ cursor: "pointer", color: "#EB8D00" }} />
  ),
  CROSS: <CancelOutlinedIcon fontSize="medium" sx={{ cursor: "pointer", color: "#ffffff" }} />,
  CROSS2: <CancelOutlinedIcon fontSize="medium" sx={{ cursor: "pointer", color: "#FF2E2E" }} />,
  CROSS3: <CloseIcon sx={{ color: "#9D0202" }} />,
  CROSS4: (
    <CancelOutlinedIcon fontSize="medium" sx={{ cursor: "pointer", color: Colors.PRIMARY }} />
  ),
  SEACRH: <SearchIcon fontSize="medium" sx={{ cursor: "pointer", color: "#667085" }} />,
  LOADING: <CircularProgress color="info" size={18} />,
  LOADING2: <CircularProgress color="info" />,
  NEXT: (
    <ArrowCircleRightOutlinedIcon fontSize="large" sx={{ cursor: "pointer", color: "#ffffff" }} />
  ),
  PREVIOUS: (
    <ArrowCircleLeftOutlinedIcon fontSize="large" sx={{ cursor: "pointer", color: "#ffffff" }} />
  ),
  DASH: <RemoveIcon fontSize="medium" sx={{ cursor: "pointer", color: "#ff6600" }} />,
  DASH2: <RemoveCircleOutlineOutlinedIcon fontSize="medium" sx={{ cursor: "pointer" }} />,
  DASH3: (
    <RemoveCircleIcon fontSize="medium" sx={{ cursor: "pointer", color: Colors.COLLAPSE_BTN }} />
  ),
  ADMIN: (
    <AdminPanelSettingsOutlinedIcon
      fontSize="medium"
      sx={{ cursor: "pointer", color: "#475467" }}
    />
  ),
  DOWNLOAD2: <DownloadIcon fontSize="medium" sx={{ cursor: "pointer", color: "#ffffff" }} />,
  PRINT: <PrintIcon fontSize="medium" sx={{ cursor: "pointer" }} />,
  QR: <QrCode2Icon fontSize="medium" sx={{ cursor: "pointer", color: "#667085" }} />,
  RELOAD: <CachedIcon fontSize="large" sx={{ cursor: "pointer", color: "#000000" }} />,
  MANAGE_ACCOUNT: (
    <ManageAccountsOutlinedIcon fontSize="medium" sx={{ cursor: "pointer", color: "#475467" }} />
  ),
  IMAGE: <ImageOutlinedIcon fontSize="medium" sx={{ cursor: "pointer", color: "#667085" }} />,
  ARROW_DOWN: <KeyboardArrowDownIcon fontSize="medium" sx={{ color: "#475467" }} />,
  REJECTED: (
    <CancelOutlinedIcon fontSize="medium" sx={{ cursor: "pointer", color: Colors.DARK_RED }} />
  ),
  APPROVED: (
    <CheckCircleOutlinedIcon fontSize="medium" sx={{ cursor: "pointer", color: Colors.SUCCESS }} />
  ),
  CHEKOUT: <ShoppingCartCheckoutIcon fontSize="medium" sx={{ cursor: "pointer" }} />,
  RETURN: <UTurnRightIcon fontSize="medium" sx={{ cursor: "pointer" }} />,
  ATTACHMENT: <AttachFileIcon fontSize="small" sx={{ cursor: "pointer" }} />,
  SQUARE_ADD: (
    <AddBoxOutlinedIcon fontSize="medium" sx={{ cursor: "pointer", color: Colors.GREY }} />
  ),
  SQUARE_REMOVE: (
    <IndeterminateCheckBoxIcon
      fontSize="medium"
      sx={{ cursor: "pointer", color: Colors.COLLAPSE_BTN }}
    />
  ),
  REMOVE_MEMBER: (
    <PersonRemoveIcon fontSize="medium" sx={{ cursor: "pointer", color: Colors.GREY }} />
  ),

  QUEUE_EQUIPMENT: <QueueOutlinedIcon fontSize="medium" sx={{ cursor: "pointer" }} />,
  IN_TRANSIST: <LocalShippingOutlinedIcon fontSize="medium" sx={{ cursor: "pointer" }} />,
  ASSIGN_EQUIPMENT_USER: <AssignmentOutlinedIcon fontSize="medium" sx={{ cursor: "pointer" }} />,
  NOTIFICATION: (
    <NotificationsNoneOutlinedIcon
      fontSize="medium"
      sx={{ cursor: "pointer", color: Colors.WHITE }}
    />
  ),
  PERSON: <PersonOutlineIcon fontSize="small" sx={{ cursor: "pointer" }} />,
  TIME: <AccessTimeOutlinedIcon fontSize="small" sx={{ cursor: "pointer" }} />,
  FORWARD_ARROW: (
    <ArrowForwardOutlinedIcon fontSize="medium" sx={{ cursor: "pointer", color: Colors.PRIMARY }} />
  ),
  ACCOUNT_PROFILE: <AccountCircleOutlinedIcon fontSize="small" sx={{ cursor: "pointer" }} />,
  CERTIFICATE_APPROVAL: <WorkspacePremiumOutlined fontSize="small" sx={{ cursor: "pointer" }} />,
  CLOSE_OUTLINE: (
    <HighlightOffOutlinedIcon fontSize="medium" sx={{ cursor: "pointer", color: "#9D0202" }} />
  ),
  CORRECT_OUTLINE: (
    <CheckBoxOutlinedIcon fontSize="medium" sx={{ cursor: "pointer", color: "#2A8C00" }} />
  ),
  VALIDITY_DATE: <EventAvailableIcon fontSize="small" sx={{ cursor: "pointer" }} />,
  QR_MISSING: <img src={QrMissing} alt="checkin" height="20px" width="20px" />,
  CERTIFICATE_MISSING: <img src={CertificateMissing} alt="checkout" height="20px" width="20px" />,
  COMMENT: <SmsOutlinedIcon fontSize="small" sx={{ cursor: "pointer", color: Colors.PRIMARY }} />,
  COMMENT_RED: (
    <SmsOutlinedIcon fontSize="small" sx={{ cursor: "pointer", color: Colors.DARK_RED }} />
  ),
  ADD_COMMENT: (
    <AddCommentOutlinedIcon fontSize="small" sx={{ cursor: "pointer", color: Colors.PRIMARY }} />
  ),
  COMMENT_DISABLE: <SmsOutlinedIcon fontSize="small" sx={{ cursor: "pointer", color: "grey" }} />,
  INFO: <InfoIcon fontSize="small" sx={{ cursor: "pointer" }} />,
};

export default Icons;
