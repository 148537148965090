// Modal Content
const ModalContent = {
  NEW_REPORT_TITLE: "New Setup Report",
  EDIT_REPORT_TITLE: "Edit Setup Report",
  REPORT_PARAMETER_FIGURE_TITLE: "Parameter & Figure",
  REPORT_PARAMETER_FIGURE_DELETE_TITLE: "Delete Parameter & Figure",
  REPORT_PARAMETER_FIGURE_DELETE_MESSAGE:
    "Are you sure you want to delete this parameter & figure?",
  DELETE_MEMBER: "Are you sure you want to delete this member?",
  DELETE_SHIFT: "Are you sure you want to delete this shift?",
  DELETE_ACTIVITY: "Are you sure you want to delete this activity?",
  DELETE_CATEGORY: "Are you sure you want to delete this category?",
  REPORT_DELETE_TITLE: "Delete Report",
  REPORT_DELETE_MESSAGE: "Are you sure you want to delete this report?",
  ADD_SHIFT_ACTIVITY: "Shift Activity",
  EDIT_SHIFT_ACTIVITY: "Edit Shift Activity",
  FEEDBACK_DELETE_TITLE: "Delete Feedback",
  FEEDBACK_DELETE_MESSAGE: "Are you sure you want to delete this feedback?",
  QHSE_CARD: "QHSE Card",
  QHSE_UPDATE_CARD: "Update QHSE Card",
  NEW_MEDICAL_QUESTION_TYPE_TITLE: "Add New Medical Question",
  NEW_Certificate_QUESTION_TYPE_TITLE: "Add New Certificate",
  UPDATE_Certificate_QUESTION_TITLE: "Update Certificate",
  UPDATE_MEDICAL_QUESTION_TITLE: "Update Medical Question",
  MEDICAL_QUESTION_DELETE_TITLE: "Delete Medical Question",
  CERTIFICATE_QUESTION_DELETE_TITLE: "Delete Certificate",
  MEDICAL_QUESTION_DELETE_MESSAGE: "Are you sure you want to delete this medical question?",
  CERTIFICATE_QUESTION_DELETE_MESSAGE: "Are you sure you want to delete this certificate?",
  MEDICAL_QUESTION_CONFIRMATION_TITLE: "Publish Medical Question",
  CERTIFICATE_QUESTION_CONFIRMATION_TITLE: "Validity Date",
  MEDICAL_QUESTION_CONFIRMATION_MESSAGE: "Are you sure you want to publish this question",
  CERTIFICATE_CONFIRMATION_MESSAGE:
    "Are you sure you want to add validity date for this certificate",
  CERTIFICATE_CONFIRMATION_REMOVE_MESSAGE:
    "Are you sure you want to remove validity date for this certificate",
  NEW_ROLE: "Role",
  EDIT_ROLE: "Edit Role",
  ROLE_STATUS: "Role status",
  ROLE_ACTIVE_STATUS_MESSAGE: "Are you sure you want to activate this role?",
  ROLE_INACTIVE_STATUS_MESSAGE: "Are you sure you want to deactivate this role?",
  CARD_CATEGORY_DELETE_TITLE: "Delete Card",
  CARD_CATEGORY_DELETE_MESSAGE: "Are you sure you want to delete this card?",
  SHIFT_DELETE_TITLE: "Delete Shift",
  SHIFT_DETAILS_MEMBER_DELETE_TITLE: "Delete Member from the shift",
  ASSIGN_ROLE_TITLE: "Assign Role",
  ASSIGN_ROLE_MESSAGE: "Are you sure you want to assign this roles?",
  UPDATE_ADMIN: "Update Admin Profile",
  DELETE_WAREHOUSE_TITLE: "Delete Warehouse",
  DELETE_WAREHOUSE_MESSAGE: "Are you sure you want to delete this warehouse?",
  DELETE_EQUIPMENT_TITLE: "Delete Equipment",
  DELETE_EQUIPMENT_MESSAGE: "Are you sure you want to delete this equipment?",
  ASSIGN_EQUIPMENT_TITLE: "Assign Equipment",
  ASSIGN_EQUIPMENT_MESSAGE: "Are you sure you want to assign this equipment?",

  NEW_PROFILE_FUNCTION_TITLE: "Add New Profile Function",
  UPDATE_PROFILE_FUNCTION_TITLE: "Update Profile Function",
  PROFILE_FUNCTION_DELETE_TITLE: "Delete Profile Function",
  PROFILE_FUNCTION_DELETE_MESSAGE: "Are you sure you want to delete this profile function?",
  PROFILE_FUNCTION_STATUS_TITLE: "Profile Function Status",
  PROFILE_FUNCTION_ACTIVE_STATUS_MESSAGE:
    "Are you sure you want to activate this profile function?",
  PROFILE_FUNCTION_INACTIVE_STATUS_MESSAGE:
    "Are you sure you want to deactivate this profile function?",
  RETURN_CART_TITLE: "Add to return cart",
  DELETE_EQUIPMENT_CART_TITLE: "Remove from cart",
  DELETE_EQUIPMENT_CART_MESSAGE: "Are you sure you want to remove this equipment from cart?",
  RETURN_EQUIPMENT_TITLE: "Return all equipments",
  UPDATE_STOCKS: "Update Stocks",
  DELETE_REPORT_ANSWER: "Delete Report Answer",
  DELETE_REPORT_ANSWER_MESSAGE: "Are you sure you want to delete this report answer?",
  UPDATE_REPORT_ANSWER: "Update Report Answer",
  ON_ROLE_CHANGE: "Your role has been changed.",
  DELETE_TOOLBOX_TALK: "Delete Toolbox Talk",
  DELETE_TOOLBOX_TALK_MESSAGE: "Are you sure you want to delete this toolbox talk?",
};

export default ModalContent;
