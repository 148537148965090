import { createSlice } from "@reduxjs/toolkit";
import { resetStateThunk } from "redux/Thunks/Authentication";
import { roleByIdThunk } from "redux/Thunks/Role";
import Constants from "utils/Constants";

const initialState = {
  loading: Constants.IDLE,
  list: [],
};

export const roleAgreementSlice = createSlice({
  name: "roleAgreementSlice",
  initialState,
  reducers: {
    // Reducer to check all rows box row wise and all rows
    updateCheckBox(state, action) {
      const { name, target, roleData } = action.payload;
      const stateData = JSON.parse(JSON.stringify(state));
      const tempRoleCheckBox = stateData.list.map((role) => {
        // Check if role id matches
        if (
          roleData &&
          roleData[Constants.MONGOOSE_ID] &&
          role[Constants.MONGOOSE_ID] === roleData[Constants.MONGOOSE_ID]
        ) {
          if (name === "checkAllRowBox") {
            return {
              ...role,
              agreement: {
                create: target,
                read: target,
                update: target,
                delete: target,
              },
            };
          }
          return {
            ...role,
            agreement: {
              create: name === "create" ? target : role.agreement.create,
              read: name === "read" ? target : role.agreement.read,
              update: name === "update" ? target : role.agreement.update,
              delete: name === "delete" ? target : role.agreement.delete,
            },
          };
        }
        // Check All
        if (name === "checkAllRows") {
          return {
            ...role,
            agreement: {
              create: target,
              read: target,
              update: target,
              delete: target,
            },
          };
        }
        return role;
      });
      return {
        ...state,
        list: tempRoleCheckBox,
      };
    },
  },
  extraReducers: {
    [roleByIdThunk.pending]: (state) => {
      if (state.list.length === 0) state.loading = Constants.PENDING;
    },
    [roleByIdThunk.fulfilled]: (state, { payload }) => {
      state.loading = Constants.FULFILLED;
      state.list = payload.data.data;
    },
    [roleByIdThunk.rejected]: (state) => {
      state.loading = Constants.REJECTED;
    },
    [resetStateThunk.fulfilled]: (state) => {
      state.loading = Constants.IDLE;
      state.list = [];
    },
  },
});

export const { updateCheckBox } = roleAgreementSlice.actions;
export default roleAgreementSlice.reducer;
