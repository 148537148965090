import React, { useEffect, useState } from "react";

// MUI Components
import { IconButton, Switch } from "@mui/material";

// Custom Components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import Author from "components/Table/Author";
import By from "components/Table/By";

// Constants
import Constants, { Icons, defaultData } from "utils/Constants";

export default function CertificateQuestionsData(
  questionsData,
  handleEdit,
  handleDelete,
  handlePublishChange
) {
  const [rows, setRows] = useState([]);
  useEffect(() => {
    if (questionsData) {
      const tempRows = questionsData.map((element, index) => {
        const temp = {
          srNo: (
            <MDTypography display="block" variant="caption">
              {index + 1}
            </MDTypography>
          ),
          certificateQuestions: (
            <MDTypography variant="caption">
              <By
                name={element?.name}
                isSuperAdmin={
                  element.createdBy?.role?.title === defaultData.SUPER_ADMIN_ROLE ||
                  element.updatedBy?.role?.title === defaultData.SUPER_ADMIN_ROLE
                }
              />
            </MDTypography>
          ),
          sortOrder: <Author name={element?.sortOrder} />,
          validityDate: (
            <Switch
              checked={element?.validityDate}
              onChange={(e) =>
                handlePublishChange(e, {
                  body: {
                    validityDate: e.target.checked,
                  },
                  id: element[Constants.MONGOOSE_ID],
                })
              }
            />
          ),
          isRequired: (
            <Switch
              checked={element?.isRequired}
              onChange={(e) =>
                handlePublishChange(
                  e,
                  {
                    body: {
                      isRequired: e.target.checked,
                    },
                    id: element[Constants.MONGOOSE_ID],
                  },
                  "isRequired"
                )
              }
            />
          ),
          action: (
            <MDBox>
              <IconButton
                fontSize="medium"
                sx={{ cursor: "pointer", color: "#475467" }}
                onClick={() =>
                  handleEdit({
                    body: {
                      validityDate: element.validityDate,
                      name: element.name,
                      sortOrder: element.sortOrder,
                    },
                    id: element[Constants.MONGOOSE_ID],
                  })
                }
              >
                {Icons.EDIT2}
              </IconButton>
              <IconButton
                color="secondary"
                fontSize="medium"
                sx={{ cursor: "pointer" }}
                onClick={() => handleDelete(element[Constants.MONGOOSE_ID])}
              >
                {Icons.DELETE}
              </IconButton>
            </MDBox>
          ),
        };
        return temp;
      });
      setRows([...tempRows]);
    }
  }, [questionsData]);
  return {
    columns: [
      { Header: "No.", accessor: "srNo", width: "5%" },
      {
        Header: "Certificate Name",
        accessor: "certificateQuestions",
        width: "30%",
        align: "left",
      },
      { Header: "Sort Order", accessor: "sortOrder", width: "8%", align: "left" },
      { Header: "Validity Date", accessor: "validityDate", width: "8%", align: "left" },
      { Header: "Action", accessor: "action", width: "5%", align: "left" },
    ],
    rows,
  };
}
