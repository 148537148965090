/* eslint-disable  */

import axios from "axios";
import Sessions from "utils/Sessions";

const BASE_URL = process.env.REACT_APP_BASE_URL;

// Axios API Service
const ReynardBackendApiService = axios.create({
  baseURL: BASE_URL,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

ReynardBackendApiService.interceptors.request.use((config) => {
  const isSuperAdminViewingAdminPanel = Sessions.isSuperAdminViewingAdminPanel;
  if (isSuperAdminViewingAdminPanel) {
    const account = localStorage.getItem("account");
    const admin = localStorage.getItem("admin");

    if (["get", "delete"].includes(config.method)) {
      config.params = {
        ...config.params,
        ...{ account: account },
        ...{ admin: admin },
      };
    } else if (["post", "put", "patch"].includes(config.method)) {
      if (config.data instanceof FormData) {
        config.data.append("account", account);
        config.data.append("admin", admin);
      } else {
        config.data = {
          ...config.data,
          ...{ account: account },
          ...{ admin: admin },
        };
      }
    }
  }
  return config;
});

export const fetchAPIService = async (url, options = {}) => {
  let endUrl = url;
  const defaultOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Sessions.userToken}`,
    },
    withCredentials: false,
  };

  const requestOptions = {
    ...defaultOptions,
    ...options,
    headers: {
      ...defaultOptions.headers,
      ...options?.headers,
    },
  };

  const isSuperAdminViewingAdminPanel = Sessions.isSuperAdminViewingAdminPanel;

  if (isSuperAdminViewingAdminPanel) {
    const account = localStorage.getItem("account");
    const admin = localStorage.getItem("admin");

    if (requestOptions.method === "GET" || requestOptions.method === "DELETE") {
      const data = new URLSearchParams({
        ...requestOptions.params,
        ...{ account: account },
        ...{ admin: admin },
      });
      if (endUrl.includes("?")) {
        endUrl = `${endUrl}&${data}`;
      } else {
        endUrl = `${endUrl}?${data}`;
      }
    } else if (
      requestOptions.method === "POST" ||
      requestOptions.method === "PUT" ||
      requestOptions.method === "PATCH"
    ) {
      if (requestOptions.data instanceof FormData) {
        requestOptions.data.append("account", account);
        requestOptions.data.append("admin", admin);
      } else {
        requestOptions.data = {
          ...requestOptions.data,
          ...{ account: account },
          ...{ admin: admin },
        };
      }
    }
  }

  return await fetch(`${BASE_URL}${endUrl}`, requestOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Request failed with status ${response.status}`);
      }
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export default ReynardBackendApiService;
