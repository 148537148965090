import { useEffect, useState } from "react";

// Material Components
import { IconButton, Popover, Typography } from "@mui/material";

// Common Components
import Status from "components/Table/Status";
import Author from "components/Table/Author";
import MDBox from "components/MDBox";

// Redux
import { storeReportId } from "redux/Slice/Report";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

// Utils
import Constants, { Icons } from "utils/Constants";
import pxToRem from "assets/theme/functions/pxToRem";

// 3rd party library
import PropTypes from "prop-types";

export function ReportStatus({ statusList }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const checkPriorityStatus = (status) => {
    if (status.includes("closed")) return "closed";
    if (status.includes("submitted")) return "submitted";
    return "open";
  };

  const mainStatus = checkPriorityStatus(statusList);

  return (
    <MDBox display="flex" alignItems="center">
      <Status title={mainStatus} />
      {!statusList.every((status) => status.includes(mainStatus)) && (
        <IconButton aria-label="report-info-status" color="info" onClick={handleClick}>
          {Icons.INFO}
        </IconButton>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography sx={{ p: 1, backgroundColor: "#f5f5f5", fontSize: pxToRem(16) }}>
          Open: {statusList.filter((status) => status.includes("open")).length}
        </Typography>
        <Typography sx={{ p: 1, backgroundColor: "#f5f5f5", fontSize: pxToRem(16) }}>
          Submitted: {statusList.filter((status) => status.includes("submitted")).length}
        </Typography>
        <Typography sx={{ p: 1, backgroundColor: "#f5f5f5", fontSize: pxToRem(16) }}>
          Closed: {statusList.filter((status) => status.includes("closed")).length}
        </Typography>
      </Popover>
    </MDBox>
  );
}

ReportStatus.propTypes = {
  statusList: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default function ReportData(ReportDetailsList, permission) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);

  const handleView = async (id) => {
    await dispatch(storeReportId(id));
    navigate(`/client/report/report-details`, {
      state: { reportId: id, fetchData: true, type: "reportDetail" },
    });
  };

  useEffect(() => {
    if (ReportDetailsList && permission?.read) {
      const list = ReportDetailsList?.map((item, index) => {
        const temp = {
          srNo: <Author name={index + 1} />,
          project: <Author name={item?.project?.title} />,
          reportTitle: <Author name={item?.report?.title} />,
          location: <Author name={item?.location?.title} />,
          assets:
            item.asset?.length > 0 ? (
              item.asset.map((aname, i) => (
                <Author
                  key={aname[Constants.MONGOOSE_ID]}
                  name={`${aname.cableName} ${i < item.asset.length - 1 ? "," : ""}`}
                />
              ))
            ) : (
              <Author name={Constants.NA} />
            ),
          totalReports: <Author name={item?.id?.length} />,
          progress: <Author name={`${item?.totalAnsweredQuestions} / ${item?.totalQuestions}`} />,
          status: <ReportStatus statusList={item?.status} />,
          action: (
            <MDBox>
              <IconButton
                aria-label="report-parameter"
                color="error"
                onClick={() => handleView(item?.id)}
              >
                {Icons.VIEW}
              </IconButton>
            </MDBox>
          ),
        };
        return temp;
      });
      setRows([...list]);
    }
  }, [ReportDetailsList, permission]);
  return {
    columns: [
      { Header: "No.", accessor: "srNo", width: "3%" },
      { Header: "Project", accessor: "project", align: "left" },
      { Header: "Title", accessor: "reportTitle", align: "left" },
      { Header: "Location", accessor: "location", width: "13%", align: "left" },
      { Header: "Assets", accessor: "assets", width: "13%", align: "left" },
      { Header: "Total Report", accessor: "totalReports", align: "center" },
      { Header: "Progress", accessor: "progress", align: "center" },
      { Header: "Status", accessor: "status", align: "left" },
      { Header: "Action", accessor: "action", width: "13%", align: "center" },
    ],
    rows,
  };
}
