// Card Titles
const CardTitles = {
  LOGIN: "Login",
  FORGET_PASSWORD: "Forgot Password",
  SHIFT: "Shift",
  SHIFT_STATUS: "Shift Status",
  SHIFT_OVERVIEW: "Shift Overview",
  TEAM_MEMBERS: "Team Members",
  ACTIVITY: "Activity",
  ORGANIZATION: "Organization",
  SYSTEM_MANAGEMENT: "User Management",
  PROJECT_MANAGEMENT: "Project Management",
  HEALTH_SAFETY: "Quality, Health & Safety",
  ACTIONS: "Actions",
  EQUIPMENT: "Equipment",
  PERSONAL_SETTING: "Personal Setting",
  ADD_REPORT: "Add Report",
  REPORT_DETAILS: "Report Details",
  ORDER_DETAILS: "Order Details",
};

export default CardTitles;
