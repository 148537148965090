// MUI Components
import Tooltip from "@mui/material/Tooltip";

// Custom Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// 3rd party
import PropTypes from "prop-types";

export default function Author({ name, icon, nickName, maxContent, iconMargin, style }) {
  const renderWithTooltip = (content) => {
    if (content.length > maxContent) {
      return (
        <Tooltip title={content}>
          <MDTypography variant="caption" sx={style}>
            {`${content.slice(0, maxContent)}...`}
          </MDTypography>
        </Tooltip>
      );
    }
    return (
      <MDTypography variant="caption" sx={style}>
        {content}
      </MDTypography>
    );
  };

  return (
    <MDBox lineHeight={1} sx={{ display: "flex", flexDirection: "column", alignItems: "left" }}>
      <MDBox lineHeight={1} sx={{ display: "flex", flexDirection: "row" }}>
        {renderWithTooltip(name)}
        <MDTypography sx={style} display="block" variant="caption" ml={iconMargin}>
          {icon ?? ""}
        </MDTypography>
      </MDBox>
      <MDBox lineHeight={1}>{renderWithTooltip(nickName)}</MDBox>
    </MDBox>
  );
}

Author.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.string,
  nickName: PropTypes.string,
  maxContent: PropTypes.number,
  iconMargin: PropTypes.number,
  style: PropTypes.objectOf(PropTypes.any),
};

Author.defaultProps = {
  name: "",
  icon: "",
  nickName: "",
  maxContent: 20,
  iconMargin: 0,
  style: { textTransform: "capitalize" },
};
