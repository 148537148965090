import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import { Icons, defaultData } from "utils/Constants";
import { IconButton } from "@mui/material";
import Author from "components/Table/Author";
import pxToRem from "assets/theme/functions/pxToRem";

export default function ProjectCertificateData(projectCertificateList, handleDelete) {
  const [rows, setRows] = useState([]);
  useEffect(() => {
    if (projectCertificateList && projectCertificateList !== undefined) {
      const list = projectCertificateList.map((item) => {
        const temp = {
          function: <Author name={item?.function.functionName} />,
          certificate: (
            <Author name={item?.certificate?.name} maxContent={defaultData.MEDIUM_CONTENT_LENGTH} />
          ),
          action: (
            <MDBox>
              <IconButton
                color="secondary"
                fontSize="medium"
                sx={{ cursor: "pointer" }}
                onClick={() =>
                  handleDelete("Project Certificate", item?.certificate?.id, item?.function.id)
                }
              >
                {Icons.DELETE}
              </IconButton>
            </MDBox>
          ),
        };
        return temp;
      });
      setRows([...list]);
    } else {
      setRows([]);
    }
  }, [projectCertificateList]);
  return {
    ProjectCertificateColumns: [
      {
        Header: "Function",
        accessor: "function",
        align: "center",
        width: pxToRem(200),
        valign: "top",
      },
      { Header: "Certificate", accessor: "certificate", align: "left", width: pxToRem(200) },
      { Header: "Action", accessor: "action", align: "center", width: pxToRem(100) },
    ],
    ProjectCertificateRows: rows,
  };
}
