import { Box, Divider, Grid, Icon, Tooltip } from "@mui/material";
import pxToRem from "assets/theme/functions/pxToRem";

// Custom Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { useEffect, useState } from "react";
import Constants, { Icons, defaultData, Colors } from "utils/Constants";
import CustomDrawer from "components/Drawer/CustomDrawer";
import { getSafetybyIdThunk } from "redux/Thunks/SafetyCard";
import { useDispatch } from "react-redux";
import Status from "components/Table/Status";
import FullScreenImageComponent from "components/ViewFullImage/ViewImage";
import StatusUpdateData from "examples/Drawers/QHSE/data/statusUpdate";
import DataTable from "examples/Tables/DataTable";

// 3rd party library
import moment from "moment";
import PropTypes from "prop-types";

export default function ViewSafetyCard({ viewQhseAnchor, setQhseAnchor, qhseViewId }) {
  const [viewSafetyData, setViewSafetyData] = useState({});
  const [fullScreenImage, setFullScreenImage] = useState(null);
  const [viewHeaderColor, setViewHeaderColor] = useState(null);
  const dispatch = useDispatch();
  const handleCloseQhseDetailDrawer = async () => {
    setQhseAnchor({ right: false });
    setViewSafetyData({});
  };

  useEffect(() => {
    (async () => {
      const res = await dispatch(getSafetybyIdThunk(qhseViewId));
      if (res.payload.status === 200) {
        setViewSafetyData(res?.payload?.data?.data);
      }
    })();
  }, [qhseViewId]);

  useEffect(() => {
    if (viewSafetyData.cardType === "safe") {
      setViewHeaderColor(Colors.SUCCESS);
    } else if (viewSafetyData.cardType === "unsafe") {
      setViewHeaderColor(Colors.ERROR);
    } else {
      setViewHeaderColor(Colors.PRIMARY);
    }
  }, [viewSafetyData]);

  const handleImageFullView = (imageUrl) => {
    setFullScreenImage(imageUrl);
  };

  const handleCloseFullView = () => {
    setFullScreenImage(null);
  };

  const formatValue = (value, fieldType) => {
    switch (fieldType) {
      case "date":
        return moment(value[0]).format(defaultData.WEB_DATE_FORMAT);
      case "datetime":
        return moment(value[0]).format(defaultData.WEB_24_HOURS_FORMAT);
      case "checkbox":
        return value.join(", ");
      default:
        return value;
    }
  };

  const cardInfo = (key, value, fieldType) => (
    <Grid item xs={6} lg={4} key={key.toLocaleLowerCase()}>
      <MDBox display="flex" flexDirection="column" alignItems="start" key={key}>
        <MDTypography
          sx={{
            mt: 1,
            color: "#475467",
            fontFamily: "Inter",
            fontSize: pxToRem(14),
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: pxToRem(20),
            textTransform: "capitalize",
            whiteSpace: "normal",
          }}
        >
          {key.replaceAll("_", " ")}
        </MDTypography>
        <MDTypography
          sx={{
            mt: 1,
            color: "var(--default-tier-brand-1, #191A51)",
            fontFamily: "Inter",
            fontSize: pxToRem(16),
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: pxToRem(20),
            textTransform: "capitalize",
            whiteSpace: "normal",
          }}
        >
          {formatValue(value, fieldType)}
        </MDTypography>
      </MDBox>
    </Grid>
  );

  const textAreaInfo = (key, value) => (
    <Grid item xs={12} lg={12} key={key.toLocaleLowerCase()}>
      <MDBox display="flex" flexDirection="column" alignItems="start" key={key}>
        <MDTypography
          sx={{
            mt: 1,
            color: "#475467",
            fontFamily: "Inter",
            fontSize: pxToRem(14),
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: pxToRem(20),
            textTransform: "capitalize",
          }}
        >
          {key}
        </MDTypography>
        <MDTypography
          sx={{
            mt: 1,
            color: "var(--default-tier-brand-1, #191A51)",
            fontFamily: "Inter",
            fontSize: pxToRem(16),
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: pxToRem(20),
            whiteSpace: "pre-line",
            textAlign: "justify",
          }}
        >
          {value}
        </MDTypography>
      </MDBox>
    </Grid>
  );

  const { columns, rows } = StatusUpdateData(viewSafetyData?.cardLogs);
  return (
    <>
      <CustomDrawer defaultAnchor={viewQhseAnchor} onDrawerClose={handleCloseQhseDetailDrawer}>
        {Object.keys(viewSafetyData).length > 0 ? (
          <>
            <MDBox
              sx={{ backgroundColor: viewHeaderColor, padding: pxToRem(15) }}
              px={pxToRem(24)}
              display="flex"
              justifyContent="start"
              alignItems="center"
              height={pxToRem(74)}
            >
              <Icon
                sx={{ cursor: "pointer", color: "beige", marginRight: "15px" }}
                fontSize="medium"
                onClick={handleCloseQhseDetailDrawer}
              >
                {Icons.CROSS}
              </Icon>
              <MDTypography
                sx={{
                  color: "var(--white-900, #ffffff)",
                  fontFamily: "Inter",
                  fontSize: pxToRem(20),
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: pxToRem(28),
                  textAlign: "center",
                  textTransform: "capitalize",
                }}
              >
                {`${
                  viewSafetyData?.cardType
                    ? `${viewSafetyData?.cardType
                        .charAt(0)
                        .toUpperCase()}${viewSafetyData?.cardType.slice(1)}`
                    : ""
                } Card`}
              </MDTypography>
            </MDBox>
            <Divider />
            <MDBox
              mt={3}
              px={pxToRem(32)}
              display="flex"
              flexDirection={{ xs: "column", md: "row" }}
              justifyContent={{ xs: "center", md: "start" }}
              alignItems={{ xs: "center", md: "flex-start" }}
            >
              <MDBox
                ml={{ xs: 0, md: pxToRem(24) }}
                display="flex"
                flexDirection="column"
                justifyContent={{ xs: "center", md: "start" }}
                alignItems={{ xs: "center", md: "flex-start" }}
              >
                {viewSafetyData?.title?.length > defaultData.VIEW_SAFETY_CARD_LENGTH ? (
                  <Tooltip title={viewSafetyData?.title}>
                    <MDTypography
                      sx={{ textTransform: "capitalize" }}
                      variant="h5"
                      fontWeight="medium"
                      mb={1}
                      width={{ xs: "100%", sm: "auto" }}
                      textAlign={{ xs: "center", lg: "left" }}
                    >
                      <span>{`${viewSafetyData?.title.slice(
                        0,
                        defaultData.VIEW_SAFETY_CARD_LENGTH
                      )}...`}</span>
                    </MDTypography>
                  </Tooltip>
                ) : (
                  <MDTypography
                    sx={{ textTransform: "capitalize" }}
                    variant="h5"
                    fontWeight="medium"
                    mb={1}
                    width={{ xs: "100%", sm: "auto" }}
                    textAlign={{ xs: "center", lg: "left" }}
                  >
                    {viewSafetyData?.title}
                  </MDTypography>
                )}
                <Grid container spacing={2}>
                  {cardInfo("Title", viewSafetyData?.title)}
                  {cardInfo("Project", viewSafetyData?.project?.title)}
                  {viewSafetyData?.defaultProject && (
                    <>{cardInfo("Other Project Name", viewSafetyData?.defaultProject?.title)}</>
                  )}
                  {cardInfo("Location", viewSafetyData?.location?.title)}
                  {viewSafetyData?.cardType !== "ncr" && (
                    <>{cardInfo("Category", viewSafetyData?.category?.categoryName)}</>
                  )}
                  {cardInfo(
                    "Status Update",
                    viewSafetyData?.statusUpdate ? viewSafetyData?.statusUpdate : "N/A"
                  )}
                  {cardInfo(
                    "Status",
                    <Status
                      title={`${
                        viewSafetyData?.status === "submitted"
                          ? Constants.SUBMITTED_CLIENT
                          : viewSafetyData?.status.replace("_", " ")
                      }`}
                    />
                  )}
                  {viewSafetyData?.cardType === "unsafe" && (
                    <>
                      {cardInfo("Severity", viewSafetyData?.severity?.title)}
                      {cardInfo("Likelihood", viewSafetyData?.likelihood?.title)}
                    </>
                  )}

                  {viewSafetyData?.cardType === "incident" && (
                    <>
                      {cardInfo("Type", viewSafetyData?.type ? viewSafetyData?.type?.title : "N/A")}
                      {cardInfo(
                        "Time on Incident",
                        viewSafetyData?.time
                          ? moment(viewSafetyData?.time).format(defaultData.WEB_24_HOURS_FORMAT)
                          : "N/A"
                      )}
                      {cardInfo("Severity", viewSafetyData?.severity?.title)}
                      {cardInfo("Likelihood", viewSafetyData?.likelihood?.title)}
                    </>
                  )}
                  {viewSafetyData?.cardType === "ncr" && (
                    <>
                      {cardInfo(
                        "Estimated Delay / Cost",
                        viewSafetyData?.estimatedDelayCost
                          ? viewSafetyData?.estimatedDelayCost
                          : "N/A"
                      )}
                      {textAreaInfo(
                        "Item / Component / Cable",
                        viewSafetyData?.item ? viewSafetyData?.item : "N/A"
                      )}
                      {textAreaInfo(
                        "Corrective Actions",
                        viewSafetyData?.description ? viewSafetyData?.description : "N/A"
                      )}
                      {textAreaInfo(
                        "Preventive  Actions",
                        viewSafetyData?.preventiveAction ? viewSafetyData?.preventiveAction : "N/A"
                      )}
                    </>
                  )}
                  {viewSafetyData?.dynamicFields.map((item) => (
                    <>{cardInfo(item.title, item.value, item.fieldId.fieldType)}</>
                  ))}
                  {textAreaInfo(
                    "Description",
                    viewSafetyData?.description ? viewSafetyData?.description : "N/A"
                  )}
                  {viewSafetyData?.cardType !== "ncr" && (
                    <>
                      {textAreaInfo(
                        "Actions / Recommendations",
                        viewSafetyData?.actionsTaken ? viewSafetyData?.actionsTaken : "N/A"
                      )}
                    </>
                  )}

                  {/*  */}
                </Grid>
              </MDBox>
            </MDBox>
            <MDBox my={pxToRem(24)} mx={pxToRem(25)}>
              {viewSafetyData?.images.length > 0 && (
                <MDBox mb={2} ml={{ xs: 0, md: pxToRem(24) }}>
                  <MDTypography display="block" variant="h4" fontWeight="medium" mb={1} mt={1}>
                    Images
                  </MDTypography>
                </MDBox>
              )}
              <MDBox display="flex" flexDirection="row" justifyContent="flex-start">
                {viewSafetyData?.images.length > 0
                  ? viewSafetyData?.images.map((item) => (
                      <MDBox
                        key={item.preview}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        mr={2}
                      >
                        <MDBox
                          display="flex"
                          flexDirection="row"
                          justifyContent="flex-start"
                          borderRadius="8px"
                          position="relative"
                          height="70px"
                          width="60px"
                          ml={{ xs: 0, md: pxToRem(24) }}
                          sx={{
                            "&:hover .overlay": {
                              display: "flex",
                              borderRadius: "8px",
                              marginTop: "5px",
                            },
                          }}
                        >
                          <img
                            src={item?.url}
                            alt="Preview"
                            height="60px"
                            width="60px"
                            style={{
                              border: "1px solid #D0D5DD",
                              borderRadius: "8px",
                              marginTop: "5px",
                            }}
                          />
                          <Box
                            display="none"
                            position="absolute"
                            top={0}
                            right={0}
                            bottom={0}
                            left={0}
                            alignItems="center"
                            justifyContent="center"
                            bgcolor="rgba(0, 0, 0, 0.5)"
                            className="overlay"
                          >
                            <Icon
                              sx={{ color: "white", width: 30, height: 30, cursor: "pointer" }}
                              onClick={() => handleImageFullView(item?.url)}
                            >
                              {Icons.VIEW2}
                            </Icon>
                          </Box>
                        </MDBox>
                        <MDTypography variant="h6" ml={3}>
                          {item?.name}
                        </MDTypography>
                      </MDBox>
                    ))
                  : null}
              </MDBox>
              <MDBox mb={2} mt={2} ml={{ xs: 0, md: pxToRem(24) }}>
                <MDTypography display="block" variant="h5" fontWeight="medium" mb={1} mt={1}>
                  Status Update History
                </MDTypography>
              </MDBox>
              <MDBox mt={3} ml={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={{ defaultValue: defaultData.PER_PAGE }}
                  showTotalEntries={false}
                  noEndBorder
                  loading="fullfilled"
                />
              </MDBox>
            </MDBox>
          </>
        ) : (
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ height: "inherit" }}
          >
            {Icons.LOADING2}
          </MDBox>
        )}
      </CustomDrawer>
      <FullScreenImageComponent
        fullScreenImage={fullScreenImage}
        handleCloseFullView={handleCloseFullView}
        // image={image}
        src={fullScreenImage}
      />
    </>
  );
}

ViewSafetyCard.propTypes = {
  viewQhseAnchor: PropTypes.objectOf(PropTypes.any).isRequired,
  setQhseAnchor: PropTypes.func.isRequired,
  qhseViewId: PropTypes.string.isRequired,
};
