import React, { useEffect, useState } from "react";

import MDBox from "components/MDBox";
import { Card, Grid, Tooltip } from "@mui/material";
import pxToRem from "assets/theme/functions/pxToRem";
import MDTypography from "components/MDTypography";

// Custom Components
import FullScreenImageComponent from "components/ViewFullImage/ViewImage";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import BasicButton from "examples/NewDesign/CustomButton/BasicButton";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PageTitle from "examples/NewDesign/PageTitle";
import FDropdown from "components/Dropdown/FDropdown";

// Constant
import Constants, { Colors, FeatureTags, Icons, defaultData, ButtonTitles } from "utils/Constants";

// Table Data
import DataTable from "examples/Tables/DataTable";
import ReportStatusData from "layouts/report/data/userReportStatusData";

// Redux Component
import { Feature } from "flagged";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbar } from "redux/Slice/Notification";
import { updateReportUserStatus } from "redux/Slice/Report";
import { getReportById, updateReportStatus } from "redux/Thunks/Report";
import { exportReportPdf } from "redux/Thunks/Filter";

// 3rd party library
import PropTypes from "prop-types";

export default function ReportCardLayout({ children, handleReload }) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(true);
  const [fullScreenImage, setFullScreenImage] = useState(null);
  const [exportLoading, setExportLoading] = useState(false);
  const { reportDetails } = useSelector((state) => state.report);
  const ConfigData = useSelector((state) => state.config);
  const permission = ConfigData?.screens?.[6]?.screensInfo?.agreement;
  useEffect(() => {
    if (location.state) {
      const { reportId, fetchData } = location.state || { reportId: "", fetchData: false };
      if (fetchData) {
        (async () => {
          const body = {
            userReports: reportId,
          };
          await dispatch(getReportById(body));
        })();
      }
    } else {
      navigate("/client/report");
    }
  }, [location]);

  const handleReportStatusUpdate = async (event) => {
    const { value } = event.target;
    const reportId = location?.state?.reportId;
    const body = {
      userReports: reportId,
      status: value,
    };
    const res = await dispatch(updateReportStatus(body));
    if (res?.payload.status === 200) {
      await dispatch(updateReportUserStatus({ status: value, type: "main" }));
      dispatch(
        openSnackbar({
          message: Constants.REPORT_STATUS_SUCCESS,
          notificationType: Constants.NOTIFICATION_SUCCESS,
        })
      );
    } else {
      dispatch(
        openSnackbar({
          message: Constants.REPORT_STATUS_ERROR,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
  };
  const determineStatus = () => {
    const userReports = reportDetails?.[0]?.[Constants.MONGOOSE_ID]?.userReportStatus;
    if (userReports?.some((report) => report.status === "closed")) {
      return "closed";
    }
    if (userReports?.some((report) => report.status === "submitted")) {
      return "submitted";
    }
    return "open";
  };

  const newStateStatus = determineStatus();

  const handleSignatureView = (url) => {
    setFullScreenImage(url);
  };

  const handleCloseFullView = () => {
    setFullScreenImage(null);
  };

  const { columns, rows } = ReportStatusData(
    reportDetails?.[0]?.[Constants.MONGOOSE_ID]?.userReportStatus,
    handleSignatureView,
    permission
  );

  useEffect(() => {
    setTimeout(() => {
      setShowLoader(false);
    }, 1000);
  });

  const handleExport = async (format) => {
    setExportLoading(true);
    const currentDate = new Date();
    const filename = `Reynard_report_${currentDate.getFullYear()}-${
      currentDate.getMonth() + 1
    }-${currentDate.getDate()}_${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}.${format}`;
    const body = {
      userReportIds: location?.state?.reportId,
    };
    const res = await dispatch(exportReportPdf(body));
    const url = window.URL.createObjectURL(res.payload);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
    setExportLoading(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox display="flex" justifyContent="space-between">
        <PageTitle title="Report Details" />
        {location?.pathname === "/client/report/report-details" &&
          (() => (
            <MDBox display="flex">
              <BasicButton
                title={exportLoading ? ButtonTitles.EXPORTING : ButtonTitles.EXPORT}
                icon={Icons.EXPORT}
                background={Colors.WHITE}
                border
                color={Colors.BLACK}
                action={() => handleExport("pdf")}
              />
              <BasicButton
                icon={Icons.RELOAD}
                background={Colors.WHITE}
                border
                color={Colors.BLACK}
                action={handleReload}
              />
            </MDBox>
          ))()}
      </MDBox>
      <Feature name={FeatureTags.SETUP_REPORT}>
        {!showLoader ? (
          <MDBox>
            <Card id="delete-account" sx={{ mt: 3, px: 2 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <MDBox mb={2}>
                    <MDBox p={2} display="flex" justifyContent="space-between" alignItems="center">
                      <MDTypography p={pxToRem(7)} variant="h6" fontWeight="medium">
                        Report
                      </MDTypography>
                    </MDBox>
                    <MDBox
                      borderRadius="lg"
                      alignItems="center"
                      sx={{
                        border: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      }}
                    >
                      <MDBox
                        borderRadius="lg"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                          py: pxToRem(10),
                          px: pxToRem(24),
                          height: pxToRem(55),
                          borderBottomRightRadius: 0,
                          borderBottomLeftRadius: 0,
                          background: Colors.PRIMARY,
                          color: Colors.WHITE,
                        }}
                      >
                        <MDTypography variant="h6" fontWeight="medium" color="white">
                          Report Overview
                        </MDTypography>
                      </MDBox>
                      <MDBox
                        borderRadius="lg"
                        display="flex"
                        justifyContent="start"
                        alignItems="center"
                        sx={{
                          py: pxToRem(4),
                          px: pxToRem(24),
                          height: pxToRem(49),
                          borderBottom: "0.0625rem solid #E0E6F5",
                        }}
                      >
                        <MDTypography variant="h6" fontWeight="medium" width="40%">
                          Project
                        </MDTypography>
                        <MDBox
                          color="secondary"
                          sx={{
                            fontSize: pxToRem(14),
                            fontWeight: 400,
                            textTransform: "capitalize",
                          }}
                        >
                          {reportDetails?.[0]?.[Constants.MONGOOSE_ID]?.project?.title?.length >
                          defaultData.MEDIUM_CONTENT_LENGTH ? (
                            <Tooltip
                              title={reportDetails?.[0]?.[Constants.MONGOOSE_ID]?.project?.title}
                            >
                              <span>{`${reportDetails?.[0]?.[
                                Constants.MONGOOSE_ID
                              ]?.project?.title?.slice(
                                0,
                                defaultData.MEDIUM_CONTENT_LENGTH
                              )}...`}</span>
                            </Tooltip>
                          ) : (
                            reportDetails?.[0]?.[Constants.MONGOOSE_ID]?.project?.title
                          )}
                        </MDBox>
                      </MDBox>
                      <MDBox
                        borderRadius="lg"
                        display="flex"
                        justifyContent="start"
                        alignItems="center"
                        sx={{
                          py: pxToRem(4),
                          px: pxToRem(24),
                          height: pxToRem(49),
                          borderBottom: "0.0625rem solid #E0E6F5",
                        }}
                      >
                        <MDTypography variant="h6" fontWeight="medium" width="40%">
                          Report
                        </MDTypography>
                        <MDBox
                          lineHeight={1}
                          color="secondary"
                          sx={{
                            fontSize: pxToRem(14),
                            fontWeight: 400,
                            textTransform: "capitalize",
                          }}
                        >
                          {reportDetails?.[0]?.[Constants.MONGOOSE_ID]?.report?.title?.length >
                          defaultData.MEDIUM_CONTENT_LENGTH ? (
                            <Tooltip
                              title={reportDetails?.[0]?.[Constants.MONGOOSE_ID]?.report?.title}
                            >
                              <span>{`${reportDetails?.[0]?.[
                                Constants.MONGOOSE_ID
                              ]?.report?.title?.slice(
                                0,
                                defaultData.MEDIUM_CONTENT_LENGTH
                              )}...`}</span>
                            </Tooltip>
                          ) : (
                            reportDetails?.[0]?.[Constants.MONGOOSE_ID]?.report?.title
                          )}
                        </MDBox>
                      </MDBox>
                      <MDBox
                        borderRadius="lg"
                        display="flex"
                        justifyContent="start"
                        alignItems="center"
                        sx={{
                          py: pxToRem(4),
                          px: pxToRem(24),
                          height: pxToRem(49),
                          borderBottom: "0.0625rem solid #E0E6F5",
                        }}
                      >
                        <MDTypography variant="h6" fontWeight="medium" width="40%">
                          Location
                        </MDTypography>
                        <MDBox
                          lineHeight={1}
                          color="secondary"
                          sx={{ fontSize: pxToRem(14), fontWeight: 400 }}
                        >
                          {reportDetails?.[0]?.[Constants.MONGOOSE_ID]?.location?.title}
                        </MDBox>
                      </MDBox>
                      <MDBox
                        borderRadius="lg"
                        display="flex"
                        justifyContent="start"
                        alignItems="center"
                        sx={{
                          py: pxToRem(4),
                          px: pxToRem(24),
                          height: pxToRem(55),
                          borderBottom: "0.0625rem solid #E0E6F5",
                        }}
                      >
                        <MDTypography variant="h6" fontWeight="medium" width="37%">
                          Status
                        </MDTypography>
                        <MDBox
                          lineHeight={1}
                          color="secondary"
                          sx={{ fontSize: pxToRem(14), fontWeight: 400 }}
                        >
                          <FDropdown
                            variant="standard"
                            id="status"
                            name="status"
                            value={newStateStatus}
                            disabled={!permission?.update}
                            menu={["open", "closed", "submitted"]}
                            handleChange={(name, value, id) =>
                              handleReportStatusUpdate({ target: { name, value, id } })
                            }
                          />
                        </MDBox>
                      </MDBox>
                      {reportDetails?.[0]?.[Constants.MONGOOSE_ID]?.asset?.length > 0 && (
                        <MDBox
                          borderRadius="lg"
                          display="flex"
                          justifyContent="start"
                          alignItems="center"
                          sx={{
                            py: pxToRem(4),
                            px: pxToRem(24),
                            height: pxToRem(55),
                            borderBottom: "0.0625rem solid #E0E6F5",
                          }}
                        >
                          <MDTypography variant="h6" fontWeight="medium" width="40%">
                            Assets
                          </MDTypography>
                          <MDBox
                            lineHeight={1}
                            color="secondary"
                            width="60%"
                            sx={{ fontSize: pxToRem(14), fontWeight: 400 }}
                          >
                            {reportDetails?.[0]?.[Constants.MONGOOSE_ID]?.asset?.map(
                              (item, i, array) =>
                                `${item.cableName}${i < array.length - 1 ? ", " : ""}`
                            )}
                          </MDBox>
                        </MDBox>
                      )}
                    </MDBox>
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} mt={2}>
                  {/* Users Reports Status table */}
                  <MDBox p={2} display="flex" justifyContent="space-between" alignItems="center">
                    <MDTypography variant="h6" fontWeight="medium">
                      User Report Status
                    </MDTypography>
                  </MDBox>
                  <MDBox>
                    <Grid item xs={12}>
                      <MDBox>
                        <DataTable
                          table={{ columns, rows }}
                          isSorted={false}
                          entriesPerPage={{ defaultValue: 4 }}
                          showTotalEntries={false}
                          noEndBorder
                          loading={Constants.FULFILLED}
                          licenseRequired
                        />
                      </MDBox>
                    </Grid>
                  </MDBox>
                </Grid>
              </Grid>
            </Card>
            {children}
          </MDBox>
        ) : (
          <MDBox
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {Icons.LOADING2}
          </MDBox>
        )}
        <FullScreenImageComponent
          fullScreenImage={fullScreenImage}
          handleCloseFullView={handleCloseFullView}
          image={fullScreenImage}
          src={fullScreenImage}
        />
      </Feature>
    </DashboardLayout>
  );
}

ReportCardLayout.propTypes = {
  children: PropTypes.node.isRequired,
  handleReload: PropTypes.func,
};

ReportCardLayout.defaultProps = {
  handleReload: () => {},
};
