/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

import React, { useEffect, useState } from "react";

// Components
import Author from "components/Table/Author";
import Status from "components/Table/Status";

// Utils
import { defaultData } from "utils/Constants";

// 3rd party library
import moment from "moment";

export default function InventoryHistoy(inventoryHistory) {
  const [inventoryRow, setInventoryRow] = useState([]);

  useEffect(() => {
    if (inventoryHistory) {
      const tempRows = inventoryHistory?.map((element, index) => {
        const temp = {
          srNo: <Author name={index + 1} />,
          orderNo: <Author name={element?.orderNumber} />,
          type: <Author name={element?.type} />,
          date: <Author name={moment(element?.createdAt).format(defaultData.WEB_DATE_FORMAT)} />,
          quantity: <Author name={element?.quantity} />,
          status: <Status title={`${element?.status?.replace("-", " ")}`} />,
          inout: <Author name={element?.inOut} />,
          fromto: <Author name={element?.tracker} />,
        };
        return temp;
      });
      setInventoryRow([...tempRows]);
    }
  }, [inventoryHistory]);
  return {
    inventoryColumns: [
      { Header: "No.", accessor: "srNo", width: "5%" },
      { Header: "Order No.", accessor: "orderNo", width: "5%" },
      { Header: "Type", accessor: "type", width: "5%" },
      { Header: "Date", accessor: "date", align: "left" },
      { Header: "Quantity", accessor: "quantity", align: "left" },
      { Header: "Status", accessor: "status", align: "left" },
      { Header: "In/Out", accessor: "inout", align: "left" },
      { Header: "From/To", accessor: "fromto", align: "left" },
    ],
    inventoryRow,
  };
}
