import { createSlice } from "@reduxjs/toolkit";
import { resetStateThunk } from "redux/Thunks/Authentication";
import {
  warehouseListThunk,
  productByWarehouseIdThunk,
  warehouseOrderListThunk,
  warehouseOrderDetailsThunk,
  warehouseReturnOrderListThunk,
  warehouseReturnOrderDetailsThunk,
} from "redux/Thunks/Warehouse";
import Constants from "utils/Constants";

const initialState = {
  loading: Constants.IDLE,
  list: [],
  warehouseProductsLoading: Constants.IDLE,
  warehouseProduct: {
    warehouseDetails: {},
    equipment: [],
  },
  warehouseOrderLoading: Constants.IDLE,
  warehouseOrderList: [],
  orderDetails: {},
  orderDetailsLoading: Constants.IDLE,
  returnOrderLoading: Constants.IDLE,
  returnOrderList: [],
  returnOrderDetails: {},
  returnOrderDetailsLoading: Constants.IDLE,
  wmOrderDetailsEquipmentLayerData: {
    pmOrderManageId: "",
    list: [],
  },
};

export const warehouseSlice = createSlice({
  name: "warehouse",
  initialState,
  reducers: {
    removeWarehouse: (state, action) => {
      state.list = state.list.filter((item) => item?.[Constants.MONGOOSE_ID] !== action.payload);
    },
    removeEquipment: (state, action) => {
      state.warehouseProduct.equipment = state.warehouseProduct.equipment.filter(
        (item) => item?.[Constants.MONGOOSE_ID] !== action.payload
      );
    },
    reloadWarehouse: (state) => {
      state.loading = Constants.PENDING;
    },
    resetWarehouseList(state) {
      state.loading = Constants.PENDING;
      state.list = [];
    },
    reloadWarehouseOrderRequest: (state) => {
      state.warehouseOrderLoading = Constants.PENDING;
      state.warehouseOrderList = [];
      state.returnOrderLoading = Constants.PENDING;
      state.returnOrderList = [];
    },

    resetOrderDetail: (state) => {
      state.orderDetailsLoading = Constants.PENDING;
      state.orderDetails = {};
    },

    resetReturnOrderDetail: (state) => {
      state.returnOrderDetailsLoading = Constants.PENDING;
      state.returnOrderDetails = {};
    },

    updateEquipmentOrderQuantity: (state, action) => {
      const tempWarehouseOrder = { ...state.orderDetails };
      tempWarehouseOrder.equipmentTypeData = tempWarehouseOrder.equipmentTypeData.map((item) => {
        if (item?.pmOrderManageId === action.payload?.id) {
          return {
            ...item,
            wmApprovedQuantity: action.payload?.approvedQuantity,
            totalPrice: action.payload.approvedQuantity * action.payload.price,
          };
        }
        return item;
      });
      state.orderDetails = tempWarehouseOrder;
    },

    // Load 2nd layer data for the equipment type on expand of the row
    loadWmOrderDetailsEquipmentLayerData(state, action) {
      const { pmOrderManageId, list } = action.payload;
      state.wmOrderDetailsEquipmentLayerData = {
        pmOrderManageId,
        list,
      };
    },

    // Update Wm Comments in the equipment type data
    updateWmComments(state, action) {
      const tempWarehouseOrder = { ...state.orderDetails };
      tempWarehouseOrder.equipmentTypeData = tempWarehouseOrder.equipmentTypeData.map((item) => {
        if (item?.pmOrderManageId === action.payload?.id) {
          const existingComments = item.comments || {};
          const updatedComments = {
            ...existingComments,
            wmComments: action.payload?.comments,
          };
          return { ...item, comments: updatedComments };
        }
        return item;
      });
      state.orderDetails = tempWarehouseOrder;
    },

    // Update List on Single Reject
    updateListOnReject(state, action) {
      const temp = state.orderDetails;
      temp.equipmentTypeData = temp.equipmentTypeData.map((item) => {
        if (item?.pmOrderManageId === action.payload) {
          return {
            ...item,
            status: "rejected",
            wmApprovedQuantity: 0,
            price: 0,
          };
        }
        return item;
      });
      state.orderDetails = temp;
      state.loading = Constants.FULFILLED;
    },
  },

  extraReducers: {
    [warehouseListThunk.pending]: (state) => {
      if (state.list.length === 0) state.loading = Constants.PENDING;
    },
    [warehouseListThunk.fulfilled]: (state, { payload }) => {
      state.loading = Constants.FULFILLED;
      if (payload?.type === "add") {
        state.list = payload?.data?.data;
      } else {
        state.list.push(...payload.data.data);
      }
    },
    [warehouseListThunk.rejected]: (state) => {
      state.loading = Constants.REJECTED;
    },
    [productByWarehouseIdThunk.pending]: (state) => {
      if (Object.keys(state.warehouseProduct.warehouseDetails).length === 0)
        state.warehouseProductsLoading = Constants.PENDING;
    },
    [productByWarehouseIdThunk.fulfilled]: (state, { payload }) => {
      state.warehouseProductsLoading = Constants.FULFILLED;
      if (payload?.type === "add") {
        state.warehouseProduct.warehouseDetails = payload?.data?.data[0];
        state.warehouseProduct.equipment = payload?.data?.data[0]?.equipments;
      } else {
        state.warehouseProduct.equipment.push(...(payload.data.data[0]?.equipments || []));
      }
    },
    [productByWarehouseIdThunk.rejected]: (state) => {
      state.warehouseProductsLoading = Constants.REJECTED;
    },
    [warehouseOrderListThunk.pending]: (state) => {
      if (state.warehouseOrderList.length === 0) state.warehouseOrderLoading = Constants.PENDING;
    },
    [warehouseOrderListThunk.fulfilled]: (state, action) => {
      state.warehouseOrderLoading = Constants.FULFILLED;
      if (action.payload?.type === "add") {
        state.warehouseOrderList = action.payload.data.data;
      } else {
        state.warehouseOrderList.push(...action.payload.data.data);
      }
    },
    [warehouseOrderListThunk.rejected]: (state) => {
      state.warehouseOrderLoading = Constants.REJECTED;
    },
    [warehouseReturnOrderListThunk.pending]: (state) => {
      if (state.returnOrderList.length === 0) state.returnOrderLoading = Constants.PENDING;
    },
    [warehouseReturnOrderListThunk.fulfilled]: (state, action) => {
      state.returnOrderLoading = Constants.FULFILLED;
      if (action.payload?.type === "add") {
        state.returnOrderList = action.payload.data.data;
      } else {
        state.returnOrderList.push(...action.payload.data.data);
      }
    },
    [warehouseReturnOrderListThunk.rejected]: (state) => {
      state.returnOrderLoading = Constants.REJECTED;
    },
    [warehouseOrderDetailsThunk.pending]: (state) => {
      state.orderDetailsLoading = Constants.PENDING;
    },
    [warehouseOrderDetailsThunk.fulfilled]: (state, action) => {
      if (action.payload.data.data.length === 0) {
        state.orderDetailsLoading = Constants.NO_DATA;
        return;
      }
      const orderDetails = action.payload.data.data;
      const modifiedOrderDetails = orderDetails[0]?.equipmentTypeData?.map((item) => ({
        ...item,
        wmApprovedQuantity:
          item.status === "requested" ? item.pmRequestedQuantity : item.wmApprovedQuantity,
      }));
      orderDetails[0].equipmentTypeData = modifiedOrderDetails;
      state.orderDetails = orderDetails.length > 0 ? orderDetails[0] : {};
      state.orderDetailsLoading = Constants.FULFILLED;
    },
    [warehouseOrderDetailsThunk.rejected]: (state) => {
      state.orderDetailsLoading = Constants.REJECTED;
    },
    [warehouseReturnOrderDetailsThunk.pending]: (state) => {
      state.returnOrderDetailsLoading = Constants.PENDING;
    },
    [warehouseReturnOrderDetailsThunk.fulfilled]: (state, action) => {
      if (action.payload.data.data.length === 0) {
        state.returnOrderDetailsLoading = Constants.NO_DATA;
        return;
      }
      const orderDetails = action.payload.data.data;
      const modifiedOrderDetails = orderDetails[0]?.equipmentTypeData?.map((item) => ({
        ...item,
        wmApprovedQuantity:
          item.status === "requested" ? item.pmRequestedQuantity : item.wmApprovedQuantity,
      }));
      orderDetails[0].equipmentTypeData = modifiedOrderDetails;
      state.returnOrderDetails = orderDetails.length > 0 ? orderDetails[0] : {};
      state.returnOrderDetailsLoading = Constants.FULFILLED;
    },
    [warehouseReturnOrderDetailsThunk.rejected]: (state) => {
      state.returnOrderDetailsLoading = Constants.REJECTED;
    },
    [resetStateThunk.fulfilled]: (state) => {
      state.loading = Constants.IDLE;
      state.warehouseProductsLoading = Constants.IDLE;
      state.list = [];
      state.warehouseProduct = {
        warehouseDetails: {},
        equipment: [],
      };
      state.warehouseOrderLoading = Constants.IDLE;
      state.warehouseOrderList = [];
      state.orderDetails = {};
      state.orderDetailsLoading = Constants.IDLE;
      state.returnOrderDetails = {};
      state.returnOrderDetailsLoading = Constants.IDLE;
    },
  },
});

export const {
  removeWarehouse,
  removeEquipment,
  reloadWarehouse,
  resetWarehouseList,
  reloadWarehouseOrderRequest,
  updateEquipmentOrderQuantity,
  resetOrderDetail,
  resetReturnOrderDetail,
  loadWmOrderDetailsEquipmentLayerData,
  updateWmComments,
  updateListOnReject,
} = warehouseSlice.actions;

export default warehouseSlice.reducer;
