import React, { useMemo, useEffect, useState } from "react";

// 3rd party library
import PropTypes from "prop-types";
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";
import jwtDecode from "jwt-decode";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Autocomplete from "@mui/material/Autocomplete";

// Common components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import { Pagination, TableCell } from "@mui/material";
import pxToRem from "assets/theme/functions/pxToRem";

// Constants
import Constants, { Icons, defaultData, Colors } from "utils/Constants";
import Session from "utils/Sessions";

// Redux
import { useSelector } from "react-redux";

function ReportCalculationTable({
  entriesPerPage,
  canSearch,
  showTotalEntries,
  table,
  isSorted,
  loading,
  licenseRequired,
  // currentPage, handleTablePagination, handleCurrentPage are use when need data size is more
  // Use this parameter to retrieve additional data when a user visits the last page
  currentPage,
  handleTablePagination,
  handleCurrentPage,
  backgroundColor, // New prop to specify the background color
  textColor,
  extraContent,
  extraHeaders,
  footerList,
}) {
  const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ["25"];
  const [status, setStatus] = useState(Constants.PENDING);
  const ConfigData = useSelector((state) => state.config);
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: currentPage } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  // update the status on role change, when superadmin login as admin and data changes
  useEffect(() => {
    const token = jwtDecode(Session.userToken);
    const { role } = token;

    const setPending = () => setStatus(Constants.PENDING);
    const setRejected = () => setStatus(Constants.REJECTED);
    const setFulfilled = () => setStatus(Constants.FULFILLED);
    const setNoData = () => setStatus("noData");

    const isSuperAdmin =
      role === defaultData.SUPER_ADMIN_ROLE && !Session.isSuperAdminViewingAdminPanel;
    const isPending = ConfigData.loading === Constants.PENDING || loading === Constants.PENDING;
    const isRejected = ConfigData.loading === Constants.REJECTED || loading === Constants.REJECTED;
    const isFulfilledWithData =
      loading === Constants.FULFILLED && rows.length > 0 && pageOptions.length > 0;
    const isFulfilledNoData =
      loading === Constants.FULFILLED && rows.length === 0 && pageOptions.length === 0;
    const isConfigFulfilled = ConfigData.loading === "fulfilled";

    // for superadmin
    if (isSuperAdmin || !licenseRequired) {
      if (loading === Constants.PENDING) setPending();
      else if (isRejected) setRejected();
      else if (isFulfilledWithData) setFulfilled();
      else if (isFulfilledNoData) setNoData();
    }
    // for admin
    else if (isPending) setPending();
    else if (isRejected) setRejected();
    else if (isConfigFulfilled && isFulfilledWithData) setFulfilled();
    else if (isConfigFulfilled && isFulfilledNoData) setNoData();
  }, [Session.userToken, Session.isSuperAdminViewingAdminPanel, ConfigData.loading, table]);

  // set current page to last page when the current page has no data
  // Works when some data is deleted from the last page
  useEffect(() => {
    if (pageOptions.length > 0 && pageOptions.length <= currentPage) {
      const lastPage = pageOptions.length - 1;
      gotoPage(lastPage);
      handleCurrentPage(lastPage);
    }
  }, [pageOptions]);
  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value) => setPageSize(value);

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  const handlePageChange = (event, value) => {
    if (pageOptions.length === value) handleTablePagination(value);
    if (event.target.innerText === "Previous") {
      previousPage();
    } else if (event.target.innerText === "Next") {
      nextPage();
    } else {
      gotoPage(value - 1);
      handleCurrentPage(value - 1);
    }
  };

  const handleCellBackground = (value) => {
    if (!value) return "transparent";

    // Check if the value is a date
    const datePattern = /^\d{2}-\d{2}-\d{4}$/;
    if (datePattern.test(value)) {
      return Colors.LIGHT_GREEN3;
    }

    if (value) {
      const numericValue = parseFloat(value.replace("%", ""));
      if (numericValue <= 50) {
        return Colors.PINK;
      }
      if (numericValue <= 75) {
        return Colors.LIGHT_PINK;
      }
      if (numericValue < 100) {
        return Colors.LIGHT_ORANGE1;
      }
      if (numericValue === 100) {
        return Colors.LIGHT_GREEN3;
      }
      return "transparent";
    }
    return "transparent";
  };

  return (
    <MDBox
      sx={{
        backgroundColor: "White",
        borderRadius: "10px",
        border: "1px solid #E0E6F5",
        width: "100%",
      }}
    >
      <TableContainer sx={{ boxShadow: "none" }}>
        {entriesPerPage.entries || canSearch ? (
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
            backgroundColor={backgroundColor}
            color={textColor}
          >
            {entriesPerPage && (
              <MDBox display="flex" alignItems="center">
                <Autocomplete
                  disableClearable
                  value={pageSize.toString()}
                  options={entries}
                  onChange={(event, newValue) => {
                    setEntriesPerPage(parseInt(newValue, 10));
                  }}
                  size="small"
                  sx={{ width: "5rem" }}
                  renderInput={(params) => <MDInput {...params} />}
                />
                <MDTypography variant="caption" color="secondary">
                  &nbsp;&nbsp;entries per page
                </MDTypography>
              </MDBox>
            )}
            {canSearch && (
              <MDBox width="12rem" ml="auto">
                <MDInput
                  placeholder="Search..."
                  value={search}
                  size="small"
                  fullWidth
                  onChange={({ currentTarget }) => {
                    setSearch(search);
                    onSearchChange(currentTarget.value);
                  }}
                />
              </MDBox>
            )}
          </MDBox>
        ) : null}
        <Table {...getTableProps()}>
          <MDBox component="thead">
            {extraHeaders.length > 0 &&
              extraHeaders?.map((headercolumns, idx) => (
                <TableRow key={headercolumns}>
                  {headercolumns?.map((column, colIdx) => (
                    <TableCell
                      key={column.key}
                      align={column.align ? column.align : "left"}
                      colSpan={column.colSpan ? column.colSpan : 1}
                      sx={({ typography: { fontWeightBold } }) => ({
                        fontSize: pxToRem(14),
                        fontWeight: fontWeightBold,
                        backgroundColor: column.Header !== "" ? Colors.WHITE : "transparent",
                        color: Colors.PRIMARY,
                        width: column.width ? column.width : "auto",
                        border: column.Header !== "" ? "1px solid #E0E6F5" : "none",
                        borderTop: "none",
                        borderBottom: idx === extraHeaders.length - 1 && "none",
                        borderRight: colIdx === headercolumns.length - 1 && "none",
                      })}
                    >
                      {column.Header}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <DataTableHeadCell
                    {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                    width={column.width ? column.width : "auto"}
                    align={column.align ? column.align : "left"}
                    sorted={setSortedValue(column)}
                    backgroundColor={backgroundColor}
                    textColor={textColor}
                  >
                    {column.render("Header")}
                  </DataTableHeadCell>
                ))}
              </TableRow>
            ))}
          </MDBox>
          <TableBody {...getTableBodyProps()}>
            {(() => {
              switch (status) {
                case Constants.PENDING:
                  return (
                    <TableRow>
                      <TableCell colSpan={columns.length} align="center">
                        <MDBox py={5} display="flex" justifyContent="center" alignItems="center">
                          {Icons.LOADING2}
                        </MDBox>
                      </TableCell>
                    </TableRow>
                  );

                case Constants.FULFILLED: {
                  const rowData = rows?.map((row) => {
                    const { original: locationObj } = row;
                    if (locationObj?.assets?.length === 0) {
                      return (
                        <TableRow key={locationObj?.key}>
                          <TableCell
                            key={locationObj?.key}
                            colSpan={locationObj?.colSpan || 1}
                            align={locationObj?.align || "left"}
                            rowSpan={locationObj?.rowSpan || 1}
                            width={locationObj?.width || "auto"}
                            sx={({ typography: { fontWeightBold } }) => ({
                              fontSize: pxToRem(14),
                              fontWeight: fontWeightBold,
                              color: Colors.PRIMARY,
                              backgroundColor: "transparent",
                              border: "1px solid #E0E6F5",
                              borderLeft: "none",
                            })}
                          >
                            {locationObj.location}
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            sx={({ typography: { fontWeightBold } }) => ({
                              fontSize: pxToRem(14),
                              fontWeight: fontWeightBold,
                              border: "1px solid #E0E6F5",
                              backgroundColor: "transparent",
                              color: Colors.PRIMARY,
                            })}
                          />
                          {locationObj.reports.map((report) => {
                            if (report.type === "location") {
                              return (
                                <TableCell
                                  key={report?.key}
                                  align={report?.align || "left"}
                                  rowSpan={report?.rowSpan || 1}
                                  colSpan={report?.colSpan || 1}
                                  width={report?.width || "auto"}
                                  sx={({ typography: { fontWeightBold } }) => ({
                                    fontSize: pxToRem(14),
                                    fontWeight: fontWeightBold,
                                    border: "1px solid #E0E6F5",
                                    backgroundColor:
                                      report?.reportList[0]?.bgColor || "transparent",
                                    color: Colors.PRIMARY,
                                  })}
                                >
                                  {report.reportList[0].completion}
                                </TableCell>
                              );
                            }
                            if (
                              report.type === "asset_per_location" ||
                              report.type === "multiple_assets"
                            ) {
                              return (
                                <TableCell
                                  key={report?.key}
                                  align={report?.align || "left"}
                                  rowSpan={report?.rowSpan || 1}
                                  colSpan={report?.colSpan || 1}
                                  width={report?.width || "auto"}
                                  sx={({ typography: { fontWeightBold } }) => ({
                                    fontSize: pxToRem(14),
                                    fontWeight: fontWeightBold,
                                    border: "1px solid #E0E6F5",
                                    backgroundColor:
                                      report?.reportList[0]?.bgColor || "transparent",
                                    color: Colors.PRIMARY,
                                  })}
                                >
                                  {report.reportList[0].completion}
                                </TableCell>
                              );
                            }
                            return null;
                          })}
                        </TableRow>
                      );
                    }
                    return locationObj?.assets?.map((asset, assetIndex) => (
                      <TableRow key={asset?.key}>
                        {assetIndex === 0 && (
                          <TableCell
                            key={locationObj?.key}
                            colSpan={locationObj?.colSpan || 1}
                            align={locationObj?.align || "left"}
                            rowSpan={locationObj?.rowSpan || 1}
                            width={locationObj?.width || "auto"}
                            sx={({ typography: { fontWeightBold } }) => ({
                              fontSize: pxToRem(14),
                              fontWeight: fontWeightBold,
                              color: Colors.PRIMARY,
                              backgroundColor: "transparent",
                              border: "1px solid #E0E6F5",
                              borderLeft: "none",
                            })}
                          >
                            {locationObj.location}
                          </TableCell>
                        )}
                        <TableCell
                          align={asset?.align || "left"}
                          width={asset?.width || "auto"}
                          colSpan={asset?.colSpan || 1}
                          rowSpan={asset?.rowSpan || 1}
                          sx={({ typography: { fontWeightBold } }) => ({
                            fontSize: pxToRem(14),
                            fontWeight: fontWeightBold,
                            border: "1px solid #E0E6F5",
                            backgroundColor: "transparent",
                            color: Colors.PRIMARY,
                          })}
                        >
                          {asset?.title}
                        </TableCell>
                        {locationObj.reports.map((report) => {
                          if (report.type === "location") {
                            return (
                              assetIndex === 0 && (
                                <TableCell
                                  key={report?.key}
                                  align={report?.align || "left"}
                                  rowSpan={report?.rowSpan || 1}
                                  colSpan={report?.colSpan || 1}
                                  width={report?.width || "auto"}
                                  sx={({ typography: { fontWeightBold } }) => ({
                                    fontSize: pxToRem(14),
                                    fontWeight: fontWeightBold,
                                    border: "1px solid #E0E6F5",
                                    backgroundColor:
                                      report?.reportList[0]?.bgColor || "transparent",
                                    color: Colors.PRIMARY,
                                  })}
                                >
                                  {report.reportList[0].completion}
                                </TableCell>
                              )
                            );
                          }
                          if (report.type === "asset_per_location") {
                            return (
                              <TableCell
                                align={report?.align || "left"}
                                rowSpan={report?.rowSpan || 1}
                                colSpan={report?.colSpan || 1}
                                width={report?.width || "auto"}
                                sx={({ typography: { fontWeightBold } }) => ({
                                  fontSize: pxToRem(14),
                                  fontWeight: fontWeightBold,
                                  border: "1px solid #E0E6F5",
                                  backgroundColor:
                                    report?.reportList[assetIndex]?.bgColor || "transparent",
                                  color: Colors.PRIMARY,
                                })}
                              >
                                {report.reportList[assetIndex].completion}
                              </TableCell>
                            );
                          }
                          if (report.type === "multiple_assets") {
                            return (
                              <TableCell
                                align={report?.align || "left"}
                                rowSpan={report?.rowSpan || 1}
                                colSpan={report?.colSpan || 1}
                                width={report?.width || "auto"}
                                sx={({ typography: { fontWeightBold } }) => ({
                                  fontSize: pxToRem(14),
                                  fontWeight: fontWeightBold,
                                  border: "1px solid #E0E6F5",
                                  backgroundColor:
                                    report?.reportList[assetIndex]?.bgColor || "transparent",
                                  color: Colors.PRIMARY,
                                })}
                              >
                                {report.reportList[assetIndex].completion}
                              </TableCell>
                            );
                          }

                          return null;
                        })}
                      </TableRow>
                    ));
                  });
                  return rowData;
                }

                case "noData":
                  return (
                    <TableRow>
                      <TableCell colSpan={columns.length} align="center">
                        <MDTypography variant="h4" color="secondary">
                          {Constants.NO_DATA_FOUND}
                        </MDTypography>
                      </TableCell>
                    </TableRow>
                  );

                case Constants.REJECTED:
                default:
                  return (
                    <TableRow>
                      <TableCell colSpan={columns.length} align="center">
                        <MDTypography variant="h4" color="secondary">
                          {Constants.SOMETHING_WENT_WRONG}
                        </MDTypography>
                      </TableCell>
                    </TableRow>
                  );
              }
            })()}
          </TableBody>
          <MDBox component="tfoot">
            {footerList.length > 0 &&
              footerList?.map((footerColumn, footerIdx) => (
                <TableRow key={footerColumn}>
                  {footerColumn?.map((column, nestFooterIdx) => (
                    <TableCell
                      key={column.key}
                      align={column.align ? column.align : "left"}
                      colSpan={column.colSpan ? column.colSpan : 1}
                      sx={({ typography: { fontWeightBold } }) => ({
                        fontSize: pxToRem(14),
                        fontWeight: fontWeightBold,
                        backgroundColor:
                          footerIdx === 0 && nestFooterIdx === 0
                            ? Colors.PRIMARY
                            : handleCellBackground(column.Footer),

                        color:
                          footerIdx === 0 && nestFooterIdx === 0 ? Colors.WHITE : Colors.PRIMARY,
                        width: column.width ? column.width : "auto",
                        border: column.Footer !== "" ? "1px solid #E0E6F5" : "none",
                        borderTop: "1px solid #E0E6F5",
                        borderLeft: footerIdx === 0 && nestFooterIdx === 0 && "none",
                        borderBottom: footerIdx === footerList.length - 1 && "none",
                        borderRight: nestFooterIdx === footerColumn.length - 1 && "none",
                      })}
                    >
                      {column.Footer}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </MDBox>
        </Table>
      </TableContainer>
      {status === Constants.FULFILLED && page.length > 0 && pageOptions.length > 1 && (
        <MDBox
          sx={{ color: "#f6f7ff" }}
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent="center"
          alignItems={{ xs: "flex-start", sm: "center" }}
          p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
        >
          <Pagination
            count={pageOptions.length}
            page={pageIndex + 1}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
            sx={{
              "& .Mui-selected:hover": {
                backgroundColor: "#f6f7ff",
              },
              "& .Mui-selected": {
                backgroundColor: "#e0e1f5",
              },
              ".MuiPaginationItem-root": {
                borderRadius: "50%",
                border: "none",
              },
            }}
          />
        </MDBox>
      )}
      {extraContent}
    </MDBox>
  );
}

// Setting default values for the props of ReportCalculationTable
ReportCalculationTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
  currentPage: 0,
  loading: Constants.PENDING,
  licenseRequired: false,
  handleTablePagination: () => {},
  handleCurrentPage: () => {},
  backgroundColor: "",
  textColor: "",
  extraContent: null,
  extraHeaders: [],
  footerList: [],
};

// Typechecking props for the ReportCalculationTable
ReportCalculationTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  loading: PropTypes.string,
  licenseRequired: PropTypes.bool,
  handleTablePagination: PropTypes.func,
  currentPage: PropTypes.number,
  handleCurrentPage: PropTypes.func,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  extraContent: PropTypes.node,
  extraHeaders: PropTypes.arrayOf(PropTypes.array),
  footerList: PropTypes.arrayOf(PropTypes.array),
};

export default ReportCalculationTable;
