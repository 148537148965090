import Sessions from "utils/Sessions";
import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiService from "redux/ApiService/ApiService";

const getAllCertificates = createAsyncThunk("/get-certificates", async (param) => {
  const res = await ApiService.get(`/upload-certificate/certificate-list?${param}`, {
    headers: { Authorization: `Bearer ${Sessions.userToken}` },
  })
    .then((r) => r)
    .catch((err) => err.response);
  const params = new URLSearchParams(param);
  const page = params.get("page");
  return page === "0"
    ? { data: res.data, type: "add", status: res.status }
    : { data: res.data, type: "append", status: res.status };
});

export const approveCertificate = createAsyncThunk("/approve-certificate", async (payload) => {
  const res = await ApiService.patch(
    `/upload-certificate/${payload.id}`,
    { ...payload.body },
    {
      headers: { Authorization: `Bearer ${Sessions.userToken}` },
    }
  )
    .then((r) => r)
    .catch((err) => err.response);
  return res;
});

export default getAllCertificates;
