import React, { useEffect } from "react";

// MUI Components
import { Icon } from "@mui/material";

// Custom Components
import MDBox from "components/MDBox";

// Constants
import { Icons } from "utils/Constants";

// 3rd party library
import PropTypes from "prop-types";
import pxToRem from "assets/theme/functions/pxToRem";

export default function FullScreenImageComponent({
  fullScreenImage,
  handleCloseFullView,
  handlePreviousImage,
  handleNextImage,
  image,
  src,
  width,
  height,
}) {
  const isPdf = typeof src === "string" && src.includes(".pdf");

  useEffect(() => {
    if (isPdf && fullScreenImage) {
      window.open(src, "_blank");
      handleCloseFullView();
    }
  }, [isPdf, fullScreenImage, src, handleCloseFullView]);

  return (
    <MDBox>
      {fullScreenImage && !isPdf && (
        <MDBox
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 9999,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Icon
            style={{
              position: "fixed",
              top: "1rem",
              right: "1rem",
              width: "30px",
              height: "30px",
              display: "flex",
              justifyContent: "flex-end",
            }}
            onClick={handleCloseFullView}
          >
            {Icons.REJECT}
          </Icon>
          {image && handlePreviousImage && image.length > 1 ? (
            <Icon
              style={{
                position: "absolute",
                top: "50%",
                left: "1rem",
                transform: "translateY(-50%)",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={handlePreviousImage}
              fontSize="large"
            >
              {Icons.PREVIOUS}
            </Icon>
          ) : null}
          <img
            src={src}
            alt="Full Screen"
            style={{
              position: "absolute",
              width,
              height,
              objectFit: "contain",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
          {image && handleNextImage && image.length > 1 ? (
            <Icon
              style={{
                position: "absolute",
                top: "50%",
                right: "1rem",
                transform: "translateY(-50%)",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={handleNextImage}
              fontSize="large"
            >
              {Icons.NEXT}
            </Icon>
          ) : null}
        </MDBox>
      )}
    </MDBox>
  );
}

FullScreenImageComponent.propTypes = {
  fullScreenImage: PropTypes.bool,
  handleCloseFullView: PropTypes.func,
  handlePreviousImage: PropTypes.func,
  handleNextImage: PropTypes.func,
  image: PropTypes.arrayOf(PropTypes.object),
  src: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

FullScreenImageComponent.defaultProps = {
  fullScreenImage: false,
  handleCloseFullView: () => {},
  handlePreviousImage: () => {},
  handleNextImage: () => {},
  image: [],
  src: "",
  width: pxToRem(1113),
  height: pxToRem(542),
};
