import React, { useEffect, useState } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import { IconButton } from "@mui/material";
import Constants, { Icons } from "utils/Constants";

// Components
import Author from "components/Table/Author";
import CustomImage from "components/Table/GroupImage";

export default function OrderDetailsData(
  list,
  status,
  openProjectConfirmationBox,
  addToQueue,
  permission,
  buttonLoading
) {
  const [projectrows, setProjectRows] = useState([]);
  useEffect(() => {
    if (list) {
      const tempRows = list?.map((element, index) => {
        const temp = {
          collapseBtn: (
            <Author
              name={{ projectId: element[Constants.MONGOOSE_ID], data: element?.equipmentTypes }}
            />
          ),
          srNo: <Author name={index + 1} />,
          project: <Author name={element?.projectName} />,
          requestedEquipment: (
            <MDBox display="flex" flexDirection="row" justifyContent="flex-start">
              {element?.equipmentTypes?.length > 5
                ? element?.equipmentTypes.slice(0, 5).map((item, imgIndex) => (
                    <React.Fragment key={item[Constants.MONGOOSE_ID]}>
                      <CustomImage
                        item={item?.equipmentTypeImage?.url}
                        index={imgIndex}
                        requestedQuantity={item?.totalEngineerRequestedQuantity}
                        remainingLength={(element?.equipmentTypes?.length ?? 0) - 5}
                      />
                    </React.Fragment>
                  ))
                : element?.equipmentTypes.map((item, imgIndex) => (
                    <React.Fragment key={item[Constants.MONGOOSE_ID]}>
                      <CustomImage
                        item={item?.equipmentTypeImage?.url}
                        index={imgIndex}
                        requestedQuantity={item?.totalEngineerRequestedQuantity}
                      />
                    </React.Fragment>
                  ))}
            </MDBox>
          ),
          reqQty: (
            <Author
              name={element?.equipmentTypes.reduce(
                (acc, total) => acc + parseInt(total?.totalEngineerRequestedQuantity, 10),
                0
              )}
            />
          ),
          reqEqpQty: <Author name={element?.totalEquipmentType} />,
          action: (
            <MDBox>
              {(status === "pending" || status === "") && permission?.update ? (
                <IconButton
                  aria-label="fingerprint"
                  color="info"
                  disabled={buttonLoading}
                  onClick={() =>
                    addToQueue(element[Constants.MONGOOSE_ID], element?.equipmentTypes)
                  }
                >
                  {Icons.QUEUE_EQUIPMENT}
                </IconButton>
              ) : null}
              {status === "queue" && permission?.update && (
                <IconButton
                  aria-label="fingerprint"
                  color="info"
                  onClick={() =>
                    openProjectConfirmationBox(
                      element?.pmOrderId,
                      "placeOrder",
                      element?.equipmentTypes
                    )
                  }
                >
                  {Icons.CHEKOUT}
                </IconButton>
              )}
              {status === "requested" ? (
                <MDBox>
                  <IconButton aria-label="fingerprint" color="info">
                    {Icons.CHECKIN}
                  </IconButton>
                </MDBox>
              ) : null}
              {status !== "requested" && permission?.update ? (
                <IconButton
                  aria-label="fingerprint"
                  color="error"
                  onClick={() =>
                    openProjectConfirmationBox(element[Constants.MONGOOSE_ID], "rejected")
                  }
                >
                  {Icons.REJECTED}
                </IconButton>
              ) : null}
            </MDBox>
          ),
        };
        return temp;
      });
      setProjectRows([...tempRows]);
    }
  }, [list, buttonLoading]);

  const projectColumns = [
    { Header: "", accessor: "collapseBtn", width: "5%" },
    { Header: "No.", accessor: "srNo", width: "5%" },
    { Header: "Project", accessor: "project" },

    { Header: "Equipment Type", accessor: "requestedEquipment", align: "left" },
    { Header: "Total Requested Qty", accessor: "reqQty", align: "center" },
    { Header: "Total Requested Equipment Type", accessor: "reqEqpQty", align: "center" },
  ];
  if (status !== "rejected" && status !== "" && permission?.update) {
    projectColumns.push({ Header: "Action", accessor: "action", width: "10%", align: "center" });
  }

  const tableData = {
    projectColumns,
    projectrows,
  };

  return tableData;
}
