// Form Fields types
const FormFields = {
  DROPDOWN_LABEL: "Dropdown",
  DROPDOWN_VALUE: "options",
  SHORT_ANSWER_LABEL: "Short Answer",
  SHORT_ANSWER_VALUE: "text",
  CHECKBOX_LABEL: "Checkbox",
  CHECKBOX_VALUE: "checkbox",
  DATE_LABEL: "Date",
  DATE_VALUE: "date",
  DATE_TIME_LABEL: "Date & Time",
  DATE_TIME_VALUE: "datetime",
  NUMBER_LABEL: "Number",
  NUMBER_VALUE: "number",
  SLIDER_LABEL: "Slider",
  SLIDER_VALUE: "slider",
  BOOLEAN_LABEL: "Boolean",
  BOOLEAN_VALUE: "boolean",
  IMAGE_LABEL: "Image",
  IMAGE_VALUE: "image",
  SIGNATURE_LABEL: "Signature",
  SIGNATURE_VALUE: "signature",
  NOT_APPLICABLE: "N/A",
};

export default FormFields;
