/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

import React, { useEffect, useState } from "react";

// Material Common Components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import BasicButton from "examples/NewDesign/CustomButton/BasicButton";
import FilterDropdown from "components/Dropdown/FilterDropdown";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CustomButton from "examples/NewDesign/CustomButton";
import SearchBar from "components/Search/SearchInTable";
import PageTitle from "examples/NewDesign/PageTitle";
import MDBox from "components/MDBox";

// Modal
import UserBulkUpload from "examples/modal/UserBulkUpload";
import NewUser from "examples/modal/NewUser";

// Table
import Usermanagementdata from "layouts/wfmwizard/System/data/userData";
import DataTable from "examples/Tables/DataTable";

// Redux
import UserListThunk, { updateSyncupTime } from "redux/Thunks/UserManagement";
import getProfileFunctions from "redux/Thunks/ProfileFunctions";
import { openSnackbar } from "redux/Slice/Notification";
import { useDispatch, useSelector } from "react-redux";
import configThunk from "redux/Thunks/Config";

// Material Library Components
import { Divider, FormControl, MenuItem, Select } from "@mui/material";
import ResetFilterButton from "components/Buttons/ResetButton";

// Utils
import Constants, { Icons, defaultData, Colors, countryList } from "utils/Constants";
import { paramCreater } from "utils/methods/methods";
import MDTypography from "components/MDTypography";

// Style
import FormControlErrorStyles from "assets/style/Component";
import pxToRem from "assets/theme/functions/pxToRem";

function Usermanagement() {
  const [loadingStatus, setLoadingStatus] = useState(Constants.PENDING);
  const [openBulk, setOpenBulk] = useState(false);
  const [userData, setUserData] = useState([]);
  const [open, setOpen] = useState(false);
  const [next, setNext] = useState(0);

  const dispatch = useDispatch();
  const { config } = useSelector((state) => state.config);

  const [syncUptime, setSyncUptime] = useState({
    currentSyncUpTime: 0,
    syncUpTime: Array.from({ length: 24 }, (_hour, hours) =>
      Array.from({ length: 4 }, (_min, index) => ({
        hours: index === 3 ? hours + 1 : hours,
        min: index === 3 ? 0 : (index + 1) * 15,
        id: `${hours}${index}`,
      }))
    ).flat(),
  });

  const [filters, setFilters] = useState([
    {
      inputLabel: "Search",
      list: [{ [Constants.MONGOOSE_ID]: "All", title: "All" }],
      selectedValue: "All",
      isLoading: false,
    },
    {
      inputLabel: "Country",
      list: ["All", ...countryList],
      selectedValue: "All",
    },
    {
      inputLabel: "Status",
      list: [
        { [Constants.MONGOOSE_ID]: "All", title: "All" },
        { [Constants.MONGOOSE_ID]: "true", title: "Active" },
        { [Constants.MONGOOSE_ID]: "false", title: "InActive" },
      ],
      selectedValue: "All",
    },
    {
      inputLabel: "Profile Function",
      list: [{ [Constants.MONGOOSE_ID]: "All", title: "All" }],
      selectedValue: "All",
    },
  ]);

  const [tablePagination, setTablePagination] = useState({
    page: 0,
    perPage: defaultData.DATE_ON_SINGLE_API_CALL,
  });
  const handleCloseBulk = () => setOpenBulk(false);
  const handleOpenBulk = () => setOpenBulk(true);
  const handleClose = () => setOpen(false);
  let debounceTimeout;

  const handleFilter = async (filterVale = filters) => {
    setTablePagination({ ...tablePagination, page: 0 });
    const paramData = {
      page: 0,
      perPage: tablePagination.perPage,
      nationality: filterVale[1].selectedValue.toLowerCase().replace(/ /g, "_"),
      isActive: filterVale[2].selectedValue === "All" ? "" : filterVale[2].selectedValue,
      profileFunction: filterVale[3].selectedValue === "All" ? "" : filterVale[3].selectedValue,
    };
    if (filterVale[0].selectedValue !== "All") {
      paramData.search = filterVale[0].selectedValue;
    }
    const res = await dispatch(UserListThunk(paramCreater(paramData)));
    setLoadingStatus(Constants.FULFILLED);
    setUserData(
      res.payload.data.data.filter((item) => item.role?.title !== defaultData.ADMIN_ROLE)
    );
    const temp = [...filters];
    let userList = [];
    if (res.payload?.data?.data) {
      // add error-handling for undefined payload and data
      userList = res.payload.data.data.reduce((acc, item) => {
        if (
          item.role?.title !== defaultData.ADMIN_ROLE &&
          item.role?.title !== defaultData.SUPER_ADMIN_ROLE &&
          item.firstName &&
          item.lastName
        ) {
          // avoid returning null elements and handle undefined first/last name
          acc.push({
            [Constants.MONGOOSE_ID]: item[Constants?.MONGOOSE_ID], // check for existence of Constants object to avoid errors
            title: `${item.firstName} ${item.lastName}`,
          });
        }
        return acc;
      }, []);
    }
    temp[0].list = [{ [Constants.MONGOOSE_ID]: "All", title: "All" }, ...userList];
    setFilters(temp);
  };

  useEffect(() => {
    (async () => {
      handleFilter();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (config?.[0]?.syncUpTime) {
        const index = syncUptime.syncUpTime.findIndex(
          (val) =>
            val.hours === parseInt(config?.[0]?.syncUpTime?.hours, 10) &&
            val.min === parseInt(config?.[0]?.syncUpTime?.min, 10)
        );
        setSyncUptime((prev) => {
          const temp = { ...prev };
          temp.currentSyncUpTime = index;
          return temp;
        });
      }
      const profileFunctionRes = await dispatch(getProfileFunctions());
      const dropdownFormat = {
        [Constants.MONGOOSE_ID]: "",
        title: "",
      };
      const filterTemp = [...filters];
      const tempProfileFunction = profileFunctionRes.payload.data.data
        ?.filter((val) => val.isActive === true)
        ?.map((item) => {
          const temp = { ...dropdownFormat };
          temp[Constants.MONGOOSE_ID] = item?.[Constants.MONGOOSE_ID];
          temp.title = item.name;
          return temp;
        });
      filterTemp[3].list = [
        { [Constants.MONGOOSE_ID]: "All", title: "All" },
        ...tempProfileFunction,
      ];
      setFilters(filterTemp);
    })();
  }, [config]);

  const handleFilterChange = (e) => {
    const temp = [...filters];
    const { value } = e.target;
    if (value) {
      const index = filters.findIndex((filter) => filter.inputLabel === e.target.name);
      temp[index].selectedValue = value;
      setFilters(temp);
      handleFilter(temp);
    }
  };

  const handleSearch = async (e) => {
    if (e.target.value === "") return;
    setFilters((prev) => {
      const temp = [...prev];
      temp[0].isLoading = true;
      return temp;
    });
    setTablePagination({ ...tablePagination, page: 0 });

    const paramData = {
      page: 0,
      perPage: tablePagination.perPage,
      nationality: filters[1].selectedValue.toLowerCase().replace(/ /g, "_"),
      search: e.target.value.trim(),
    };
    Object.keys(paramData).forEach((key) => {
      if (paramData[key] === "") {
        delete paramData[key];
      }
    });

    const data = new URLSearchParams(paramData);
    const res = await dispatch(UserListThunk(data));
    const temp = [...filters];
    const userList = res.payload.data.data
      .filter((item) => item.role?.title !== defaultData.ADMIN_ROLE)
      .map((item) => ({
        [Constants.MONGOOSE_ID]: item[Constants.MONGOOSE_ID],
        title: `${item.firstName} ${item.lastName}`,
      }));
    temp[0].list = [{ [Constants.MONGOOSE_ID]: "all", title: "All" }, ...userList];
    temp[0].isLoading = false;
    setFilters(temp);
  };

  const debouncedHandleSearch = (e) => {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(async () => handleSearch(e), 300);
  };

  const handleSyncUptime = async (e) => {
    const index = syncUptime.syncUpTime.findIndex((val) => val.id === e.target.value);
    const paramAndBody = {
      body: {
        syncUpTime: {
          hours: syncUptime.syncUpTime[index].hours,
          min: syncUptime.syncUpTime[index].min,
        },
      },
      accountId: config?.[0]?.account,
    };
    const res = await dispatch(updateSyncupTime(paramAndBody));
    if (res.payload.status === 200) {
      await dispatch(configThunk());
      await dispatch(
        openSnackbar({
          message: Constants.SYNC_UP_TIME_SUCCESS,
          notificationType: Constants.NOTIFICATION_SUCCESS,
        })
      );
    } else {
      dispatch(
        openSnackbar({
          message: Constants.SYNC_UP_TIME_ERROR,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
  };

  const handleReload = async () => {
    setLoadingStatus(Constants.PENDING);
    handleFilter();
  };

  const handleReset = async () => {
    const tempFilters = [...filters];
    tempFilters[0].selectedValue = "All";
    tempFilters[1].selectedValue = "All";
    tempFilters[2].selectedValue = "All";
    tempFilters[3].selectedValue = "All";
    setFilters(tempFilters);
    await handleFilter(tempFilters);
  };

  const handleTablePagination = async () => {
    const data = {
      page: next + 1,
      perPage: tablePagination.perPage,
      isActive: filters[0].selectedValue === "All" ? "" : filters[0].selectedValue,
    };
    const res = await dispatch(UserListThunk(paramCreater(data)));
    if (res.payload.status === 200) {
      setUserData([...userData, ...res.payload.data.data]);
      setNext(res.payload.data.data.length > 0 ? next + 1 : next);
    }
  };

  const { columns, rows } = Usermanagementdata(userData, handleFilter);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox display="flex" justifyContent="space-between" alignItems="center">
        {open && <NewUser open={open} handleClose={handleClose} handleFilter={handleFilter} />}
        {openBulk && <UserBulkUpload openBulk={openBulk} handleCloseBulk={handleCloseBulk} />}
        <PageTitle title="User Management" />
        <MDBox display="flex" flexDirection="row">
          <CustomButton
            title="New"
            icon="add_circle_outline"
            background="#191A51"
            color="#ffffff"
            openModal={setOpen}
          />
          <CustomButton
            title="Bulk Upload"
            icon="add_circle_outline"
            background="#D3D3D3"
            color="#ffffff"
            openModal={handleOpenBulk}
            disabled
          />
          <Divider
            orientation="vertical"
            sx={{
              backgroundColor: "var(--gray-300, #D0D5DD)",
              height: "auto",
              marginLeft: pxToRem(16),
              marginRight: 0,
            }}
          />
          <BasicButton
            icon={Icons.RELOAD}
            background={Colors.WHITE}
            border
            color={Colors.BLACK}
            action={handleReload}
          />
        </MDBox>
      </MDBox>
      <Divider sx={{ marginTop: pxToRem(22) }} />

      {/* Search and filter */}
      <MDBox
        display="flex"
        alignItems="start"
        flexWrap="wrap"
        sx={{ flexDirection: "row", mr: 2, mb: 3 }}
        style={{ width: "100%" }}
      >
        <SearchBar
          options={filters[0]?.list.map((val) => val.title) || []}
          filters={filters}
          placeholder="Search"
          value={filters[0].selectedValue}
          handleFilterChange={(e, value) => {
            handleFilterChange({
              target: {
                name: filters[0].inputLabel,
                value,
              },
            });
          }}
          debouncedHandleSearch={debouncedHandleSearch}
          isLoading={filters[0].isLoading}
        />

        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="start"
          sx={{ flexDirection: "column", mr: 2 }}
        >
          <FormControl
            sx={{
              ml: 0,
              mb: 0,
              mt: 2.5,
              minWidth: 150,
              maxHeight: 400,
              ...FormControlErrorStyles,
            }}
            size="small"
          >
            <MDTypography
              variant="caption"
              mb={1}
              sx={{ fontSize: pxToRem(14), fontWeight: 600, color: "#344054" }}
            >
              Sync Up Time
            </MDTypography>
            <Select
              value={syncUptime.syncUpTime[syncUptime.currentSyncUpTime]?.id}
              sx={{
                height: 40,
                color: "black",
                fontWeight: 600,
                backgroundColor: "#fff",
                paddingY: "0.65rem",
                paddingRight: "0.55rem",
                maxHeight: 100,
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                PaperProps: {
                  style: {
                    width: pxToRem(150),
                    height: 200,
                    opacity: 1,
                    transform: "none",
                    top: 183,
                    left: 442,
                  },
                },
              }}
              onChange={handleSyncUptime}
              IconComponent={Icons.DROPDOWN}
            >
              {syncUptime.syncUpTime.map((item) => (
                <MenuItem
                  value={item.id}
                  sx={{
                    textTransform: "capitalize",
                    maxHeight: 400,
                    fontSize: pxToRem(14),
                    fontWeight: 400,
                    color: "#344054",
                  }}
                  key={item.id}
                >
                  {(item.hours === 0 && item.min !== 0 && `${item.min}min`) ||
                    (item.hours !== 0 && item.min === 0 && `${item.hours}hr`) ||
                    (item.hours !== 0 && item.min !== 0 && `${item.hours}hr ${item.min}min`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </MDBox>

        {filters
          ?.filter((item) => item.inputLabel !== "Sync Up Time" && item.inputLabel !== "Search")
          ?.map((val) => (
            <FilterDropdown
              key={val.inputLabel}
              label={val.inputLabel}
              name={val.inputLabel}
              defaultValue={val.selectedValue}
              value={val.selectedValue}
              handleChange={handleFilterChange}
              menu={val.list}
              style={{ marginLeft: pxToRem(1) }}
              maxContent={defaultData.LARGE_CONTENT_LENGTH}
            />
          ))}
        <ResetFilterButton handleReset={handleReset} style={{ marginLeft: "1rem" }} />
      </MDBox>

      <MDBox mt={1}>
        <DataTable
          table={{ columns, rows }}
          isSorted={false}
          entriesPerPage={{ defaultValue: defaultData.PER_PAGE }}
          showTotalEntries={false}
          loading={loadingStatus}
          currentPage={tablePagination.page}
          handleTablePagination={handleTablePagination}
          handleCurrentPage={(page) => setTablePagination({ ...tablePagination, page })}
        />
      </MDBox>
    </DashboardLayout>
  );
}

export default Usermanagement;
