import React, { useState } from "react";
import { useDispatch } from "react-redux";

// Redux
import exportImportSampleFileThunk from "redux/Thunks/Other";
import { paramCreater } from "utils/methods/methods";

function ExportHOC(WrappedComponent) {
  return function ExportFile(props) {
    const dispatch = useDispatch();
    const [exportLoading, setExportLoading] = useState(false);

    const handleEquipmentSampleFileExport = async (exportFileName, root) => {
      setExportLoading(true);
      const currentDate = new Date();
      const fileName = `Reynard_${root}_${currentDate.getFullYear()}-${
        currentDate.getMonth() + 1
      }-${currentDate.getDate()}_${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}.${
        exportFileName.split(".")[1]
      }`;

      const res = await dispatch(exportImportSampleFileThunk(exportFileName));
      const url = window.URL.createObjectURL(res.payload);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      setExportLoading(false);
    };

    const handlePdfExport = async (fileName, exportThunk, dateFormat = true) => {
      setExportLoading(true);
      const res = await dispatch(exportThunk);
      let name = fileName;
      if (dateFormat) {
        const currentDate = new Date();
        name = `Reynard_${fileName}_${currentDate.getFullYear()}-${
          currentDate.getMonth() + 1
        }-${currentDate.getDate()}_${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}.pdf`;
      }
      if (res.error !== undefined) {
        setExportLoading(false);
        return res;
      }
      const url = window.URL.createObjectURL(res.payload);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
      link.remove();
      setExportLoading(false);
      return res;
    };

    const handleExportZipFile = async (filters, exportThunk) => {
      setExportLoading(true);
      const paramData = { ...filters };
      Object.keys(paramData).forEach((key) => {
        if (paramData[key] === "") {
          delete paramData[key];
        }
      });
      const param = paramCreater({ ...paramData, export: true });
      const res = await dispatch(exportThunk(param));
      const fileName = res.payload.fileName.split("filename=")[1];
      const url = window.URL.createObjectURL(
        new Blob([res.payload.data], { type: "application/zip" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setExportLoading(false);
    };
    return (
      <WrappedComponent
        {...props}
        handleEquipmentSampleFileExport={handleEquipmentSampleFileExport}
        handleExportZipFile={handleExportZipFile}
        exportLoading={exportLoading}
        handlePdfExport={handlePdfExport}
      />
    );
  };
}

export default ExportHOC;
