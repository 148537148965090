import { createAsyncThunk } from "@reduxjs/toolkit";
import Sessions from "utils/Sessions";
import ApiService from "../ApiService/ApiService";

const getProfileFunctions = createAsyncThunk("/get-profile-functions", async (param) => {
  const res = await ApiService.get(`/profile-function?${param}`, {
    headers: { Authorization: `Bearer ${Sessions.userToken}` },
  })
    .then((r) => r)
    .catch((err) => err.response);
  const params = new URLSearchParams(param);
  const page = params.get("page");
  return page === "0"
    ? { data: res.data, type: "add", status: res.status }
    : { data: res.data, type: "append", status: res.status };
});

export const createProfileFunctions = createAsyncThunk(
  "/create-profile-functions",
  async (body) => {
    const res = await ApiService.post(
      `/profile-function`,
      { ...body },
      {
        headers: { Authorization: `Bearer ${Sessions.userToken}` },
      }
    )
      .then((r) => r)
      .catch((err) => err.response);
    return res;
  }
);

export const updateProfileFunctions = createAsyncThunk(
  "/update-profile-functions",
  async (body) => {
    const res = await ApiService.patch(
      `/profile-function/${body.id}`,
      { ...body },
      {
        headers: { Authorization: `Bearer ${Sessions.userToken}` },
      }
    )
      .then((r) => r)
      .catch((err) => err.response);
    return res;
  }
);

export const profileFunctionDeleteThunk = createAsyncThunk(
  "profile-function/delete",
  async (id) => {
    const res = await ApiService.delete(`profile-function/${id}`, {
      headers: { Authorization: `Bearer ${Sessions.userToken}` },
    })
      .then((r) => r)
      .catch((err) => err.response);
    return res;
  }
);

export default getProfileFunctions;
