import React, { useState } from "react";

// Material ui Components
import { Divider } from "@mui/material";
import MDTypography from "components/MDTypography";

// Custom Components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PageTitle from "examples/NewDesign/PageTitle";
import pxToRem from "assets/theme/functions/pxToRem";
import MDBox from "components/MDBox";
import BasicButton from "examples/NewDesign/CustomButton/BasicButton";
import FilterDropdown from "components/Dropdown/FilterDropdown";
import ResetFilterButton from "components/Buttons/ResetButton";
import AccessDenied from "components/AccessDenied/accessDenied";
import PmOrderList from "layouts/Approver/approverOrders";
import PmReturnOrderList from "layouts/Approver/approverReturnOrders";
import EquipmentList from "layouts/Approver/index";

// Redux component
import { reloadData, OrderReloadData, ReturnOrderReloadData } from "redux/Slice/EquipmentRequest";
import AllEquipmentRequestListThunk, {
  getPMOrders,
  getPMReturnOrders,
} from "redux/Thunks/EquipmentRequest";
import { useDispatch, useSelector } from "react-redux";

// Constants
import Constants, { Colors, Icons, PageTitles } from "utils/Constants";
import { paramCreater } from "utils/methods/methods";

function AllEquipments() {
  const [filters, setFilters] = useState([
    {
      inputLabel: "Status",
      list: [
        { [Constants.MONGOOSE_ID]: "pending", title: "Requests" },
        { [Constants.MONGOOSE_ID]: "rejected", title: "Rejected" },
        { [Constants.MONGOOSE_ID]: "queue", title: "Shopping Cart" },
        { [Constants.MONGOOSE_ID]: "order", title: "Orders" },
        { [Constants.MONGOOSE_ID]: "returnorders", title: "Return Orders" },
      ],
      selectedValue: "pending",
    },
  ]);
  const dispatch = useDispatch();
  const ConfigData = useSelector((state) => state.config);
  const permission = ConfigData?.screens?.[10]?.screensInfo?.agreement;
  const configLoading = ConfigData?.loading;

  const handleReload = async () => {
    await dispatch(reloadData());
    const paramData = {
      status: filters[0].selectedValue,
    };
    await dispatch(AllEquipmentRequestListThunk(paramCreater(paramData)));
  };

  const handleFilterType = async (e) => {
    const temp = [...filters];
    const { value } = e.target;
    if (value) {
      const i = temp.findIndex((item) => item.inputLabel === e.target.name);
      temp[i].selectedValue = value;
      setFilters(temp);
    }
  };

  const handleReset = async () => {
    const resetFilters = filters.map((filter) => ({ ...filter, selectedValue: "pending" }));
    setFilters(resetFilters);
  };

  const handleOrderReload = async () => {
    await dispatch(OrderReloadData());
    const paramData = {
      status: filters[0].selectedValue,
    };
    await dispatch(getPMOrders(paramCreater(paramData)));
  };

  const handleProjectReload = async () => {
    if (filters[0].selectedValue === "order") {
      await handleOrderReload();
    } else if (filters[0].selectedValue === "returnorders") {
      await dispatch(ReturnOrderReloadData());
      await dispatch(getPMReturnOrders());
    } else {
      await handleReload();
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {configLoading === Constants.PENDING && (
        <MDBox py={30} display="flex" justifyContent="center" alignItems="center">
          {Icons.LOADING2}
        </MDBox>
      )}

      {configLoading === "fulfilled" && permission.read && (
        <>
          <MDBox display="flex" justifyContent="space-between">
            <PageTitle title={PageTitles.PROJECT_ORDERS} />
            <MDBox mt={{ lg: 0, sm: 2 }} display="flex" flexWrap="wrap">
              <BasicButton
                icon={Icons.RELOAD}
                background={Colors.WHITE}
                border
                color={Colors.BLACK}
                action={handleProjectReload}
              />
            </MDBox>
          </MDBox>
          <Divider sx={{ marginTop: 2 }} />
          <MDBox
            display="flex"
            alignItems="start"
            flexWrap="wrap"
            sx={{ flexDirection: "row", mr: 2 }}
            style={{ width: "100%" }}
          >
            {filters
              ?.filter((val) => val.inputLabel !== "Search")
              ?.map((val) => (
                <FilterDropdown
                  key={val.inputLabel}
                  label={val.inputLabel}
                  name={val.inputLabel}
                  defaultValue={val?.selectedValue}
                  value={val?.selectedValue}
                  handleChange={handleFilterType}
                  menu={val.list}
                  style={{ marginLeft: pxToRem(1) }}
                />
              ))}
            <ResetFilterButton handleReset={handleReset} style={{ marginLeft: "1rem" }} />
          </MDBox>
          <MDBox
            my={pxToRem(24)}
            sx={{
              backgroundColor: Colors.WHITE,
            }}
          >
            {(() => {
              const status = filters[0].selectedValue;
              switch (status) {
                case "":
                case "check-in":
                case "pre-check-out":
                case "check-out":
                case "order":
                  return <PmOrderList status={status} key={status} />;
                case "returnorders":
                  return <PmReturnOrderList />;
                default:
                  return <EquipmentList status={status} key={status} />;
              }
            })()}
          </MDBox>
        </>
      )}

      {configLoading === "fulfilled" && !permission?.read && <AccessDenied />}

      {configLoading === Constants.REJECTED && (
        <MDTypography variant="h4" color="secondary">
          {Constants.SOMETHING_WENT_WRONG}
        </MDTypography>
      )}
    </DashboardLayout>
  );
}
export default AllEquipments;
