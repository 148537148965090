import React from "react";

// Custom Components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

// 3rd party
import PropTypes from "prop-types";

export default function FTextArea({
  title,
  name,
  error,
  handleChange,
  defaultValue,
  id,
  value,
  helperText,
  placeholder,
  marginTop,
}) {
  return (
    <MDBox
      marginTop={marginTop}
      sx={{
        mr: 2,
        ml: 0,
        mb: 0,
        display: "flex",
        flexDirection: "column",
        minWidth: "100%",
      }}
    >
      <MDTypography
        variant="body"
        sx={{
          fontSize: "14px",
          fontWeight: 500,
          color: "#344054",
          mb: 1,
          lineHeight: "20px",
        }}
      >
        {title}
      </MDTypography>
      <MDInput
        multiline
        autoComplete="off"
        size="small"
        variant="outlined"
        id={id}
        name={name}
        value={value}
        placeholder={placeholder}
        defaultValue={defaultValue}
        error={error}
        helperText={helperText}
        onChange={handleChange}
        FormHelperTextProps={{
          sx: { marginLeft: 0, color: "#FF2E2E" },
        }}
        inputProps={{
          style: { flex: "1", minHeight: "5em" },
        }}
        sx={{
          mb: 2,
          mt: 0,
          minWidth: "100%",
          "& .MuiInputBase-input": {
            fontSize: "16px",
            fontWeight: 400,
            color: "#667085",
          },
        }}
      />
    </MDBox>
  );
}

FTextArea.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.bool,
  handleChange: PropTypes.func,
  defaultValue: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  marginTop: PropTypes.number,
};

FTextArea.defaultProps = {
  marginTop: 0,
  title: "",
  name: "",
  error: false,
  defaultValue: "",
  id: "",
  helperText: "",
  placeholder: "",
  handleChange: () => {},
};
