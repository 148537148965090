import React, { useEffect, useState } from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import { IconButton } from "@mui/material";

// Custom Components
import Author from "components/Table/Author";
import Status from "components/Table/Status";

// assets
import pxToRem from "assets/theme/functions/pxToRem";

// Redux
import { useSelector } from "react-redux";

// Utils
import Constants, { Icons } from "utils/Constants";

export default function WmReturnOrderDetailsData({
  returnOrderDetails,
  returnOrderDetailsLoading,
  handleRejectEquipmentData,
}) {
  const [rows, setRows] = useState([]);
  const ConfigData = useSelector((state) => state.config);
  const permission = ConfigData?.screens?.[9]?.screensInfo?.agreement;

  useEffect(() => {
    if (returnOrderDetailsLoading === Constants.FULFILLED) {
      const tempRows = returnOrderDetails?.equipment.map((element, index) => {
        const temp = {
          srNo: <Author name={index + 1} />,
          equipment: (
            <MDBox display="flex" justifyContent="center" alignItems="center">
              <img
                src={
                  element?.equipmentImage?.[0]?.url
                    ? element?.equipmentImage?.[0]?.url
                    : process.env.REACT_APP_IMAGE_NOT_FOUND
                }
                alt={element?.equipmentImage?.[0]?.name}
                key={element?.equipmentImage?.[0]?.name}
                style={{
                  width: pxToRem(50),
                  height: pxToRem(50),
                  marginRight: pxToRem(10),
                  borderRadius: "8px",
                }}
              />{" "}
              <Author name={element?.name} />
            </MDBox>
          ),
          productNumber: <Author name={element.equipmentNumber} />,
          serialNumber: <Author name={element.serialNumber || Constants.NA} />,
          returnQuantity: <Author name={element?.pmDispatchQuantity} />,
          status: <Status title={element?.status.replace("-", " ")} />,
          remark: <Author name={element?.wmComment?.[0]?.comment} />,
          action: (
            <MDBox>
              {element.status === "return" && permission?.delete && (
                <IconButton
                  aria-label="fingerprint"
                  color="error"
                  onClick={() =>
                    handleRejectEquipmentData(
                      returnOrderDetails.returnOrderId,
                      element?.[Constants.MONGOOSE_ID]
                    )
                  }
                >
                  {Icons.REJECTED}
                </IconButton>
              )}
            </MDBox>
          ),
        };
        return temp;
      });
      setRows([...tempRows]);
    }
  }, [returnOrderDetailsLoading, returnOrderDetails, permission]);

  const tempColumns = [
    { Header: "No.", accessor: "srNo", width: "5%" },
    {
      Header: "Equipment",
      accessor: "equipment",
      width: "10%",
      align: "left",
    },
    { Header: "Product Number", accessor: "productNumber", align: "center" },
    { Header: "Serial Number", accessor: "serialNumber", align: "center" },
    { Header: "Return Quantity", accessor: "returnQuantity", align: "center" },
    { Header: "Status", accessor: "status", align: "center" },
    { Header: "WM Remark", accessor: "remark", align: "left" },
  ];

  if (returnOrderDetails.status !== "rejected" && permission?.delete) {
    tempColumns.push({ Header: "Action", accessor: "action", align: "center" });
  }

  return {
    columns: tempColumns,
    rows,
  };
}
