import { Grid, Icon, IconButton, Modal, TextField } from "@mui/material";
import PropTypes from "prop-types";
import style from "assets/style/Modal";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import pxToRem from "assets/theme/functions/pxToRem";
import { Icons, ButtonTitles } from "utils/Constants";
import { useEffect, useState } from "react";
import MDTypography from "components/MDTypography";
import { numericFieldValidation } from "utils/methods/methods";

function BasicModal({
  open,
  handleClose,
  title = "Edit Quantity",
  actionButton = ButtonTitles.UPDATE,
  handleAction,
  disabled = false,
  minWidth = 700,
  editEquipmentData,
}) {
  const [approvedQuantity, setApprovedQuantity] = useState(0);

  useEffect(() => {
    setApprovedQuantity(editEquipmentData?.approvedQuantity);
  }, [editEquipmentData]);

  const handleChange = (event) => {
    if (event.target.value < 1) return;
    setApprovedQuantity(event.target.value);
  };

  const handleUpdateQuantity = () => {
    const data = {
      ...editEquipmentData,
      approvedQuantity,
    };
    handleAction(data);
  };
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <MDBox sx={{ ...style, minWidth }}>
        <MDBox
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          borderRadius="lg"
          sx={{
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
            height: pxToRem(72),
            padding: "16px 16px 8px 16px",
            marginBottom: "8px",
          }}
        >
          <MDTypography sx={{ fontSize: pxToRem(20), color: "#191D31", fontWeight: "700" }}>
            {title}
          </MDTypography>

          <Icon sx={{ cursor: "pointer" }} fontSize="medium" onClick={handleClose}>
            {Icons.CLOSE}
          </Icon>
        </MDBox>
        <MDBox
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          px={3}
          sx={{
            maxHeight: 500,
            overflowY: "scroll",
            "::-webkit-scrollbar": { display: "none" },
            scrollbarWidth: "none",
          }}
        >
          <MDBox display="flex" border="1px solid #E0E6F5" borderRadius="8px" p={1} mt={1} ml={1}>
            <MDBox
              display="flex"
              borderRadius="8px"
              position="relative"
              height="70px"
              width="60px"
              sx={{
                "&:hover .overlay": {
                  display: "flex",
                  borderRadius: "8px",
                  marginTop: "5px",
                  marginLeft: "4px",
                },
              }}
            >
              <img
                src={
                  editEquipmentData?.equipmentImage
                    ? editEquipmentData?.equipmentImage
                    : process.env.REACT_APP_IMAGE_NOT_FOUND
                }
                alt={editEquipmentData?.imageName}
                key={editEquipmentData?.imageName}
                height="60px"
                width="60px"
                style={{
                  border: "1px solid #D0D5DD",
                  borderRadius: "8px",
                  marginTop: "15px",
                  marginLeft: "4px",
                }}
              />
            </MDBox>
            <MDBox ml={2} mt={0}>
              <MDBox>
                <MDTypography
                  display="block"
                  sx={{
                    fontSize: pxToRem(14),
                    fontWeight: 600,
                    textTransform: "capitalize",
                  }}
                >
                  {editEquipmentData?.equipmentName}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" flexDirection="row">
                <MDTypography
                  display="block"
                  sx={{
                    fontSize: pxToRem(14),
                    fontWeight: 500,
                    textTransform: "capitalize",
                    color: "#344054",
                  }}
                >
                  Requested by : &nbsp;
                </MDTypography>
                <MDTypography
                  display="block"
                  sx={{
                    fontSize: pxToRem(14),
                    fontWeight: 600,
                    textTransform: "capitalize",
                    color: "#F60",
                  }}
                >
                  {editEquipmentData?.requestedBy}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" flexDirection="row">
                <MDTypography
                  display="block"
                  sx={{
                    fontSize: pxToRem(14),
                    fontWeight: 500,
                    textTransform: "capitalize",
                    color: "#344054",
                  }}
                >
                  Total Price : &nbsp;
                </MDTypography>
                <MDTypography
                  display="block"
                  sx={{
                    fontSize: pxToRem(14),
                    fontWeight: 600,
                    textTransform: "capitalize",
                    color: "#F60",
                  }}
                >
                  {editEquipmentData?.totalRequestedQuantity && editEquipmentData?.price
                    ? `${editEquipmentData?.currencySymbol} ${
                        approvedQuantity * editEquipmentData.price
                      }`
                    : "N/A"}
                </MDTypography>
              </MDBox>

              <MDBox display="flex" flexDirection="row" justifyContent="space-between" mt={0.5}>
                <MDBox>
                  <MDTypography
                    display="block"
                    sx={{
                      fontSize: pxToRem(12),
                      fontWeight: 400,
                      textTransform: "capitalize",
                      color: "#344054",
                    }}
                  >
                    {`${editEquipmentData.currencySymbol} ${editEquipmentData.price}/ PCs`}
                  </MDTypography>
                </MDBox>
                <MDBox ml={35} mt={-6} display="flex" flexDirection="row">
                  <IconButton
                    aria-label="fingerprint"
                    color="info"
                    onClick={() =>
                      handleChange({ target: { value: parseInt(approvedQuantity, 10) - 1 } })
                    }
                  >
                    {Icons.DASH2}
                  </IconButton>
                  <TextField
                    type="number"
                    name="totalRequestedQuantity"
                    id="totalRequestedQuantity"
                    label="Quantity"
                    value={approvedQuantity}
                    variant="standard"
                    onKeyDown={(e) => numericFieldValidation(e)}
                    onChange={handleChange}
                    sx={{
                      width: "50px",
                      "& .MuiInputBase-input": {
                        textAlign: "center",
                      },
                    }}
                  />
                  <IconButton
                    aria-label="fingerprint"
                    color="info"
                    onClick={() =>
                      handleChange({ target: { value: parseInt(approvedQuantity, 10) + 1 } })
                    }
                  >
                    {Icons.NEW}
                  </IconButton>
                </MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
        <MDBox px={2} mb={2} mr={1} mt={2}>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Grid item xs={0}>
              <MDButton
                variant="contained"
                disabled={disabled}
                color="info"
                onClick={handleUpdateQuantity}
                style={{ textTransform: "none", boxShadow: "none" }}
              >
                {actionButton}
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Modal>
  );
}

BasicModal.defaultProps = {
  title: "Edit Quantity",
  actionButton: ButtonTitles.UPDATE,
  disabled: false,
  minWidth: 700,
  editEquipmentData: {},
};

BasicModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  actionButton: PropTypes.string,
  handleAction: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  minWidth: PropTypes.number,
  editEquipmentData: PropTypes.shape({
    approvedQuantity: PropTypes.number,
    equipmentImage: PropTypes.string,
    imageName: PropTypes.string,
    equipmentName: PropTypes.string,
    requestedBy: PropTypes.string,
    totalRequestedQuantity: PropTypes.number,
    price: PropTypes.number,
    currencySymbol: PropTypes.string,
  }),
};

export default BasicModal;
