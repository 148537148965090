/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

import React, { useEffect, useState } from "react";

// Custom Components
import DataTable from "examples/Tables/DataTable/NestedTable";
import ApproverOrderlayerData from "layouts/Approver/data/ApproverOrderlayerData";
import approverProjectLayerData from "layouts/Approver/data/ApproverProjectLayerData";
import approverEquipmentLayerData from "layouts/Approver/data/ApproverEquipmentLayerData";
import ApproverEquipmentTypelayerData from "layouts/Approver/data/ApproverEquipmentTypeLayerData";
import CheckInCheckOutComments from "examples/modal/CheckInCheckOutComments/CheckInCheckOutComments";

// Material Components
import MDBox from "components/MDBox";

// redux
import { getPMOrders } from "redux/Thunks/EquipmentRequest";
import {
  loadApproverProjectLayerData,
  loadApproverEquipmentTypeLayerData,
  loadApproverEquipmentLayerData,
  OrderReloadData,
} from "redux/Slice/EquipmentRequest";

// utils
import Constants, { Colors, defaultData, FeatureTags } from "utils/Constants";

// 3rd party libraries
import { Feature } from "flagged";
import { useDispatch, useSelector } from "react-redux";

function ApproverOrders({ status }) {
  const [tablePagination, setTablePagination] = useState({
    page: 0,
    perPage: defaultData.DATE_ON_SINGLE_API_CALL,
  });
  const [next, setNext] = useState(0);
  const [wmCommentsData, setWMCommentsData] = useState({
    open: false,
    type: "",
    comments: [],
  });
  const [rejectRemarkData, setRejectRemarkData] = useState({
    open: false,
    type: "",
    comments: [],
  });
  const dispatch = useDispatch();
  const {
    approverOrderList = [],
    approverOrderProjectData = "",
    approverOrderEquipmentTypeData = "",
    approverOrderEquipmentData = "",
    approverOrderLoading,
  } = useSelector((state) => state.equipmentRequest);

  const handleFilter = async () => {
    setTablePagination({ ...tablePagination, page: 0 });
    setNext(0);
    await dispatch(OrderReloadData());
    await dispatch(getPMOrders());
  };

  const openWarehouseCommentModal = (type, intialComments) => {
    const { open, ...rest } = wmCommentsData;
    setWMCommentsData({
      ...rest,
      open: true,
      type,
      comments: [...(intialComments.pmComments || []), ...(intialComments.wmComments || [])],
    });
  };

  const openRejectCommentModal = (type, remark) => {
    const { open, ...rest } = rejectRemarkData;
    setRejectRemarkData({
      ...rest,
      open: true,
      type,
      comments: [remark],
    });
  };

  const { approverProjectColumns, approverOrderProjectrows } = approverProjectLayerData(
    approverOrderList,
    status
  );

  const { approverOrderColumns, approverOrderrows } = ApproverOrderlayerData(
    approverOrderProjectData,
    approverOrderList,
    status
  );

  const { approverEquipmentTypeColumns, approverEquipmentTyperows } =
    ApproverEquipmentTypelayerData(
      approverOrderEquipmentTypeData,
      approverOrderList,
      openWarehouseCommentModal,
      openRejectCommentModal
    );

  const { approverEquipmentColumns, approverEquipmentrows } = approverEquipmentLayerData(
    approverOrderEquipmentData
  );

  useEffect(() => {
    (async () => {
      handleFilter();
    })();
  }, [status]);

  const handleTablePagination = async () => {
    const paramData = {
      page: next + 1,
      perPage: tablePagination.perPage,
    };
    const data = new URLSearchParams(paramData);
    const res = await dispatch(getPMOrders(data));
    if (res.payload.status === 200) setNext(res.payload.data.length > 0 ? next + 1 : next);
  };

  return (
    <Feature name={FeatureTags.WAREHOUSE}>
      <MDBox mt={3} mb={3}>
        <DataTable
          table={{ columns: approverProjectColumns, rows: approverOrderProjectrows }}
          isSorted={false}
          entriesPerPage={{ defaultValue: defaultData.PER_PAGE }}
          showTotalEntries={false}
          noEndBorder
          loadLayerId={loadApproverProjectLayerData}
          loading={approverOrderLoading}
          currentPage={tablePagination.page}
          handleTablePagination={handleTablePagination}
          handleCurrentPage={(page) => setTablePagination({ ...tablePagination, page })}
          childTable={[
            {
              columns: approverOrderColumns,
              rows: approverOrderrows,
              backgroundColor: Colors.LIGHT_GRAY,
              textColor: Colors.PRIMARY,
              isSorted: false,
              loadLayerId: loadApproverEquipmentTypeLayerData,
              entriesPerPage: { defaultValue: defaultData.PER_PAGE },
              showTotalEntries: false,
              noEndBorder: true,
              loading: Constants.FULFILLED,
            },
            {
              columns: approverEquipmentTypeColumns,
              rows: approverEquipmentTyperows,
              backgroundColor: Colors.LIGHT_GRAY,
              textColor: Colors.PRIMARY,
              isSorted: false,
              loadLayerId: loadApproverEquipmentLayerData,
              entriesPerPage: { defaultValue: defaultData.PER_PAGE },
              showTotalEntries: false,
              noEndBorder: true,
              loading: Constants.FULFILLED,
            },
            {
              columns: approverEquipmentColumns,
              rows: approverEquipmentrows,
              backgroundColor: Colors.LIGHT_GRAY,
              textColor: Colors.PRIMARY,
              isSorted: false,
              entriesPerPage: { defaultValue: defaultData.PER_PAGE },
              showTotalEntries: false,
              noEndBorder: true,
              loading: Constants.FULFILLED,
            },
          ]}
        />
      </MDBox>
      {wmCommentsData.open && (
        <CheckInCheckOutComments
          open={wmCommentsData.open}
          type={wmCommentsData.type}
          intialComments={wmCommentsData.comments}
          handleClose={() =>
            setWMCommentsData({
              open: false,
              type: "",
              comments: [],
            })
          }
          minWidth={600}
        />
      )}
      {rejectRemarkData.open && (
        <CheckInCheckOutComments
          open={rejectRemarkData.open}
          type={rejectRemarkData.type}
          intialComments={rejectRemarkData.comments}
          handleClose={() =>
            setRejectRemarkData({
              open: false,
              type: "",
              comments: [],
            })
          }
          minWidth={600}
        />
      )}
    </Feature>
  );
}

export default ApproverOrders;
