import React, { useEffect, useState } from "react";

// Common Components
import Author from "components/Table/Author";
import Status from "components/Table/Status";
import MDBox from "components/MDBox";
import pxToRem from "assets/theme/functions/pxToRem";
import { Icons, Colors } from "utils/Constants";
import { IconButton } from "@mui/material";

export default function ApproverReturnEquipmentData({ listing, openRejectRemarkModal }) {
  const [returnEquipmentRows, setReturnEquipmentRows] = useState([]);

  useEffect(() => {
    if (listing) {
      const tempRows = listing?.returnOrderdata?.map((element, index) => {
        const temp = {
          srNo: <Author name={index + 1} />,
          equipment: (
            <MDBox display="flex" justifyContent="center" alignItems="center">
              <img
                src={
                  element?.equipment?.equipmentImage?.length > 0
                    ? element?.equipment?.equipmentImage[0]?.url
                    : process.env.REACT_APP_IMAGE_NOT_FOUND
                }
                alt={element?.equipment}
                key={element?.equipment?.name}
                style={{
                  width: pxToRem(50),
                  height: pxToRem(50),
                  marginRight: pxToRem(10),
                  borderRadius: "8px",
                }}
              />
              <Author name={element?.equipment?.name} />
            </MDBox>
          ),
          equipmentType: <Author name={element?.equipment?.equipmentType?.type} />,
          returnQty: <Author name={element?.pmDispatchQuantity} />,
          status: (
            <MDBox display="flex" flexDirection="row" justifyContent="space-between">
              {element?.status === "rejected" &&
                element?.wmComment !== null &&
                element?.wmComment !== "" && (
                  <MDBox
                    bgColor={Colors.LIGHT_RED}
                    mb={1}
                    borderRadius={pxToRem(16)}
                    sx={{ width: pxToRem(30), height: pxToRem(30) }}
                    display="flex"
                    justifyContent="center"
                    align="center"
                  >
                    <IconButton
                      aria-label="fingerprint"
                      onClick={() => openRejectRemarkModal("user", element?.wmComment)}
                    >
                      {Icons.COMMENT_RED}
                    </IconButton>
                  </MDBox>
                )}
              <MDBox sx={{ marginLeft: pxToRem(10) }}>
                <Status title={`${element?.status.replace("-", " ")}`} />
              </MDBox>
            </MDBox>
          ),
          remark: (
            <MDBox>
              {element?.status === "in-stock" ? (
                <IconButton
                  aria-label="fingerprint"
                  color="info"
                  disabled={element?.wmComment?.length === 0 || element?.wmComment === ""}
                  onClick={() => openRejectRemarkModal("user", element?.wmComment)}
                >
                  {element?.wmComment?.length === 0 || element?.wmComment === ""
                    ? Icons.COMMENT_DISABLE
                    : Icons.COMMENT}
                </IconButton>
              ) : null}
            </MDBox>
          ),
        };
        return temp;
      });
      setReturnEquipmentRows([...tempRows]);
    }
  }, [listing]);

  return {
    returnEquipmentColumns: [
      { Header: "No.", accessor: "srNo", width: "5%" },
      { Header: "Equipment", accessor: "equipment" },
      { Header: "Equipment Type", accessor: "equipmentType" },
      { Header: "Return Qty", accessor: "returnQty", align: "center" },
      { Header: "Status", accessor: "status", align: "center" },
      { Header: "WM Remark", accessor: "remark", align: "center" },
    ],
    returnEquipmentRows,
  };
}
