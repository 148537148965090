import React from "react";

// Material Components
import { Divider } from "@mui/material";

// Common Components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable/NoColumnTable";
import PageTitle from "examples/NewDesign/PageTitle";
import MDBox from "components/MDBox";

// Table Data
import NotificationData from "layouts/wfmwizard/Actions/data/notificationData";

// 3rd party library
import { Feature } from "flagged";

// Utils
import Constants, { Colors, defaultData, PageTitles, FeatureTags } from "utils/Constants";

function SystemNotification() {
  const { columns, rows } = NotificationData();
  const handleMarkAllasRead = () => {
    console.log("Mark All click");
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <PageTitle title={PageTitles.SYSTEM_NOTIFICATION} />
      <Divider sx={{ marginTop: 2 }} />
      <Feature name={FeatureTags.NOTIFICATION}>
        <MDBox mt={2}>
          <DataTable
            table={{ columns, rows }}
            backgroundColor={Colors.White}
            isSorted={false}
            entriesPerPage={{ defaultValue: defaultData.PER_PAGE }}
            showTotalEntries={false}
            noEndBorder
            loading={Constants.FULFILLED}
            isColumn={false}
            handleMarkAllasRead={handleMarkAllasRead}
          />
        </MDBox>
      </Feature>
    </DashboardLayout>
  );
}

export default SystemNotification;
