import { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import pxToRem from "assets/theme/functions/pxToRem";
import MDTypography from "components/MDTypography";
import Author from "components/Table/Author";
import MDBox from "components/MDBox";
import DynamicTypeData from "components/Table/DynamicTypeData";
import { useDispatch } from "react-redux";
import { setQuestionTree } from "redux/Slice/Report";
import Constants, { Icons, defaultData } from "utils/Constants";
import moment from "moment";

class TreeNode {
  constructor(value) {
    this.value = value;
    this.children = [];
    this.expanded = true; // Default to expanded
  }
}

const determineAnswer = (element, answer) => {
  const answerText = typeof answer?.answer === "string" ? answer.answer : "";
  if (
    element?.parameterType?.uniqueKey === "dateTime" &&
    answer.answer !== "" &&
    answer.answer !== null
  ) {
    return moment.utc(answer.answer).format(defaultData.WEB_24_HOURS_FORMAT);
  }
  if (element?.parameterType?.uniqueKey === "date") {
    return moment(answer.answer).format(defaultData.WEB_DATE_FORMAT);
  }
  if (answerText.includes("##")) {
    const parts = answerText
      .split("##")
      .map((part) => part.trim())
      .filter((part) => part !== "");

    return parts.join(", ");
  }
  return answer.answer;
};

export default function ReportDetailsData(
  report,
  handleView,
  handleDelete,
  handleAnswerEdit,
  permission
) {
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (report) {
      (async () => {
        const questionTree = new TreeNode("Question Tree");
        const sortedReport = [...report].sort((a, b) => a.sortOrder - b.sortOrder);

        // Map to keep track of usernames and their associated report IDs
        const userReportMap = new Map();

        const list = sortedReport?.map((item, idx) => {
          const getTitle = item.answerTypes.map((b) => b?.title?.length);
          const titleLength = getTitle?.reduce((acc, title) => acc + parseInt(title, 10));
          const processedAnswerTitleIds = new Set();

          item.answerTypes.forEach((element1) => {
            element1.userAnswers?.forEach((item21) => {
              item21.answers.forEach((answer1) => {
                if (
                  element1.title.map((a) =>
                    item21.answers.some((b) => b.answerTitleId === a[Constants.MONGOOSE])
                  )
                ) {
                  if (!processedAnswerTitleIds.has(answer1.answerTitleId)) {
                    processedAnswerTitleIds.add(answer1.answerTitleId);
                  }
                }
              });
            });
          });

          const questionObj = {
            id: item?.questionId,
            queNo: idx + 1,
            createdBy: "",
            questionAnswer: (
              <MDBox>
                <MDTypography
                  variant="caption"
                  sx={{ overflowWrap: "anywhere", textTransform: "capitalize" }}
                >
                  {`Q${idx + 1}.  ${item.title}`}
                </MDTypography>
              </MDBox>
            ),
            progress: `${processedAnswerTitleIds.size} / ${titleLength}`,
            action: item.answerTypes.length > 0 && (
              <IconButton
                aria-label="view report detail"
                color="info"
                onClick={() => handleView(item, idx + 1)}
              >
                {Icons.VIEW}
              </IconButton>
            ),
            isQuestion: true,
            queId: idx + 1,
          };
          questionTree.children.push(new TreeNode(questionObj));

          if (item.answerTypes.length > 0) {
            item.answerTypes.forEach((element) => {
              element.userAnswers?.forEach((item2) => {
                const createdByKey = `${item2.createdBy.firstName} ${item2.createdBy.lastName}`;
                const reportId = item2?.userReport;

                // Initialize the map for the user if not already present
                if (!userReportMap.has(createdByKey)) {
                  userReportMap.set(createdByKey, new Map());
                }

                // Add the current report ID to the set of IDs for the user
                const reportMap = userReportMap.get(createdByKey);
                if (!reportMap.has(reportId)) {
                  reportMap.set(reportId, reportMap.size + 1);
                }

                // Determine the display name based on the count of unique report IDs
                const userCount = reportMap.get(reportId);

                // Construct the user name with the count only if it is greater than 1
                const userName =
                  userCount > 1 ? `${createdByKey} (${userCount})` : `${createdByKey}`;

                item2.answers.forEach((answer, answerIdx) => {
                  const correspondingTitle = element.title.find(
                    (title) => title[Constants.MONGOOSE_ID] === answer.answerTitleId
                  );
                  const answerObj2 = {
                    id: answer[Constants.MONGOOSE_ID],
                    queNo: "",
                    createdBy: answerIdx === 0 ? userName : "", // Show userName only for the first answer
                    questionAnswer: (
                      <MDBox display="flex" flexDirection="row">
                        <Author
                          name={`${correspondingTitle?.value} :`}
                          maxContent={defaultData.LARGE_CONTENT_LENGTH}
                          style={{ color: "#7B809A" }}
                        />
                        {element?.parameterType?.uniqueKey === "image" ||
                        element?.parameterType?.uniqueKey === "signature" ? (
                          <MDBox
                            sx={{
                              paddingBottom: pxToRem(8),
                            }}
                          >
                            <DynamicTypeData
                              type={element?.parameterType?.uniqueKey}
                              data={
                                answer.answer?.length > 0
                                  ? answer.answer
                                  : process.env.REACT_APP_IMAGE_NOT_FOUND
                              }
                            />
                          </MDBox>
                        ) : (
                          <Author
                            name={determineAnswer(element, answer)}
                            style={{ marginLeft: "8px", textTransform: "capitalize" }}
                            maxContent={defaultData.LARGE_CONTENT_LENGTH}
                          />
                        )}
                      </MDBox>
                    ),
                    progress: "",
                    action: (
                      <MDBox>
                        {permission?.update && element?.parameterType?.uniqueKey !== "signature" && (
                          <IconButton
                            aria-label="edit report detail"
                            color="error"
                            onClick={() =>
                              handleAnswerEdit(
                                item,
                                element,
                                answer,
                                item2,
                                correspondingTitle?.value
                              )
                            }
                          >
                            {Icons.EDIT}
                          </IconButton>
                        )}
                        {permission?.delete && (
                          <IconButton
                            aria-label="delete report detail"
                            color="error"
                            onClick={() =>
                              handleDelete(item2[Constants.MONGOOSE_ID], answer?.answerTitleId)
                            }
                          >
                            {Icons.DELETE}
                          </IconButton>
                        )}
                      </MDBox>
                    ),
                    isQuestion: false,
                    queId: idx + 1,
                    ansId: "",
                    title: "",
                  };

                  questionTree.children[idx].children.push(new TreeNode(answerObj2));
                });
              });
            });
          }

          const questionRow = {
            queNo: idx + 1,
            createdBy: "",
            questionAnswer: item?.title,
            action: "",
          };
          return questionRow;
        });
        await dispatch(setQuestionTree(questionTree));
        setRows([...list]);
      })();
    }
  }, [report, permission]);

  return {
    reportColumns: [
      { Header: "No.", accessor: "queNo", width: "10%" },
      { Header: "Submitted By", accessor: "createdBy", align: "left", width: "20%" },
      { Header: "Question", accessor: "questionAnswer", align: "left" },
      { Header: "Progress", accessor: "progress", align: "center", width: "10%" },
      { Header: "Action", accessor: "action", align: "center", width: "10%" },
    ],
    reportRows: rows,
  };
}
