import { useState, useEffect } from "react";

// Material UI
import { Modal } from "@mui/material";
import style from "assets/style/Modal";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Assets
import pxToRem from "assets/theme/functions/pxToRem";

// 3rd party
import PropTypes from "prop-types";

export default function Timer({ open, message, callback }) {
  const [countdown, setCountdown] = useState(10);

  useEffect(() => {
    if (open) {
      setCountdown(10);
      const timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev === 1) {
            clearInterval(timer);
            callback();
          }
          return prev - 1;
        });
      }, 1000);
      return () => clearInterval(timer);
    }

    return () => {};
  }, [open]);

  return (
    <Modal open={open} aria-labelledby="Custom dialog" aria-describedby="Custom dialog for timer">
      <MDBox sx={{ ...style, minWidth: 100, maxWidh: 400 }}>
        <MDBox sx={{ maxHeight: 500, paddingX: "16px", marginY: "16px" }}>
          <MDTypography
            fontSize={pxToRem(16)}
            fontWeight="400"
            lineHeight="20px"
            sx={{ color: "#475467" }}
          >
            {message}
            <span style={{ color: "#191A51", fontWeight: "bold" }}>
              {`You will be logged out in ${countdown} seconds.`}
            </span>
          </MDTypography>
        </MDBox>
      </MDBox>
    </Modal>
  );
}

Timer.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
};
