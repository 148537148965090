const defaultData = {
  DEFAULT_DATA_IDENTIFIER: "default",
  NORMAL_DATA_IDENTIFIER: "normal",
  DATE_ON_SINGLE_API_CALL: 50,
  DATE_ON_SINGLE_API_CALL_2: 200,
  PER_PAGE: 25,
  PER_PAGE_2: 10,
  PER_PAGE_3: 20,
  PER_PAGE_4: 100,
  PAGE: 0,
  WEB_DATE_FORMAT: "DD-MM-YYYY",
  DATABSE_DATE_FORMAT: "YYYY-MM-DD",
  WEB_12_FORMAT: "DD-MM-YYYY HH:mm A",
  WEB_24_HOURS_FORMAT: "DD-MM-YYYY HH:mm:ss",
  DATABASE_24_HOURS_FORMAT: "YYYY-MM-DDTHH:mm:ss",
  REACTDATETIMEPICKER_DATE_FORMAT: "dd-MM-yyyy",
  REACTDATETIMEPICKER_12_HOURS_FORMAT: "dd-MM-yyyy HH:mm aa",
  REACTDATETIMEPICKER_24_HOURS_FORMAT: "dd-MM-yyyy HH:mm:ss",
  UNAUTHORIZED_ROLE: "unauthorized",
  ADMIN_ROLE: "admin",
  SUPER_ADMIN_ROLE: "superadmin",
  PROJECT_MANAGER: "projectManager",
  WAREHOUSE_MANAGER: "warehouseManager",
  WEB_ACCESSTYPE: "web",
  BOTH_ACCESSTYPE: "both",
  MOBILE_ACCESSTYPE: "mobile",
  PENDING_STATUS: "pending",
  REJECTED_STATUS: "rejected",
  FILTER_MINIMUM_LENGTH: 15,
  SMALLER_CONTENT_LENGTH: 20,
  MEDIUM_CONTENT_LENGTH: 36,
  MEDIUM_CONTENT_LENGTH_2: 65,
  VIEW_SAFETY_CARD_LENGTH: 75,
  LARGE_CONTENT_LENGTH: 100,
  DEFAULT_PHONE_IN: "+31",
  DEFAULT_COUNTRY_CODE: "NL",

  // Config Screens Id
  SAFE_CARD_SCREEN_ID: "safe_01",
  UNSAFE_CARD_SCREEN_ID: "unsafe_02",
  NCR_CARD_SCREEN_ID: "ncr_03",
  INCIDENT_CARD_SCREEN_ID: "incident_04",
  FEEDBACK_SCREEN_ID: "submit_feedback_05",
  SHIFT_SCREEN_ID: "open_shift_06",
  REPORT_SCREEN_ID: "report_07",
  SHIFT_ACTIVITY_SCREEN_ID: "shift_activity_08",
  EQUIPMENT_SCREEN_ID: "inventory_10",
  WAREHOUSE_SCREEN_ID: "warehouse_11",
  APPROVER_SCREEN_ID: "order_approval_13",
};

export default defaultData;
