import Sessions from "utils/Sessions";
import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiService from "redux/ApiService/ApiService";

const getReturnEquipment = createAsyncThunk("return-equipment-listing/api", async (param) => {
  const res = await ApiService.get(`return-order?${param}`, {
    headers: { Authorization: `Bearer ${Sessions.userToken}` },
  })
    .then((r) => r)
    .catch((err) => err.response);
  const params = new URLSearchParams(param);
  const page = params.get("page");
  return page === "0" ? { data: res.data, type: "add" } : { data: res.data, type: "append" };
});

export const addToReturnCart = createAsyncThunk("add-to-return-cart/api", async (body) => {
  const res = await ApiService.post(`return-order/pre-checkout`, body, {
    headers: { Authorization: `Bearer ${Sessions.userToken}` },
  })
    .then((r) => r)
    .catch((err) => err.response);
  return res;
});

export const returnCartList = createAsyncThunk("return-cart-list/api", async (param) => {
  const res = await ApiService.get(`return-order/equipment-history/${param.projectId}`, {
    headers: { Authorization: `Bearer ${Sessions.userToken}` },
  })
    .then((r) => r)
    .catch((err) => err.response);
  return res;
});

export const projectApproverList = createAsyncThunk("project-approver-list/api", async () => {
  const res = await ApiService.get(`projects/get-projects-by-approver`, {
    headers: { Authorization: `Bearer ${Sessions.userToken}` },
  })
    .then((r) => r)
    .catch((err) => err.response);
  return res;
});

export const returnEquipment = createAsyncThunk("return-equipment/api", async (body) => {
  const res = await ApiService.post(`return-order`, body, {
    headers: { Authorization: `Bearer ${Sessions.userToken}` },
  })
    .then((r) => r)
    .catch((err) => err.response);
  return res;
});

export const deleteCartEquipment = createAsyncThunk(
  "delete-cart-equipment/api",
  async (equipmentId) => {
    const res = await ApiService.delete(`return-order/remove-equipment/${equipmentId}`, {
      headers: { Authorization: `Bearer ${Sessions.userToken}` },
    });
    return res;
  }
);

export default getReturnEquipment;
