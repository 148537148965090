// Lib
import countries from "countries-list";

// Constants
import Messages from "utils/Constants/Messages.constants";
import ButtonTitlesConstants from "utils/Constants/ButtonTitles.constants";
import CardTitlesConstants from "utils/Constants/CardTitles.constants";
import FormFieldsConstants from "utils/Constants/ConfigurationFields.constants";
import defaultDataConstants from "utils/Constants/DefaultData.constants";
import FeatureTagsConstants from "utils/Constants/FeatureTags.constants";
import LicensePermissionConstants from "utils/Constants/LicensePermission.constants";
import ModelContentConstants from "utils/Constants/ModelContent.constants";
import PageTitleConstants from "utils/Constants/PageTitles.constants";
import ColorsConstants from "utils/Constants/Colors.constants";
import IconsConstants from "utils/Constants/Icons.constants";

export default Messages;
export const ButtonTitles = ButtonTitlesConstants;
export const CardTitles = CardTitlesConstants;
export const FormFields = FormFieldsConstants;
export const defaultData = defaultDataConstants;
export const FeatureTags = FeatureTagsConstants;
export const LicensePermission = LicensePermissionConstants;
export const ModalContent = ModelContentConstants;
export const PageTitles = PageTitleConstants;
export const Colors = ColorsConstants;
export const Icons = IconsConstants;
export const countryList = Object.values(countries.countries).map((country) => country.name);
