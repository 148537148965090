import { createSlice } from "@reduxjs/toolkit";
import { resetStateThunk } from "redux/Thunks/Authentication";
import ProductListThunk from "redux/Thunks/Equipment";
import Constants from "utils/Constants";

const initialState = {
  loading: Constants.IDLE,
  list: [],
  stockData: [],
};

export const productSlice = createSlice({
  name: "productSlice",
  initialState,
  reducers: {
    reloadData(state) {
      state.loading = Constants.PENDING;
    },

    resetListState(state) {
      state.loading = Constants.PENDING;
      state.list = [];
      state.stockData = [];
    },

    updateStockCheckbox(state, action) {
      const { equipmentData, checked } = action.payload;
      const stateData = JSON.parse(JSON.stringify(state));
      let updatedStockData;

      if (checked) {
        // Add equipmentData to the stockData array when checked
        updatedStockData = [...state.stockData, equipmentData];
      } else {
        // Remove equipmentData from the stockData array when unchecked
        updatedStockData = state.stockData.filter(
          (item) => item[Constants.MONGOOSE_ID] !== equipmentData[Constants.MONGOOSE_ID]
        );
      }

      const tempRoleCheckBox = stateData.list.map((item) => ({
        ...item,
        check:
          checked && equipmentData[Constants.MONGOOSE_ID] === item[Constants.MONGOOSE_ID]
            ? true
            : item.check,
      }));

      return {
        ...state,
        list: tempRoleCheckBox,
        stockData: updatedStockData,
      };
    },

    updateQuantity(state, action) {
      const { target, equipmentId, name } = action.payload;
      const stateData = JSON.parse(JSON.stringify(state));
      const tempQuantity = stateData.stockData?.map((item) => {
        const parsedStockQuantity = parseInt(target, 10);
        const parsedQuantity = parseInt(item.quantity, 10);
        if (equipmentId === item[Constants.MONGOOSE_ID] && name === "updateStockQuantity") {
          return {
            ...item,
            stockQuantity: target,
            totalStock: (parsedStockQuantity || 0) + (parsedQuantity || 0),
          };
        }

        if (equipmentId === item[Constants.MONGOOSE_ID] && name === "validateStockQuantity") {
          return {
            ...item,
            stockQuantity: target === "" ? 0 : parseInt(target, 10),
            totalStock: (parsedStockQuantity || 0) + (parsedQuantity || 0),
          };
        }

        return item;
      });
      return {
        ...state,
        stockData: tempQuantity,
      };
    },

    deleteStock(state, action) {
      const { deleteId } = action.payload;
      const stateData = JSON.parse(JSON.stringify(state));
      stateData.stockData = stateData.stockData.filter(
        (item) => item[Constants.MONGOOSE_ID] !== deleteId
      );

      return stateData;
    },
  },

  extraReducers: {
    [ProductListThunk.pending]: (state) => {
      if (state.list.length === 0) state.loading = Constants.PENDING;
    },
    [ProductListThunk.fulfilled]: (state, { payload }) => {
      state.loading = Constants.FULFILLED;
      const equipmentData = payload.data.data;
      const modifiedData = equipmentData?.map((item) => {
        const parsedStockQuantity = parseInt(item.stockQuantity, 10);
        const parsedQuantity = parseInt(item.quantity, 10);

        return {
          ...item,
          stockQuantity: parsedStockQuantity || 0, // Set to 0 if parsing fails
          totalStock: (parsedStockQuantity || 0) + (parsedQuantity || 0),
        };
      });

      if (payload.type === "add") state.list = modifiedData ?? [];
      else state.list.push(...(modifiedData ?? []));
    },
    [ProductListThunk.rejected]: (state, { payload }) => {
      const { canceled } = payload;
      if (!canceled) state.loading = Constants.REJECTED;
    },
    [resetStateThunk.fulfilled]: (state) => {
      state.loading = Constants.IDLE;
      state.list = [];
    },
  },
});

export const { reloadData, resetListState, updateQuantity, updateStockCheckbox, deleteStock } =
  productSlice.actions;
export default productSlice.reducer;
