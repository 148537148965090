/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

import { useEffect, useState } from "react";

// MUI Components
import { IconButton } from "@mui/material";

// Common Components
import Author from "components/Table/Author";
import MDBox from "components/MDBox";

// Constants
import Constants, { Colors, defaultData, Icons } from "utils/Constants";

// 3rd party libraries
import moment from "moment";

const reportTypeMapper = {
  location: "Location",
  asset_per_location: "Asset",
  multiple_assets: "Multiple Asset",
};

export default function ReportCalculationData(
  data,
  reportData,
  totalCompletionPercentage,
  handleLocationClick
) {
  // Initial State
  const [rows, setRows] = useState([]);
  const [scopeHeader, setScopeHeader] = useState([]);
  const [reportTitleHeader, setReportTitleHeader] = useState([]);
  const [reportValueHeader, setReportValueHeader] = useState([]);
  const [reportCompletionFooter, setReportCompletionFooter] = useState([]);
  const [reportTotalFooter, setReportTotalFooter] = useState([]);

  const handleCellBackground = (value) => {
    if (!value) return "transparent";

    if (value) {
      if (value <= 50) {
        return Colors.PINK;
      }
      if (value <= 75) {
        return Colors.LIGHT_PINK;
      }
      if (value < 100) {
        return Colors.LIGHT_ORANGE1;
      }
      if (value === 100) {
        return Colors.LIGHT_GREEN3;
      }
      return "transparent";
    }
    return "transparent";
  };

  const completionPercentage = (isRequired, completion) => {
    if (isRequired && completion !== 0) {
      return `${completion?.toFixed(2)}%`;
    }
    return "";
  };

  useEffect(() => {
    if (data) {
      const reportTitleList = [];
      const reportValueList = [];
      const reportCompletionList = [];
      let totalReportColSpan = 0;

      const scopeList = data.map((scope) => {
        scope?.reports?.forEach((report) => {
          reportTitleList.push({
            Header: `${report?.title} (${reportTypeMapper[report?.type]})`,
            align: "center",
            colSpan: 1,
            key: report[Constants.MONGOOSE_ID],
          });
          reportValueList.push({
            Header: `${report?.weightage?.toFixed(2)}%`,
            align: "center",
            colSpan: 1,
            type: report?.type,
            key: report[Constants.MONGOOSE_ID],
            accessor: report[Constants.MONGOOSE_ID],
          });
          reportCompletionList.push({
            Footer: `${report?.reportCompletion?.toFixed(2)}%`,
            align: "center",
            colSpan: 1,
            key: report[Constants.MONGOOSE_ID],
          });
        });
        totalReportColSpan += scope?.reports?.length || 0;
        return {
          Header: scope?.name,
          align: "center",
          colSpan: scope?.reports?.length,
          key: scope[Constants.MONGOOSE_ID],
        };
      });

      // set the headers and footers
      setScopeHeader([{ Header: "", colSpan: 2, key: "scope" }, ...scopeList]);
      setReportTitleHeader([{ Header: "", colSpan: 2, key: "reportTitle" }, ...reportTitleList]);
      setReportValueHeader([
        {
          Header: "Location",
          accessor: "location",
          align: "left",
          width: "20%",
          type: "location",
          key: "location",
        },
        {
          Header: "Asset",
          accessor: "asset",
          align: "left",
          width: "20%",
          type: "asset",
          key: "asset",
        },
        ...reportValueList,
      ]);
      setReportCompletionFooter([
        { Footer: "Completion", align: "center", colSpan: 2, key: "completion" },
        ...reportCompletionList,
      ]);
      setReportTotalFooter([
        { Footer: "", align: "center", colSpan: totalReportColSpan, key: "completion" },
        { Footer: "Total Completion", align: "center", colSpan: 1, key: "totalCompletion" },
        {
          Footer: `${totalCompletionPercentage}%`,
          align: "center",
          colSpan: 1,
          key: "totalPercentage",
        },
      ]);
    }

    if (reportData) {
      const dataList = reportData?.map((report) => ({
        key: report[Constants.MONGOOSE_ID],
        rowSpan: report?.assets?.length || 1,
        colSpan: 1,
        align: "left",
        location: (
          <MDBox
            sx={{ cursor: "pointer", display: "flex", alignItems: "center" }}
            onClick={() => {
              handleLocationClick(report[Constants.MONGOOSE_ID], report?.locations);
            }}
          >
            <Author name={report?.locations || ""} />
            <IconButton aria-label="report-parameter" color="info">
              {Icons.VIEW}
            </IconButton>
          </MDBox>
        ),
        assets: report?.assets?.map((asset) => ({
          title: (
            <Author
              maxContent={defaultData.MEDIUM_CONTENT_LENGTH_2}
              name={`${asset?.fromLocation?.title || ""} - ${asset?.toLocation?.title || ""}`}
            />
          ),
          key: asset[Constants.MONGOOSE_ID],
        })),
        reports: report?.reportList?.map((item) => ({
          key: item[Constants.MONGOOSE_ID],
          type: item?.type,
          rowSpan: item?.type === "location" ? report?.assets?.length || 1 : 1,
          colSpan: 1,
          align: "center",
          reportList: item?.reportList?.map((ele) => ({
            completion: (
              <MDBox>
                {ele?.completion !== 100
                  ? completionPercentage(ele?.isRequired, ele?.completion)
                  : moment(ele?.completedDate).format(defaultData.WEB_DATE_FORMAT)}
              </MDBox>
            ),
            isRequired: ele?.isRequired,
            bgColor: ele?.isRequired ? handleCellBackground(ele?.completion) : Colors.GREY1,
          })),
        })),
      }));

      setRows(dataList);
    }
  }, [data, reportData, totalCompletionPercentage]);

  return {
    columns: reportValueHeader,
    extraHeadersList: [scopeHeader, reportTitleHeader],
    footerList: [reportCompletionFooter, reportTotalFooter],
    rows,
  };
}
