import { createAsyncThunk } from "@reduxjs/toolkit";
import Sessions from "utils/Sessions";
import ApiService from "../ApiService/ApiService";

const AllEquipmentRequestListThunk = createAsyncThunk(
  "all-Euipment-Request-List/api",
  async (param) => {
    const res = await ApiService.get(`equipment-orders?${param}`, {
      headers: { Authorization: `Bearer ${Sessions.userToken}` },
    })
      .then((r) => r)
      .catch((error) => error.response);
    const params = new URLSearchParams(param);
    const page = params.get("page");
    const status = params.get("status");
    if (res.status === 200)
      return page === "0"
        ? { data: res.data, type: "add", status: res.status, currentStatus: status }
        : { data: res.data, type: "append", status: res.status, currentStatus: status };
    throw res;
  }
);
export const getPMOrders = createAsyncThunk("get-pm-orders/api", async () => {
  const res = await ApiService.get(`pm-order`, {
    headers: { Authorization: `Bearer ${Sessions.userToken}` },
  })
    .then((r) => r)
    .catch((error) => error.response);
  return res;
});

export const getPMUserOrders = createAsyncThunk("get-pm-user-orders/api", async (param) => {
  const res = await ApiService.get(`pm-order-request/user-ordered-equipments?${param}`, {
    headers: { Authorization: `Bearer ${Sessions.userToken}` },
  })
    .then((r) => r)
    .catch((error) => error.response);
  const params = new URLSearchParams(param);
  const page = params.get("page");
  return page === "0"
    ? { data: res.data, type: "add", status: res.status }
    : { data: res.data, type: "append", status: res.status };
});
export const orderNumberListThunk = createAsyncThunk("order-number/api", async () => {
  const res = await ApiService.get(`pm-order-request/order-number-list`, {
    headers: { Authorization: `Bearer ${Sessions.userToken}` },
  });
  return res.data;
});
export const equipmentPermissionThunk = createAsyncThunk(
  "equipment-order-requestss/api",
  async (param) => {
    const res = await ApiService.patch(
      `equipment-orders/change-status?${param.id}`,
      { ...param.status },
      {
        headers: { Authorization: `Bearer ${Sessions.userToken}` },
      }
    )
      .then((r) => r)
      .catch((err) => err.response);
    return res;
  }
);

export const addToQueueEquipment = createAsyncThunk("add-queue/api", async (body) => {
  const res = await ApiService.post(
    `pm-order/add-to-queue`,
    { ...body },
    {
      headers: { Authorization: `Bearer ${Sessions.userToken}` },
    }
  )
    .then((r) => r)
    .catch((err) => err.response);
  return res;
});

export const orderEquipment = createAsyncThunk("order-equipment/api", async (body) => {
  const res = await ApiService.patch(
    `pm-order/request/${body.id}`,
    { ...body.body },
    {
      headers: { Authorization: `Bearer ${Sessions.userToken}` },
    }
  )
    .then((r) => r)
    .catch((err) => err.response);
  return res;
});

export const updateApproverCommentThunk = createAsyncThunk(
  "update-approver-comment-queue",
  async (body) => {
    const res = await ApiService.patch(
      `pm-order/order-details/${body.id}`,
      { ...body.body },
      {
        headers: { Authorization: `Bearer ${Sessions.userToken}` },
      }
    )
      .then((r) => r)
      .catch((err) => err.response);
    return res;
  }
);

export const getPMReturnOrders = createAsyncThunk("get-pm-return-orders/api", async (param) => {
  const res = await ApiService.get(`return-order/pm-return-order`, {
    headers: { Authorization: `Bearer ${Sessions.userToken}` },
  })
    .then((r) => r)
    .catch((error) => error.response);
  const params = new URLSearchParams(param);
  const page = params.get("page");
  const status = params.get("status");
  if (res.status === 200)
    return page === "0"
      ? { data: res.data, type: "add", status: res.status, currentStatus: status }
      : { data: res.data, type: "append", status: res.status, currentStatus: status };
  throw res;
});

export default AllEquipmentRequestListThunk;
