/* eslint-disable react/function-component-definition */

import React, { useEffect, useState } from "react";

// 3rd party library
import PropTypes from "prop-types";
import moment from "moment";

// Custom Components
import Author from "components/Table/Author";
import Status from "components/Table/Status";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox";
import MDBox from "components/MDBox";

// Functions
import pxToRem from "assets/theme/functions/pxToRem";

// Constants
import Constants, { defaultData } from "utils/Constants";

export default function ProjectEquipmentData(projectEquipmentData, handleChange) {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (projectEquipmentData && projectEquipmentData?.length > 0) {
      const list = projectEquipmentData?.map((item, index) => {
        let certificateExpiryDate = null;
        const validCertificates = item?.equipmentCertificate?.filter(
          (certificate) => certificate.endDate !== null
        );
        const certificateExpiry = validCertificates?.map(
          (certificate) => new Date(certificate.endDate)
        );
        const currentDate = new Date();
        const filteredDates =
          certificateExpiry?.length > 0
            ? certificateExpiry?.filter(
                (expiryDate) => expiryDate.getTime() !== currentDate.getTime()
              )
            : [];
        filteredDates?.sort((a, b) => a - b);
        if (filteredDates?.length > 0) {
          certificateExpiryDate = filteredDates[0].toISOString();
        }
        const temp = {
          check: (
            <CustomCheckbox
              name="check"
              checked={item?.check}
              disabled={
                (item?.returnStatus !== null &&
                  item?.returnStatus !== undefined &&
                  item?.returnStatus !== "" &&
                  item?.returnStatus !== "partially-returned") ||
                item?.equipmentPriceType === "buy"
              }
              onChange={(e) => handleChange("check", e, index)}
            />
          ),
          srNo: <Author name={index + 1} />,
          equipmentName: (
            <MDBox display="flex" justifyContent="center" alignItems="center">
              <img
                src={
                  item?.equipmentImage?.[0]?.url
                    ? item?.equipmentImage?.[0]?.url
                    : process.env.REACT_APP_IMAGE_NOT_FOUND
                }
                alt={item?.equipmentImage}
                key={item?.equipmentImage?.[0]?.name}
                style={{
                  width: pxToRem(50),
                  height: pxToRem(50),
                  marginRight: pxToRem(10),
                  borderRadius: "8px",
                }}
              />
              <Author name={item?.equipmentName} />
            </MDBox>
          ),
          productNumber: <Author name={item?.equipmentNumber} />,
          equipmentType: <Author name={item?.equipmentType} />,
          priceType: <Author name={item?.equipmentPriceType} />,
          equipmentCategory: <Author name={item?.equipmentCategory} />,
          receivedQty: (
            <Author
              name={
                item?.pmDispatchQuantity === null
                  ? item?.pmReceivedQuantity
                  : Number(item?.pmReceivedQuantity, 10) - Number(item?.pmDispatchQuantity, 10)
              }
            />
          ),
          serialNumber: <Author name={item?.serialNumber ? item?.serialNumber : Constants.NA} />,
          status: (
            <Status
              title={
                item?.returnStatus !== null && item?.returnStatus !== ""
                  ? `${item?.returnStatus.replace("-", " ")}`
                  : "on site"
              }
            />
          ),
          certificateExpiry: (
            <Author
              name={
                certificateExpiryDate !== null
                  ? moment(certificateExpiryDate).format(defaultData.WEB_DATE_FORMAT)
                  : ""
              }
            />
          ),
        };
        return temp;
      });
      setRows([...list]);
    } else {
      setRows([]);
    }
  }, [projectEquipmentData]);

  return {
    columns: [
      { Header: "", accessor: "check", width: "7%" },
      { Header: "No.", accessor: "srNo", width: "7%" },
      { Header: "Equipment Category", accessor: "equipmentCategory", align: "left" },
      { Header: "Equipment Type", accessor: "equipmentType", align: "left" },
      { Header: "Price Type", accessor: "priceType", align: "left" },
      { Header: "Product Number", accessor: "productNumber", align: "left" },
      { Header: "Equipment Name", accessor: "equipmentName", align: "left" },
      { Header: "Serial Number", accessor: "serialNumber", align: "left" },
      { Header: "Certificate Expiry", accessor: "certificateExpiry", align: "left" },
      { Header: "Onsite Qty", accessor: "receivedQty", align: "center" },
      { Header: "Status", accessor: "status", align: "left" },
    ],
    rows,
  };
}

ProjectEquipmentData.propTypes = {
  projectEquipmentData: PropTypes.arrayOf(PropTypes.object).isRequired,
  checkAll: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
};
