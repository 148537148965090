import React, { useEffect, useState } from "react";

// Material ui Components
import { Divider, Grid, Icon, IconButton, Tab, Tabs, Tooltip } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

// Custom Components
import CustomDrawer from "components/Drawer/CustomDrawer";
import Frame from "assets/images/Frame.svg";
import pxToRem from "assets/theme/functions/pxToRem";
import DataTable from "examples/Tables/DataTable";
import Pdf from "assets/images/pdf.svg";

// Table Data
import InventoryHistory from "examples/Drawers/Equiupment/InventoryHistory";
// Redux
import { useDispatch } from "react-redux";
import { equipmentGetByIdThunk, equipmentInventoryHistoryThunk } from "redux/Thunks/Equipment";

// Constant
import Constants, { Icons, defaultData } from "utils/Constants";
import moment from "moment";

// Methods
import { downloadFile, paramCreater } from "utils/methods/methods";

// 3rd party library
import PropTypes from "prop-types";

function EquipmentDetailDrawer({
  equipmentAnchor,
  equipmentId,
  closeDrawer,
  handleViewImage,
  equipmentKey,
}) {
  const tabsList = ["Details", "Inventory/Order History", "Documents"];
  const orderTabsList = ["Details", "Documents"];
  const [tablePagination, setTablePagination] = useState({
    page: 0,
    perPage: defaultData.DATE_ON_SINGLE_API_CALL,
  });
  const [next, setNext] = useState(0);
  const [currentTab, setCurrentTab] = useState("Details");
  const [equipmentData, setEquipmentData] = useState({});
  const [inventoryData, setInventoryData] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState(Constants.PENDING);
  const dispatch = useDispatch();
  const { inventoryColumns, inventoryRow } = InventoryHistory(inventoryData);

  useEffect(() => {
    (async () => {
      const res = await dispatch(equipmentGetByIdThunk(equipmentId));
      if (res.payload.status === 200) {
        setEquipmentData(res?.payload?.data?.data);
      }
    })();

    return () => {
      setEquipmentData({});
    };
  }, [equipmentId]);

  const handleData = async () => {
    setTablePagination({ ...tablePagination, page: 0 });
    setNext(0);
    const paramData = {
      page: 0,
      perPage: tablePagination.perPage,
    };
    const data = {
      equipmentId,
      param: paramCreater(paramData),
    };
    const [inventoryRes] = await Promise.all([dispatch(equipmentInventoryHistoryThunk(data))]);
    setInventoryData(inventoryRes.payload.data.data);
    setLoadingStatus(Constants.FULFILLED);
  };

  useEffect(() => {
    handleData();
  }, []);

  const handleTablePagination = async () => {
    const paramData = {
      page: next + 1,
      perPage: tablePagination.perPage,
    };
    const data = {
      equipmentId,
      param: paramCreater(paramData),
    };
    const res = await dispatch(equipmentInventoryHistoryThunk(data));
    if (res.payload.status === 200) {
      setInventoryData([...inventoryData, ...res.payload.data.data]);
      setNext(res.payload.data.data?.length > 0 ? next + 1 : next);
    }
  };

  const productInfo = (key, value, grid = 4) => (
    <Grid item xs={6} lg={grid} key={key.toLocaleLowerCase()}>
      <MDBox display="flex" flexDirection="column" alignItems="start" key={key}>
        <MDTypography
          sx={{
            mt: 1,
            color: "#475467",
            fontFamily: "Inter",
            fontSize: pxToRem(14),
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: pxToRem(20),
          }}
        >
          {key}
        </MDTypography>
        <MDTypography
          sx={{
            mt: 1,
            color: "var(--default-tier-brand-1, #191A51)",
            fontFamily: "Inter",
            fontSize: pxToRem(16),
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: pxToRem(20),
            textTransform: "capitalize",
            whiteSpace: "normal",
          }}
        >
          {value}
        </MDTypography>
      </MDBox>
    </Grid>
  );

  const equipmentDocuments = (name, url, size, startDate, endDate) => (
    <Grid item xs={12} lg={10}>
      <MDBox
        display="flex"
        border="1px solid #E0E6F5"
        borderRadius="8px"
        p={1}
        ml={1}
        sx={{ cursor: "pointer" }}
        onClick={() => handleViewImage(url)}
      >
        <img
          src={name?.includes(".pdf") ? Pdf : url || Frame}
          alt="Preview"
          height="60px"
          width="60px"
          style={{
            border: "1px solid #D0D5DD",
            borderRadius: "8px",
            marginTop: "5px",
            marginLeft: "4px",
          }}
        />

        <MDBox ml={2} mt={0}>
          {name && name?.length > defaultData.MEDIUM_CONTENT_LENGTH ? (
            <MDTypography display="block" variant="caption" sx={{ textTransform: "capitalize" }}>
              <Tooltip title={name}>
                <span>{`${name.slice(0, defaultData.MEDIUM_CONTENT_LENGTH)}...`}</span>
              </Tooltip>
            </MDTypography>
          ) : (
            <MDTypography display="block" variant="caption" sx={{ textTransform: "capitalize" }}>
              {name}
            </MDTypography>
          )}
          {startDate && (
            <MDTypography display="block" variant="caption" color="text">
              {`Start Date: ${moment(startDate).format(defaultData.WEB_DATE_FORMAT)}`}
            </MDTypography>
          )}
          {endDate && (
            <MDTypography display="block" variant="caption" color="text">
              {`End Date: ${moment(endDate).format(defaultData.WEB_DATE_FORMAT)}`}
            </MDTypography>
          )}
        </MDBox>
        {url && (
          <Icon
            fontSize="medium"
            sx={{ ml: "auto", cursor: "pointer" }}
            justifyContent="flex-end"
            onClick={(event) => downloadFile(url, event, name)}
          >
            {Icons.DOWNLOAD}
          </Icon>
        )}
      </MDBox>
    </Grid>
  );

  return (
    <CustomDrawer defaultAnchor={equipmentAnchor} width="75%" onDrawerClose={closeDrawer}>
      {Object.keys(equipmentData)?.length > 0 ? (
        <>
          <MDBox
            px={pxToRem(24)}
            p={pxToRem(10)}
            display="flex"
            justifyContent="start"
            alignItems="center"
          >
            <IconButton mr={2} aria-label="fingerprint" color="info" onClick={closeDrawer}>
              {Icons.CLOSE}
            </IconButton>
            <MDTypography
              sx={{
                color: "var(--gray-900, #101828)",
                fontFamily: "Inter",
                fontSize: pxToRem(20),
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: pxToRem(28),
                textAlign: "center",
              }}
            >
              {equipmentData?.name}
            </MDTypography>
          </MDBox>
          <Divider />

          <MDBox
            px={pxToRem(20)}
            display="flex"
            flexDirection={{ xs: "column", md: "row" }}
            justifyContent={{ xs: "center", md: "start" }}
            alignItems={{ xs: "center", md: "flex-start" }}
          >
            <MDBox display="flex" flexDirection="column" mt={pxToRem(32)}>
              <img
                src={equipmentData?.equipmentImage?.[0]?.url || Frame}
                alt="frame"
                style={{
                  borderRadius: pxToRem(16),
                  width: pxToRem(128),
                  height: pxToRem(128),
                }}
              />
            </MDBox>
            <MDBox
              ml={{ xs: 0, md: pxToRem(24) }}
              mt={pxToRem(20)}
              display="flex"
              flexDirection="column"
              justifyContent={{ xs: "center", md: "start" }}
              alignItems={{ xs: "center", md: "flex-start" }}
            >
              <Tabs
                value={currentTab}
                onChange={(e, newTab) => {
                  setCurrentTab(newTab);
                }}
                aria-label="Product detail Tabs"
                sx={{
                  paddingY: pxToRem(0),
                  background: "transparent",
                  width: "auto",
                  borderRadius: 0,
                }}
              >
                {(equipmentKey === "equipmentDrawer" ? tabsList : orderTabsList).map((tab) => (
                  <Tab
                    key={tab}
                    label={tab}
                    value={tab}
                    sx={{
                      paddingY: pxToRem(14),
                      paddingX: pxToRem(24),
                      borderBottom: currentTab === tab ? `2px solid #FF5E13` : "none",
                      borderRadius: 0,
                      fontWeight: `${currentTab === tab ? "600" : null}`,
                      lineHeight: pxToRem(24),
                    }}
                  />
                ))}
              </Tabs>
              <Grid container spacing={2} mb={5} mt={pxToRem(1)}>
                {currentTab === "Details" && (
                  <>
                    {productInfo("Name", equipmentData?.name, 12)}
                    {productInfo("Equipment Type", equipmentData?.equipmentType?.type, 12)}
                    {productInfo(
                      "Category",
                      equipmentData?.equipmentType?.equipmentCategory?.name,
                      12
                    )}
                    {productInfo(
                      "HS Code",
                      `${equipmentData?.equipmentType.hsCode?.name} (${equipmentData?.equipmentType.hsCode?.code})`,
                      12
                    )}
                    {productInfo(
                      "Quantity Type",
                      `${equipmentData?.equipmentType?.quantityType?.name}(${equipmentData?.equipmentType?.quantityType?.priceType}, ${equipmentData?.equipmentType?.quantityType?.quantityType}) `,
                      12
                    )}
                    {productInfo(
                      "Product Number",
                      equipmentData?.equipmentNumber ? equipmentData?.equipmentNumber : "N/A"
                    )}
                    {productInfo(
                      "Serial Number",
                      equipmentData?.serialNumber ? equipmentData?.serialNumber : "N/A"
                    )}
                    {productInfo(
                      "Price/Value",
                      `${equipmentData?.equipmentType?.currencyUnit?.symbol} ${equipmentData?.value}`
                    )}
                    {productInfo(
                      "Rental Price",
                      equipmentData?.equipmentType?.quantityType?.priceType === "buy"
                        ? "N/A"
                        : `${equipmentData?.equipmentType?.currencyUnit?.symbol} ${equipmentData?.equipmentType?.price}`
                    )}
                    {productInfo(
                      "Weight",
                      `${equipmentData?.weight} ${equipmentData?.equipmentType?.equipmentUnit?.abbreviation}`
                    )}
                    {productInfo("Available Stock", equipmentData?.quantity)}

                    {productInfo(
                      "Certificate Validity Date",
                      equipmentData?.certificateValidateDate &&
                        equipmentData?.certificateValidateDate !== null
                        ? moment(equipmentData?.certificateValidateDate).format(
                            defaultData.WEB_DATE_FORMAT
                          )
                        : "N/A"
                    )}
                    {productInfo("Warehouse", equipmentData?.warehouse?.name)}
                    {productInfo("Equipment Location", equipmentData?.equipmentLocationInWarehouse)}
                    {productInfo(
                      "Current Location",
                      equipmentData?.equipmentCurrentLocation.replace(/_/g, " ")
                    )}
                    {productInfo("Status", equipmentData?.isActive ? "Active" : "Inactive")}
                    {productInfo(
                      "QR Number",
                      equipmentData?.qrCode &&
                        equipmentData.qrCode.length > 0 &&
                        equipmentData.qrCode[equipmentData.qrCode.length - 1]?.code
                        ? equipmentData.qrCode[equipmentData.qrCode.length - 1].code
                        : "N/A"
                    )}
                  </>
                )}
                {currentTab === "Inventory/Order History" && (
                  <MDBox mt={2} px={pxToRem(24)}>
                    <DataTable
                      table={{ columns: inventoryColumns, rows: inventoryRow }}
                      isSorted={false}
                      entriesPerPage={{ defaultValue: 10 }}
                      showTotalEntries={false}
                      pagination={{ variant: "gradient", color: "info" }}
                      loading={loadingStatus}
                      currentPage={tablePagination.page}
                      handleTablePagination={handleTablePagination}
                      handleCurrentPage={(page) => setTablePagination({ ...tablePagination, page })}
                    />
                  </MDBox>
                )}
                {currentTab === "Documents" && (
                  <Grid container spacing={2} display="flex" flexDirection="column" width="600px">
                    {equipmentData?.certificateType &&
                    equipmentData?.certificateType?.length > 0 ? (
                      equipmentData?.certificateType.map((data) => (
                        <MDBox key={data[Constants.MONGOOSE_ID]} mb={2} mt={2} px={pxToRem(35)}>
                          <MDTypography
                            display="block"
                            variant="caption"
                            fontWeight="medium"
                            mb={1}
                            mt={1}
                            ml={1}
                          >
                            {data && data.title?.length > defaultData.MEDIUM_CONTENT_LENGTH ? (
                              <Tooltip title={data.title}>
                                <span>{`${data.title.slice(
                                  0,
                                  defaultData.MEDIUM_CONTENT_LENGTH
                                )}...`}</span>
                              </Tooltip>
                            ) : (
                              data?.title
                            )}
                          </MDTypography>
                          {equipmentDocuments(
                            data.name,
                            data.url,
                            data.size,
                            data.startDate,
                            data.endDate
                          )}
                        </MDBox>
                      ))
                    ) : (
                      <MDTypography
                        display="block"
                        variant="h5"
                        fontWeight="medium"
                        textAlign="center"
                        py={5}
                        mb={1}
                        mt={10}
                      >
                        No Documents Found
                      </MDTypography>
                    )}
                  </Grid>
                )}
              </Grid>
            </MDBox>
          </MDBox>
        </>
      ) : (
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ height: "inherit" }}
        >
          {Icons.LOADING2}
        </MDBox>
      )}
    </CustomDrawer>
  );
}

EquipmentDetailDrawer.defaultProps = {
  equipmentId: "",
  equipmentAnchor: "",
  closeDrawer: () => {},
  handleViewImage: () => {},
  equipmentKey: "",
};

EquipmentDetailDrawer.propTypes = {
  equipmentId: PropTypes.string,
  equipmentAnchor: PropTypes.string,
  closeDrawer: PropTypes.func,
  handleViewImage: PropTypes.func,
  equipmentKey: PropTypes.string,
};

export default EquipmentDetailDrawer;
