/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

import { useEffect, useState } from "react";

// Components
import Author from "components/Table/Author";
import { IconButton, TextField } from "@mui/material";

// Methods
import { numericFieldValidation } from "utils/methods/methods";

// Utils
import Constants, { Icons } from "utils/Constants";

export default function Data(stockUpdateList, handleStockChange, handleDeleteStock) {
  const [stockRows, setStockRows] = useState([]);

  useEffect(() => {
    if (stockUpdateList) {
      const list = stockUpdateList.map((item, index) => {
        const temp = {
          srNo: <Author name={index + 1} />,
          type: <Author name={item?.equipmentType?.type} />,
          name: <Author name={item?.name} />,
          availableStock: <Author name={item?.quantity} />,
          newStock: (
            <TextField
              type="text"
              name="stockQty"
              id="stockQty"
              value={item?.stockQuantity}
              onChange={(e) =>
                handleStockChange(e, item[Constants.MONGOOSE_ID], "updateStockQuantity")
              }
              onBlur={(e) =>
                handleStockChange(e, item[Constants.MONGOOSE_ID], "validateStockQuantity")
              }
              onKeyDown={(e) => numericFieldValidation(e)}
              variant="standard"
              sx={{
                width: "100px",
                "& .MuiInputBase-input": {
                  textAlign: "center",
                },
              }}
            />
          ),
          totalStock: <Author name={item?.totalStock} />,
          action: (
            <IconButton
              aria-label="fingerprint"
              color="error"
              onClick={() => handleDeleteStock(item[Constants.MONGOOSE_ID])}
            >
              {Icons.DELETE}
            </IconButton>
          ),
        };
        return temp;
      });
      setStockRows([...list]);
    }
  }, [stockUpdateList]);

  return {
    stockColumns: [
      { Header: "No.", accessor: "srNo", width: "3%" },
      { Header: "Type", accessor: "type", align: "left" },
      { Header: "Name", accessor: "name", align: "left" },
      { Header: "Available Stock", accessor: "availableStock", align: "left" },
      { Header: "New Stock", accessor: "newStock", align: "left" },
      { Header: "Total Stock", accessor: "totalStock", align: "left" },
      { Header: "Action", accessor: "action", align: "left" },
    ],
    stockRows,
  };
}
