// Material Dashboard 2 React components
import { Grid } from "@mui/material";
import MDBox from "components/MDBox";

// Components
import pxToRem from "assets/theme/functions/pxToRem";
import ConfigDropdown from "components/Dropdown/ConfigDropdown";
import DateTime from "components/DateTime/DateTime";
import FTextField from "components/Form/FTextField";

// libraries
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import PropTypes from "prop-types";

// Redux
import { useSelector } from "react-redux";

// utils
import Constants, { defaultData } from "utils/Constants";
import MDTypography from "components/MDTypography";
import { useEffect } from "react";

export default function ProductWarehouse({
  warehouseData,
  setWarehouseData,
  checkEquipmentId,
  setUpdatedBody,
  searchData,
  quantityType,
}) {
  const ConfigData = useSelector((state) => state.config);
  const locationDate = new Date();
  const handleConfigChange = (name, value) => {
    setWarehouseData((prevData) => ({ ...prevData, [name]: value }));
    if (name.includes("date")) {
      setWarehouseData((prevData) => ({
        ...prevData,
        [name]: name.includes("date") && value,
      }));
    }
    setUpdatedBody((prevData) => ({ ...prevData, [name]: value }));
  };
  useEffect(() => {
    const initialDate = moment(locationDate).format(defaultData.DATABSE_DATE_FORMAT).toString();
    if (checkEquipmentId === undefined) {
      if (!searchData?.equipmentLocationFromDate) {
        setUpdatedBody((prevData) => ({
          ...prevData,
          equipmentLocationFromDate: moment(locationDate).toDate(),
        }));
        handleConfigChange("equipmentLocationFromDate", initialDate);
      }
      ConfigData?.equipmentConfig?.properties?.warehouseInfo?.forEach((item) => {
        if (item.type === "options" && item.id === "warehouse") {
          handleConfigChange(
            item.id,
            item.options.length === 1
              ? item.options[0].id
              : searchData?.warehouse[Constants.MONGOOSE_ID]
          );
        } else if (item.type === "number" && item.id === "quantity") {
          handleConfigChange(item.id, quantityType === true ? 1 : warehouseData[item.id]);
        }
      });
    }
  }, [checkEquipmentId]);
  return (
    <Grid container spacing={2} sx={{ display: "flex", justifyContent: "center" }}>
      <Grid item xs={12}>
        <MDTypography
          sx={{
            fontSize: pxToRem(24),
            fontWeight: 600,
            color: "#667085",
            padding: "12px 0px",
          }}
        >
          Warehouse Info
        </MDTypography>
      </Grid>
      {ConfigData?.equipmentConfig?.properties?.warehouseInfo?.length > 0
        ? ConfigData?.equipmentConfig?.properties?.warehouseInfo?.map((item) => {
            const fieldValue = warehouseData[item.id] || "";
            switch (item.type) {
              case "text":
                return (
                  <Grid item lg={6} sm={12} key={item.id}>
                    <FTextField
                      label={item?.IsRequired ? `${item.title}*` : item.title}
                      placeholder={item?.hint}
                      name={item?.id}
                      id={item?.id}
                      type="text"
                      value={fieldValue}
                      error={Boolean(warehouseData?.errors[item.id])}
                      helperText={warehouseData?.errors[item.id]}
                      handleChange={(e) => handleConfigChange(item?.id, e.target.value, item?.id)}
                    />
                  </Grid>
                );
              case "options":
                return (
                  <Grid item lg={item?.id === "warehouse" ? 12 : 6} sm={12} key={item.id}>
                    <ConfigDropdown
                      label={item?.IsRequired ? `${item.title}*` : item.title}
                      menu={item?.options.map((val) => ({
                        [Constants.MONGOOSE_ID]: val?.id,
                        title: val?.title,
                      }))}
                      name={item?.id}
                      id={item?.id}
                      error={warehouseData.errors && warehouseData.errors[item.id]}
                      helperText={warehouseData.errors && warehouseData.errors[item.id]}
                      defaultValue=""
                      value={fieldValue}
                      handleChange={(e, value, id) => handleConfigChange(e, value, id)}
                    />
                  </Grid>
                );
              case "date":
                return (
                  <Grid item lg={6} sm={12} key={item.id}>
                    <MDBox
                      sx={{ minWidth: "100%", mt: 0, mr: 1, zIndex: 10, position: "relative" }}
                      display="flex"
                    >
                      <ReactDatePicker
                        selected={
                          (warehouseData?.[item.id]
                            ? moment(warehouseData?.[item.id].split(".")[0]).toDate()
                            : new Date()) || null
                        }
                        onChange={(date) =>
                          handleConfigChange(
                            item.id,
                            moment(date).format(defaultData.DATABSE_DATE_FORMAT).toString(),
                            item.questionId ? item.questionId : item.id
                          )
                        }
                        customInput={
                          <DateTime
                            item={item}
                            value={fieldValue}
                            label={`${item?.title}${item?.IsRequired ? "*" : ""}`}
                            errors={warehouseData.errors?.[item.id]}
                            helperText={warehouseData.errors?.[item.id]}
                            placeholder="mm/dd/yyyy"
                          />
                        }
                        placeholderText="mm/dd/yyyy"
                        dateFormat={defaultData.REACTDATETIMEPICKER_DATE_FORMAT}
                      />
                    </MDBox>
                  </Grid>
                );
              case "number":
                return (
                  <Grid item lg={6} sm={12} key={item.id}>
                    <FTextField
                      label={item?.IsRequired ? `${item.title}*` : item.title}
                      placeholder={item?.hint}
                      name={item?.id}
                      id={item?.id}
                      value={
                        !checkEquipmentId && item?.id === "quantity" && quantityType === true
                          ? 1
                          : warehouseData[item.id]
                      }
                      type="number"
                      disabled={
                        (checkEquipmentId && item?.id === "quantity") ||
                        (item?.id === "quantity" && quantityType === true)
                      }
                      error={Boolean(warehouseData?.errors[item.id])}
                      helperText={warehouseData?.errors[item.id]}
                      handleChange={(e) => handleConfigChange(item?.id, e.target.value, item?.id)}
                    />
                  </Grid>
                );
              default:
                return null;
            }
          })
        : null}
    </Grid>
  );
}

ProductWarehouse.propTypes = {
  warehouseData: PropTypes.objectOf(PropTypes.any).isRequired,
  setWarehouseData: PropTypes.func.isRequired,
  checkEquipmentId: PropTypes.bool.isRequired,
  setUpdatedBody: PropTypes.func.isRequired,
  searchData: PropTypes.objectOf(PropTypes.any).isRequired,
  quantityType: PropTypes.bool.isRequired,
};
