import { useEffect, useState } from "react";

// Common components
import MDBox from "components/MDBox";
import Status from "components/Table/Status";
import Author from "components/Table/Author";
import pxToRem from "assets/theme/functions/pxToRem";

// Material components
import { IconButton, Popover, Typography } from "@mui/material";

// 3rd party library
import moment from "moment";
import PropTypes from "prop-types";

// Utils
import { defaultData, Icons } from "utils/Constants";

export function ReportStatus({ statusList }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const checkPriorityStatus = (status) => {
    if (status.includes("closed")) return "closed";
    if (status.includes("submitted")) return "submitted";
    return "open";
  };

  const mainStatus = checkPriorityStatus(statusList);

  return (
    <MDBox display="flex" alignItems="center">
      <Status title={mainStatus} />
      {!statusList.every((status) => status.includes(mainStatus)) && (
        <IconButton aria-label="report-info-status" color="info" onClick={handleClick}>
          {Icons.INFO}
        </IconButton>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography sx={{ p: 1, backgroundColor: "#f5f5f5", fontSize: pxToRem(16) }}>
          Open: {statusList.filter((status) => status.includes("open")).length}
        </Typography>
        <Typography sx={{ p: 1, backgroundColor: "#f5f5f5", fontSize: pxToRem(16) }}>
          Submitted: {statusList.filter((status) => status.includes("submitted")).length}
        </Typography>
        <Typography sx={{ p: 1, backgroundColor: "#f5f5f5", fontSize: pxToRem(16) }}>
          Closed: {statusList.filter((status) => status.includes("closed")).length}
        </Typography>
      </Popover>
    </MDBox>
  );
}

export default function TowerData(reportProgressData) {
  const [rows, setRows] = useState([]);

  const handleReportType = (reportType) => {
    if (reportType.includes("Location based")) {
      return "Location based report";
    }
    if (reportType.includes("Asset per location")) {
      return "Asset based report";
    }
    if (reportType.includes("Multiple")) {
      return "Multiple asset based reports";
    }
    return reportType;
  };

  const handlePercentage = (total, completed) => {
    const calculatePercentage = (totalCal, completedCal) =>
      totalCal > 0 ? (completedCal / totalCal) * 100 : 0;
    return calculatePercentage(total, completed);
  };

  useEffect(() => {
    if (reportProgressData) {
      const list = reportProgressData.flatMap((report, index) => {
        const header = {
          srNo: <Author name="" />,
          reportName: <Author name="" />,
          locasset: <Author name="" />,
          scope: (
            <Author
              name={handleReportType(report.reportType)}
              maxContent={100}
              style={{ fontWeight: "bold" }}
            />
          ),
          status: <Author name="" />,
          date: <Author name="" />,
          percentage: <Author name="" />,
        };

        const reportData = report.reportData.map((item, subIndex) => {
          const totalDuration = item?.totalDuration || 0;
          const completedDuration = item?.completedDuration || 0;
          const percentage = handlePercentage(totalDuration, completedDuration).toFixed(2);
          return {
            srNo: <Author name={`${index + 1}.${subIndex + 1}`} />,
            reportName: <Author name={item?.report?.title !== null ? item?.report?.title : ""} />,
            locasset: (
              <Author
                name={
                  report.reportType.includes("Location")
                    ? item?.location?.title
                    : `${item?.asset?.fromLocation}-${item?.asset?.toLocation}`
                }
                maxContent={defaultData.MEDIUM_CONTENT_LENGTH}
              />
            ),
            scope: <Author name={item?.scope?.name !== null ? item?.scope?.name : ""} />,
            status:
              item?.status && item?.status !== null ? (
                <ReportStatus statusList={item?.status} />
              ) : (
                ""
              ),
            date:
              item?.completionDate && percentage.includes(100) ? (
                <Author
                  name={moment(item?.completionDate).format(defaultData.WEB_24_HOURS_FORMAT)}
                />
              ) : (
                ""
              ),
            percentage:
              percentage > 0 ? (
                <Author name={`${percentage < 100 && percentage > 0 ? percentage : 100}%`} />
              ) : (
                ""
              ),
          };
        });

        return [header, ...reportData];
      });

      setRows([...list]);
    }
  }, [reportProgressData]);

  return {
    columns: [
      { Header: "No.", accessor: "srNo", width: "3%" },
      { Header: "Report Name", accessor: "reportName", align: "left" },
      { Header: "Location/Asset", accessor: "locasset", align: "left" },
      { Header: "Scope", accessor: "scope", align: "left" },
      { Header: "Status", accessor: "status", width: "13%", align: "left" },
      { Header: "Completion Date", accessor: "date", width: "13%", align: "left" },
      { Header: "Completion Percentage", accessor: "percentage", width: "6%", align: "center" },
    ],
    rows,
  };
}

ReportStatus.defaultProps = {
  statusList: [],
};

ReportStatus.propTypes = {
  statusList: PropTypes.arrayOf(PropTypes.string),
};
