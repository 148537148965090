import { createSlice } from "@reduxjs/toolkit";
import { resetStateThunk } from "redux/Thunks/Authentication";
import {
  getAllReports,
  getAllReportTypes,
  getAllQuestionTypes,
  getReportById,
} from "redux/Thunks/Report";
import Constants from "utils/Constants";

const initialState = {
  reportTypeLoading: Constants.IDLE,
  reportType: [],
  reportLoading: Constants.IDLE,
  reportList: [],
  questionTree: {},
  questionsTypeLoading: Constants.IDLE,
  questionsType: [],
  reportDetails: [],
  reportDetailsLoading: Constants.IDLE,
  reportId: [],
};

export const reportSlice = createSlice({
  name: "reportSlice",
  initialState,
  reducers: {
    removeReportAnswer(state, action) {
      const stateData = JSON.parse(JSON.stringify(state));

      // Iterate over reportDetails
      const updatedReportDetails = stateData.reportDetails.map((report) => ({
        ...report,
        reportQuestions: report.reportQuestions.map((question) => ({
          ...question,
          answerTypes: question.answerTypes.map((answerType) => ({
            ...answerType,
            userAnswers: answerType.userAnswers.map((userAnswer) => ({
              ...userAnswer,
              answers: userAnswer.answers.filter(
                (answer) =>
                  answer.answerTitleId !== action.payload.answerTitleId ||
                  userAnswer[Constants.MONGOOSE_ID] !== action.payload.userId
              ),
            })),
          })),
        })),
      }));

      // Update the state with modified reportDetails
      return {
        ...state,
        reportDetails: updatedReportDetails,
      };
    },

    updateReport(state, action) {
      const stateData = JSON.parse(JSON.stringify(state));
      // Iterate over reportDetails
      const updatedReportDetails = stateData.reportDetails.map((report) => ({
        ...report,
        reportQuestions: report.reportQuestions.map((question) => ({
          ...question,
          answerTypes: question.answerTypes.map((answerType) => ({
            ...answerType,
            userAnswers: answerType.userAnswers.map((userAnswer) => ({
              ...userAnswer,
              answers: userAnswer.answers.map((answer) => {
                if (
                  answer.answerTitleId === action.payload.data.answerTitleId &&
                  userAnswer[Constants.MONGOOSE_ID] === action.payload.answerId
                ) {
                  return {
                    ...answer,
                    answer: action.payload.data.answer,
                  };
                }
                return answer;
              }),
            })),
          })),
        })),
      }));

      // Update the state with modified reportDetails
      return {
        ...state,
        reportDetails: updatedReportDetails,
      };
    },

    // Update the user report status based on the type
    updateReportUserStatus: (state, action) => {
      const stateData = JSON.parse(JSON.stringify(state));
      const updatedReportDetails = stateData.reportDetails.map((report) => ({
        ...report,
        [Constants.MONGOOSE_ID]: {
          ...report[Constants.MONGOOSE_ID],
          userReportStatus: report[Constants.MONGOOSE_ID].userReportStatus.map((reportStatus) => {
            if (action.payload.type === "main") {
              return {
                ...reportStatus,
                status: action.payload.status,
              };
            }
            if (
              action.payload.type === "sub" &&
              reportStatus.userReportId === action.payload.reportId
            ) {
              return {
                ...reportStatus,
                status: action.payload.status,
              };
            }
            return reportStatus;
          }),
        },
        reportQuestions: report.reportQuestions,
      }));
      return {
        ...state,
        reportDetails: updatedReportDetails,
      };
    },

    removeReportType(state, action) {
      state.reportType = state.reportType.filter(
        (item) => item?.[Constants.MONGOOSE_ID] !== action.payload
      );
    },

    reloadReportTypeData(state) {
      state.reportTypeLoading = Constants.PENDING;
    },

    setQuestionTree(state, action) {
      state.questionTree = action.payload;
    },

    reloadReport(state) {
      state.reportLoading = Constants.PENDING;
    },

    reloadReportDetails(state) {
      state.reportDetailsLoading = Constants.PENDING;
    },

    storeReportId(state, action) {
      state.reportId = action.payload;
    },
  },

  extraReducers: {
    [getAllReportTypes.pending]: (state) => {
      if (state.reportType.length === 0) state.reportTypeLoading = Constants.PENDING;
    },
    [getAllReportTypes.fulfilled]: (state, { payload }) => {
      state.reportTypeLoading = Constants.FULFILLED;
      // Use `byPassSlice: true` to avoid loading data into the slice.
      // For example, this is useful when calling an API to retrieve data for filters.
      if (!payload.byPassSlice && payload.type === "add") state.reportType = payload.data.data;
      else if (!payload.byPassSlice) state.reportType.push(...payload.data.data);
    },
    [getAllReportTypes.rejected]: (state) => {
      state.reportTypeLoading = Constants.REJECTED;
    },
    [getAllReports.pending]: (state) => {
      if (state.reportList.length === 0) state.reportLoading = Constants.PENDING;
    },
    [getAllReports.fulfilled]: (state, { payload }) => {
      state.reportLoading = Constants.FULFILLED;
      if (payload.type === "add") state.reportList = payload.data.data;
      else state.reportList.push(...payload.data);
    },
    [getAllReports.rejected]: (state) => {
      state.reportLoading = Constants.REJECTED;
    },
    [getAllQuestionTypes.pending]: (state) => {
      if (state.questionsType.length === 0) state.questionsTypeLoading = Constants.PENDING;
    },
    [getAllQuestionTypes.fulfilled]: (state, { payload }) => {
      state.questionsTypeLoading = Constants.FULFILLED;
      state.questionsType = payload.data.data;
    },
    [getAllQuestionTypes.rejected]: (state) => {
      state.questionsTypeLoading = Constants.REJECTED;
    },

    [getReportById.pending]: (state) => {
      if (state.reportDetails.length === 0) state.reportDetailsLoading = Constants.PENDING;
    },
    [getReportById.fulfilled]: (state, { payload }) => {
      state.reportDetailsLoading = Constants.FULFILLED;
      state.reportDetails = payload.data.data;
    },
    [getReportById.rejected]: (state) => {
      state.reportDetailsLoading = Constants.REJECTED;
    },
    [resetStateThunk.fulfilled]: (state) => {
      state.reportList = [];
      state.reportType = [];
      state.reportDetails = [];
    },
  },
});

export const {
  removeReportAnswer,
  removeReportType,
  reloadReportTypeData,
  setQuestionTree,
  reloadReport,
  updateReport,
  reloadReportDetails,
  storeReportId,
  updateReportUserStatus,
} = reportSlice.actions;
export default reportSlice.reducer;
