import { createSlice } from "@reduxjs/toolkit";
import { resetStateThunk } from "redux/Thunks/Authentication";
import configThunk from "redux/Thunks/Config";
import Constants, { CardTitles, defaultData } from "utils/Constants";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import WFMOrganizationIcon from "assets/images/icons/WFMWizard/Organization.svg";
import WFMHealthnSafetyIcon from "assets/images/icons/WFMWizard/Health&Safety.png";
import WFMdailyactivityIcon from "assets/images/icons/WFMWizard/Dailyactivity.png";
import WFMActionsIcon from "assets/images/icons/WFMWizard/Actions.svg";
import WFMPersonalSettingIcon from "assets/images/icons/WFMWizard/Settings.svg";
import WFMSystemIcon from "assets/images/icons/WFMWizard/User.svg";

// Sessions
import Session from "utils/Sessions";

// JWT decode
import jwtDecode from "jwt-decode";

const initialState = {
  loading: Constants.IDLE,

  cards: [
    {
      index: 0,
      cardTitle: CardTitles.ORGANIZATION,
      logo: WFMOrganizationIcon,
      logoBgr: "info",
      menu: [
        {
          menuTitle: "Logo & Color",
          key: "logo-color",
          icon: <AccessTimeIcon />,
          link: "",
          isVisible: true,
        },
        {
          menuTitle: "Company Details",
          key: "company-details",
          icon: <AccessTimeIcon />,
          link: "",
          isVisible: true,
        },
        {
          menuTitle: "Setup Licenses",
          key: "setup-licenses",
          link: "/client/setting/wizard-licenses",
          isVisible: false,
        },
        {
          menuTitle: "Roles & Permission",
          key: "roles-permission",
          icon: <AccessTimeIcon />,
          link: "",
          isVisible: true,
        },
      ],
      isVisible: true,
    },
    {
      index: 1,
      cardTitle: CardTitles.PROJECT_MANAGEMENT,
      logo: WFMdailyactivityIcon,
      logoBgr: "info",
      menu: [
        {
          menuTitle: "Project Setup",
          key: "project-setup",
          link: "/client/setting/project-setup",
          isVisible: false,
        },
        {
          menuTitle: "Report Setup",
          key: "report-setup",
          link: "/client/setting/setup-report",
          isVisible: false,
        },
      ],
      isVisible: false,
    },
    {
      index: 2,
      cardTitle: CardTitles.ACTIONS,
      logo: WFMActionsIcon,
      logoBgr: "info",
      menu: [
        {
          menuTitle: "System Notification ",
          key: "system-notification",
          link: "/client/setting/system-notification",
          isVisible: true,
        },
        {
          menuTitle: "Send Notification ",
          key: "send-notification",
          icon: <AccessTimeIcon />,
          link: "",
          isVisible: true,
        },
      ],
      isVisible: true,
    },
    {
      index: 3,
      cardTitle: CardTitles.SYSTEM_MANAGEMENT,
      logo: WFMSystemIcon,
      logoBgr: "info",
      menu: [
        {
          menuTitle: "User Management",
          key: "user-management",
          link: "/client/setting/user-management",
          isVisible: false,
        },
        {
          menuTitle: "Role Management",
          key: "role-management",
          link: "/client/setting/role-management",
          isVisible: false,
        },
        {
          menuTitle: "Certificate Management",
          key: "certificate-management",
          link: "/client/setting/certificate-management",
          isVisible: false,
        },
        {
          menuTitle: "Profile Functions",
          key: "profile-functions",
          link: "/client/setting/profile-functions",
          isVisible: false,
        },
      ],
      isVisible: false,
    },
    {
      index: 4,
      cardTitle: CardTitles.HEALTH_SAFETY,
      logo: WFMHealthnSafetyIcon,
      logoBgr: "info",
      menu: [
        {
          menuTitle: "Setup Card Categories",
          key: "setup-card-categories",
          link: "/client/setting/category",
          isVisible: true,
        },
        {
          menuTitle: "Medical Management",
          key: "medical-management",
          link: "/client/setting/medical-management",
          isVisible: true,
        },
        {
          menuTitle: "Configuration",
          key: "configuration",
          link: "/client/setting/configuration",
          isVisible: false,
        },
      ],
      isVisible: true,
    },
    {
      index: 5,
      cardTitle: CardTitles.EQUIPMENT,
      logo: WFMdailyactivityIcon,
      logoBgr: "info",
      menu: [
        {
          menuTitle: "Equipment Setup",
          key: "equipment-setup",
          link: "/client/setting/equipment-setup",
          isVisible: false,
        },
        {
          menuTitle: "Add Warehouse",
          key: "add-warehouse",
          link: "/client/setting/add-warehouse",
          isVisible: false,
        },
        {
          menuTitle: "Warehouse",
          key: "warehouse",
          link: "/client/setting/warehouse",
          isVisible: false,
        },
      ],
      isVisible: false,
    },
    {
      index: 6,
      cardTitle: CardTitles.PERSONAL_SETTING,
      logo: WFMPersonalSettingIcon,
      logoBgr: "info",
      menu: [
        {
          menuTitle: "Time format",
          key: "time-format",
          icon: <AccessTimeIcon />,
          link: "",
          isVisible: true,
        },
        {
          menuTitle: "Language",
          key: "language",
          icon: <AccessTimeIcon />,
          link: "",
          isVisible: true,
        },
        {
          menuTitle: "Dark theme",
          key: "dark-theme",
          icon: <AccessTimeIcon />,
          link: "",
          isVisible: true,
        },
      ],
      isVisible: true,
    },
  ],
};

export const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {},

  extraReducers: {
    [configThunk.pending]: (state) => {
      state.loading = Constants.PENDING;
    },
    [configThunk.fulfilled]: (state, action) => {
      const token = jwtDecode(Session.userToken);
      const { role } = token;

      // Cards and menus related to admin role
      if (
        role === defaultData.ADMIN_ROLE ||
        (role === defaultData.SUPER_ADMIN_ROLE && Session.isSuperAdminViewingAdminPanel)
      ) {
        // provide access of setup license
        state.cards[0].menu[2].isVisible = true;

        state.cards[3].isVisible = true;
        // provide access of user management card
        state.cards[3].menu.forEach((element, index) => {
          state.cards[3].menu[index].isVisible = true;
        });
      }

      // Provide access to project setup card when user role is admin or super admin or project manager
      if (
        role === defaultData.ADMIN_ROLE ||
        role === defaultData.SUPER_ADMIN_ROLE ||
        role === defaultData.PROJECT_MANAGER
      ) {
        state.cards[1].menu[0].isVisible = true;
        state.cards[1].menu[1].isVisible = role !== defaultData.PROJECT_MANAGER;
        state.cards[1].isVisible = true;
      }

      // Provide Access of Setup Report when report read aggrement is present

      action.payload.data.screens.forEach((element) => {
        const currentScreenId = element.screenId;

        if (currentScreenId === defaultData.WAREHOUSE_SCREEN_ID) {
          const warehouseAggrements = element.agreement;

          // provide access of register warehouse on create aggrement
          if (warehouseAggrements?.create || warehouseAggrements?.read) {
            state.cards[5].menu[1].isVisible = true;
            state.cards[5].menu[2].isVisible = true;
            state.cards[5].isVisible = true;
          }
        }
        // Provide Access of Equipment setup only when the user has all equipment agreement like create, update, delete, read
        else if (currentScreenId === defaultData.EQUIPMENT_SCREEN_ID) {
          const equipmentAggrements = element.agreement;

          if (
            equipmentAggrements?.create &&
            equipmentAggrements?.update &&
            equipmentAggrements?.delete &&
            equipmentAggrements?.read
          ) {
            state.cards[5].menu[0].isVisible = true;
            state.cards[5].isVisible = true;
          }
        }
        // Provide Access of configuation menu when user has atleast 1 create aggrement of safe, unsafe, ncr and incident
        else if (
          [
            defaultData.SAFE_CARD_SCREEN_ID,
            defaultData.UNSAFE_CARD_SCREEN_ID,
            defaultData.NCR_CARD_SCREEN_ID,
            defaultData.INCIDENT_CARD_SCREEN_ID,
          ].includes(currentScreenId)
        ) {
          const qhseCardsAggrement = element.agreement;

          if (qhseCardsAggrement?.create) {
            state.cards[4].menu[2].isVisible = true;
            state.cards[4].isVisible = true;
          }
        }
      });
    },
    [configThunk.rejected]: (state) => {
      state.loading = Constants.REJECTED;
    },

    [resetStateThunk.fulfilled]: (state) => {
      state.loading = Constants.IDLE;
      state.cards = initialState.cards;
    },
  },
});

export default settingSlice.reducer;
