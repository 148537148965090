import { useState } from "react";

// MUI Components
import { FormControl, FormHelperText, MenuItem, IconButton, Select } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// Custom Components
import FormControlErrorStyles from "assets/style/Component";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Functions
import pxToRem from "assets/theme/functions/pxToRem";

// Constants
import { Colors } from "utils/Constants";

// 3rd party
import PropTypes from "prop-types";

export default function FormDropdown2({
  width,
  label,
  value,
  id,
  name,
  defaultValue,
  options,
  error,
  helperText,
  handleChange,
  marginBottom,
  maxWidth,
  disabled = false,
}) {
  const [open, setOpen] = useState(false);
  const mongooseId = "_id";
  return (
    <FormControl
      size="small"
      error={Boolean(error)}
      sx={{
        ml: 0,
        mt: pxToRem(16),
        minWidth: "100%",
        width,
        marginBottom,
        maxHeight: 400,
        ...FormControlErrorStyles,
      }}
    >
      <MDTypography
        variant="caption"
        mb={1}
        sx={{ fontSize: pxToRem(14), fontWeight: 500, color: "#344054" }}
      >
        {label}
      </MDTypography>
      <MDBox
        sx={{
          display: "flex",
          alignItems: "center",
          position: "relative",
          backgroundColor: disabled ? Colors.DISABLED : "transparent",
        }}
      >
        <Select
          displayEmpty
          labelId={id}
          id={id}
          name={name}
          value={value}
          defaultValue={defaultValue}
          onChange={handleChange}
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          disabled={disabled}
          sx={{
            height: 45,
            minWidth: "100%",
            "& .MuiInputBase-input": {
              fontSize: pxToRem(16),
              fontWeight: 400,
              color: "#667085",
            },
            textTransform: "capitalize",
            backgroundColor: "black",
            paddingY: "0.65rem",
            // paddingRight: "0.55rem",
            maxHeight: 100,
            cursor: "pointer",
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 34,
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            PaperProps: {
              style: {
                maxHeight: 200,
                maxWidth,
                opacity: 1,
                transform: "none",
                border: "1px solid #D0D5DD",
              },
            },
          }}
        >
          <MenuItem disabled value="">
            Select
          </MenuItem>
          {options.length > 0 ? (
            options.map((item) => (
              <MenuItem
                sx={{
                  textTransform: "capitalize",
                  maxHeight: 400,
                  fontSize: pxToRem(16),
                  fontWeight: 400,
                  marginTop: "4px",
                  color: "#667085",
                }}
                key={item[mongooseId] || item}
                value={item[mongooseId] || item}
              >
                {item.title || item.name}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>No data available</MenuItem>
          )}
        </Select>
        <IconButton
          onClick={() => setOpen(!open)}
          sx={{
            position: "absolute",
            top: "50%",
            right: 0,
            transform: "translateY(-50%)",
            zIndex: 1,
          }}
          disabled={disabled}
        >
          <KeyboardArrowDownIcon />
        </IconButton>
      </MDBox>
      <FormHelperText sx={{ marginLeft: 0 }}>{helperText}</FormHelperText>
    </FormControl>
  );
}

FormDropdown2.propTypes = {
  width: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  error: PropTypes.bool,
  helperText: PropTypes.string,
  handleChange: PropTypes.func,
  marginBottom: PropTypes.string,
  maxWidth: PropTypes.string,
  disabled: PropTypes.bool,
};

FormDropdown2.defaultProps = {
  width: "",
  label: "",
  value: "",
  id: "",
  name: "",
  defaultValue: "",
  options: [],
  error: false,
  helperText: "",
  marginBottom: "0",
  maxWidth: "100%",
  disabled: false,
  handleChange: () => {},
};
