// Material Components
import { Box } from "@mui/material";

// Material Common Components
import pxToRem from "assets/theme/functions/pxToRem";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

// Utils
import Constants, { Colors, Icons } from "utils/Constants";

// 3rd party
import PropTypes from "prop-types";

const titleDimensions = {
  [Constants.OPEN]: { bgColor: Colors.LIGHT_RED, color: Colors.DARK_RED, width: "70px" },
  [Constants.SUBMITTED]: {
    bgColor: Colors.LIGHT_ORANGE,
    color: Colors.DARK_ORANGE,
    width: pxToRem(105),
  },
  [Constants.IN_DISCUSSION]: {
    bgColor: Colors.LIGHT_YELLOW,
    color: Colors.DARK_YELLOW,
    width: pxToRem(130),
  },
  [Constants.CLOSED]: { bgColor: Colors.LIGHT_GREEN, color: Colors.DARK_GREEN, width: "80px" },
  [Constants.APPROVED]: { bgColor: Colors.LIGHT_GREEN, color: Colors.DARK_GREEN, width: "100px" },
  [Constants.STATUS_PENDING]: {
    bgColor: Colors.LIGHT_YELLOW,
    color: Colors.DARK_YELLOW,
    width: pxToRem(100),
  },
  [Constants.STATUS_REJECTED]: {
    bgColor: Colors.LIGHT_RED,
    color: Colors.DARK_RED,
    width: pxToRem(100),
  },
  [Constants.REQUESTED]: {
    bgColor: Colors.LIGHT_YELLOW,
    color: Colors.DARK_YELLOW,
    width: pxToRem(105),
  },
  [Constants.IN_TRANSIT]: {
    bgColor: Colors.LIGHT_GRAY5,
    color: Colors.DARK_GREY,
    width: pxToRem(100),
  },
  [Constants.CHECK_IN]: {
    bgColor: Colors.LIGHT_GRAY5,
    color: Colors.DARK_GREY,
    width: pxToRem(105),
  },
  [Constants.PARTIAL_CHECK_IN]: {
    bgColor: Colors.LIGHT_GRAY5,
    color: Colors.DARK_GREY,
    width: pxToRem(155),
  },
  [Constants.CHECK_OUT]: {
    bgColor: Colors.LIGHT_GRAY5,
    color: Colors.DARK_GREY,
    width: pxToRem(105),
  },
  [Constants.IN_STOCK]: { bgColor: Colors.LIGHT_GRAY5, color: Colors.DARK_GREY, width: "90px" },
  [Constants.PARTIAL_PRE_TRANSIT]: {
    bgColor: Colors.LIGHT_GRAY5,
    color: Colors.DARK_GREY,
    width: pxToRem(170),
  },
  [Constants.PRE_TRANSIT]: {
    bgColor: Colors.LIGHT_GRAY5,
    color: Colors.DARK_GREY,
    width: pxToRem(107),
  },
  [Constants.LINKED]: {
    bgColor: Colors.LIGHT_GRAY5,
    color: Colors.DARK_GREY,
    width: pxToRem(80),
  },
  [Constants.PRE_RETURN]: {
    bgColor: Colors.LIGHT_GRAY5,
    color: Colors.DARK_GREY,
    width: pxToRem(107),
  },
  [Constants.RETURN]: {
    bgColor: Colors.LIGHT_GRAY5,
    color: Colors.DARK_GREY,
    width: pxToRem(80),
  },
  [Constants.ON_SITE]: {
    bgColor: Colors.LIGHT_GRAY5,
    color: Colors.DARK_GREY,
    width: pxToRem(85),
  },
  [Constants.PARTIALLY_IN_TRANSIT]: {
    bgColor: Colors.LIGHT_GRAY5,
    color: Colors.DARK_GREY,
    width: pxToRem(170),
  },
  [Constants.PARTIALLY_RETURNED]: {
    bgColor: Colors.LIGHT_GRAY5,
    color: Colors.DARK_GREY,
    width: pxToRem(160),
  },
  [Constants.QUEUE]: {
    bgColor: Colors.LIGHT_GRAY5,
    color: Colors.DARK_GREY,
    width: pxToRem(80),
  },
  [Constants.SUBMITTED_CLIENT]: {
    bgColor: Colors.LIGHT_ORANGE,
    color: Colors.DARK_ORANGE,
    width: pxToRem(155),
  },
  [Constants.ORDER_REQUEST]: {
    bgColor: Colors.LIGHT_GRAY5,
    color: Colors.DARK_GREY,
    width: pxToRem(130),
  },
  [Constants.SHOPPING_CART]: {
    bgColor: Colors.LIGHT_GRAY5,
    color: Colors.DARK_GREY,
    width: pxToRem(130),
  },
  [Constants.REQUEST]: {
    bgColor: Colors.LIGHT_YELLOW,
    color: Colors.DARK_YELLOW,
    width: pxToRem(90),
  },
  [Constants.SHIPPED_ORDERS]: {
    bgColor: Colors.LIGHT_GRAY5,
    color: Colors.DARK_GREY,
    width: pxToRem(135),
  },
  [Constants.PACKED_ORDERS]: {
    bgColor: Colors.LIGHT_GRAY5,
    color: Colors.DARK_GREY,
    width: pxToRem(135),
  },
  [Constants.EXPIRED]: {
    bgColor: Colors.LIGHT_GRAY5,
    color: Colors.DARK_GREY,
    width: pxToRem(90),
  },
};

export default function Status({ title }) {
  const { bgColor, color, width } = titleDimensions[title] || {};

  let icon;
  switch (title) {
    case Constants.STATUS_REJECTED:
    case Constants.OPEN:
      icon = Icons.CROSS3;
      break;
    case Constants.STATUS_PENDING:
      icon = Icons.WARNING;
      break;
    case Constants.SUBMITTED:
    case Constants.SUBMITTED_CLIENT:
      icon = Icons.DASH;
      break;
    case Constants.CLOSED:
    case Constants.APPROVED:
    case Constants.CHECK_IN:
      icon = Icons.ACCEPT;
      break;
    default:
      icon = (
        <Box
          component="span"
          backgroundColor={color}
          sx={{
            borderRadius: "50%",
            width: pxToRem(10),
            height: pxToRem(10),
          }}
        />
      );
      break;
  }

  return (
    <MDBox
      bgColor={bgColor}
      color={color}
      variant="contained"
      borderRadius={pxToRem(16)}
      opacity={1}
      p={1}
      width={width}
      height={pxToRem(24)}
      textAlign="right"
      display="flex"
      alignItems="center"
      justifyContent="space-around"
    >
      <Box
        sx={{
          width: pxToRem(10),
          height: pxToRem(10),
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {icon}
      </Box>
      <MDTypography
        ml={1}
        variant="caption"
        sx={{
          textTransform: "capitalize",
          color,
        }}
      >
        {title}
      </MDTypography>
    </MDBox>
  );
}

Status.propTypes = {
  title: PropTypes.string.isRequired,
};
