import React, { useEffect, useState } from "react";

// Material ui Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Divider } from "@mui/material";

// Custom Components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PageTitle from "examples/NewDesign/PageTitle";
import BasicButton from "examples/NewDesign/CustomButton/BasicButton";
import DataTable from "examples/Tables/DataTable";
import WmOrderLayerData from "layouts/wmOrdersManagement/data/WmOrderLayerData";
import WmReturnOrderData from "layouts/wmOrdersManagement/data/WmReturnOrdersData";
import pxToRem from "assets/theme/functions/pxToRem";
import ConfirmationModal from "examples/modal/Confirmation/Confirmation";
import FilterDropdown from "components/Dropdown/FilterDropdown";
import ResetFilterButton from "components/Buttons/ResetButton";
import AccessDenied from "components/AccessDenied/accessDenied";

// Utils
import Constants, { Colors, Icons, PageTitles, FeatureTags, defaultData } from "utils/Constants";
import { paramCreater } from "utils/methods/methods";

// 3rd party libraries
import { Feature } from "flagged";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  warehouseOrderListThunk,
  inTransistOrderThunk,
  warehouseReturnOrderListThunk,
} from "redux/Thunks/Warehouse";
import { reloadWarehouseOrderRequest } from "redux/Slice/Warehouse";
import { openSnackbar } from "redux/Slice/Notification";

function Order() {
  const [filters, setFilters] = useState([
    {
      inputLabel: "Status",
      list: [
        { [Constants.MONGOOSE_ID]: "requested", title: "Order Requests" },
        { [Constants.MONGOOSE_ID]: "rejected", title: "Rejected" },
        { [Constants.MONGOOSE_ID]: "approved", title: "Open Orders" },
        { [Constants.MONGOOSE_ID]: "pre-transit", title: "Packed Orders" },
        { [Constants.MONGOOSE_ID]: "in-transit", title: "Transport to Project" },
        { [Constants.MONGOOSE_ID]: "check-in", title: "At Project" },
        { [Constants.MONGOOSE_ID]: "return", title: "Return Orders" },
        { [Constants.MONGOOSE_ID]: "return-rejected", title: "Return Rejected" },
        { [Constants.MONGOOSE_ID]: "in-stock", title: "In Stock" },
      ],
      selectedValue: "requested",
    },
  ]);
  const [openIntransistDialog, setOpenIntransistDialog] = useState({
    open: false,
    order: {},
    actionButton: Constants.YES,
  });
  const [tablePagination, setTablePagination] = useState({
    page: 0,
    perPage: defaultData.DATE_ON_SINGLE_API_CALL,
  });
  const [next, setNext] = useState(0);
  const warehouseOrders = useSelector((state) => state.Warehouse);
  const ConfigData = useSelector((state) => state.config);
  const permission = ConfigData?.screens?.[9]?.screensInfo?.agreement;
  const configLoading = ConfigData?.loading;
  const dispatch = useDispatch();

  const handleOpenIntransistDialog = (order) =>
    setOpenIntransistDialog({ open: true, order, actionButton: Constants.YES });

  const handleCloseIntransistDialog = () =>
    setOpenIntransistDialog({ open: false, order: {}, actionButton: Constants.YES });

  const handleFilter = async () => {
    setTablePagination({ ...tablePagination, page: 0 });
    setNext(0);
    const thunkAction = ["return", "in-stock", "return-rejected"].includes(filters[0].selectedValue)
      ? warehouseReturnOrderListThunk
      : warehouseOrderListThunk;
    const paramData = {
      page: 0,
      perPage: tablePagination.perPage,
      status: filters[0].selectedValue,
    };
    const param = new URLSearchParams(paramCreater(paramData));
    await dispatch(reloadWarehouseOrderRequest());
    await dispatch(thunkAction(param));
  };

  const handleFilterType = async (e) => {
    const temp = [...filters];
    const { value } = e.target;
    if (value) {
      const i = temp.findIndex((item) => item.inputLabel === e.target.name);
      temp[i].selectedValue = value;
      setFilters(temp);
    }
  };

  const handleReset = async () => {
    const resetFilters = filters.map((filter) => ({ ...filter, selectedValue: "requested" }));
    setFilters(resetFilters);
  };

  const handleIntransist = async (order) => {
    setOpenIntransistDialog({ ...openIntransistDialog, actionButton: Constants.LOADING });
    const data = {
      id: order[Constants.MONGOOSE_ID],
      body: {
        status: "in-transit",
      },
    };
    const res = await dispatch(inTransistOrderThunk(data));
    if (res.payload.status === 200) {
      await dispatch(
        openSnackbar({
          message: Constants.ORDER_INTRANSIT_SUCCESS,
          notificationType: Constants.NOTIFICATION_SUCCESS,
        })
      );
      handleCloseIntransistDialog();
      handleFilter();
    } else {
      await dispatch(
        openSnackbar({
          message: Constants.ORDER_INTRANSIT_FAILED,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
      setOpenIntransistDialog({ ...openIntransistDialog, actionButton: Constants.YES });
    }
  };

  const { orderColumns, orderRow } = WmOrderLayerData(
    {
      warehouseOrderList: warehouseOrders.warehouseOrderList,
      warehouseOrderLoading: warehouseOrders.warehouseOrderLoading,
    },
    filters[0].selectedValue,
    handleOpenIntransistDialog,
    permission
  );

  const { returnOrderColumns, returnOrderRow } = WmReturnOrderData({
    wmReturnOrderList: warehouseOrders.returnOrderList,
    wmReturnOrderLoading: warehouseOrders.returnOrderLoading,
    currentFilter: filters[0].selectedValue,
  });

  useEffect(() => {
    (async () => {
      handleFilter();
    })();

    return () => {
      dispatch(reloadWarehouseOrderRequest());
    };
  }, [filters[0].selectedValue]);

  const handleTablePagination = async () => {
    const data = new URLSearchParams({
      page: next + 1,
      perPage: tablePagination.perPage,
      status: filters[0].selectedValue,
    });
    const res = await dispatch(warehouseOrderListThunk(data));
    setNext(res.payload.data.data.length > 0 ? next + 1 : next);
  };

  const handleReload = async () => {
    await dispatch(reloadWarehouseOrderRequest());
    handleFilter();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {configLoading === Constants.PENDING && (
        <MDBox py={30} display="flex" justifyContent="center" alignItems="center">
          {Icons.LOADING2}
        </MDBox>
      )}

      {configLoading === "fulfilled" && permission.read && (
        <MDBox>
          <MDBox display="flex" justifyContent="space-between">
            <PageTitle title={PageTitles.ORDERS_MANAGEMENT} />
            <BasicButton
              icon={Icons.RELOAD}
              background={Colors.WHITE}
              border
              color={Colors.BLACK}
              action={handleReload}
            />
          </MDBox>
          <Divider sx={{ marginTop: 2 }} />
          <Feature name={FeatureTags.WAREHOUSE}>
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "flex-start",
                flexShrink: 0,
              }}
              style={{ width: "100%" }}
            >
              {filters
                ?.filter((val) => val.inputLabel !== "Search")
                ?.map((val) => (
                  <FilterDropdown
                    key={val.inputLabel}
                    label={val.inputLabel}
                    name={val.inputLabel}
                    defaultValue={val?.selectedValue}
                    value={val?.selectedValue}
                    handleChange={handleFilterType}
                    menu={val.list}
                    style={{ marginLeft: pxToRem(1) }}
                  />
                ))}
              <ResetFilterButton handleReset={handleReset} style={{ marginLeft: "1rem" }} />
            </MDBox>

            <MDBox mt={pxToRem(24)}>
              {/* Order Table */}
              {!["return", "in-stock", "return-rejected"].includes(filters[0].selectedValue) ? (
                <DataTable
                  table={{ columns: orderColumns, rows: orderRow }}
                  isSorted={false}
                  entriesPerPage={{ defaultValue: defaultData.PER_PAGE }}
                  showTotalEntries={false}
                  loading={warehouseOrders?.warehouseOrderLoading}
                  currentPage={tablePagination.page}
                  handleTablePagination={handleTablePagination}
                  handleCurrentPage={(page) => setTablePagination({ ...tablePagination, page })}
                />
              ) : (
                <DataTable
                  table={{ columns: returnOrderColumns, rows: returnOrderRow }}
                  isSorted={false}
                  entriesPerPage={{ defaultValue: defaultData.PER_PAGE }}
                  showTotalEntries={false}
                  loading={warehouseOrders?.returnOrderLoading}
                  currentPage={tablePagination.page}
                  handleTablePagination={handleTablePagination}
                  handleCurrentPage={(page) => setTablePagination({ ...tablePagination, page })}
                />
              )}
            </MDBox>

            {/* Approve and Reject confirmation modal */}
            <ConfirmationModal
              open={openIntransistDialog.open}
              handleClose={handleCloseIntransistDialog}
              title={Constants.IN_TRANSIT_CONFIRMATION_MESSAGE}
              actionButton={openIntransistDialog.actionButton}
              handleAction={() => handleIntransist(openIntransistDialog.order)}
            />
          </Feature>
        </MDBox>
      )}

      {configLoading === "fulfilled" && !permission?.read && <AccessDenied />}

      {configLoading === Constants.REJECTED && (
        <MDTypography variant="h4" color="secondary">
          {Constants.SOMETHING_WENT_WRONG}
        </MDTypography>
      )}
    </DashboardLayout>
  );
}

export default Order;
