/* eslint-disable react/function-component-definition */

import React, { useEffect, useState } from "react";

// Library
import PropTypes from "prop-types";

// Common Components
import Author from "components/Table/Author";
import MDBox from "components/MDBox";
import pxToRem from "assets/theme/functions/pxToRem";

// Material Components
import { IconButton, TextField } from "@mui/material";

// Methods
import { numericFieldValidation } from "utils/methods/methods";

// Utils
import Constants, { Icons } from "utils/Constants";

export default function ReturnCartData(listing, handleDeleteOpen, handleChange) {
  const [rows, setRows] = useState([]);
  useEffect(() => {
    if (listing) {
      const list = listing?.map((item, index) => {
        const dispatchQuantity =
          item?.pmDispatchQuantity !== null
            ? Number(item?.pmReceivedQuantity, 10) - Number(item?.pmDispatchQuantity, 10)
            : item?.pmReceivedQuantity;
        const temp = {
          srNo: <Author name={index + 1} />,
          equipmentName: (
            <MDBox display="flex" justifyContent="center" alignItems="center">
              <img
                src={
                  item?.equipmentImage?.[0]?.url
                    ? item?.equipmentImage?.[0]?.url
                    : process.env.REACT_APP_IMAGE_NOT_FOUND
                }
                alt={item?.equipmentImage}
                key={item?.equipmentImage?.[0]?.name}
                style={{
                  width: pxToRem(50),
                  height: pxToRem(50),
                  marginRight: pxToRem(10),
                  borderRadius: "8px",
                }}
              />
              <Author name={item?.equipmentName} />
            </MDBox>
          ),
          productNumber: <Author name={item?.equipmentNumber} />,
          equipmentType: <Author name={item?.equipmentType} />,
          equipmentCategory: <Author name={item?.equipmentCategory} />,
          receivedQty: <Author name={dispatchQuantity} />,
          returnQty: (
            <MDBox display="flex" flexDirection="row">
              <IconButton
                aria-label="fingerprint"
                color="info"
                disabled={item?.returnQty === 1 || item?.returnQty <= 0}
                onClick={(e) => handleChange("decrement", e, index, item?.returnQty)}
              >
                {Icons.DASH2}
              </IconButton>
              <TextField
                type="text"
                name="returnQty"
                id="returnQty"
                // value={
                //   item?.pmDispatchQuantity !== null
                //     ? Number(item?.returnQty, 10) - Number(item?.pmDispatchQuantity, 10)
                //     : item?.returnQty
                // }
                value={item?.returnQty}
                onChange={(e) => handleChange("updateReturnQty", e, index)}
                onBlur={(e) => handleChange("validateReturnQty", e, index)}
                onKeyDown={(e) => numericFieldValidation(e)}
                variant="standard"
                sx={{
                  width: "50px",
                  "& .MuiInputBase-input": {
                    textAlign: "center",
                  },
                }}
              />
              <IconButton
                aria-label="fingerprint"
                color="info"
                disabled={item?.returnQty >= dispatchQuantity}
                onClick={(e) => handleChange("increment", e, index, item?.returnQty)}
              >
                {Icons.NEW}
              </IconButton>
            </MDBox>
          ),
          action: (
            <IconButton
              aria-label="fingerprint"
              color="error"
              onClick={() => handleDeleteOpen(item[Constants.MONGOOSE_ID])}
            >
              {Icons.DELETE}
            </IconButton>
          ),
        };
        return temp;
      });
      setRows([...list]);
    } else {
      setRows([]);
    }
  }, [listing]);

  return {
    columns: [
      { Header: "No.", accessor: "srNo", width: "7%" },
      { Header: "Equipment", accessor: "equipmentName", align: "left" },
      { Header: "Product number", accessor: "productNumber", align: "left" },
      { Header: "Equipment type", accessor: "equipmentType", align: "left" },
      { Header: "Equipment category", accessor: "equipmentCategory", align: "left" },
      { Header: "Onsite Qty", accessor: "receivedQty", align: "left" },
      { Header: "Return Qty", accessor: "returnQty", align: "center" },
      { Header: "Action", accessor: "action", align: "center" },
    ],
    rows,
  };
}

ReturnCartData.propTypes = {
  listing: PropTypes.arrayOf(PropTypes.object).isRequired,
};
