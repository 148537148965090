// Page titles
const PageTitles = {
  SAFETY_CARD: "QHSE Cards",
  DAL_SHIFT: "Shifts",
  SHIFT_DETAILS: "Shift Details",
  REPORT: "Report",
  REPORT_CALCULATION: "Project Tracker",
  DPR: "DPR",
  CONFIGURE_SAFE_CARD: "Configure Safe Card",
  CONFIGURE_UNSAFE_CARD: "Configure Unsafe Card",
  CONFIGURE_NCR_CARD: "Configure NCR Card",
  CONFIGURE_INCIDENT_CARD: "Configure Incident Card",
  WFM_WIZARD: "WFM Wizard",
  SETUP_LICENCE: "Setup Licence",
  USER_MANAGEMENT: "User Management",
  SETUP_PROJECT: "Setup Project",
  SETUP_CATEGORY: "Setup Category",
  LICENSE_APPROVAL: "License Approval",

  // Report
  SETUP_REPORT: "Setup Report",
  REPORT_QUESTIONS: "Report Questions",

  CONFIGURATION: "Configuration",
  FEEDBACK: "Feedback",
  SETTINGS: "Settings",
  ROLE_MANAGEMENT: "Role Management",
  ROLE_AGGREMENT: "Role Agreement",
  EQUIPMENT: "Inventory",
  REGISTER_EQUIPMENT: "Register Equipment",
  PROJECT_ORDERS: "Project Orders",
  UPDATE_EQUIPMENT: "Update Equipment",
  EQUIPMENT_SETUP: "Equipment Setup",
  ADD_NEW_WAREHOUSE: "Add New Warehouse",
  EDIT_WAREHOUSE: "Edit Warehouse",
  WAREHOUSE: "Warehouse",
  ORDERS_MANAGEMENT: "Orders",
  ORDER_DETAIL: "Order #",
  WAREHOUSE_DETAILS: "Warehouse Details",
  SYSTEM_NOTIFICATION: "System Notification Management",
  PROJECT_EQUIPMENT: "Project Inventory",
  TRAINING_MATRIX: "Training Matrix",
  PROFILE_FUNCTION: "Profile Functions",
  RETURN_CART: "Return Cart",
  TOOLBOX_TALK: "Toolbox Talk",
};

export default PageTitles;
