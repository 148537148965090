import { useEffect, useState } from "react";

// material-ui and MD dhasboard components
import { Modal, Grid, Icon, InputAdornment, IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

import ModalTitle from "examples/NewDesign/ModalTitle";
import style from "assets/style/Modal";

// utils
import Validations from "utils/Validations/index";
import Constants, { Icons, defaultData } from "utils/Constants";

// redux
import getProfileFunctions from "redux/Thunks/ProfileFunctions";
import { CreateNewUser } from "redux/Thunks/UserManagement";
import { openSnackbar } from "redux/Slice/Notification";
import RoleListThunk from "redux/Thunks/Role";
import { useDispatch } from "react-redux";

// 3rd party lib
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import PropTypes from "prop-types";

// Custom functions and components
import pxToRem from "assets/theme/functions/pxToRem";
import CustomAutoComplete from "components/Dropdown/CustomAutoComeplete";

function NewUser({ open, handleClose, handleFilter }) {
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    role: "",
    email: "",
    password: "",
    contactNumber: {
      number: "",
      code: defaultData.DEFAULT_PHONE_CODE,
      in: defaultData.DEFAULT_PHONE_IN,
    },
    profileFunction: "",
  });
  const [roleOptions, setRoleOptions] = useState([]);
  const [profileFunction, setProfileFunction] = useState([]);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();

  const handleShowPasswordClick = () => {
    setShowPassword(!showPassword);
  };
  useEffect(() => {
    (async () => {
      const res = await dispatch(RoleListThunk());
      const profileFunctionRes = await dispatch(getProfileFunctions());
      const filteredRoles = res.payload.data.data.filter(
        (element) =>
          element?.title !== defaultData.ADMIN_ROLE &&
          element?.title !== defaultData.SUPER_ADMIN_ROLE
      );
      const filterProfileFunctions = profileFunctionRes.payload.data.data.filter(
        (item) => item.isActive === true
      );
      setProfileFunction(filterProfileFunctions);
      setRoleOptions(filteredRoles);
    })();
  }, []);
  const validate = () => {
    const emailValidate = Validations.validate("email", values.email, null, null, false);
    const passwordValidate = Validations.validate("password", values.password, 6, 30, true);

    const newErrors = {};

    if (!values) {
      return false;
    }

    if (!values.email) {
      newErrors.email = "Email is required";
    } else if (emailValidate) {
      newErrors.email = Constants.EMAIL_NOT_VALID;
    }

    if (!values.firstName) {
      newErrors.firstName = "First name is required";
    } else if (values.firstName.trim() === "") {
      newErrors.firstName = Constants.INVALID_SPACE;
    }

    if (!values.lastName) {
      newErrors.lastName = "Last name is required";
    } else if (values.lastName.trim() === "") {
      newErrors.lastName = Constants.INVALID_SPACE;
    }

    if (!values.role) {
      newErrors.role = "Role name is required";
    } else if (values.role.trim() === "") {
      newErrors.role = Constants.INVALID_SPACE;
    }

    if (!values.password) {
      newErrors.password = Constants.PASSWORD_REQUIRED;
    } else if (passwordValidate) {
      newErrors.password = passwordValidate;
    }

    if (values.contactNumber.number === "") {
      newErrors.contactNumber = "Contact number is required";
    }

    if (!values.profileFunction) {
      newErrors.profileFunction = "Profile Function is required";
    } else if (values.profileFunction.trim() === "") {
      newErrors.profileFunction = Constants.INVALID_SPACE;
    }

    setErrors(newErrors);
    return Object.values(newErrors).filter((val) => val !== "").length === 0;
  };
  const handleResetModal = () => {
    setErrors({});
    setValues({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      role: "",
      contactNumber: {
        number: "",
        code: "",
        in: "",
      },
    });
    handleClose();
  };

  const handleChange = (e) => {
    if (e.target.value !== "") {
      setValues({
        ...values,
        [e.target.name]: e.target.value,
      });
    } else {
      const tempValue = { ...values };
      delete tempValue[e.target.name];
      setValues(tempValue);
    }
  };

  const handlePhoneNumberChange = (num, country, type) => {
    setValues({
      ...values,
      [type]: {
        number: num.substring(country.dialCode.length),
        code: country.countryCode.toUpperCase(),
        in: `+${country.dialCode}`,
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const val = validate();
    if (val) {
      const body = {
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim(),
        email: values.email,
        password: values.password,
        contactNumber: values.contactNumber,
        role: values.role.trim(),
        profileFunction: values.profileFunction.trim(),
      };
      const res = await dispatch(CreateNewUser(body));
      if (res.payload.status === 200) {
        handleResetModal();
        dispatch(openSnackbar({ message: res.payload.data.message, notificationType: "success" }));
        handleFilter();
      } else if (res.payload.status === 401) {
        const temp = { ...errors };
        temp.email = Constants.EMAIL_EXIST;
        setErrors(temp);
      } else if (res.payload.status === 422) {
        const newErrors = {};
        // add key pair of error into newErrors
        res.payload.data.data.error.forEach((error) => {
          Object.keys(error).forEach((key) => {
            newErrors[key] = error[key];
          });
        });
        setErrors(newErrors);
      }
    }
    setLoading(false);
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <MDBox sx={style}>
        <MDBox
          bgColor="info"
          p={3}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          borderRadius="lg"
          sx={{ borderBottomRightRadius: 0, borderBottomLeftRadius: 0, height: pxToRem(72) }}
        >
          <ModalTitle title="New User" color="white" />
          <Icon
            sx={{ cursor: "pointer", color: "beige" }}
            fontSize="medium"
            onClick={handleResetModal}
          >
            {Icons.CROSS}
          </Icon>
        </MDBox>
        <MDBox
          display="flex"
          flexWrap="wrap"
          px={3}
          py={2}
          sx={{
            maxHeight: 500,
            overflowY: "scroll",
            "::-webkit-scrollbar": { display: "none" },
            scrollbarWidth: "none",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomAutoComplete
                label="Role Name*"
                id="role"
                name="role"
                hint="Enter Role Name*"
                handleChange={handleChange}
                menu={roleOptions}
                error={Boolean(errors.role)}
                helperText={errors.role}
                getOptionLabel={(option) => option.title || ""}
                value={{
                  title: roleOptions.find((role) => role[Constants.MONGOOSE_ID] === values.role)
                    ?.title,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <MDTypography
                variant="caption"
                mb={1}
                sx={{ fontSize: pxToRem(14), fontWeight: 500, color: "#344054" }}
              >
                First Name*
              </MDTypography>
              <MDInput
                sx={{
                  marginTop: 0,
                  "& input": {
                    fontSize: "16px",
                    color: "#667085",
                  },
                }}
                name="firstName"
                placeholder="First Name*"
                value={values.firstName}
                onChange={handleChange}
                error={Boolean(errors.firstName)}
                helperText={errors.firstName}
                margin="normal"
                fullWidth
                FormHelperTextProps={{
                  sx: { marginLeft: 0, color: "#FF2E2E" },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <MDTypography
                variant="caption"
                sx={{ fontSize: pxToRem(14), fontWeight: 500, color: "#344054" }}
              >
                Last Name*
              </MDTypography>
              <MDInput
                sx={{
                  marginTop: 0,
                  "& input": {
                    fontSize: "16px",
                    color: "#667085",
                  },
                }}
                name="lastName"
                placeholder="Last Name*"
                value={values.lastName}
                onChange={handleChange}
                error={Boolean(errors.lastName)}
                helperText={errors.lastName}
                margin="normal"
                fullWidth
                FormHelperTextProps={{
                  sx: { marginLeft: 0, color: "#FF2E2E" },
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <MDTypography
                variant="caption"
                sx={{ fontSize: pxToRem(14), fontWeight: 500, color: "#344054" }}
              >
                Email*
              </MDTypography>
              <MDInput
                sx={{
                  marginTop: 0,
                  "& input": {
                    fontSize: "16px",
                    color: "#667085",
                  },
                }}
                name="email"
                placeholder="Email*"
                value={values.email}
                error={Boolean(errors.email)}
                helperText={errors.email}
                onChange={handleChange}
                FormHelperTextProps={{
                  sx: { marginLeft: 0, color: "#FF2E2E" },
                }}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <MDTypography
                variant="caption"
                sx={{ fontSize: pxToRem(14), fontWeight: 500, color: "#344054" }}
              >
                Password*
              </MDTypography>
              <MDInput
                sx={{
                  marginTop: 0,
                  "& input": {
                    fontSize: "16px",
                    color: "#667085",
                  },
                }}
                name="password"
                type={showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleChange}
                error={Boolean(errors.password)}
                helperText={errors.password}
                placeholder="Password*"
                margin="normal"
                fullWidth
                FormHelperTextProps={{
                  sx: { marginLeft: 0, color: "#FF2E2E" },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPasswordClick}>
                        {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <MDTypography variant="caption" ml={0}>
                Contact Number*
              </MDTypography>
              <PhoneInput
                country="us"
                value={values.contactNumber.in + values.contactNumber.number}
                onChange={(num, country) => handlePhoneNumberChange(num, country, "contactNumber")}
                inputStyle={{
                  width: "100%",
                  height: "43px",
                }}
              />
              <MDTypography variant="caption" color="error" sx={{ marginTop: 2 }}>
                {errors?.contactNumber}
              </MDTypography>
            </Grid>
            <Grid item xs={6}>
              <CustomAutoComplete
                label="Profile Function*"
                id="profileFunction"
                name="profileFunction"
                hint="Enter Profile Function Name*"
                handleChange={handleChange}
                menu={profileFunction}
                error={Boolean(errors.profileFunction)}
                helperText={errors.profileFunction}
                getOptionLabel={(option) => option.name || ""}
                value={{
                  name: profileFunction.find(
                    (pfunc) => pfunc[Constants.MONGOOSE_ID] === values.profileFunction
                  )?.name,
                }}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox px={2} mb={2} mr={1} mt={1}>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Grid item xs={0}>
              <MDButton
                variant="contained"
                color="info"
                onClick={handleSubmit}
                style={{ boxShadow: "none", textTransform: "none" }}
              >
                {loading ? "Loading..." : "Submit"}
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Modal>
  );
}

// default props
NewUser.defaultProps = {
  open: false,
};

// prop types
NewUser.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleFilter: PropTypes.func.isRequired,
};

export default NewUser;
