import React, { useEffect, useState } from "react";

// Components
import Author from "components/Table/Author";

// Utils
import Constants, { defaultData } from "utils/Constants";

export default function ApproverReturnProjectData({ listing }) {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (listing) {
      const tempRows = listing?.map((element, index) => {
        const temp = {
          collapseBtn: (
            <Author
              name={{
                returnProjectId: element[Constants.MONGOOSE_ID],
                returnProjectdata: element?.returnOrderList,
              }}
            />
          ),
          srNo: <Author name={index + 1} />,
          project: (
            <Author name={element?.project?.title} maxContent={defaultData.LARGE_CONTENT_LENGTH} />
          ),
          totalReturnOrders: <Author name={element?.returnOrderList?.length} />,
        };
        return temp;
      });
      setRows([...tempRows]);
    }
  }, [listing]);

  return {
    columns: [
      { Header: "", accessor: "collapseBtn", width: "5%" },
      { Header: "No.", accessor: "srNo", width: "5%" },
      { Header: "Project", accessor: "project" },
      { Header: "Total Return Orders", accessor: "totalReturnOrders", align: "center" },
    ],
    rows,
  };
}
