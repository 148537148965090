import React, { forwardRef } from "react";

// Custom Components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

// MUI Components
import { IconButton, InputAdornment } from "@mui/material";

// Functions
import pxToRem from "assets/theme/functions/pxToRem";

// Constants
import { Icons } from "utils/Constants";

// 3rd party
import PropTypes from "prop-types";

const DateTime = forwardRef(
  ({ value, onClick, item, errors, label, minWidth = "100%", onClear }, ref) => (
    <MDBox
      sx={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        mr: 2,
        ml: 0,
        mb: 0,
        minWidth: "100%",
      }}
    >
      <MDTypography
        variant="caption"
        mb={1}
        sx={{ fontSize: pxToRem(14), fontWeight: 500, color: "#344054" }}
      >
        {label}
      </MDTypography>
      <MDInput
        id={label}
        error={Boolean(errors)}
        helperText={errors}
        FormHelperTextProps={{
          sx: { marginLeft: 1, color: "#FF2E2E" },
        }}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {value && onClear && <IconButton onClick={onClear}>{Icons.CLOSE}</IconButton>}
            </InputAdornment>
          ),
        }}
        placeholder={item?.hint}
        value={value}
        sx={{ ml: 0, minWidth, mb: 2 }}
        onClick={onClick}
        ref={ref}
      />
    </MDBox>
  )
);

DateTime.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
  item: PropTypes.shape({
    hint: PropTypes.string,
  }),
  errors: PropTypes.string,
  label: PropTypes.string.isRequired,
  minWidth: PropTypes.string,
  onClear: PropTypes.func,
};

DateTime.defaultProps = {
  value: "",
  onClick: () => {},
  item: {},
  errors: "",
  minWidth: "100%",
  onClear: () => {},
};

export default DateTime;
