import Constants from "utils/Constants";

// Remove key for values
export function filterObj(obj, values) {
  const newObj = { ...obj };
  Object.keys(newObj).forEach((key) => {
    if (newObj[key] === values) {
      delete newObj[key];
    }
  });

  return newObj;
}

// param creater from object
export function paramCreater(obj) {
  const filteredObject = filterObj(obj, "");
  const param = new URLSearchParams(filteredObject);
  return param;
}

export function generateCertificateList(data) {
  const certificateList = [];

  data?.forEach((item) => {
    const functionObject = {
      id: item?.function[Constants.MONGOOSE_ID],
      functionName: item?.function?.functionName,
    };

    item?.certificates?.forEach((certificate) => {
      const certificateObject = {
        id: certificate[Constants.MONGOOSE_ID],
        name: certificate?.name,
      };

      certificateList.push({
        function: functionObject,
        certificate: certificateObject,
      });
    });
  });

  return certificateList;
}

export function generateTrainingMatrixCertificateList(data) {
  const certificateList = [];

  data?.forEach((element) => {
    const key = Object.keys(element)[0];
    const value = Object.values(element)[0];
    const functionObject = {
      id: key,
      functionName: key,
    };

    value.forEach((user) => {
      const userKey = Object.keys(user)[0];
      const userValue = Object.values(user)[0];
      const userCertificates = {
        id: userKey,
        name: userKey,
        certificates: userValue,
      };

      certificateList.push({
        function: functionObject,
        certificate: userCertificates,
      });
    });
  });

  return certificateList;
}

const getFileNameFromUrl = (url, name) => {
  const parts = url.split("/");
  const fileName = parts[parts.length - 1];
  return name ? `${name}` : fileName;
};

export const downloadFile = (imageUrl, event, name) => {
  fetch(imageUrl)
    .then((response) => response.blob())
    .then((blob) => {
      const fileName = getFileNameFromUrl(imageUrl, name);
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      a.click();
      URL.revokeObjectURL(url);
    });
  event.stopPropagation();
};

export const numericFieldValidation = (e) => {
  if (e.key === "Backspace" || e.key === "ArrowLeft" || e.key === "ArrowRight") {
    return;
  }

  // Prevent default for non-numeric keys
  if (!/(?=.*?\d)/.test(e.key)) {
    e.preventDefault();
  }
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const handlePdfExport = async (fileName, res, dateFormat = true) => {
  let name = fileName;
  if (dateFormat) {
    const currentDate = new Date();
    name = `Reynard_${fileName}_${currentDate.getFullYear()}-${
      currentDate.getMonth() + 1
    }-${currentDate.getDate()}_${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}.pdf`;
  }
  if (res.error !== undefined) return res;

  const url = window.URL.createObjectURL(res.payload);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", name);
  document.body.appendChild(link);
  link.click();
  link.remove();
  return res;
};
