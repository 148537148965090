/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

import React, { useEffect, useState } from "react";

// Custom Components
import DataTable from "examples/Tables/DataTable/NestedTable";
import CheckInCheckOutComments from "examples/modal/CheckInCheckOutComments/CheckInCheckOutComments";
// Material Components
import MDBox from "components/MDBox";

// Data Tables
import ApproverReturnProjectData from "layouts/Approver/data/ApproverReturnProjectData";
import ApproverReturnOrderData from "layouts/Approver/data/ApproverReturnOrderData";
import ApproverReturnEquipmentData from "layouts/Approver/data/ApproverReturnEquipmentData";

// utils
import Constants, { defaultData, Colors } from "utils/Constants";

// redux
import { getPMReturnOrders } from "redux/Thunks/EquipmentRequest";
import { useDispatch, useSelector } from "react-redux";
import {
  ReturnOrderReloadData,
  loadReturnProjectLayerData,
  loadReturnOrderLayerData,
} from "redux/Slice/EquipmentRequest";
import { paramCreater } from "utils/methods/methods";

function ApproverReturnOrders() {
  const [tablePagination, setTablePagination] = useState({
    page: 0,
    perPage: defaultData.DATE_ON_SINGLE_API_CALL,
  });
  const [returnCommentsData, setReturnCommentsData] = useState({
    open: false,
    type: "",
    comments: [],
  });
  const [rejectCommentsData, setRejectCommentsData] = useState({
    open: false,
    type: "",
    comments: [],
  });
  const [next, setNext] = useState(0);
  const {
    approverReturnOrderList,
    approverReturnOrderLoading,
    approverReturnProjectData,
    approverReturnOrderData,
  } = useSelector((state) => state.equipmentRequest);
  const dispatch = useDispatch();

  const handleFilter = async () => {
    setTablePagination({ ...tablePagination, page: 0 });
    setNext(0);
    const paramData = {
      page: next + 1,
      perPage: tablePagination.perPage,
    };
    await dispatch(ReturnOrderReloadData());
    await dispatch(getPMReturnOrders(paramCreater(paramData)));
  };

  useEffect(() => {
    (async () => {
      handleFilter();
    })();
  }, []);

  const handleTablePagination = async () => {
    const paramData = {
      page: next + 1,
      perPage: tablePagination.perPage,
    };
    const res = await dispatch(getPMReturnOrders(paramCreater(paramData)));
    if (res.payload.status === 200) setNext(res.payload.data.length > 0 ? next + 1 : next);
  };

  const openReturnRemarkModal = (type, returnComments) => {
    const { open, ...rest } = returnCommentsData;
    setReturnCommentsData({
      ...rest,
      open: true,
      type,
      comments: returnComments,
    });
  };

  const openRejectRemarkModal = (type, rejectComments) => {
    const { open, ...rest } = rejectCommentsData;
    setRejectCommentsData({
      ...rest,
      open: true,
      type,
      comments: rejectComments,
    });
  };
  const { columns, rows } = ApproverReturnProjectData({ listing: approverReturnOrderList });

  const { returnOrderColumns, returnOrderRows } = ApproverReturnOrderData({
    listing: approverReturnProjectData,
    openReturnRemarkModal,
  });

  const { returnEquipmentColumns, returnEquipmentRows } = ApproverReturnEquipmentData({
    listing: approverReturnOrderData,
    openRejectRemarkModal,
  });

  return (
    <MDBox mt={2}>
      <DataTable
        table={{ columns, rows }}
        isSorted={false}
        entriesPerPage={{ defaultValue: defaultData.PER_PAGE }}
        showTotalEntries={false}
        noEndBorder
        loading={approverReturnOrderLoading}
        loadLayerId={loadReturnProjectLayerData}
        currentPage={tablePagination.page}
        handleTablePagination={handleTablePagination}
        handleCurrentPage={(page) => setTablePagination({ ...tablePagination, page })}
        childTable={[
          {
            columns: returnOrderColumns,
            rows: returnOrderRows,
            backgroundColor: Colors.LIGHT_GRAY,
            textColor: Colors.PRIMARY,
            isSorted: false,
            loadLayerId: loadReturnOrderLayerData,
            entriesPerPage: { defaultValue: defaultData.PER_PAGE },
            showTotalEntries: false,
            noEndBorder: true,
            loading: Constants.FULFILLED,
          },
          {
            columns: returnEquipmentColumns,
            rows: returnEquipmentRows,
            backgroundColor: Colors.LIGHT_GRAY,
            textColor: Colors.PRIMARY,
            isSorted: false,
            entriesPerPage: { defaultValue: defaultData.PER_PAGE },
            showTotalEntries: false,
            noEndBorder: true,
            loading: Constants.FULFILLED,
          },
        ]}
      />
      {returnCommentsData.open && (
        <CheckInCheckOutComments
          open={returnCommentsData.open}
          type={returnCommentsData.type}
          intialComments={returnCommentsData.comments}
          handleClose={() =>
            setReturnCommentsData({
              open: false,
              type: "",
              comments: [],
            })
          }
          minWidth={600}
        />
      )}
      {rejectCommentsData.open && (
        <CheckInCheckOutComments
          open={rejectCommentsData.open}
          type={rejectCommentsData.type}
          intialComments={rejectCommentsData.comments}
          handleClose={() =>
            setRejectCommentsData({
              open: false,
              type: "",
              comments: [],
            })
          }
          minWidth={600}
        />
      )}
    </MDBox>
  );
}

export default ApproverReturnOrders;
