import React, { useEffect, useState } from "react";

// MUI Components
import MDBox from "components/MDBox";
import { Card, Divider } from "@mui/material";
import pxToRem from "assets/theme/functions/pxToRem";
import MDTypography from "components/MDTypography";

// Custom Components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PageTitle from "examples/NewDesign/PageTitle";
import DoughnutPieChart from "components/Charts/doughnutPieChart";
import FDropdown from "components/Dropdown/FDropdown";

// Constant
import Constants, { Colors, Icons } from "utils/Constants";

// Methods
import { capitalizeFirstLetter } from "utils/methods/methods";

// Redux Component
import { useDispatch } from "react-redux";
import getProjectProgressData, {
  getReportProgressData,
  getReportStatusData,
  getShiftStatusData,
  getFilterProjects,
  getFilterReports,
} from "redux/Thunks/projectDashboard";
import { openSnackbar } from "redux/Slice/Notification";

const chartColors = (title) => {
  let colors;
  switch (title.toLowerCase()) {
    case "open":
    case "unsafe":
      colors = {
        backgroundColor: Colors.LIGHT_BROWN,
        fontColor: Colors.DARK_BROWN,
      };
      break;

    case "submitted":
    case "incident":
      colors = {
        backgroundColor: Colors.LIGHT_ORANGE2,
        fontColor: Colors.DARK_ORANGE,
      };
      break;

    case "closed":
    case "safe":
    case "completed":
      colors = {
        backgroundColor: Colors.LIGHT_GREEN2,
        fontColor: Colors.DARK_GREEN2,
      };
      break;

    case "in_discussion":
      colors = {
        backgroundColor: Colors.LIGHT_YELLOW2,
        fontColor: Colors.DARK_YELLOW,
      };
      break;

    case "ncr":
    case "pending":
      colors = {
        backgroundColor: Colors.LIGHT_BLUE,
        fontColor: Colors.DARK_BLUE,
      };
      break;

    default:
      colors = {
        backgroundColor: Colors.LIGHT_GRAY,
        fontColor: Colors.DARK_GREY,
      };
      break;
  }
  return colors;
};

const reportShiftStatuses = ["In discussion", "Open", "Closed", "Submitted"];
const progressType = ["Pending", "Completed"];

function ProjectManagement() {
  const dispatch = useDispatch();

  // Initialize state variables
  const [projectProgress, setProjectProgress] = useState([
    {
      name: "Completed",
      value: 0,
      color: Colors.LIGHT_GREEN2,
      textColor: Colors.DARK_GREEN2,
    },
    {
      name: "Pending",
      value: 0,
      color: Colors.LIGHT_BLUE,
      textColor: Colors.DARK_BLUE,
    },
  ]);

  const [reportProgress, setReportProgress] = useState([
    {
      name: "Completed",
      value: 0,
      color: Colors.LIGHT_GREEN2,
      textColor: Colors.DARK_GREEN2,
    },
    {
      name: "Pending",
      value: 0,
      color: Colors.LIGHT_BLUE,
      textColor: Colors.DARK_BLUE,
    },
  ]);

  const [reportStatus, setReportSatus] = useState([
    {
      name: "Submitted",
      value: 0,
      color: Colors.LIGHT_ORANGE2,
      textColor: Colors.DARK_ORANGE,
    },
    {
      name: "Closed",
      value: 0,
      color: Colors.LIGHT_GREEN2,
      textColor: Colors.DARK_GREEN2,
    },
    {
      name: "In discussion",
      value: 0,
      color: Colors.LIGHT_YELLOW2,
      textColor: Colors.DARK_YELLOW,
    },
    {
      name: "Open",
      value: 0,
      color: Colors.LIGHT_BROWN,
      textColor: Colors.DARK_BROWN,
    },
  ]);

  const [shiftStatus, setShiftStatus] = useState([
    {
      name: "Submitted",
      value: 0,
      color: Colors.LIGHT_ORANGE2,
      textColor: Colors.DARK_ORANGE,
    },
    {
      name: "Closed",
      value: 0,
      color: Colors.LIGHT_GREEN2,
      textColor: Colors.DARK_GREEN2,
    },
    {
      name: "In discussion",
      value: 0,
      color: Colors.LIGHT_YELLOW2,
      textColor: Colors.DARK_YELLOW,
    },
    {
      name: "Open",
      value: 0,
      color: Colors.LIGHT_BROWN,
      textColor: Colors.DARK_BROWN,
    },
  ]);

  const [totalCount, setTotalCount] = useState({
    projectTotalCount: 0,
    reportTotalCount: 0,
    reportStatusTotalCount: 0,
    shiftTotalCount: 0,
  });
  const [loading, setLoading] = useState(true);
  const [projectFilterLoading, setProjectFilterLoading] = useState(false);
  const [filters, setFilters] = useState({
    projectList: [],
    reportList: [],
  });
  const [filterValue, setFilterValue] = useState({
    project: "",
    report: "",
  });

  // Fetch and process dashboard data
  const projectDashboardData = async (id) => {
    let fetchApiDataFlag = false;
    let updatedTotalCount = {
      projectTotalCount: totalCount.projectTotalCount,
      reportTotalCount: totalCount.reportTotalCount,
      reportStatusTotalCount: totalCount.reportStatusTotalCount,
      shiftTotalCount: totalCount.shiftTotalCount,
    };
    const projectRes = await dispatch(getProjectProgressData(id));
    const reportStatusRes = await dispatch(getReportStatusData());
    const shiftStatusRes = await dispatch(getShiftStatusData(id));
    const reportListRes = await dispatch(getFilterReports(id));
    if (reportListRes.payload.status === 200) {
      const reportRes = await dispatch(getReportProgressData());
      let updatedReportRes = await Promise.all(
        reportRes.payload.reportCount.map(async (item) => {
          const { backgroundColor, fontColor } = chartColors(item.name);
          return {
            name: capitalizeFirstLetter(item.name),
            value: item.count,
            color: backgroundColor,
            textColor: fontColor,
          };
        })
      );
      await Promise.all(
        progressType.map(async (item) => {
          const exist = updatedReportRes.some((card) => card.name === item);
          if (!exist) {
            const { backgroundColor, fontColor } = chartColors(item);
            updatedReportRes.push({
              name: item,
              value: 0,
              color: backgroundColor,
              textColor: fontColor,
            });
          }
        })
      );
      updatedReportRes = updatedReportRes.sort((a, b) => {
        if (a.name === "Completed") return -1;
        if (b.name === "Pending") return 1;
        return 0;
      });
      const tempReportList = reportListRes.payload.data.data.map((item) => ({
        [Constants.MONGOOSE_ID]: item[Constants.MONGOOSE_ID],
        title: item.title,
      }));
      setFilters((prevFilters) => ({
        ...prevFilters,
        reportList: tempReportList,
      }));
      setFilterValue((prevFilterValue) => ({
        ...prevFilterValue,
        report: tempReportList.length > 0 ? tempReportList[0][Constants.MONGOOSE_ID] : ``,
      }));
      setReportProgress(updatedReportRes);
      updatedTotalCount = {
        ...updatedTotalCount,
        reportTotalCount: reportRes.payload?.totalReportCount,
      };
    } else {
      fetchApiDataFlag = true;
    }

    if (projectRes.payload.status === 200) {
      const completedProgress = projectRes.payload.data.data?.reportDurations || 0;
      const totalProgress = projectRes.payload.data.data?.totalReportDurations || 0;
      const pendingProgress = totalProgress - completedProgress;
      const selectedprojectProgress = [
        {
          name: "Completed",
          value: completedProgress,
          color: Colors.LIGHT_GREEN2,
          textColor: Colors.DARK_GREEN2,
        },
        {
          name: "Pending",
          value: pendingProgress,
          color: Colors.LIGHT_BLUE,
          textColor: Colors.DARK_BLUE,
        },
      ];
      setProjectProgress(selectedprojectProgress);
      updatedTotalCount = {
        ...updatedTotalCount,
        projectTotalCount: totalProgress,
      };
    } else {
      fetchApiDataFlag = true;
    }

    const updatedReportStatusRes = await Promise.all(
      reportStatusRes.payload.reportStatusCount.map(async (item) => {
        const { backgroundColor, fontColor } = chartColors(item.name);
        return {
          name: item.name === "in_discussion" ? "In discussion" : capitalizeFirstLetter(item.name),
          value: item.count,
          color: backgroundColor,
          textColor: fontColor,
        };
      })
    );
    await Promise.all(
      reportShiftStatuses.map(async (item) => {
        const exist = updatedReportStatusRes.some((card) => card.name === item);
        if (!exist) {
          const { backgroundColor, fontColor } = chartColors(item);
          updatedReportStatusRes.push({
            name: item,
            value: 0,
            color: backgroundColor,
            textColor: fontColor,
          });
        }
      })
    );

    if (shiftStatusRes.payload.status === 200) {
      const { payload } = shiftStatusRes;
      const updatedShiftStatusRes = await Promise.all(
        payload.data.data?.shiftCount.map(async (item) => {
          const { backgroundColor, fontColor } = chartColors(item.status);
          return {
            name:
              item.status === "in_discussion"
                ? "In discussion"
                : capitalizeFirstLetter(item.status),
            value: item.count,
            color: backgroundColor,
            textColor: fontColor,
          };
        })
      );
      await Promise.all(
        reportShiftStatuses.map(async (item) => {
          const exist = updatedShiftStatusRes.some((card) => card.name === item);
          if (!exist) {
            const { backgroundColor, fontColor } = chartColors(item);
            updatedShiftStatusRes.push({
              name: item,
              value: 0,
              color: backgroundColor,
              textColor: fontColor,
            });
          }
        })
      );
      setShiftStatus(updatedShiftStatusRes);
      updatedTotalCount = {
        ...updatedTotalCount,
        shiftTotalCount: payload.data.data?.totalShiftCount,
      };
    } else {
      fetchApiDataFlag = true;
    }

    setReportSatus(updatedReportStatusRes);
    setTotalCount({
      ...totalCount,
      projectTotalCount: updatedTotalCount.projectTotalCount,
      reportTotalCount: updatedTotalCount.reportTotalCount,
      reportStatusTotalCount: reportStatusRes.payload?.totalReportStatusCount,
      shiftTotalCount: updatedTotalCount.shiftTotalCount,
    });
    setLoading(false);
    if (fetchApiDataFlag) {
      await dispatch(
        openSnackbar({
          message: Constants.SOMETHING_WENT_WRONG,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
  };

  // Fetch and process filters
  const fetchFilters = async () => {
    const projectListData = await dispatch(getFilterProjects(true));
    if (projectListData.payload.status === 200) {
      const tempProjectList = projectListData.payload.data.data.map((item) => ({
        [Constants.MONGOOSE_ID]: item[Constants.MONGOOSE_ID],
        title: item.title,
      }));

      setFilters((prevFilters) => ({
        ...prevFilters,
        projectList: tempProjectList,
      }));
      setFilterValue((prevFilterValue) => ({
        ...prevFilterValue,
        project: tempProjectList.length > 0 ? tempProjectList[0][Constants.MONGOOSE_ID] : ``,
      }));
      return tempProjectList[0][Constants.MONGOOSE_ID];
    }
    return null;
  };

  // Effect to load data on component mount
  useEffect(() => {
    setLoading(true);
    (async () => {
      const projectID = await fetchFilters();
      if (projectID) {
        await projectDashboardData(projectID);
      } else {
        setLoading(false);
        await dispatch(
          openSnackbar({
            message: Constants.SOMETHING_WENT_WRONG,
            notificationType: Constants.NOTIFICATION_ERROR,
          })
        );
      }
    })();
  }, []);

  const handleFilterChange = async (value) => {
    setProjectFilterLoading(true);
    await projectDashboardData(value);
    setFilterValue((prevFilterValue) => ({
      ...prevFilterValue,
      project: value,
    }));
    setProjectFilterLoading(false);
  };

  const handleReportFilterChnage = async (event) => {
    const { value } = event.target;
    setFilterValue((prevFilterValue) => ({
      ...prevFilterValue,
      report: value,
    }));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <PageTitle title="Project Management" />
      <Divider sx={{ marginTop: 2 }} />
      <MDBox
        sx={{
          paddingBottom: 3,
        }}
      >
        {!loading ? (
          <Card sx={{ marginTop: 3, height: "fit-content", paddingBottom: 3 }}>
            <MDBox>
              <MDTypography
                sx={{
                  fontWeight: "600",
                  fontSize: pxToRem(26),
                  lineHeight: pxToRem(38),
                  textAlign: "center",
                  backgroundColor: "#191A51",
                  color: "#ffffff",
                  paddingX: pxToRem(10),
                  paddingY: pxToRem(16),
                  borderRadius: "0.75rem 0.75rem 0 0",
                }}
              >
                Overview of Project, Reports and Shifts
              </MDTypography>
              <MDBox
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <MDBox
                  sx={{
                    width: "100%",
                  }}
                >
                  <MDBox
                    sx={{
                      width: pxToRem(230),
                      float: "right",
                      marginRight: pxToRem(50),
                      paddingTop: pxToRem(10),
                    }}
                  >
                    <FDropdown
                      id="project"
                      name="project"
                      menu={filters.projectList}
                      value={filterValue.project}
                      handleChange={(_, value) => handleFilterChange(value)}
                    />
                  </MDBox>
                </MDBox>
                {!projectFilterLoading ? (
                  <MDBox
                    sx={{
                      display: "flex",
                      justifyContent: {
                        lg: "start",
                        md: "center",
                        sm: "center",
                        xs: "center",
                      },
                      flexDirection: "row",
                      flexWrap: "wrap",
                      alignItems: "start",
                      paddingX: pxToRem(30),
                    }}
                  >
                    <MDBox
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        alignItems: "start",
                        gap: pxToRem(20),
                      }}
                    >
                      <DoughnutPieChart
                        data={projectProgress}
                        title="Project Progress"
                        totalCount={totalCount.projectTotalCount}
                        label="percentage"
                      />
                      <DoughnutPieChart
                        data={reportProgress}
                        title="Report Progress"
                        totalCount={totalCount.reportTotalCount}
                        label="percentage"
                        isapplyFilter
                        handleFilterChange={handleReportFilterChnage}
                        filterData={{
                          selectedValue: filterValue.report,
                          menu: filters.reportList,
                          name: "Report",
                        }}
                      />
                    </MDBox>
                    <MDBox
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        alignItems: "start",
                        gap: pxToRem(20),
                      }}
                    >
                      <DoughnutPieChart
                        data={reportStatus}
                        title="Reports by Status"
                        totalCount={totalCount.reportStatusTotalCount}
                      />
                      <DoughnutPieChart
                        data={shiftStatus}
                        title="Shifts by Status"
                        totalCount={totalCount.shiftTotalCount}
                      />
                    </MDBox>
                  </MDBox>
                ) : (
                  <MDBox
                    sx={{
                      height: pxToRem(400),
                    }}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {Icons.LOADING2}
                  </MDBox>
                )}
              </MDBox>
            </MDBox>
          </Card>
        ) : (
          <MDBox
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {Icons.LOADING2}
          </MDBox>
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default ProjectManagement;
