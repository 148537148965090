/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

import { useEffect, useState } from "react";

// Custom components
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import Author from "components/Table/Author";

// MUI Components
import { IconButton } from "@mui/material";

// Constants
import Constants, { Icons, defaultData } from "utils/Constants";

// 3rd party library
import moment from "moment";

export default function CertificateApproval(
  userList,
  handleCertificateOpen,
  handleCertificateView
) {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (userList) {
      const tempRows = userList?.map((element) => {
        const temp = {
          name: (
            <MDBox display="flex" alignItems="center" gap={1}>
              <MDAvatar
                size="xs"
                bgColor="primary"
                fontSize={2}
                alt="User Avatar"
                src={element?.user?.profileImage}
              />
              <Author
                name={`${element?.user?.firstName} ${element?.user?.lastName}`}
                style={{ color: "#757575", fontSize: "14px" }}
              />
            </MDBox>
          ),
          email: <Author name={element?.user?.email} style={{ textTransform: "inherit" }} />,
          certificates: (
            <MDBox display="flex" justifyContent="space-between" alignItems="center">
              <Author name={element?.certificateType?.name} maxContent={12} />
              <IconButton
                aria-label="Edit Safety Card"
                color="info"
                onClick={() => handleCertificateView(element?.link)}
              >
                {Icons.VIEW}
              </IconButton>
            </MDBox>
          ),
          fromDate: (
            <Author
              name={
                element?.startDate
                  ? moment(element?.startDate).format(defaultData.WEB_DATE_FORMAT)
                  : Constants.NA
              }
            />
          ),
          toDate: (
            <Author
              name={
                element?.endDate
                  ? moment(element?.endDate).format(defaultData.WEB_DATE_FORMAT)
                  : Constants.NA
              }
            />
          ),
          action: (
            <MDBox justifyContent="center">
              <IconButton
                onClick={() => handleCertificateOpen("rejected", element[Constants.MONGOOSE_ID])}
              >
                {Icons.CLOSE_OUTLINE}
              </IconButton>
              <IconButton
                onClick={() => handleCertificateOpen("approved", element[Constants.MONGOOSE_ID])}
              >
                {Icons.CORRECT_OUTLINE}
              </IconButton>
            </MDBox>
          ),
        };
        return temp;
      });
      setRows([...tempRows]);
    }
  }, [userList]);
  return {
    columns: [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Certificates",
        accessor: "certificates",
      },
      {
        Header: "Start Date",
        accessor: "fromDate",
      },
      {
        Header: "End Date",
        accessor: "toDate",
      },
      {
        Header: "Action",
        accessor: "action",
        align: "center",
      },
    ],
    rows,
  };
}
