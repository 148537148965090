import { createAsyncThunk } from "@reduxjs/toolkit";
import Sessions from "utils/Sessions";
import ApiService from "../ApiService/ApiService";

const getToolBoxTalk = createAsyncThunk("tool-box-talk-list/api", async (params) => {
  const res = await ApiService.get(`toolbox-talk?${params}`, {
    headers: { Authorization: `Bearer ${Sessions.userToken}` },
  })
    .then((r) => r)
    .catch((error) => error.response);
  return res;
});

export const toolBoxTalkByIdThunk = createAsyncThunk("tool-box-talk-by-id/api", async (id) => {
  const res = await ApiService.get(`toolbox-talk/${id}`, {
    headers: { Authorization: `Bearer ${Sessions.userToken}` },
  })
    .then((r) => r)
    .catch((error) => error.response);
  return res;
});

export const deleteToolBoxTalkThunk = createAsyncThunk("delete-toolBox-talk/api", async (id) => {
  const res = await ApiService.delete(`toolbox-talk/${id}`, {
    headers: { Authorization: `Bearer ${Sessions.userToken}` },
  })
    .then((r) => r)
    .catch((err) => err.response);
  return res;
});

export default getToolBoxTalk;
