import React, { useEffect, useState } from "react";

// MUI Components
import MDBox from "components/MDBox";
import { Card, Divider } from "@mui/material";
import pxToRem from "assets/theme/functions/pxToRem";
import MDTypography from "components/MDTypography";

// Custom Components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PageTitle from "examples/NewDesign/PageTitle";
import DoughnutPieChart from "components/Charts/doughnutPieChart";
import FilterDropdown from "components/Dropdown/FilterDropdown";

// Constant
import Constants, { Colors, Icons } from "utils/Constants";

// Methods
import { capitalizeFirstLetter } from "utils/methods/methods";

// Redux Component
import getQhseDashboardData from "redux/Thunks/qhseDashboard";
import { useDispatch } from "react-redux";
import { openSnackbar } from "redux/Slice/Notification";
import { projectListThunk } from "redux/Thunks/Filter";

const qhseCardStatuses = ["In discussion", "Open", "Closed", "Submitted"];
const qhseCardTypes = ["Safe", "Unsafe", "NCR", "Incident"];

const chartColors = (title) => {
  let colors;
  switch (title.toLowerCase()) {
    case "open":
    case "unsafe":
      colors = {
        backgroundColor: Colors.LIGHT_BROWN,
        fontColor: Colors.DARK_BROWN,
      };
      break;

    case "submitted":
    case "incident":
      colors = {
        backgroundColor: Colors.LIGHT_ORANGE2,
        fontColor: Colors.DARK_ORANGE,
      };
      break;

    case "closed":
    case "safe":
      colors = {
        backgroundColor: Colors.LIGHT_GREEN2,
        fontColor: Colors.DARK_GREEN2,
      };
      break;

    case "in_discussion":
      colors = {
        backgroundColor: Colors.LIGHT_YELLOW2,
        fontColor: Colors.DARK_YELLOW,
      };
      break;

    case "ncr":
      colors = {
        backgroundColor: Colors.LIGHT_BLUE,
        fontColor: Colors.DARK_BLUE,
      };
      break;

    default:
      colors = {
        backgroundColor: Colors.LIGHT_GRAY,
        fontColor: Colors.DARK_GREY,
      };
      break;
  }
  return colors;
};

function QhseManagement() {
  const mongooseId = "_id";
  const dispatch = useDispatch();
  // Initialize state variables
  const [cardStatusCount, setCardStatusCount] = useState([]);
  const [cardTypeCount, setCardTypeCount] = useState([]);
  const [combinedCountList, setCombinedCountList] = useState([]);
  const [filters, setFilters] = useState([
    {
      inputLabel: "Project",
      list: [{ [Constants.MONGOOSE_ID]: "all", title: "All" }],
      selectedValue: "all",
    },
  ]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);

  // Fetch and process dashboard data
  const qhseDashboardData = async (params) => {
    const response = await dispatch(getQhseDashboardData(params));
    const { payload } = response;
    if (payload.status === 200) {
      const statusCountList = payload.data.data?.cardStatusCount || [];
      const typeCountList = payload.data.data?.cardTypeCounts || [];
      const cardStatusCountList = await Promise.all(
        statusCountList.map(async (item) => {
          const colors = chartColors(item.status);
          const { backgroundColor, fontColor } = colors;
          return {
            name:
              item.status === "in_discussion"
                ? "In discussion"
                : capitalizeFirstLetter(item.status),
            value: item.count,
            color: backgroundColor,
            textColor: fontColor,
          };
        })
      );

      const cardTypeCountList = await Promise.all(
        typeCountList.map(async (item) => {
          const { backgroundColor, fontColor } = chartColors(item.cardType);
          return {
            name: item.cardType === "ncr" ? "NCR" : capitalizeFirstLetter(item.cardType),
            value: item.count,
            color: backgroundColor,
            textColor: fontColor,
          };
        })
      );

      await Promise.all(
        qhseCardTypes.map(async (item) => {
          const exist = cardTypeCountList.some((card) => card.name === item);
          if (!exist) {
            const { backgroundColor, fontColor } = chartColors(item);
            cardTypeCountList.push({
              name: item,
              value: 0,
              color: backgroundColor,
              textColor: fontColor,
            });
          }
        })
      );

      await Promise.all(
        qhseCardStatuses.map(async (item) => {
          const exist = cardStatusCountList.some((card) => card.name === item);
          if (!exist) {
            const { backgroundColor, fontColor } = chartColors(
              item !== "In discussion" ? item : "in_discussion"
            );
            cardStatusCountList.push({
              name: item,
              value: 0,
              color: backgroundColor,
              textColor: fontColor,
            });
          }
        })
      );
      setCardStatusCount(cardStatusCountList);
      setCardTypeCount(cardTypeCountList);
      setCombinedCountList([...cardStatusCountList, ...cardTypeCountList]);
      setTotalCount(payload.data.data?.totalCardCount || 0);
      setLoading(false);
    } else {
      setLoading(false);
      await dispatch(
        openSnackbar({ message: Constants.SOMETHING_WENT_WRONG, notificationType: "error" })
      );
    }
  };

  // Fetch and process filters
  const fetchFilters = async () => {
    const projectList = await dispatch(projectListThunk());
    if (projectList.payload.status) {
      setFilters([
        {
          inputLabel: "Project",
          list: [{ [mongooseId]: "all", title: "All" }, ...projectList.payload.data],
          selectedValue: "all",
        },
      ]);
    }
  };

  // Effect to load data on component mount
  useEffect(() => {
    setLoading(true);
    qhseDashboardData("all");
    fetchFilters();
  }, []);

  const handleFilterChange = async (e) => {
    await qhseDashboardData(e.target.value);
    const temp = [...filters];
    const { value } = e.target;
    temp[0].selectedValue = value;
    setFilters(temp);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <PageTitle title="QHSE Management" />
      <Divider sx={{ marginTop: 2 }} />
      <MDBox
        sx={{
          paddingBottom: 3,
        }}
      >
        {!loading ? (
          <Card sx={{ marginTop: 3, height: "fit-content", paddingBottom: 3 }}>
            <MDBox>
              <MDTypography
                sx={{
                  fontWeight: "600",
                  fontSize: pxToRem(26),
                  lineHeight: pxToRem(38),
                  textAlign: "center",
                  backgroundColor: "#191A51",
                  color: "#ffffff",
                  paddingX: pxToRem(10),
                  paddingY: pxToRem(16),
                  borderRadius: "0.75rem 0.75rem 0 0",
                }}
              >
                Overview of QHSE Cards
              </MDTypography>
              <MDBox
                sx={{
                  display: "flex",
                  justifyContent: {
                    lg: "space-between",
                    md: "center",
                    sm: "center",
                    xs: "center",
                  },
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignItems: "start",
                  paddingX: pxToRem(30),
                  paddingY: pxToRem(20),
                }}
              >
                <MDBox>
                  <DoughnutPieChart
                    data={cardTypeCount}
                    title="QHSE Cards by Type"
                    totalCount={totalCount}
                  />
                  <DoughnutPieChart
                    data={cardStatusCount}
                    title="QHSE Cards by Status"
                    totalCount={totalCount}
                  />
                </MDBox>
                <MDBox
                  sx={{
                    marginTop: pxToRem(25),
                  }}
                >
                  {filters?.map((val) => (
                    <FilterDropdown
                      key={val.inputLabel}
                      label={val.inputLabel}
                      name={val.inputLabel}
                      defaultValue={val?.selectedValue}
                      value={val?.selectedValue}
                      handleChange={handleFilterChange}
                      menu={val.list}
                      style={{ marginLeft: pxToRem(1), width: pxToRem(225) }}
                    />
                  ))}
                  <MDBox
                    sx={{
                      padding: pxToRem(20),
                      marginTop: pxToRem(20),
                      border: `4px solid ${Colors.LIGHT_GRAY}`,
                      borderRadius: pxToRem(24),
                      marginRight: pxToRem(20),
                      display: "flex",
                      flexDirection: { lg: "column", md: "row", sm: "row", xs: "column" },
                      gap: pxToRem(20),
                      flexWrap: "wrap",
                      width: "fit-content",
                      height: "fit-content",
                    }}
                  >
                    {combinedCountList.map((item) => {
                      if (
                        item.name === "Unsafe" ||
                        item.name === "Incident" ||
                        item.name === "Open"
                      ) {
                        return (
                          <MDBox
                            key={item.name}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              width: pxToRem(180),
                              height: pxToRem(130),
                              backgroundColor: item.color,
                              borderRadius: pxToRem(16),
                              gap: pxToRem(1),
                            }}
                          >
                            <MDTypography
                              sx={{
                                fontSize: pxToRem(40),
                                fontWeight: "bold",
                                color: item.textColor,
                              }}
                            >
                              {item.value}
                            </MDTypography>
                            <MDTypography
                              sx={{
                                fontSize: pxToRem(20),
                                fontWeight: "bold",
                                color: item.textColor,
                              }}
                            >
                              {item.name}
                            </MDTypography>
                          </MDBox>
                        );
                      }
                      return null;
                    })}
                  </MDBox>
                </MDBox>
              </MDBox>
            </MDBox>
          </Card>
        ) : (
          <MDBox
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {Icons.LOADING2}
          </MDBox>
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default QhseManagement;
