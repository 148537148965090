import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { resetStateThunk } from "redux/Thunks/Authentication";
import AllEquipmentRequestListThunk, {
  getPMOrders,
  getPMReturnOrders,
} from "redux/Thunks/EquipmentRequest";
import Constants from "utils/Constants";

const initialState = {
  loading: Constants.IDLE,
  list: [],
  approverOrderLoading: Constants.IDLE,
  approverOrderList: [],
  approverReturnOrderLoading: Constants.IDLE,
  approverReturnOrderList: [],
  currentProjectLayerId: "",
  currentEquipmentTypeLayerId: "",
  approverOrderProjectData: "",
  approverOrderEquipmentTypeData: "",
  approverOrderEquipmentData: "",
  approverReturnProjectData: "",
  approverReturnOrderData: "",
  currentStatus: "pending",
};

export const equipmentOrderSlice = createSlice({
  name: "equipmentOrderSlice",
  initialState,
  reducers: {
    reloadData(state) {
      state.loading = Constants.PENDING;
    },
    OrderReloadData(state) {
      state.approverOrderLoading = Constants.PENDING;
    },

    ReturnOrderReloadData(state) {
      state.approverReturnOrderLoading = Constants.PENDING;
    },

    // Store Nested table required data
    loadProjectLayerData(state, action) {
      state.currentProjectLayerId = action.payload;
    },

    loadEquipmentTypeLayerData(state, action) {
      state.currentEquipmentTypeLayerId = action.payload;
    },
    loadApproverProjectLayerData(state, action) {
      state.approverOrderProjectData = action.payload;
    },
    loadApproverEquipmentTypeLayerData(state, action) {
      state.approverOrderEquipmentTypeData = action.payload;
    },
    loadApproverEquipmentLayerData(state, action) {
      state.approverOrderEquipmentData = action.payload;
    },
    loadReturnProjectLayerData(state, action) {
      state.approverReturnProjectData = action.payload;
    },

    loadReturnOrderLayerData(state, action) {
      state.approverReturnOrderData = action.payload;
    },

    // Update the requested quantity for Pending & Queue tab on user layer table
    updateEquipmentOrderQuantity(state, action) {
      const tempEquipmentQuantity = state.list.map((project) => ({
        ...project,
        equipmentTypes: project?.equipmentTypes?.map((equip) => {
          // To get the updated approved quantity
          const updatedUsers = equip?.users?.map((item) => {
            if (
              project[Constants.MONGOOSE_ID] === action.payload?.projectId &&
              equip[Constants.MONGOOSE_ID] === action.payload?.equipmentId &&
              item?.equipmentRequestId === action.payload?.id
            ) {
              const approvedQuantity = action.payload?.approvedQuantity || 0;
              return {
                ...item,
                approvedQuantity: approvedQuantity.toString(),
                totalAmount:
                  action.payload && action.payload.price !== undefined
                    ? Number(approvedQuantity * action.payload.price)
                    : 0,
              };
            }
            return item;
          });

          // Calculate the total approved quantity for the equipment type
          const totalApprovedQuantity = updatedUsers.reduce((acc, user) => {
            const quantityToAdd = user?.approvedQuantity
              ? user?.approvedQuantity
              : user?.engineerRequestedQuantity || 0;
            return acc + parseInt(quantityToAdd, 10);
          }, 0);

          return {
            ...equip,
            users: updatedUsers,
            totalApprovedQuantity: totalApprovedQuantity.toString(),
          };
        }),
      }));

      state.list = tempEquipmentQuantity;
    },

    // Add Approvers comments for pending tab
    getApproverComments(state, action) {
      const tempComments = state.list.map((project) => ({
        ...project,
        equipmentTypes: project?.equipmentTypes?.map((equip) => {
          if (
            project[Constants.MONGOOSE_ID] === action.payload?.projectId &&
            equip[Constants.MONGOOSE_ID] === action.payload?.equipmentId
          ) {
            return { ...equip, pmComments: action.payload?.comments };
          }
          return equip;
        }),
      }));
      state.list = tempComments;
    },

    // To store current status for approver screen
    storeCurrentStatus(state, action) {
      state.currentStatus = action.payload;
    },
  },

  extraReducers: {
    [AllEquipmentRequestListThunk.pending]: (state) => {
      if (state.list.length === 0) state.loading = Constants.PENDING;
    },
    [AllEquipmentRequestListThunk.fulfilled]: (state, action) => {
      state.loading = Constants.FULFILLED;
      if (state.currentStatus !== action.payload.currentStatus) return;
      const modifiedRequestDetails = action.payload.data.data;

      const modifiedRequestUserData = modifiedRequestDetails?.map((project) => ({
        ...project,
        equipmentTypes: project?.equipmentTypes?.map((equip) => {
          // Calculate the total approved quantity for the equipment type
          const totalApprovedQuantity = equip?.users?.reduce((acc, user) => {
            const quantityToAdd = user?.approvedQuantity
              ? user?.approvedQuantity
              : user?.engineerRequestedQuantity || 0;
            return acc + parseInt(quantityToAdd, 10);
          }, 0);
          return {
            ...equip,
            totalApprovedQuantity: totalApprovedQuantity.toString(),
            pmComments: equip?.pmComments ? equip?.pmComments : [],
            totalRentalDays: equip?.users.reduce(
              (acc, equipm) => acc + parseInt(equipm?.rentalDays, 10),
              0
            ),

            users: equip?.users?.map((user) => {
              const fromDate = moment(user?.fromDate);
              const toDate = moment(user?.toDate);
              const duration = moment.duration(toDate.diff(fromDate));
              const days = duration.asDays() + 1;
              return {
                ...user,
                approvedQuantity: user?.approvedQuantity
                  ? user?.approvedQuantity
                  : user?.engineerRequestedQuantity,
                rentalDays: days,
              };
            }),
          };
        }),
      }));

      state.list = modifiedRequestUserData;
    },
    [AllEquipmentRequestListThunk.rejected]: (state) => {
      state.loading = Constants.REJECTED;
    },

    [getPMOrders.pending]: (state) => {
      if (state.approverOrderList.length === 0) state.approverOrderLoading = Constants.PENDING;
    },
    [getPMOrders.fulfilled]: (state, { payload }) => {
      state.approverOrderLoading = Constants.FULFILLED;
      state.approverOrderList = payload.data.data;
    },
    [getPMOrders.rejected]: (state) => {
      state.approverOrderLoading = Constants.REJECTED;
    },

    [getPMReturnOrders.pending]: (state) => {
      if (state.approverReturnOrderList.length === 0)
        state.approverReturnOrderLoading = Constants.PENDING;
    },
    [getPMReturnOrders.fulfilled]: (state, { payload }) => {
      state.approverReturnOrderLoading = Constants.FULFILLED;
      state.approverReturnOrderList = payload.data.data;
    },
    [getPMReturnOrders.rejected]: (state) => {
      state.approverReturnOrderLoading = Constants.REJECTED;
    },

    [resetStateThunk.fulfilled]: (state) => {
      state.loading = Constants.IDLE;
      state.list = [];
    },
  },
});

export const {
  reloadData,
  OrderReloadData,
  ReturnOrderReloadData,
  loadProjectLayerData,
  loadEquipmentTypeLayerData,
  updateEquipmentOrderQuantity,
  loadApproverProjectLayerData,
  loadApproverEquipmentTypeLayerData,
  loadApproverEquipmentLayerData,
  loadReturnProjectLayerData,
  loadReturnOrderLayerData,
  getApproverComments,
  storeCurrentStatus,
} = equipmentOrderSlice.actions;
export default equipmentOrderSlice.reducer;
