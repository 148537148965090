import React from "react";

// MUI Components
import { Checkbox } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

// 3rd party library
import PropTypes from "prop-types";

export default function CustomCheckbox({ name, defaultChecked, checked, onChange, disabled }) {
  return (
    <Checkbox
      name={name}
      defaultChecked={defaultChecked}
      disabled={disabled}
      checked={checked}
      onChange={onChange}
      style={{
        border: disabled ? "2px solid #BDBDBD" : "2px solid #191A51",
        borderRadius: "2px",
        padding: "0",
        height: "18px",
        width: "18px",
      }}
      icon={
        <CheckCircleOutlineIcon
          style={{
            border: "0px solid #191A51",
            borderRadius: "0px",
            padding: "0",
            margin: 0,
          }}
        />
      }
      checkedIcon={
        <CheckCircleIcon
          style={{
            border: "0px solid #191A51",
            borderRadius: "0px",
            padding: "0",
            margin: 0,
          }}
        />
      }
    />
  );
}

CustomCheckbox.defaultProps = {
  name: "",
  defaultChecked: false,
  checked: false,
  onChange: () => {},
  disabled: false,
};

CustomCheckbox.propTypes = {
  name: PropTypes.string,
  defaultChecked: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};
