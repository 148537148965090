import React, { useEffect, useState } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import { IconButton } from "@mui/material";
import Constants, { Icons } from "utils/Constants";

// Components
import Author from "components/Table/Author";
import pxToRem from "assets/theme/functions/pxToRem";

export default function OrderDetailsData(
  equipmentList,
  status,
  openConfirmationBox,
  addToQueueEquipmnt,
  list,
  openApproverCommentModal,
  projectId,
  equipmentId,
  permission,
  buttonLoading
) {
  const [equipmentTypeRows, setEquipmentTypeRows] = useState([]);
  useEffect(() => {
    if (equipmentList) {
      const tempRows = equipmentList?.data?.map((element, index) => {
        const approverComments = list?.flatMap((proj) =>
          proj?.equipmentTypes?.flatMap((equip) => {
            if (
              proj[Constants.MONGOOSE_ID] === projectId &&
              equip[Constants.MONGOOSE_ID] === element[Constants.MONGOOSE_ID]
            ) {
              return equip?.pmComments || [];
            }
            return [];
          })
        );
        let userApproved = null;
        const totalApproved = list?.flatMap((proj) =>
          proj?.equipmentTypes?.flatMap((equip) => {
            if (
              proj[Constants.MONGOOSE_ID] === projectId &&
              equip[Constants.MONGOOSE_ID] === element[Constants.MONGOOSE_ID]
            ) {
              userApproved = equip?.totalApprovedQuantity;
              return (
                <React.Fragment key={equip[Constants.MONGOOSE_ID]}>
                  <Author name={equip?.totalApprovedQuantity} />
                </React.Fragment>
              );
            }
            return <Author name="" />; // Return the original equipment type if ids don't match
          })
        );
        const temp = {
          collapseBtn: (
            <Author
              name={{
                equipmentId: element[Constants.MONGOOSE_ID],
                data: element?.users,
                equipmentData: element,
              }}
            />
          ),
          srNo: <Author name={index + 1} />,
          reqQty: <Author name={element?.totalEngineerRequestedQuantity} />,
          totalApproved:
            status === "pending" ? (
              <Author name={totalApproved} />
            ) : (
              <Author
                name={element?.users?.reduce(
                  (acc, user) => acc + parseInt(user.pmApprovedQuantity, 10),
                  0
                )}
              />
            ),

          equipmenttype: (
            <MDBox display="flex" justifyContent="center" alignItems="center">
              <img
                src={
                  element?.equipmentTypeImage?.url
                    ? element?.equipmentTypeImage?.url
                    : process.env.REACT_APP_IMAGE_NOT_FOUND
                }
                alt={element?.equipmentType}
                key={element?.equipmentTypeImage?.name}
                style={{
                  width: pxToRem(50),
                  height: pxToRem(50),
                  marginRight: pxToRem(10),
                  borderRadius: "8px",
                }}
              />
              <Author name={element?.type} />
            </MDBox>
          ),
          equipmentcategory: <Author name={element?.equipmentCategory?.name} />,
          price: <Author name={element?.price} />,
          amount: (
            <Author
              name={
                status === "pending"
                  ? (() => {
                      if (
                        userApproved &&
                        element.price &&
                        !Number.isNaN(Number(element?.totalRentalDays))
                      ) {
                        return userApproved * element.price * element.totalRentalDays;
                      }
                      return userApproved * element.price;
                    })()
                  : (() => {
                      const totalQuantity = (element?.users || []).reduce(
                        (acc, user) => acc + parseInt(user.pmApprovedQuantity || 0, 10),
                        0
                      );
                      const price = element?.price || 0;
                      if (price && !Number.isNaN(Number(element?.totalRentalDays))) {
                        return totalQuantity * element.price * element.totalRentalDays;
                      }
                      return totalQuantity * element.price;
                    })()
              }
            />
          ),
          comments: element?.users?.some((isc) => isc.engineerComment?.length > 0) && (
            <IconButton aria-label="fingerprint">{Icons.CORRECT_OUTLINE}</IconButton>
          ),
          action: (
            <MDBox display="flex">
              {status === "pending" && permission?.update && (
                <IconButton
                  aria-label="fingerprint"
                  color="info"
                  disabled={buttonLoading}
                  onClick={() => addToQueueEquipmnt(element[Constants.MONGOOSE_ID], element)}
                >
                  {Icons.QUEUE_EQUIPMENT}
                </IconButton>
              )}
              {permission?.update && (
                <IconButton
                  aria-label="fingerprint"
                  color="info"
                  onClick={() => {
                    const commentsToOpen =
                      status === "queue"
                        ? element?.pmOrderManageDetails?.comments?.pmComments
                        : approverComments;
                    openApproverCommentModal(
                      "approver",
                      commentsToOpen,
                      element[Constants.MONGOOSE_ID],
                      element?.pmOrderManageDetails[Constants.MONGOOSE_ID],
                      status
                    );
                  }}
                >
                  {Icons.ADD_COMMENT}
                </IconButton>
              )}
              {status !== "requested" && permission?.update ? (
                <IconButton
                  aria-label="fingerprint"
                  color="error"
                  onClick={() => openConfirmationBox(element[Constants.MONGOOSE_ID], "rejected")}
                >
                  {Icons.REJECTED}
                </IconButton>
              ) : null}
            </MDBox>
          ),
        };
        return temp;
      });
      setEquipmentTypeRows(tempRows);
    }
  }, [equipmentList, list, equipmentId, buttonLoading]);

  const equipmentTypeColumns = [
    { Header: "", accessor: "collapseBtn", width: "5%" },
    { Header: "No.", accessor: "srNo", width: "5%" },
    { Header: "Equipment Type", accessor: "equipmenttype", align: "left" },
    { Header: "Equipment Category", accessor: "equipmentcategory", align: "left" },
    { Header: "Total Req Qty", accessor: "reqQty", align: "left" },
    ...(status !== "rejected"
      ? [{ Header: "Total Approved Qty", accessor: "totalApproved", align: "left" }]
      : []),
    { Header: "Price Per Equipment Type", accessor: "price", align: "left" },
    ...(status !== "rejected"
      ? [{ Header: "Comments", accessor: "comments", align: "left", width: "5%" }]
      : []),
  ];
  if (status !== "rejected" && status !== "requested" && status !== "" && permission?.update) {
    equipmentTypeColumns.push({
      Header: "Action",
      accessor: "action",
      width: "10%",
      align: "center",
    });
  }

  const tableData = {
    equipmentTypeColumns,
    equipmentTypeRows,
  };

  return tableData;
}
