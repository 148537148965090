import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import { IconButton, Switch } from "@mui/material";
import Constants, { Icons } from "utils/Constants";
import { Link } from "react-router-dom";
import Author from "components/Table/Author";

export default function SetupReportData(
  { reportTypeLoading, reportType },
  handleEdit,
  handleDelete
) {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (reportTypeLoading === Constants.FULFILLED) {
      const list = reportType.map((item, index) => {
        const tempType = item?.type.replaceAll("_", " ");
        const temp = {
          srNo: (
            <MDTypography variant="caption" color="text">
              {index + 1}
            </MDTypography>
          ),
          title: <Author name={item?.title} style={{ textTransform: "normal" }} />,
          project: <Author name={item?.project?.title} />,
          type: <Author name={tempType.charAt(0).toUpperCase() + tempType.slice(1)} />,
          isProgresable: <Switch disabled checked={item.isProgressable} />,
          publish: <Switch disabled checked={item.isPublish} />,
          action: (
            <MDBox>
              <IconButton
                aria-label="report-questions"
                color="error"
                component={Link}
                to={`/client/setting/setup-report/${item[Constants.MONGOOSE_ID]}/questions`}
              >
                {Icons.VIEW}
              </IconButton>
              <IconButton
                aria-label="edit setup report"
                color="error"
                onClick={() => handleEdit(item)}
              >
                {Icons.EDIT}
              </IconButton>
              <IconButton
                aria-label="delete setup report"
                color="error"
                onClick={() => handleDelete(item[Constants.MONGOOSE_ID])}
              >
                {Icons.DELETE}
              </IconButton>
            </MDBox>
          ),
        };
        return temp;
      });
      setRows([...list]);
    }
  }, [reportTypeLoading, reportType]);

  return {
    columns: [
      { Header: "No.", accessor: "srNo", width: "5%", align: "left" },
      { Header: "Title", accessor: "title", width: "20%", align: "left" },
      { Header: "Project", accessor: "project", width: "20%", align: "left" },
      { Header: "Types", accessor: "type", width: "40%", align: "left" },
      { Header: "Isprogressable", accessor: "isProgresable", width: "10%", align: "center" },
      { Header: "Publish", accessor: "publish", width: "10%", align: "center" },
      { Header: "Action", accessor: "action", width: "10%", align: "center" },
    ],
    rows,
  };
}
