// Custom Components
import MDBox from "components/MDBox";

// Functions
import pxToRem from "assets/theme/functions/pxToRem";

// 3rd party libraries
import PropTypes from "prop-types";

export default function CustomImage({ item, index, requestedQuantity, remainingLength }) {
  // return image image when index is less than 5
  // return image with the number of more images in number when index is 5 or more
  if (index !== 4) {
    return (
      <MDBox display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <>
          <img
            src={!item || item.length === 0 ? process.env.REACT_APP_IMAGE_NOT_FOUND : item}
            alt={item}
            style={{
              width: pxToRem(50),
              height: pxToRem(50),
              marginRight: pxToRem(10),
              borderRadius: "8px",
            }}
          />
          {requestedQuantity !== 0 && (
            <MDBox
              component="span"
              sx={{
                fontSize: pxToRem(12),
                fontWeight: 700,
              }}
            >
              {requestedQuantity}
            </MDBox>
          )}
        </>
      </MDBox>
    );
  }
  return (
    <MDBox display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start">
      <MDBox display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <>
          <img
            src={!item || item.length === 0 ? process.env.REACT_APP_IMAGE_NOT_FOUND : item}
            alt={item}
            style={{
              width: pxToRem(50),
              height: pxToRem(50),
              marginRight: pxToRem(10),
              borderRadius: "8px",
            }}
          />
          <MDBox
            component="span"
            sx={{
              fontSize: pxToRem(12),
              fontWeight: 700,
            }}
          >
            {requestedQuantity}
          </MDBox>
        </>
      </MDBox>
      <MDBox component="span" fontSize={pxToRem(12)} color="text.disabled">
        {remainingLength} more
      </MDBox>
    </MDBox>
  );
}

CustomImage.propTypes = {
  item: PropTypes.string,
  index: PropTypes.number,
  requestedQuantity: PropTypes.number,
  remainingLength: PropTypes.number,
};

CustomImage.defaultProps = {
  item: "",
  index: 0,
  requestedQuantity: 0,
  remainingLength: 0,
};
