import { createSlice } from "@reduxjs/toolkit";
import { resetStateThunk } from "redux/Thunks/Authentication";
import getReturnEquipment, { returnCartList } from "redux/Thunks/ReturnEquipment";
import Constants from "utils/Constants";

const initialState = {
  loading: Constants.IDLE,
  list: [],
  cartLoading: Constants.IDLE,
  cartList: [],
};

export const returnEquipmentSlice = createSlice({
  name: "returnEquipmentSlice",
  initialState,
  reducers: {
    reloadData(state) {
      state.loading = Constants.PENDING;
      state.cartLoading = Constants.PENDING;
    },

    updateCheckBox(state, action) {
      const { name, target, returnIndex } = action.payload;
      const stateData = JSON.parse(JSON.stringify(state));
      const tempRoleCheckBox = stateData.list.map((item, index) => {
        if (returnIndex === index) {
          return {
            ...item,
            check: name === "check" ? target : item.check,
          };
        }
        // Check All & it should check when equipmentPriceType is rental and
        // returnStatus is null or undefined or ""
        if (
          name === "checkAllRows" &&
          item.equipmentPriceType !== "buy" &&
          (item.returnStatus === null ||
            item.returnStatus === undefined ||
            item.returnStatus === "" ||
            item?.returnStatus === "partially-returned")
        ) {
          return {
            ...item,
            check: target,
          };
        }
        return item;
      });
      return {
        ...state,
        list: tempRoleCheckBox,
      };
    },

    updateQuantity(state, action) {
      const { name, target, returnIndex, quantity } = action.payload;
      const stateData = JSON.parse(JSON.stringify(state));
      const tempQuantity = stateData.cartList?.map((item, index) => {
        const dispatchQuantity =
          item?.pmDispatchQuantity !== null
            ? Number(item?.pmReceivedQuantity, 10) - Number(item?.pmDispatchQuantity, 10)
            : item?.pmReceivedQuantity;
        if (returnIndex === index && name === "increment") {
          return {
            ...item,
            returnQty: quantity + 1,
          };
        }
        if (returnIndex === index && name === "decrement") {
          return {
            ...item,
            returnQty: quantity - 1,
          };
        }
        if (returnIndex === index && name === "updateReturnQty") {
          return {
            ...item,
            returnQty: target,
          };
        }
        if (returnIndex === index && name === "validateReturnQty") {
          return {
            ...item,
            returnQty:
              (target < 1 && 1) || (target > dispatchQuantity && dispatchQuantity) || target,
          };
        }

        return item;
      });
      return {
        ...state,
        cartList: tempQuantity,
      };
    },
  },

  extraReducers: {
    [getReturnEquipment.pending]: (state) => {
      if (state.list.length === 0) state.loading = Constants.PENDING;
    },
    [getReturnEquipment.fulfilled]: (state, { payload }) => {
      state.loading = Constants.FULFILLED;
      const returnDetail = payload.data.data;
      const modifiedReturnData = returnDetail?.map((item) => ({
        ...item,
        check: false,
      }));
      if (payload.type === "add") state.list = modifiedReturnData ?? [];
      else state.list.push(...(modifiedReturnData ?? []));
    },
    [getReturnEquipment.rejected]: (state) => {
      state.loading = Constants.REJECTED;
    },

    [returnCartList.pending]: (state) => {
      if (state.cartList.length === 0) state.cartLoading = Constants.PENDING;
    },
    [returnCartList.fulfilled]: (state, { payload }) => {
      state.cartLoading = Constants.FULFILLED;
      const returnCartDetail = payload.data.data;
      const modifiedReturnCartData = returnCartDetail[0]?.returnEquipmentData?.map((item) => ({
        ...item,
        return: returnCartDetail[0][Constants.MONGOOSE_ID],
        returnQty:
          item?.pmDispatchQuantity !== null
            ? Number(item?.pmReceivedQuantity, 10) - Number(item?.pmDispatchQuantity, 10)
            : item?.pmReceivedQuantity,
      }));
      state.cartList = modifiedReturnCartData ?? [];
    },
    [returnCartList.rejected]: (state) => {
      state.cartLoading = Constants.REJECTED;
    },
    [resetStateThunk.fulfilled]: (state) => {
      state.loading = Constants.IDLE;
      state.list = [];
      state.cartList = [];
      state.cartLoading = Constants.IDLE;
    },
  },
});

export const { reloadData, updateCheckBox, updateQuantity } = returnEquipmentSlice.actions;
export default returnEquipmentSlice.reducer;
