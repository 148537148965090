/* eslint-disable react/function-component-definition */

import React, { useEffect, useState } from "react";

// 3rd party library
import { Feature } from "flagged";

// MUI Components
import { Card, Divider } from "@mui/material";
import pxToRem from "assets/theme/functions/pxToRem";

// Custom Componentsu
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PageTitle from "examples/NewDesign/PageTitle";
import BasicButton from "examples/NewDesign/CustomButton/BasicButton";
import MDBox from "components/MDBox";
import ProjectTrackerTable from "examples/Tables/DataTable/projectTrackerTable";
import FilterDropdown from "components/Dropdown/FilterDropdown";
import LocationCalculation from "examples/Drawers/Report/locationCalculation/index";
import MDTypography from "components/MDTypography";
import ResetFilterButton from "components/Buttons/ResetButton";

// Redux
import { useDispatch } from "react-redux";
import { projectListThunk } from "redux/Thunks/Filter";
import getProjectTrackerData from "redux/Thunks/ProjectTracker";

// Constants
import Constants, { PageTitles, Icons, Colors, FeatureTags } from "utils/Constants";
import reportCalculationData from "./data/reportCalculationData";

const ReportCalculation = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [locationData, setLocationData] = useState({
    id: "",
    name: "",
  });
  const [filters, setFilters] = useState([
    {
      inputLabel: "Project",
      list: [{ [Constants.MONGOOSE_ID]: "select", title: "Select" }],
      selectedValue: "select",
    },
  ]);
  const [viewLocationProgressAnchor, setViewLocationProgressAnchor] = useState({ right: false });
  const [isscopeReportDataExist, setIsscopeReportDataExist] = useState(false);
  const [reportHeaderFooterData, setReportHeaderFooterData] = useState(null);
  const [reportRowsData, setReportRowsData] = useState(null);
  const [totalCompletion, setTotalCompletion] = useState(0);

  const handleLocationClick = (locId, locName) => {
    setViewLocationProgressAnchor({ right: true });
    setLocationData({
      id: locId,
      name: locName,
    });
  };

  const fetchFilters = async () => {
    const projectList = await dispatch(projectListThunk());
    if (projectList.payload.status) {
      const temp = [...filters];

      temp[0].list = [
        { [Constants.MONGOOSE_ID]: "select", title: "Select" },
        ...projectList.payload.data,
      ];

      setFilters(temp);
    }
  };

  const fetchProjectReportProgress = async (id) => {
    const res = await dispatch(getProjectTrackerData(id));
    if (res.payload.status === 200) {
      const projectTrackerData = res?.payload?.data?.data?.projectTrackerData;
      const scopeData = res?.payload?.data?.data?.scopeData;
      const totalCompletions = res?.payload?.data?.data?.totalCompletions || 0;
      setReportHeaderFooterData(scopeData);
      setReportRowsData(projectTrackerData);
      setTotalCompletion(totalCompletions.toFixed(2));

      if (
        scopeData?.length === 0 ||
        (scopeData?.length === 1 && scopeData[0]?.reports?.length === 0)
      ) {
        setIsscopeReportDataExist(false);
      } else {
        setIsscopeReportDataExist(true);
      }
    }
  };

  // Effect to load data on component mount
  useEffect(() => {
    (async () => {
      setLoading(true);
      await fetchFilters();
      setLoading(false);
    })();
  }, []);

  const handleReload = async () => {
    setLoading(true);
    await fetchProjectReportProgress(filters[0].selectedValue);
    setLoading(false);
  };

  const handleFilterChange = async (e) => {
    setLoading(true);
    const temp = [...filters];
    const { value } = e.target;
    await fetchProjectReportProgress(value);
    temp[0].selectedValue = value;
    setFilters(temp);
    setLoading(false);
  };

  const handleResetFilter = () => {
    const temp = filters.map((filter) => ({
      ...filter,
      selectedValue: filter.list[0][Constants.MONGOOSE_ID] || filter.list[0],
    }));
    setFilters(temp);
  };

  const { columns, rows, extraHeadersList, footerList } = reportCalculationData(
    reportHeaderFooterData,
    reportRowsData,
    totalCompletion,
    handleLocationClick
  );
  const renderContent = () => {
    if (loading) {
      return (
        <MDBox
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {Icons.LOADING2}
        </MDBox>
      );
    }
    if (filters[0]?.selectedValue === "select" || !isscopeReportDataExist) {
      return (
        <Card sx={{ height: "60vh", marginTop: 3 }}>
          <MDBox display="flex" justifyContent="center" alignItems="center" height="100%">
            <MDTypography sx={{ fontSize: "25px", fontWeight: "semibold", opacity: "0.8" }}>
              {filters[0]?.selectedValue === "select"
                ? "Please Select Project"
                : "Nothing to track for selected project"}
            </MDTypography>
          </MDBox>
        </Card>
      );
    }
    return (
      <MDBox
        sx={{
          paddingTop: pxToRem(24),
          paddingBottom: pxToRem(32),
        }}
      >
        <ProjectTrackerTable
          table={{ columns, rows }}
          isSorted={false}
          entriesPerPage={{ defaultValue: 10 }}
          showTotalEntries={false}
          pagination={{ variant: "gradient", color: "info" }}
          loading={Constants.FULFILLED}
          extraHeaders={extraHeadersList}
          footerList={footerList}
        />
      </MDBox>
    );
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox display="flex" justifyContent="space-between">
          <PageTitle title={PageTitles.REPORT_CALCULATION} />
          <BasicButton
            icon={Icons.RELOAD}
            background={Colors.WHITE}
            border
            color={Colors.BLACK}
            action={handleReload}
            disabled={filters[0]?.selectedValue === "select"}
          />
        </MDBox>
        <Divider sx={{ marginTop: 2 }} />
        <Feature name={FeatureTags.PROJECT_TRACKER}>
          <>
            <MDBox display="flex" justifyContent="space-between" mt={1} mx={0} width="100%">
              <MDBox display="flex" flexWrap="wrap" mt={0} mx={0} width="100%">
                {filters?.map((val) => (
                  <FilterDropdown
                    key={val.inputLabel}
                    label={val.inputLabel}
                    name={val.inputLabel}
                    defaultValue={val?.selectedValue}
                    value={val?.selectedValue}
                    handleChange={handleFilterChange}
                    menu={val.list}
                  />
                ))}
                <ResetFilterButton handleReset={handleResetFilter} style={{ marginLeft: "1rem" }} />
              </MDBox>
              <MDBox
                sx={{
                  display: "flex",
                  alignItems: "end",
                  flexWrap: "wrap",
                }}
              >
                <BasicButton
                  title="Export"
                  icon={Icons.EXPORT}
                  background={Colors.WHITE}
                  border
                  color={Colors.BLACK}
                  disabled
                  tooltipLabel={Constants.COMMING_SOON}
                />
              </MDBox>
            </MDBox>
            {renderContent()}
          </>
        </Feature>
      </DashboardLayout>
      {viewLocationProgressAnchor.right && (
        <LocationCalculation
          viewLocationProgressAnchor={viewLocationProgressAnchor}
          setViewLocationProgressAnchor={setViewLocationProgressAnchor}
          locationData={locationData}
          setLocationData={setLocationData}
        />
      )}
    </>
  );
};

export default ReportCalculation;
