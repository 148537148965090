import React, { forwardRef, useEffect, useState } from "react";

// Custom Components
import CustomDrawer from "components/Drawer/CustomDrawer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import FullScreenImageComponent from "components/ViewFullImage/ViewImage";

// MUI Components
import {
  Autocomplete,
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Switch,
  TextField,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// Constants
import Constants, { Icons, ButtonTitles, defaultData } from "utils/Constants";

// Functions
import pxToRem from "assets/theme/functions/pxToRem";

// Redux
import { useDispatch, useSelector } from "react-redux";
import configThunk from "redux/Thunks/Config";
import { createSafetyCardThunk } from "redux/Thunks/SafetyCard";
import uploadImageThunk from "redux/Thunks/ImageUpload";
import { openSnackbar } from "redux/Slice/Notification";

// Assets
import FormControlErrorStyles from "assets/style/Component";

// 3rd party library
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import PropTypes from "prop-types";

const CustomDateTimeInput = forwardRef(({ value, onClick, item, errors }, ref) => (
  <MDInput
    error={Boolean(errors[item.id])}
    helperText={errors[item.id]}
    FormHelperTextProps={{
      sx: { marginLeft: 1, color: "red" },
    }}
    InputLabelProps={{
      shrink: true,
    }}
    placeholder={item.hint}
    value={value}
    sx={{ ml: 0, width: "100%", mt: 1 }}
    onClick={onClick}
    ref={ref}
  />
));

CustomDateTimeInput.propTypes = {
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default function SafetyCard({
  qhseAnchor,
  bgColor,
  screenIndex,
  closeDrawer,
  cardType,
  handleReset,
}) {
  const [form, setForm] = useState({});
  const [fullScreenImage, setFullScreenImage] = useState(null);
  const [fullScreenImageIndex, setFullScreenImageIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [errors, setErrors] = useState({});
  const [image, setImage] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [safetyCardBody, setSafetyCardBody] = useState({});
  const requiredList = [
    "title",
    "project",
    "location",
    "category",
    "description",
    "subject",
    "type",
    "time",
    "severity",
    "likelihood",
    "savingAction",
    "savingRule",
    "actionsTaken",
    "statusUpdate",
    "status",
    "images",
    "item",
    "correctiveAction",
    "preventiveAction",
    "estimatedDelayCost",
    "defaultProject",
  ];
  const ConfigData = useSelector((state) => state.config);
  const dispatch = useDispatch();

  useEffect(() => {
    setForm(ConfigData.screens?.[screenIndex].screensInfo);
  }, [ConfigData]);
  const validate = () => {
    const newErrors = {};

    if (Object.keys(form).length > 0) {
      form.properties.forEach((item) => {
        if (item.IsRequired && !item.questionId && !safetyCardBody[item.id]) {
          newErrors[item.id] = `This field is required`;
        } else if (
          item.IsRequired &&
          item.questionId &&
          !safetyCardBody?.dynamicFields?.filter((val) => val?.title.replace(/\s/g, "") === item.id)
            .length > 0
        ) {
          newErrors[item.id] = `This field is required`;
        }
        if (item.IsRequired && !item.questionId && safetyCardBody[item.id]?.trim() === "") {
          newErrors[item.id] = "Empty Space is not allowed";
        }
        if (item.IsRequired && item.questionId) {
          const index = safetyCardBody?.dynamicFields?.findIndex(
            (val) => val?.title.replace(/\s/g, "") === item.id
          );
          if (
            typeof safetyCardBody?.dynamicFields?.[index]?.value[0] === "string" &&
            safetyCardBody?.dynamicFields?.[index]?.value[0]?.trim() === ""
          ) {
            newErrors[item.id] = "Empty Space is not allowed";
          }
        }
      });
    }

    setErrors(newErrors);
    return Object.values(newErrors).filter((val) => val !== "").length === 0;
  };

  const updateFormField = (idArray = []) => {
    if (idArray.length > 0) {
      const temp = JSON.parse(JSON.stringify(form));
      temp.properties.forEach((item, i) => {
        const index = idArray.findIndex((val) => val === item?.id);
        if (index !== -1) {
          temp.properties[i].isDefaultVisible = true;
          temp.properties[i].IsRequired = true;
        }
      });
      setForm(temp);
    } else {
      setForm(ConfigData.screens?.[screenIndex].screensInfo);
    }
  };

  const handleChange = (name, value, id) => {
    const temp = safetyCardBody;
    if (!requiredList.includes(name)) {
      const index = temp.dynamicFields.findIndex((val) => val.fieldId === id);
      if (index >= 0 && value !== "") {
        temp.dynamicFields[index].value = [value];
      } else if (index >= 0 && value === "") {
        temp.dynamicFields.splice(index, 1);
      } else {
        const dynamicFieldValueObject = {
          title: name,
          value: [value],
          fieldId: id,
        };
        temp.dynamicFields.push(dynamicFieldValueObject);
      }
    } else if (name in temp && value === "") {
      delete temp[name];
    } else {
      temp[name] = value;
    }

    form?.properties.forEach((item) => {
      // remove child field when parent field is changed
      if (item?.parentFieldId === name) {
        delete temp?.[item?.id];
      }
    });

    setSafetyCardBody({ ...temp });
  };

  const handleImageChange = (name, value, id) => {
    const temp = safetyCardBody;
    const index = temp.dynamicFields.findIndex((val) => val.fieldId === id);
    if (!requiredList.includes(name)) {
      if (index >= 0 && value.length > 0) {
        temp.dynamicFields[index].value = value;
      } else if (index >= 0 && value.length === 0) {
        temp.dynamicFields.splice(index, 1);
      } else {
        const dynamicFieldValueObject = {
          title: name,
          value,
          fieldId: id,
        };
        temp.dynamicFields.push(dynamicFieldValueObject);
      }
    } else if (name in temp && value.length === 0) {
      delete temp[name];
    } else {
      temp[name] = value;
    }
    setSafetyCardBody({ ...temp });
  };

  const handleBoolean = (name, id, value) => {
    const temp = safetyCardBody;
    const index = temp.dynamicFields.findIndex((val) => val.fieldId === id);
    if (index >= 0) {
      temp.dynamicFields[index].value = [value.toString()];
      setSafetyCardBody({ ...temp });
    } else {
      const dynamicFieldValueObject = {
        title: name,
        value: [value.toString()],
        fieldId: id,
      };
      temp.dynamicFields.push(dynamicFieldValueObject);
      setSafetyCardBody({ ...temp });
    }
    temp[name] = value;
    setSafetyCardBody({ ...temp });
  };

  const handleCheckbox = (name, id, value, checked) => {
    const temp = { ...safetyCardBody };
    const index = temp.dynamicFields.findIndex((val) => val.fieldId === id);
    if (index >= 0) {
      if (checked) {
        temp.dynamicFields[index].value.push(value.toString());
      } else {
        temp.dynamicFields[index].value = temp.dynamicFields[index].value.filter(
          (val) => val !== value.toString()
        );
      }
    } else {
      const dynamicFieldValueObject = {
        title: name,
        value: checked ? [value.toString()] : [],
        fieldId: id,
      };
      temp.dynamicFields.push(dynamicFieldValueObject);
    }
    if (index >= 0 && temp.dynamicFields[index].value.length === 0) {
      temp.dynamicFields.splice(index, 1);
    }
    setSafetyCardBody(temp);
  };

  useEffect(() => {
    if (qhseAnchor) {
      const temp = {};
      temp.cardType = `${cardType.toLowerCase()}`;
      temp.dynamicFields = [];
      setSafetyCardBody({ ...temp });
    } else {
      setImage([]);
    }
  }, [qhseAnchor]);
  const createUnsafecard = async () => {
    setLoading(true);
    const val = validate();
    if (val) {
      const tempBody = { ...safetyCardBody };
      Object.entries(tempBody).forEach(([key]) => {
        if (typeof tempBody[key] === "string") {
          tempBody[key] = tempBody[key].trim();
        }
      });
      setDisableSubmit(true);
      const res = await dispatch(createSafetyCardThunk(tempBody));
      setDisableSubmit(false);
      if (res.error === undefined) {
        closeDrawer(false);
        setImageUrls([]);
        setImage([]);
        handleReset();
        dispatch(
          openSnackbar({
            message: Constants.SAFETY_CARD_CREATE_SUCCESS,
            notificationType: Constants.NOTIFICATION_SUCCESS,
          })
        );
        await dispatch(configThunk());
      }
    }
    setLoading(false);
  };
  const handleCancelClick = (index, name, id) => {
    const newImageArray = [...image];
    newImageArray.splice(index, 1);
    setImage(newImageArray);

    const newImageUrlArray = [...imageUrls];
    newImageUrlArray.splice(index, 1);
    setImageUrls(newImageUrlArray);
    handleImageChange(name, newImageUrlArray, id);
  };
  const handleFileChange = async (name, e, id) => {
    const eventCopy = { ...e };
    const uploadCount = imageUrls.filter((url) => url.name.startsWith(Constants.Image_W));
    let newCount = 0;
    if (uploadCount.length > 0) {
      newCount = parseInt(uploadCount[uploadCount.length - 1].name.split("w")[1], 10);
    }
    const allowedFormats = ["image/jpeg", "image/jpg", "image/png"];
    if (imageUrls.length + e.target.files.length <= 6) {
      const newImages = [];
      for (let i = 0; i < e.target.files.length; i += 1) {
        const file = e.target.files[i];
        if (!allowedFormats.includes(file.type)) {
          dispatch(
            openSnackbar({
              message: "Only JPG, JPEG, and PNG formats are allowed.",
              notificationType: "error",
            })
          );
          return;
        }
        const preview = URL.createObjectURL(file);
        newImages.push({ file, preview });
      }

      const type = "Qhse_Safety_Card";
      const uploadPromises = newImages.map((img) =>
        dispatch(uploadImageThunk({ file: img.file, type }))
      );
      const responses = await Promise.all(uploadPromises);
      const newImageUrlArray = responses.map((response, index) => ({
        name: `Image w${newCount + index + 1}`,
        url: response.payload.data.iconUrl,
      }));
      setImageUrls([...imageUrls, ...newImageUrlArray]);
      handleImageChange(name, [...imageUrls, ...newImageUrlArray], id);
      setImage([...image, ...newImages]);
    } else {
      dispatch(
        openSnackbar({ message: "You can only upload up to 6 images.", notificationType: "error" })
      );
    }
    eventCopy.target.value = null;
  };
  const dropdownIcon = () => <KeyboardArrowDownIcon fontSize="medium" sx={{ color: "#667085" }} />;
  const handleImage = (id) => {
    document.getElementById(id).click();
  };

  const handleImageFullView = (imageUrl, i) => {
    setFullScreenImageIndex(i);
    setFullScreenImage(imageUrl);
  };
  const handleCloseFullView = () => {
    setFullScreenImage(null);
  };
  const handleNextImage = () => {
    setFullScreenImageIndex((prevIndex) => (prevIndex + 1) % image.length);
  };

  const handlePreviousImage = () => {
    setFullScreenImageIndex((prevIndex) => (prevIndex - 1 + image.length) % image.length);
  };

  return (
    <>
      <CustomDrawer defaultAnchor={qhseAnchor} width="55%">
        <MDBox
          sx={{ backgroundColor: bgColor, padding: pxToRem(15) }}
          px={pxToRem(24)}
          display="flex"
          justifyContent="start"
          alignItems="center"
          height={pxToRem(74)}
        >
          <Icon
            sx={{ cursor: "pointer", color: "beige", marginRight: "15px" }}
            fontSize="medium"
            onClick={closeDrawer}
          >
            {Icons.CROSS}
          </Icon>
          <MDTypography
            sx={{
              color: "var(--white-900, #ffffff)",
              fontFamily: "Inter",
              fontSize: pxToRem(20),
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: pxToRem(28),
              textAlign: "center",
            }}
          >
            {`${cardType} Card`}
          </MDTypography>
        </MDBox>
        <Divider />
        <MDBox
          px={5}
          py={2}
          sx={{
            overflowY: "scroll",
            "::-webkit-scrollbar": {
              width: "5px",
            },
            "::-webkit-scrollbar-thumb": {
              background: "gray",
            },
            scrollbarWidth: "thin",
            scrollbarColor: "gray transparent",
          }}
        >
          {Object.keys(form).length > 0 &&
            form?.properties
              .slice()
              .filter((item) => item.isDefaultVisible)
              .sort((a, b) => a.fieldSortOrder - b.fieldSortOrder)
              .map((item, index) => (
                <MDBox mb={1} key={item?.id}>
                  <MDBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="start"
                    flexWrap="wrap"
                  >
                    <MDTypography
                      mt={2}
                      ml={0}
                      id="modal-modal-description"
                      alignItems="center"
                      sx={{
                        textTransform: "capitalize",
                        fontSize: pxToRem(14),
                        fontWeight: 500,
                        width: "30%",
                        color: "#344054",
                        whiteSpace: "normal",
                      }}
                    >
                      {item.IsRequired ? `${item.title}*` : item.title}
                    </MDTypography>
                    {item.type === "text" ? (
                      <MDInput
                        sx={{
                          width: "65%",
                          mt: 1,
                          mr: 2,
                          "& input": {
                            fontSize: "16px",
                            color: "#667085",
                          },
                        }}
                        size="small"
                        variant="outlined"
                        placeholder={`Please Enter ${item.title}`}
                        id={item.id}
                        name={item.id}
                        error={Boolean(errors[item.id])}
                        helperText={errors[item.id]}
                        value={
                          safetyCardBody?.[item.id] ||
                          safetyCardBody?.dynamicFields.filter(
                            (val) => val?.fieldId === item?.questionId
                          )[0]?.value ||
                          ""
                        }
                        FormHelperTextProps={{
                          sx: { marginLeft: 0, marginTop: 1, color: "red" },
                        }}
                        onChange={(e) =>
                          handleChange(
                            item.id,
                            e.target.value,
                            item.questionId ? item.questionId : item.id
                          )
                        }
                      />
                    ) : null}

                    {item.type === "options" && item.parentFieldId === "" ? (
                      <FormControl
                        sx={{
                          mr: 2,
                          mt: 1,
                          width: "65%",
                          ...FormControlErrorStyles,
                        }}
                        error={Boolean(errors[item.id])}
                      >
                        <InputLabel id={item.id}>{item.hint}</InputLabel>
                        <Select
                          width="90%"
                          onChange={(e) => {
                            if (item.hasChildField) {
                              const val = item.options.findIndex(
                                (opt) => opt.id === e.target.value
                              );
                              if (val !== -1) {
                                updateFormField(item?.options[val]?.dependentFieldIds);
                              }
                            }
                            handleChange(
                              item.id,
                              e.target.value,
                              item.questionId ? item.questionId : item.id
                            );
                          }}
                          IconComponent={dropdownIcon}
                          labelId={item.id}
                          label={item.hint}
                          id={item.questionId ? item.questionId : item.id}
                          name={item.id}
                          sx={{
                            color: "#667085",
                            backgroundColor: "black",
                            paddingY: "0.5rem",
                            paddingX: "0.5rem",
                            fontSize: pxToRem(16),
                            fontWeight: 400,
                          }}
                          renderValue={(selected) => {
                            const val = item.options.filter(
                              (opt) => opt.id === selected || opt.id === selected[0]
                            )[0];
                            return (
                              <MDBox sx={{ display: "flex", alignItems: "center" }}>
                                {val?.color && val?.color !== "" ? (
                                  <Box
                                    component="span"
                                    sx={{
                                      backgroundColor: `#${val?.color}`,
                                      borderRadius: "50%",
                                      width: "10px",
                                      height: "10px",
                                      mr: 1,
                                    }}
                                  />
                                ) : null}
                                <MDTypography
                                  variant="caption"
                                  sx={{ textTransform: "capitalize" }}
                                >
                                  {val.title}
                                </MDTypography>
                              </MDBox>
                            );
                          }}
                          value={
                            safetyCardBody[item.id] ||
                            safetyCardBody?.dynamicFields.filter(
                              (val) => val?.fieldId === item?.questionId
                            )[0]?.value ||
                            ""
                          }
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 34,
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            PaperProps: {
                              style: {
                                maxHeight: 200,
                                // maxWidth,
                                opacity: 1,
                                transform: "none",
                                border: "1px solid #D0D5DD",
                              },
                            },
                          }}
                        >
                          <MenuItem disabled value="">
                            {item.hint}
                          </MenuItem>
                          {item.options.map((val) => (
                            <MenuItem
                              value={val?.id}
                              id={val?.id}
                              sx={{ display: "flex" }}
                              key={val?.id}
                            >
                              {val?.color && val?.color !== "" ? (
                                <Box
                                  component="span"
                                  sx={{
                                    backgroundColor: `#${val?.color}`,
                                    borderRadius: "50%",
                                    width: "10px",
                                    height: "10px",
                                    mr: 1,
                                  }}
                                />
                              ) : null}
                              <MDTypography
                                id={val?.id}
                                variant="caption"
                                sx={{
                                  textTransform: "capitalize",
                                  fontSize: pxToRem(16),
                                  fontWeight: 400,
                                  color: "#667085",
                                  display: "flex",
                                }}
                              >
                                {val?.title}
                              </MDTypography>
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText sx={{ marginLeft: 0 }}>{errors[item.id]}</FormHelperText>
                      </FormControl>
                    ) : null}

                    {(item.type === "options" || item.type === "autocomplete") &&
                      item.parentFieldId !== "" &&
                      (() => {
                        const parentField = form?.properties.find(
                          (val) => val.id === item.parentFieldId
                        );
                        const type = parentField?.options.find(
                          (element) => element.id === safetyCardBody[item.parentFieldId]
                        )?.isDefault;

                        const selectedValue = item.options.find(
                          (val) => val.id === safetyCardBody[item.id]
                        );
                        const currentValue =
                          typeof selectedValue === "object"
                            ? selectedValue?.title || selectedValue
                            : selectedValue || "";
                        const options =
                          (type
                            ? item.options
                                .filter((val) => val?.isDefault)
                                .map((val) => val?.title || "")
                            : item.options
                                .filter((val) =>
                                  val.isVisibleForOptions.includes(
                                    safetyCardBody[item.parentFieldId]
                                  )
                                )
                                .map((val) => val?.title || "")) || [];

                        return (
                          <Autocomplete
                            onChange={(e, value) => {
                              const selectecteValue = item.options.filter(
                                (val) => val?.title === value
                              );
                              handleChange(
                                item.id,
                                selectecteValue.length > 0 ? selectecteValue[0]?.id : value,
                                item.questionId || item.id
                              );
                            }}
                            freeSolo={type}
                            value={currentValue}
                            options={options}
                            sx={{
                              width: "65%",
                              mt: 1,
                              mr: 2,
                              "& .MuiAutocomplete-inputRoot": {
                                padding: "4px",
                              },
                            }}
                            popupIcon={
                              <KeyboardArrowDownIcon fontSize="medium" sx={{ color: "#667085" }} />
                            }
                            renderInput={(params) => (
                              <MDInput
                                {...params}
                                label={item.hint}
                                error={Boolean(errors[item.id])}
                                helperText={errors[item.id]}
                                FormHelperTextProps={{
                                  sx: { marginLeft: 0, marginTop: 1, color: "red" },
                                }}
                                onChange={(e) =>
                                  handleChange(item.id, e.target.value, item.questionId || item.id)
                                }
                                sx={{
                                  ...FormControlErrorStyles,
                                }}
                              />
                            )}
                            PaperProps={{
                              style: {
                                maxHeight: 200,
                              },
                            }}
                          />
                        );
                      })()}

                    {item.type === "boolean" ? (
                      <MDBox
                        sx={{ width: "65%", mt: 1, mr: 3.2 }}
                        display="flex"
                        justifyContent="start"
                        flexDirection="column"
                      >
                        <Switch
                          color="warning"
                          sx={{
                            "&.Mui-checked": {
                              color: "#fff",
                              backgroundColor: "#FF8E53",
                            },
                          }}
                          onChange={(e) =>
                            handleBoolean(item.title, item.questionId, e.target.checked)
                          }
                          error={Boolean(errors[item.id])}
                        />
                        <FormHelperText sx={{ marginLeft: 0, color: "#FF2E2E" }}>
                          {errors[item.id]}
                        </FormHelperText>
                      </MDBox>
                    ) : null}
                    {item.type === "checkbox" ? (
                      <MDBox sx={{ width: "65%", mr: 2 }} display="flex" justifyContent="start">
                        <FormGroup error={Boolean(errors[item.id])}>
                          {item.options.map((val) => (
                            <FormControlLabel
                              key={val.id}
                              control={
                                <Checkbox
                                  onChange={(e) =>
                                    handleCheckbox(
                                      item.id,
                                      item.questionId,
                                      val.title,
                                      e.target.checked
                                    )
                                  }
                                />
                              }
                              label={val.title}
                            />
                          ))}
                          <FormHelperText sx={{ marginLeft: 0, color: "#FF2E2E" }}>
                            {errors[item.id]}
                          </FormHelperText>
                        </FormGroup>
                      </MDBox>
                    ) : null}

                    {item.type === "datetime" ? (
                      <MDBox
                        sx={{ width: "65%", mr: 2, zIndex: 1000 - index }}
                        display="flex"
                        justifyContent="start"
                      >
                        <ReactDatePicker
                          selected={
                            (safetyCardBody?.[item.id] &&
                              moment(safetyCardBody?.[item.id]?.split(".")[0]).toDate()) ||
                            (safetyCardBody?.dynamicFields?.filter(
                              (val) => val?.fieldId === item?.questionId
                            ).length > 0 &&
                              moment(
                                safetyCardBody?.dynamicFields?.filter(
                                  (val) => val?.fieldId === item?.questionId
                                )?.[0]?.value[0]
                              ).toDate()) ||
                            ""
                          }
                          onChange={(date) =>
                            handleChange(
                              item.id,
                              moment(date).format(defaultData.DATABASE_24_HOURS_FORMAT).toString(),
                              item.questionId ? item.questionId : item.id
                            )
                          }
                          customInput={<CustomDateTimeInput item={item} errors={errors} />}
                          showTimeSelect
                          dateFormat={defaultData.REACTDATETIMEPICKER_24_HOURS_FORMAT}
                          timeIntervals={5}
                          timeFormat="HH:mm"
                        />
                      </MDBox>
                    ) : null}
                    {item.type === "number" ? (
                      <MDBox
                        sx={{ width: "65%", mr: 2, mt: 1 }}
                        display="flex"
                        justifyContent="start"
                      >
                        <MDInput
                          sx={{
                            width: "100%",
                          }}
                          type="number"
                          placeholder={`Please Enter ${item.title.toUpperCase()}`}
                          id={item.id}
                          name={item.id}
                          error={Boolean(errors[item.id])}
                          helperText={errors[item.id]}
                          value={safetyCardBody[item.id]}
                          FormHelperTextProps={{
                            sx: { marginLeft: 0 },
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) =>
                            handleChange(
                              item.id,
                              e.target.value,
                              item.questionId ? item.questionId : item.id
                            )
                          }
                        />
                      </MDBox>
                    ) : null}
                    {item.type === "slider" ? (
                      <MDBox
                        sx={{ width: "64%", mr: 2, mt: 1 }}
                        display="flex"
                        justifyContent="start"
                        flexDirection="column"
                      >
                        <Slider
                          id={item.id}
                          name={item.id}
                          aria-label="Default"
                          valueLabelDisplay="auto"
                          min={item?.range?.min}
                          max={item?.range?.max}
                          step={2}
                          onChange={(e) =>
                            handleChange(
                              item.id,
                              e.target.value,
                              item.questionId ? item.questionId : item.id
                            )
                          }
                        />
                        <FormHelperText sx={{ marginLeft: 0, color: "#FF2E2E" }}>
                          {errors[item.id]}
                        </FormHelperText>
                      </MDBox>
                    ) : null}
                    {item.type === "date" ? (
                      <MDBox
                        sx={{ width: "65%", mr: 2, zIndex: 1000 - index }}
                        display="flex"
                        justifyContent="start"
                      >
                        <ReactDatePicker
                          selected={
                            (safetyCardBody?.[item.id] &&
                              moment(safetyCardBody?.[item.id]).toDate()) ||
                            (safetyCardBody?.dynamicFields?.filter(
                              (val) => val?.fieldId === item?.questionId
                            ).length > 0 &&
                              moment(
                                safetyCardBody?.dynamicFields?.filter(
                                  (val) => val?.fieldId === item?.questionId
                                )?.[0]?.value[0]
                              ).toDate()) ||
                            ""
                          }
                          onChange={(date) =>
                            handleChange(
                              item.id,
                              moment(date).format(defaultData.DATABSE_DATE_FORMAT).toString(),
                              item.questionId ? item.questionId : item.id
                            )
                          }
                          customInput={<CustomDateTimeInput item={item} errors={errors} />}
                          dateFormat={defaultData.REACTDATETIMEPICKER_DATE_FORMAT}
                          timeIntervals={5}
                        />
                      </MDBox>
                    ) : null}
                  </MDBox>
                  {item.type === "textarea" ? (
                    <MDBox
                      style={{
                        width: "100%",
                        paddingRight: pxToRem(16),
                      }}
                    >
                      <MDInput
                        sx={{
                          mt: 0,
                          width: "100%",
                          ml: 0,

                          "& .MuiInputBase-input": {
                            fontSize: "16px",
                            fontWeight: 400,
                            color: "#667085",
                          },
                        }}
                        multiline
                        autoComplete="off"
                        inputProps={{
                          style: { flex: "1", minHeight: "4em" },
                        }}
                        id={item.id}
                        name={item.id}
                        error={Boolean(errors[item.id])}
                        helperText={errors[item.id]}
                        FormHelperTextProps={{
                          sx: { marginLeft: 0 },
                        }}
                        value={safetyCardBody[item.id]}
                        placeholder={`Please Enter ${item.title}`}
                        onChange={(e) =>
                          handleChange(
                            item.id,
                            e.target.value.substring(0, 3000),
                            item.questionId ? item.questionId : item.id
                          )
                        }
                      />
                      <MDTypography
                        sx={{
                          color: "#667085",
                          fontSize: "10px",
                          display: "flex",
                          justifyContent: "flex-end",
                          marginRight: "15px",
                        }}
                      >
                        {safetyCardBody?.[item.id]
                          ? `${safetyCardBody?.[item.id].length}/3000`
                          : `0/3000`}
                      </MDTypography>
                    </MDBox>
                  ) : null}
                  {item.type === "images" ? (
                    <MDBox
                      display="flex"
                      flexDirection="row"
                      justifyContent="flex-start"
                      my={1}
                      mr={2}
                      ml={0}
                      sx={{ width: 600 }}
                    >
                      {imageUrls.map((img, i) => (
                        <MDBox
                          key={img.name}
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          mr={2}
                        >
                          <MDBox
                            display="flex"
                            borderRadius="8px"
                            position="relative"
                            sx={{ "&:hover .overlay": { display: "flex", borderRadius: "8px" } }}
                          >
                            <img
                              src={img.url}
                              alt="Preview"
                              width={70}
                              height={70}
                              style={{ borderRadius: "8px" }}
                            />

                            <Box
                              display="none"
                              position="absolute"
                              top={0}
                              right={0}
                              bottom={0}
                              left={0}
                              alignItems="center"
                              justifyContent="center"
                              bgcolor="rgba(0, 0, 0, 0.5)"
                              className="overlay"
                            >
                              <Icon
                                sx={{ color: "white", width: 30, height: 30, cursor: "pointer" }}
                                onClick={() => handleImageFullView(img?.preview || img, i)}
                              >
                                {Icons.VIEW2}
                              </Icon>
                            </Box>
                            <Box
                              display="none"
                              position="absolute"
                              top={0}
                              right={0}
                              bottom={60}
                              left={70}
                              alignItems="center"
                              justifyContent="center"
                              bgcolor="rgba(0, 0, 0, 0.5)"
                              className="overlay"
                            >
                              <Icon
                                sx={{ color: "white", width: 30, height: 30, cursor: "pointer" }}
                                onClick={() =>
                                  handleCancelClick(
                                    i,
                                    item.id,
                                    item.questionId ? item.questionId : item.id
                                  )
                                }
                              >
                                {Icons.CROSS2}
                              </Icon>
                            </Box>
                          </MDBox>
                          <MDTypography variant="h6">{img?.name}</MDTypography>
                        </MDBox>
                      ))}
                      <MDBox
                        width={pxToRem(70)}
                        height={70}
                        borderRadius="lg"
                        sx={{
                          border: "2px dashed #D0D5DD",
                          borderRadius: "8px",
                          cursor: "pointer",
                          justifyContent: "center",
                          alignItems: "center",
                          display: image.length < 6 ? "flex" : "none",
                        }}
                        onClick={() => handleImage(item.id)}
                      >
                        {Icons.ADD}
                      </MDBox>
                      <TextField
                        id={item.id}
                        sx={{
                          display: "none",
                        }}
                        size="small"
                        variant="outlined"
                        placeholder="Please Enter"
                        type="file"
                        inputProps={{
                          multiple: true,
                          accept: "image/*",
                        }}
                        onChange={(e) =>
                          handleFileChange(item.id, e, item.questionId ? item.questionId : item.id)
                        }
                      />
                    </MDBox>
                  ) : null}
                </MDBox>
              ))}
        </MDBox>
        <MDBox px={1} mb={2} mt={1} mr={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <MDButton
            variant="contained"
            disabled={disableSubmit}
            color={
              (cardType === "Safe" && "success") ||
              (cardType === "Unsafe" && "unsafe") ||
              ((cardType === "Ncr" || cardType === "Incident") && "info")
            }
            onClick={createUnsafecard}
            style={{ textTransform: "none", boxShadow: "none" }}
          >
            {loading ? ButtonTitles.SUBMIT_LOADING : ButtonTitles.SUBMIT}
          </MDButton>
        </MDBox>
      </CustomDrawer>
      <FullScreenImageComponent
        fullScreenImage={fullScreenImage}
        handleCloseFullView={handleCloseFullView}
        handlePreviousImage={handlePreviousImage}
        handleNextImage={handleNextImage}
        image={image}
        src={image[fullScreenImageIndex]?.preview}
      />
    </>
  );
}

SafetyCard.propTypes = {
  qhseAnchor: PropTypes.objectOf(PropTypes.any).isRequired,
  bgColor: PropTypes.string.isRequired,
  screenIndex: PropTypes.number.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  cardType: PropTypes.string.isRequired,
  handleReset: PropTypes.func.isRequired,
};
