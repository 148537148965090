import React, { useState, useEffect } from "react";

// 3rd party library
import PropTypes from "prop-types";

// MUI Components
import Drawer from "@mui/material/Drawer";

// Custom Components
import MDBox from "components/MDBox";

function CustomDrawer({ defaultAnchor, children, width, onDrawerClose }) {
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  useEffect(() => {
    setState({ ...state, ...defaultAnchor });
  }, [defaultAnchor]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    if (!open && onDrawerClose) {
      onDrawerClose(); // Trigger function when drawer is closed
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <MDBox>
      {["left", "right", "top", "bottom"].map((anchor) => (
        <Drawer
          key={anchor}
          anchor={anchor}
          open={state[anchor]}
          onClose={toggleDrawer(anchor, false)}
          PaperProps={{
            style: {
              width,
              height: "100%",
              margin: 0,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            },
          }}
        >
          {children}
        </Drawer>
      ))}
    </MDBox>
  );
}

CustomDrawer.defaultProps = {
  width: "70%",
  onDrawerClose: null,
};

CustomDrawer.propTypes = {
  defaultAnchor: PropTypes.objectOf(PropTypes.object).isRequired,
  children: PropTypes.node.isRequired,
  width: PropTypes.string,
  onDrawerClose: PropTypes.func,
};

export default CustomDrawer;
