// Custom Components
import MDBox from "components/MDBox";

// 3rd party
import PropTypes from "prop-types";

export default function Status2({ color, bgColor, title }) {
  return (
    <MDBox
      color={color}
      bgColor={bgColor}
      variant="contained"
      borderRadius="lg"
      opacity={1}
      p={1}
      width="90px"
      height="24px"
      textAlign="right"
      display="flex"
      alignItems="center"
      justifyContent="space-around"
    >
      {title}
    </MDBox>
  );
}

Status2.propTypes = {
  color: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
