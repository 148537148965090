/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

import React, { useEffect, useState } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import { IconButton } from "@mui/material";
import Constants, { Icons, defaultData } from "utils/Constants";

// Components
import Author from "components/Table/Author";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox";
import pxToRem from "assets/theme/functions/pxToRem";

// Routing
import { Link } from "react-router-dom";
import moment from "moment";

export default function ProductData(
  productList,
  handleImageFullView,
  handleDeleteOpen,
  handleOpenEquipmentDetailDrawer,
  permission,
  stockShow,
  handleChange,
  stockData
) {
  const [rows, setRows] = useState([]);

  const filteredList = stockShow
    ? productList?.filter(
        (item) => item?.equipmentType?.quantityType?.[0]?.quantityType !== "unique"
      )
    : productList;

  useEffect(() => {
    if (filteredList && permission?.read) {
      const tempRows = filteredList.map((element, index) => {
        let certificateExpiryDate = null;
        const validCertificates = element?.certificateType?.filter(
          (certificate) => certificate.endDate !== null
        );
        const certificateExpiry = validCertificates?.map(
          (certificate) => new Date(certificate.endDate)
        );
        const currentDate = new Date();
        const filteredDates =
          certificateExpiry?.length > 0
            ? certificateExpiry?.filter(
                (expiryDate) => expiryDate.getTime() !== currentDate.getTime()
              )
            : [];
        filteredDates?.sort((a, b) => a - b);
        if (filteredDates?.length > 0) {
          certificateExpiryDate = filteredDates[0].toISOString();
        }
        const temp = {
          stockCheck: (
            <CustomCheckbox
              name="check"
              checked={stockData?.stockData?.some(
                (item) => item[Constants.MONGOOSE_ID] === element[Constants.MONGOOSE_ID]
              )}
              onChange={(e) => handleChange(element, e.target.checked)}
            />
          ),
          srNo: <Author name={index + 1} />,
          equipmentname: (
            <MDBox display="flex" justifyContent="center" alignItems="center">
              <img
                src={
                  element?.equipmentImage?.[0]?.url
                    ? element?.equipmentImage?.[0]?.url
                    : process.env.REACT_APP_IMAGE_NOT_FOUND
                }
                alt={element?.equipmentImage}
                key={element?.equipmentImage?.[0]?.name}
                style={{
                  width: pxToRem(50),
                  height: pxToRem(50),
                  marginRight: pxToRem(10),
                  borderRadius: "8px",
                }}
              />
              <Author name={element?.name} maxContent={40} />
            </MDBox>
          ),
          productNumber: (
            <Author
              name={
                element?.equipmentNumber && element?.equipmentNumber !== null
                  ? element?.equipmentNumber
                  : Constants.NA
              }
            />
          ),
          serialNumber: (
            <Author name={element?.serialNumber ? element?.serialNumber : Constants.NA} />
          ),
          qrcode: (
            <Author
              name={
                element?.qrCode && element.qrCode.length > 0
                  ? element.qrCode[element.qrCode.length - 1]?.code
                  : Constants.NA
              }
              style={{ textTransform: "inherit" }}
            />
          ),
          equipmenttype: <Author name={element?.equipmentType?.type} />,
          equipmentcategory: (
            <Author
              name={
                element?.equipmentType?.equipmentCategory[0]?.name ||
                element?.equipmentType?.equipmentCategory?.name
              }
            />
          ),
          currentLocation: (
            <Author name={`${element?.equipmentCurrentLocation?.replace(/_/g, " ")}`} />
          ),
          availableStocks: <Author name={element?.quantity} />,
          certificateExpiry: (
            <Author
              name={
                certificateExpiryDate !== null
                  ? moment(certificateExpiryDate).format(defaultData.WEB_DATE_FORMAT)
                  : ""
              }
            />
          ),
          missing: (() => {
            if (
              (element?.certificateType === null || element?.certificateType?.length === 0) &&
              (element?.qrCode?.length === 0 || element?.qrCode === null)
            ) {
              return (
                <MDBox>
                  <IconButton aria-label="fingerprint" color="info">
                    {Icons.CERTIFICATE_MISSING}
                  </IconButton>
                  <IconButton aria-label="fingerprint" color="info">
                    {Icons.QR_MISSING}
                  </IconButton>
                </MDBox>
              );
            }

            if (element?.certificateType === null || element?.certificateType?.length === 0) {
              return <Author icon={Icons.CERTIFICATE_MISSING} />;
            }

            if (element?.qrCode?.length === 0) {
              return <Author icon={Icons.QR_MISSING} />;
            }

            return <Author name="" />;
          })(),
          action: (
            <MDBox>
              <IconButton
                aria-label="fingerprint"
                color="info"
                onClick={() => handleOpenEquipmentDetailDrawer(element?.[Constants.MONGOOSE_ID])}
              >
                {Icons.VIEW}
              </IconButton>
              {permission?.update && (
                <IconButton
                  aria-label="fingerprint"
                  color="error"
                  component={Link}
                  to={`/client/update-equipment/${element?.[Constants.MONGOOSE_ID]}`}
                >
                  {Icons.EDIT}
                </IconButton>
              )}
              {permission?.delete && (
                <IconButton
                  aria-label="fingerprint"
                  color="error"
                  onClick={() => handleDeleteOpen(element?.[Constants.MONGOOSE_ID])}
                >
                  {Icons.DELETE}
                </IconButton>
              )}
            </MDBox>
          ),
        };
        return temp;
      });
      setRows([...tempRows]);
    }
  }, [productList, permission, stockShow, stockData]);

  const columns = [
    ...(stockShow ? [{ Header: "", accessor: "stockCheck", align: "left" }] : []),
    { Header: "No.", accessor: "srNo", width: "3%" },
    { Header: "Equipment Category", accessor: "equipmentcategory", align: "left" },
    { Header: "Equipment Type", accessor: "equipmenttype", align: "left" },
    { Header: "Product Number", accessor: "productNumber", width: "10%", align: "left" },
    {
      Header: "Equipment Name",
      accessor: "equipmentname",
      width: "5%",
      align: "left",
    },
    { Header: "Serial Number", accessor: "serialNumber", align: "left" },
    { Header: "Location", accessor: "currentLocation", align: "left" },
    { Header: "Available Stocks", accessor: "availableStocks", align: "center" },
    { Header: "Certificate Expiry", accessor: "certificateExpiry", align: "left" },
    {
      Header: "Missing",
      accessor: "missing",
      align: "center",
    },
    { Header: "Action", accessor: "action", align: "center" },
  ];

  const tableData = {
    columns,
    rows,
  };
  return tableData;
}
