// color constants
const Colors = {
  PRIMARY: "#191A51",
  PRIMARY1: "191A51",
  SECONDARY: "#FFC107",
  ERROR: "#9D0202",
  ERROR2: "#FF0000",
  SUCCESS: "#029E3B",
  INFO: "#2196F3",
  WARNING: "#FF9800",
  WHITE: "#FFFFFF",
  BLACK: "#000000",
  GREY: "#9E9E9E",
  STATUS_Background: "#F5F5F5",
  STATUS_COLOR: "#FFC107",
  LIGHT_YELLOW: "#f7f2e5",
  DARK_YELLOW: "#B68300",
  LIGHT_ORANGE: "#ffefe5",
  DARK_ORANGE: "#ff6600",
  LIGHT_RED: "#9d02021a",
  DARK_RED: "#9D0202",
  LIGHT_GREEN: "#2a8c001a",
  DARK_GREEN: "#2A8C00",
  LIGHT_GRAY: "#E8E8EE",
  LIGHT_GRAY2: "#D9D9D9",
  COLLAPSE_BTN: "#FF6600",
  BLACK2: "#101828",
  LIGHT_GRAY3: "#49586D",
  LIGHT_GRAY4: "#E8ECF4",
  LIGHT_GRAY5: "#E4E6E9",
  DARK_GREY: "#475467",
  LIGHT_ORANGE2: "#FEC299",
  LIGHT_BROWN: "#E2B3B4",
  DARK_BROWN: "#9E0003",
  LIGHT_GREEN2: "#CDECD8",
  DARK_GREEN2: "#049E3D",
  LIGHT_YELLOW2: "#EADAB3",
  LIGHT_BLUE: "#BABBCB",
  DARK_BLUE: "#191A51",
  DISABLED: "#f0f2f5",
  GREY1: "#e2e8f0",
  PINK: "#FFCCCC",
  LIGHT_PINK: "#FBE4D5",
  LIGHT_ORANGE1: "#FEF2CB",
  LIGHT_GREEN3: "#E2EFD9",
  DARK_RED2: "#F04438",
  DARK_ORANGE2: "#E46A11",
};

export default Colors;
