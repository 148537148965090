import React, { useEffect } from "react";

// Material Components
import { Icon, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { useTheme } from "@emotion/react";

// Common Components
import MDTypography from "components/MDTypography";
import FilterButton from "components/Buttons/FilterButton";
import pxToRem from "assets/theme/functions/pxToRem";
import MDBox from "components/MDBox";

// Utils
import { Icons, Colors, ButtonTitles } from "utils/Constants";

// 3rd Party
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

export default function NotificationDialog({ open, onClose }) {
  const theme = useTheme();
  const handleCloseDialog = () => {
    onClose();
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (open && event.target.closest(".notification-dialog") === null) {
        handleCloseDialog(); // Close the dialog
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [open]);
  const notificationData = [
    {
      id: 1,
      user: "Haul Taylor",
      time: "1 day ago",
      title: "GOW - Fire Safety Certificate Updated",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus finibu vestibulum hendrerit. Nulla est diam, efficitur eu ullamcorper quis, ultrices nec nisl",
    },
    {
      id: 2,
      user: "Peter Parker",
      time: "2 day ago",
      title: "GOW - Fire Safety Certificate Updated",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus finibu vestibulum hendrerit. Nulla est diam, efficitur eu ullamcorper quis, ultrices nec nisl",
    },
    {
      id: 3,
      user: "Haul Taylor",
      time: "1 day ago",
      title: "GOW - Passport Updated",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus finibu vestibulum hendrerit. Nulla est diam, efficitur eu ullamcorper quis, ultrices nec nisl",
    },
  ];
  return (
    open && (
      <MDBox
        className="notification-dialog"
        onClose={onClose}
        sx={{
          position: "absolute",
          right: 0,
          top: "100%",
          width: pxToRem(432),
          backgroundColor: Colors.WHITE,
          padding: pxToRem(16),
          boxShadow: "4px 14px 34px 0px rgba(0, 0, 0, 0.15)",
          [theme.breakpoints.up("md")]: {
            width: pxToRem(432),
            top: "100%",
          },
          [theme.breakpoints.only("xs")]: {
            width: "100%",
            top: "100%",
          },
        }}
      >
        <MDBox>
          <MDTypography
            sx={{
              color: Colors.BLACK2,
              fontSize: pxToRem(20),
              fontWeight: "600",
              borderBottom: "1px solid #E0E2E7",
            }}
          >
            Notification
          </MDTypography>
        </MDBox>
        <List>
          {notificationData?.length > 0 ? (
            notificationData.map((data) => (
              <ListItem disablePadding key={data.id}>
                <ListItemButton>
                  <ListItemText>
                    <MDBox display="flex" flexDirection="column">
                      <MDBox display="flex" flexDirection={{ xs: "column", md: "row" }}>
                        <MDBox display="flex" flexDirection="row">
                          <Icon>{Icons.PERSON}</Icon>
                          <MDTypography
                            sx={{
                              color: Colors.BLACK2,
                              fontSize: pxToRem(14),
                              fontWeight: "400",
                              marginLeft: pxToRem(8),
                            }}
                          >
                            From {data?.user}
                          </MDTypography>
                        </MDBox>
                        <MDBox display="flex" flexDirection="row" mt={{ xs: "5px", md: "0" }}>
                          <Icon sx={{ marginLeft: { xs: pxToRem(0), md: pxToRem(8) } }}>
                            {Icons.TIME}
                          </Icon>
                          <MDTypography
                            sx={{
                              color: Colors.BLACK2,
                              fontSize: pxToRem(14),
                              fontWeight: "400",
                              marginLeft: pxToRem(8),
                            }}
                          >
                            {data?.time}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                      <MDTypography
                        sx={{
                          color: Colors.BLACK2,
                          fontSize: pxToRem(16),
                          fontWeight: "700",
                          marginTop: pxToRem(5),
                        }}
                      >
                        {data?.title}
                      </MDTypography>
                      <MDBox>
                        <MDTypography
                          sx={{
                            color: Colors.BLACK2,
                            fontSize: pxToRem(14),
                            fontWeight: "400",
                            marginTop: pxToRem(4),
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "2",
                            WebkitBoxOrient: "vertical",
                            lineClamp: "2",
                          }}
                        >
                          {data?.description}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            ))
          ) : (
            <MDBox padding={pxToRem(16)} display="flex" justifyContent="center" alignItems="center">
              <MDTypography
                sx={{
                  color: Colors.BLACK2,
                  fontSize: pxToRem(16),
                  fontWeight: "600",
                }}
              >
                No new notification
              </MDTypography>
            </MDBox>
          )}
        </List>
        <MDBox
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          sx={{ borderTop: "1px solid #E0E2E7" }}
        >
          {notificationData?.length > 0 && (
            <FilterButton
              title={ButtonTitles.MARK_ALL_AS_READ}
              variant="none"
              color={Colors.PRIMARY}
              icon={Icons.ACCEPT3}
              mt={pxToRem(4)}
            />
          )}
          <MDBox display="flex" flexDirection="row" marginTop="8px">
            <MDTypography
              component={Link}
              to="/client/setting/system-notification"
              sx={{
                color: Colors.PRIMARY,
                fontSize: pxToRem(14),
                fontWeight: "600",
                marginRight: pxToRem(8),
              }}
            >
              See More
            </MDTypography>
            <Icon>{Icons.FORWARD_ARROW}</Icon>
          </MDBox>
        </MDBox>
      </MDBox>
    )
  );
}

NotificationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
