import { createSlice } from "@reduxjs/toolkit";
import { resetStateThunk } from "redux/Thunks/Authentication";
import { getAllSafetyThunk } from "redux/Thunks/SafetyCard";
import Constants from "utils/Constants";

const initialState = {
  loading: Constants.PENDING,
  list: [],
  screens: [],
};

export const safetyCardSlice = createSlice({
  name: "safetycard",
  initialState,
  reducers: {
    updateList(state, action) {
      state.list = action.payload;
      state.loading = Constants.FULFILLED;
    },
    loadSafetyCardData(state, action) {
      state.list.push(...action.payload);
    },
    updateSafetyCardData(state, action) {
      const index = state.list.findIndex(
        (item) => item?.[Constants.MONGOOSE_ID] === action.payload[Constants.MONGOOSE_ID]
      );
      state.list[index] = action.payload;
    },
    removeSafetyCard(state, action) {
      const index = state.list.findIndex(
        (item) => item?.[Constants.MONGOOSE_ID] === action.payload
      );
      state.list.splice(index, 1);
    },
    reloadData(state) {
      state.loading = Constants.PENDING;
    },
  },

  extraReducers: {
    [getAllSafetyThunk.pending]: (state) => {
      if (state.list.length === 0) state.loading = Constants.PENDING;
    },
    [getAllSafetyThunk.fulfilled]: (state, action) => {
      state.loading = Constants.FULFILLED;
      state.list = action.payload.data;
    },
    [getAllSafetyThunk.rejected]: (state) => {
      state.loading = Constants.REJECTED;
    },
    [resetStateThunk.fulfilled]: (state) => {
      state.loading = Constants.IDLE;
      state.list = [];
      state.screens = [];
    },
  },
});

export const {
  updateList,
  loadSafetyCardData,
  updateSafetyCardData,
  reloadData,
  removeSafetyCard,
} = safetyCardSlice.actions;

export default safetyCardSlice.reducer;
