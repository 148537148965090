import React, { useEffect, useState } from "react";

// Components
import Author from "components/Table/Author";

// Utils
import Constants, { defaultData } from "utils/Constants";

export default function ApproverOrderDetailsData(approverOrderList) {
  const [approverOrderProjectrows, setApproverOrderProjectRows] = useState([]);
  useEffect(() => {
    if (approverOrderList) {
      const tempRows = approverOrderList?.map((element, index) => {
        const temp = {
          collapseBtn: (
            <Author
              name={{
                approverProjectId: element[Constants.MONGOOSE_ID],
                approverProjectdata: element?.orderData,
              }}
            />
          ),
          srNo: <Author name={index + 1} />,
          project: (
            <Author name={element?.projectName} maxContent={defaultData.LARGE_CONTENT_LENGTH} />
          ),
          totalOrders: <Author name={element?.orderData?.length} />,
        };
        return temp;
      });
      setApproverOrderProjectRows([...tempRows]);
    }
  }, [approverOrderList]);

  return {
    approverProjectColumns: [
      { Header: "", accessor: "collapseBtn", width: "5%" },
      { Header: "No.", accessor: "srNo", width: "5%" },
      { Header: "Project", accessor: "project" },

      { Header: "Total Orders", accessor: "totalOrders", align: "center" },
    ],
    approverOrderProjectrows,
  };
}
