import { useState } from "react";

// MUI Components
import {
  Box,
  Chip,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// Custom Components
import FormControlErrorStyles from "assets/style/Component";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

// Functions
import pxToRem from "assets/theme/functions/pxToRem";

// Constants
import Constants, { Colors } from "utils/Constants";

// 3rd party
import PropTypes from "prop-types";

export default function MultiSelectDropdown({
  label,
  id,
  name,
  values, // Change value to values for multi-select
  hint,
  handleChange,
  menu,
  error,
  helperText,
  marginBottom,
  disabled = false,
}) {
  const [open, setOpen] = useState(false);
  const dropdownIcon = () => <KeyboardArrowDownIcon fontSize="medium" sx={{ color: "#667085" }} />;

  return (
    <FormControl
      sx={{
        mr: 2,
        ml: 0,
        mt: pxToRem(8),
        width: "100%",
        marginBottom,
        maxHeight: 400,
        ...FormControlErrorStyles,
      }}
      error={Boolean(error)}
      formhelpertextprops={{
        sx: { marginLeft: 0 },
      }}
    >
      <MDTypography
        variant="caption"
        mb={1}
        sx={{ fontSize: pxToRem(14), fontWeight: 500, color: "#344054" }}
      >
        {label}
      </MDTypography>
      <MDBox
        sx={{
          display: "flex",
          alignItems: "center",
          position: "relative",
          backgroundColor: disabled ? Colors.DISABLED : "transparent",
        }}
      >
        <Select
          onChange={(e) => {
            handleChange(name, e.target.value, id);
          }}
          multiple
          IconComponent={dropdownIcon}
          labelId={id}
          id={id}
          name={name}
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          placeholder={hint}
          sx={{
            color: "#667085",
            backgroundColor: "black",
            paddingY: "0.5rem",
            paddingX: "0.5rem",
            fontSize: pxToRem(16),
            fontWeight: 400,
            width: "100%",
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 32,
              horizontal: -10,
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            PaperProps: {
              style: {
                width: pxToRem(405),
                maxHeight: 150,
                opacity: 1,
                transform: "none",
                border: "1px solid #D0D5DD",
              },
            },
          }}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {menu
                .filter((val) => selected.some((option) => option === val[Constants.MONGOOSE_ID]))
                .map((value) => {
                  if (value.title.length > 30) {
                    return (
                      <Tooltip key={value[Constants.MONGOOSE_ID]} title={value.title}>
                        <Chip
                          key={value[Constants.MONGOOSE_ID]}
                          label={`${value.title.slice(0, 30)}...`}
                        />
                      </Tooltip>
                    );
                  }
                  return <Chip key={value[Constants.MONGOOSE_ID]} label={value.title} />;
                })}
            </Box>
          )}
          value={values}
        >
          <MenuItem disabled value="">
            Select
          </MenuItem>
          {menu.length > 0 ? (
            menu.map((val) => (
              <MenuItem
                key={val[Constants.MONGOOSE_ID]}
                value={val[Constants.MONGOOSE_ID]}
                sx={{ p: 0 }}
              >
                <CustomCheckbox
                  name={val[Constants.MONGOOSE_ID]}
                  checked={values.includes(val[Constants.MONGOOSE_ID])}
                />
                <MDTypography
                  variant="subtitle1"
                  sx={{
                    ml: 1,
                    textTransform: "capitalize",
                    fontSize: pxToRem(16),
                    fontWeight: 400,
                    color: "#667085",
                  }}
                >
                  {val && val?.title?.length > 30 ? (
                    <Tooltip title={val.title}>
                      <span>{`${val.title.slice(0, 30)}...`}</span>
                    </Tooltip>
                  ) : (
                    val.title
                  )}
                </MDTypography>
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>No data available</MenuItem>
          )}
        </Select>
        <IconButton
          onClick={() => !disabled && setOpen(!open)}
          sx={{
            position: "absolute",
            top: "50%",
            right: 0,
            transform: "translateY(-50%)",
            zIndex: 1,
          }}
        >
          <KeyboardArrowDownIcon />
        </IconButton>
      </MDBox>
      <FormHelperText sx={{ marginLeft: 0 }}>{helperText}</FormHelperText>
    </FormControl>
  );
}

MultiSelectDropdown.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  hint: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  menu: PropTypes.arrayOf(PropTypes.object),
  values: PropTypes.arrayOf(PropTypes.string),
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  marginBottom: PropTypes.number,
};

MultiSelectDropdown.defaultProps = {
  id: "",
  name: "",
  label: "",
  hint: "",
  error: false,
  helperText: "",
  menu: [],
  values: [],
  disabled: false,
  marginBottom: 0,
};
