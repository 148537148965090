import React from "react";

// material-ui
import { Autocomplete, Chip, FormControl, TextField } from "@mui/material";
import MDTypography from "components/MDTypography";

// utils
import Constants, { Icons } from "utils/Constants";

// 3rd party
import PropTypes, { object } from "prop-types";
import pxToRem from "assets/theme/functions/pxToRem";
import FormControlErrorStyles from "assets/style/Component";

function MultiSelectAutoComplete({
  label,
  id,
  name,
  handleChange,
  handleRemove,
  menu,
  error,
  getOptionLabel,
  value,
  width,
  hint,
}) {
  return (
    <FormControl
      size="small"
      error={Boolean(error)}
      sx={{
        ml: 0,
        mt: pxToRem(16),
        minWidth: "100%",
        width,
        maxHeight: 400,
        ...FormControlErrorStyles,
      }}
    >
      <MDTypography
        variant="caption"
        sx={{ fontSize: pxToRem(14), fontWeight: 500, color: "#344054", mb: 1 }}
      >
        {label}
      </MDTypography>
      <Autocomplete
        multiple
        options={menu}
        getOptionLabel={getOptionLabel}
        name={name}
        id={id}
        variant="standard"
        value={value}
        sx={{
          "& .MuiAutocomplete-inputRoot": {
            padding: "6px",
          },
        }}
        popupIcon={Icons.ARROW_DOWN}
        onChange={(e) => {
          const { innerHTML } = e.target;
          const selectedElement = menu.find((el) => el.title === innerHTML);
          // check if element is already selected
          if (
            value.filter(
              (item) => item[Constants.MONGOOSE_ID] === selectedElement[Constants.MONGOOSE_ID]
            ).length > 0
          )
            return;

          if (selectedElement) handleChange({ target: { name, value: selectedElement } });
        }}
        renderTags={(v, getTagProps) =>
          v.map((option, index) => (
            <Chip
              key={option[Constants.MONGOOSE_ID]}
              label={option.title}
              color="warning"
              size="small"
              {...getTagProps({ index })}
              onDelete={() => handleRemove(name, index)}
            />
          ))
        }
        renderInput={(params) => <TextField {...params} placeholder={hint} />}
      />
    </FormControl>
  );
}

MultiSelectAutoComplete.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  hint: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
  handleRemove: PropTypes.func,
  menu: PropTypes.arrayOf(PropTypes.object),
  error: PropTypes.bool,
  helperText: PropTypes.string,
  getOptionLabel: PropTypes.func.isRequired,
  value: PropTypes.string || PropTypes.objectOf(object),
  width: PropTypes.string,
};

MultiSelectAutoComplete.defaultProps = {
  handleChange: () => {},
  handleRemove: () => {},
  menu: [],
  error: false,
  helperText: "",
  value: "",
  width: "auto",
};

export default MultiSelectAutoComplete;
