/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

import { useEffect, useState } from "react";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox";
import Author from "components/Table/Author";
import { useSelector } from "react-redux";

export default function RoleData({ handleChange }) {
  const [rows, setRows] = useState([]);
  const roleAggrementData = useSelector((state) => state.roleAgreement?.list);

  useEffect(() => {
    if (roleAggrementData) {
      const tempRows = roleAggrementData?.map((element, index) => {
        const temp = {
          srNo: <Author name={index + 1} />,
          module: <Author name={element?.accountLicence?.permission[0].name} />,
          read: (
            <CustomCheckbox
              name="read"
              checked={element?.agreement?.read}
              onChange={(e) => handleChange("read", e, element)}
            />
          ),
          write: (
            <CustomCheckbox
              name="create"
              checked={element?.agreement?.create}
              onChange={(e) => handleChange("create", e, element)}
            />
          ),
          update: (
            <CustomCheckbox
              name="update"
              checked={element?.agreement?.update}
              onChange={(e) => handleChange("update", e, element)}
            />
          ),
          delete: (
            <CustomCheckbox
              name="delete"
              checked={element?.agreement?.delete}
              onChange={(e) => handleChange("delete", e, element)}
            />
          ),
          action: (
            <CustomCheckbox
              name="checkAllRowBox"
              checked={
                element?.agreement?.read &&
                element?.agreement?.create &&
                element?.agreement?.update &&
                element?.agreement?.delete
              }
              onChange={(e) => handleChange("checkAllRowBox", e, element)}
            />
          ),
        };
        return temp;
      });
      setRows([...tempRows]);
    }
  }, [roleAggrementData]);

  return {
    columns: [
      { Header: "No.", accessor: "srNo", width: "3%", align: "center" },
      { Header: "Module", accessor: "module", width: "30%", align: "left" },
      {
        Header: "Read",
        accessor: "read",
        align: "center",
      },
      {
        Header: "Write",
        accessor: "write",
        align: "center",
      },
      {
        Header: "Update",
        accessor: "update",
        align: "center",
      },
      {
        Header: "Delete",
        accessor: "delete",
        align: "center",
      },
      {
        Header: "Check All",
        accessor: "action",
        align: "center",
      },
    ],
    rows,
  };
}
