import Sessions from "utils/Sessions";
import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiService from "redux/ApiService/ApiService";

const getProjectTrackerData = createAsyncThunk("projectTracker/api", async (params) => {
  const endPoint = `reports/project-tracker/${params}`;
  const res = await ApiService.get(endPoint, {
    headers: { Authorization: `Bearer ${Sessions.userToken}` },
  });
  return res;
});

export default getProjectTrackerData;
