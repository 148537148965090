import React from "react";

// 3rd party library
import PropTypes from "prop-types";

// Custom components
import MDTypography from "components/MDTypography";

// Styles
import PageTitle from "../style/styles";

function PageTitleComponent({ title }) {
  return (
    <MDTypography sx={(theme) => ({ color: "#101828", ...PageTitle(theme) })}>{title}</MDTypography>
  );
}

PageTitleComponent.propTypes = {
  title: PropTypes.string,
};

PageTitleComponent.defaultProps = {
  title: "",
};

export default PageTitleComponent;
