// Functions
import pxToRem from "assets/theme/functions/pxToRem";

// Custom Components
import MDBox from "components/MDBox";

// Constants
import { Colors } from "utils/Constants";

// 3rd party
import PropTypes from "prop-types";

export default function RiskFactor({ risk }) {
  return (
    <MDBox
      bgColor={
        (risk >= 12 && Colors.LIGHT_RED) ||
        (risk >= 6 && risk <= 12 && Colors.LIGHT_YELLOW) ||
        (risk <= 4 && Colors.LIGHT_GREEN)
      }
      color={
        (risk >= 12 && Colors.DARK_RED) ||
        (risk >= 6 && risk <= 12 && Colors.DARK_YELLOW) ||
        (risk <= 4 && Colors.DARK_GREEN)
      }
      variant="contained"
      borderRadius="16px"
      opacity={1}
      p={1}
      paddingX={pxToRem(16)}
      height="24px"
      textAlign="center"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {risk}
    </MDBox>
  );
}

RiskFactor.propTypes = {
  risk: PropTypes.number.isRequired,
};
