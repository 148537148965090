import React, { useEffect, useState } from "react";
// Material Dashboard 2 React components

// Components
import FilterButton from "components/Buttons/FilterButton";
import { Icons, Colors, ButtonTitles } from "utils/Constants";
import pxToRem from "assets/theme/functions/pxToRem";
import MDTypography from "components/MDTypography";
import { IconButton } from "@mui/material";
import MDBox from "components/MDBox";

const notificationData = [
  {
    id: 1,
    user: "Haul Taylor",
    time: "1 day ago",
    status: "unread",
    title: "GOW - Fire Safety Certificate Updated",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus finibu vestibulum hendrerit. Nulla est diam, efficitur eu ullamcorper quis, ultrices nec nisl",
  },
  {
    id: 2,
    user: "Peter Parker",
    time: "2 day ago",
    status: "read",
    title: "GOW - Fire Safety Certificate Updated",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus finibu vestibulum hendrerit. Nulla est diam, efficitur eu ullamcorper quis, ultrices nec nisl",
  },
  {
    id: 3,
    user: "Haul Taylor",
    time: "1 day ago",
    status: "read",
    title: "GOW - Passport Updated",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus finibu vestibulum hendrerit. Nulla est diam, efficitur eu ullamcorper quis, ultrices nec nisl",
  },
];

export default function OrderDetailsData() {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (notificationData) {
      const tempRows = Array.from(
        { length: 30 },
        (_, index) => notificationData[index % notificationData.length]
      ).map((element) => {
        const temp = {
          equipmentname: (
            <MDBox display="flex" flexDirection="column">
              <MDBox display="flex" flexDirection="row">
                <IconButton aria-label="fingerprint" sx={{ marginLeft: pxToRem(-8) }}>
                  {Icons.PERSON}
                </IconButton>
                <MDTypography
                  sx={{
                    color: "#101828",
                    fontSize: pxToRem(14),
                    fontWeight: "400",
                    marginLeft: pxToRem(2),
                    marginTop: pxToRem(8),
                  }}
                >
                  From {element?.user}
                </MDTypography>
                <IconButton sx={{ marginLeft: pxToRem(8) }}>{Icons.TIME}</IconButton>
                <MDTypography
                  sx={{
                    color: "#101828",
                    fontSize: pxToRem(14),
                    fontWeight: "400",
                    marginLeft: pxToRem(2),
                    marginTop: pxToRem(8),
                  }}
                >
                  {element?.time}
                </MDTypography>
              </MDBox>
              <MDTypography
                sx={{
                  color: "#101828",
                  fontSize: pxToRem(16),
                  fontWeight: element?.status === "read" ? "400" : "700",
                  marginTop: pxToRem(5),
                }}
              >
                {element?.title}
              </MDTypography>
              <MDBox>
                <MDTypography
                  sx={{
                    color: "#101828",
                    fontSize: pxToRem(14),
                    fontWeight: element?.status === "read" ? "400" : "700",
                    marginTop: pxToRem(5),
                  }}
                >
                  {element?.description.length > 130
                    ? `${element?.description.slice(0, 130)}...`
                    : element?.description}
                </MDTypography>
              </MDBox>
              {element?.status === "unread" && (
                <MDBox display="flex" justifyContent="flex-start">
                  <FilterButton
                    title={ButtonTitles.MARK_AS_READ}
                    variant="none"
                    color={Colors.PRIMARY}
                    icon={Icons.ACCEPT3}
                    mt={0}
                    ml={pxToRem(-18)}
                  />
                </MDBox>
              )}
            </MDBox>
          ),
        };
        return temp;
      });
      setRows([...tempRows]);
    }
  }, [notificationData]);
  return {
    columns: [
      {
        accessor: "equipmentname",
        width: "10%",
        align: "left",
      },
    ],
    rows,
  };
}
