import React from "react";

// 3rd party library
import PropTypes from "prop-types";

// Custom components
import MDTypography from "components/MDTypography";

// Styles
import { ModalTitle } from "../style/styles";

function ModalTitleComponent({ title, color, fontSize }) {
  return (
    <MDTypography
      sx={(theme) => ({ color: { color }, fontSize: { fontSize }, ...ModalTitle(theme) })}
    >
      {title}
    </MDTypography>
  );
}

ModalTitleComponent.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ModalTitleComponent.defaultProps = {
  title: "",
  color: "",
  fontSize: "16px",
};

export default ModalTitleComponent;
